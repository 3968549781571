import React from 'react';
import { Menu, Segment, Image } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import oneLineLogo from '../../oneLineLogo.png';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useSignOutContext } from '../Authenticator/ClearMindAuthenticator';
import BackButton from '../BackButton/BackButton';

const ClearMindHeader: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useClearMindContext();
  const { signOut } = useSignOutContext();

  const logout = () => {
    signOut();
    history.push('/');
  };

  const getHomePath = () => {
    return user.isMember ? '/member-dashboard' : '/supporter-dashboard';
  };

  const isOnboardingState =
    location.pathname.indexOf("onboarding") >= 0 ||
    location.pathname.indexOf("user-photo") >= 0 ||
    location.pathname.indexOf("language-select") >= 0 ||
    location.pathname.indexOf("demographics") >= 0 ||
    location.pathname.indexOf("welcome") >= 0;

  return (
    <>
      <Segment
        basic
        style={{
          display: "flex",
          height: "115px",
          marginBottom: 0,
          zIndex: 0,
        }}
      >
        <Menu
          secondary
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#rgba(0,0,0,.0)",
            height: "110px",
            padding: "0.78571429em, 0.92857143em, 0,0.92857143em ",
          }}
          size="large"
        >
          <BackButton />
          <Menu.Item style={{ width: "30%" }} position="left">
            <Image style={{ width: "230px" }} src={oneLineLogo} />
          </Menu.Item>
          {!isOnboardingState && (
            <>
              <Menu.Item
                as={Link}
                active={location.pathname === getHomePath()}
                to={getHomePath()}
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Home
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/supporters"
                active={location.pathname === "/supporters"}
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Supporters
              </Menu.Item>
              <Menu.Item
                as={Link}
                active={location.pathname === "/user-profile"}
                to="/user-profile"
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Profile
              </Menu.Item>
              <Menu.Item
                as="a"
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
                target="#"
                href="https://clearlee.com/about-us/"
              >
                About
              </Menu.Item>
              <Menu.Item
                as={Link}
                active={location.pathname === "/user-profile"}
                to="/help"
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Help
              </Menu.Item>

              <Menu.Item
                onClick={logout}
                to="/user-profile"
                style={{
                  color: "var(--secondary-color)",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Sign Out
              </Menu.Item>
            </>
          )}
        </Menu>
      </Segment>
    </>
  );
};

export default ClearMindHeader;
