import React, { useState } from 'react';
import { IBehaviorContract } from '../Models/BehaviorContract';
import PhoneInput from 'react-phone-number-input/input';
import { Input, Header, Segment } from 'semantic-ui-react';
import User, { IUser } from '../Models/User';
import { observer } from 'mobx-react-lite';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import ClearMindButton from '../Components/Controls/ClearMindButton/ClearMindButton';
import RadioSelector from '../Components/Controls/Selector/RadioSelector';
import ErrorDisplay from '../Components/Messaging/ErrorDisplay';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

interface UpdateSupporterListProps {
  behaviorContract: IBehaviorContract;
  onSupporterUpdate: () => void;
}

const UpdateSupporterList: React.FC<UpdateSupporterListProps> = (props) => {
  const { behaviorContract, onSupporterUpdate } = props;
  const { promiseInProgress } = usePromiseTracker();
  const [errorMsg, setErrorMsg] = useState('');
  const { user } = useClearMindContext();
  const [newUser, setNewUser] = useState<IUser>(
    User.create({
      email: '',
      firstName: '',
      lastName: '',
      supporterRole: '',
      role: 'Supporter',
      phoneNumber: '',
      invitedBy: user.userId,
    })
  );

  const createUser = async () => {
    behaviorContract.addSupporter(newUser);
    await trackPromise(behaviorContract.saveSupporters());
    await trackPromise(behaviorContract.save());

    onSupporterUpdate();

    setNewUser(
      User.create({
        email: '',
        firstName: '',
        lastName: '',
        supporterRole: '',
        role: 'Supporter',
        phoneNumber: '',
        invitedBy: user.userId,
      })
    );
  };

  const showErrorMessage = (message: string) => {
    setErrorMsg(message);
    setTimeout(() => {
      setErrorMsg('');
    }, 3500);
  };

  const addUser = () => {
    if (!newUser.isValidEmail) {
      showErrorMessage(
        'This is an invalid email. Please try re-typing address or use another one.'
      );
      return;
    }

    if (behaviorContract.alreadyAdded(newUser)) {
      showErrorMessage('Supporter is already on the supporter list.');
      return;
    }

    createUser();
  };

  const options = [
    'Significant Other',
    'Co-parent (ex-spouse)',
    'Parent',
    'Sibling',
    'Friend',
    'Other',
  ];

  const inputStyle = { maxWidth: 400, width: '100%' };

  const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h4"} icon>
        First Name
      </Header>
      <Input style={inputStyle} value={capitalize(newUser.firstName)} name="firstName" onChange={newUser.onInputChangeEvent} placeholder="First Name"></Input>
      <Header textAlign="center" as={"h4"} icon>
        Last Name
      </Header>
      <Input style={inputStyle} value={capitalize(newUser.lastName)} name="lastName" onChange={newUser.onInputChangeEvent} placeholder="Last Name"></Input>
      <Header textAlign="center" as={"h4"} icon>
        Email
      </Header>
      <Input style={inputStyle} name={"email"} value={newUser.email} onChange={newUser.onInputChangeEvent} placeholder="Email" />
      <Header textAlign="center" as={"h4"} icon>
        Phone Number
      </Header>
      <div className="ui input" style={inputStyle}>
        <PhoneInput country="US" placeholder="Phone Number" value={newUser.phoneNumber} onChange={newUser.setPhoneNumber} />
      </div>
      <Header textAlign="center" as={"h4"} icon>
        Supporter Type
      </Header>

      <RadioSelector options={options} onChange={newUser.onSupporterRoleChangeEvent} selectedIndex={options.indexOf(newUser.supporterRole || "")} />
      <br />
      <ClearMindButton opaque onClick={addUser} disabled={!newUser.hasRequiredFields} style={{ marginTop: 10 }} loading={promiseInProgress}>
        Add Supporter
      </ClearMindButton>
      <br />
      <ErrorDisplay message={errorMsg} />
    </Segment>
  );
};

export default observer(UpdateSupporterList);
