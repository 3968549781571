import React, { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import useWebSocket from 'react-use-websocket';

import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import processImage from './ProcessImage';
import { observer } from 'mobx-react-lite';
import { trackPromise } from 'react-promise-tracker';
import * as Sentry from "@sentry/react";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Curtain from '../Curtain/CurtainComponent';

const TestCamera: React.FC = (props) => {
  const { children } = props;
  const webcamRef = useRef<any>(null);
  const { user, testingService, testingS3Service } = useClearMindContext();
  const [capturingRef, setCapturingRef] = useState<any>();
  const mediaRecorderRef = useRef<any>(null);
  const [capturingVideo, setCapturingVideo] = useState(false);

  const completeVideoUpload = React.useCallback(async () => {
    console.log("Waiting on chunks to finish");

    try {
      await trackPromise(testingS3Service.chunksUploadPromise);
    } catch {
      console.log(`Chunks promise failed for test session ${testingService.testSession?.sessionId}`);
    }
    console.log("Chunks are finished");
    if (testingS3Service.allVideoChunksUploadedSuccessfully) {
      if (!testingS3Service.videoUploadComplete) {
        console.log(`Completing video upload for test session ${testingService.testSession?.sessionId}`);

        await testingS3Service.completeVideoUpload();
        testingService.testSession?.setTestCompleted(true);
        await testingService.testSession?.update();
      }
      testingService.onStateTransition('test-complete');
    } else {
      console.log(`Upload failure for test session ${testingService.testSession?.sessionId}`);

      testingService.onStateTransition('upload-failure');
    }
  }, [testingS3Service, testingService]);

  const handleStartCapture = React.useCallback(() => {
    testingS3Service.reset();
    console.log('Starting capture');
    const mimeType = MediaRecorder.isTypeSupported('video/webm')
      ? 'video/webm'
      : 'video/mp4';

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      videoBitsPerSecond: 800000,
      audioBitsPerSecond: 0,
      mimeType,
    });

    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      async ({ data }: { data: Blob }) => {
        if (data.size > 0) {
          console.log(`Adding chunk for test session ${testingService.testSession?.sessionId}`);
          await trackPromise(
            testingS3Service.addVideoChunkToBucket(data, mimeType.split(";")[0])
          );

          testingService.testSession?.setUploadId(testingS3Service.uploadId || '');
          console.log(`Current upload id ${testingS3Service.uploadId} for test session ${testingService.testSession?.sessionId}`);
        }
      }
    );

    mediaRecorderRef.current.start(5000);

    setCapturingVideo(true);
  }, [testingS3Service, testingService.testSession]);

  const stopVideoRecording = React.useCallback(async () => {
    if (!capturingVideo) {
      return;
    }

    setCapturingVideo(false);
    testingS3Service.setVideoCaptureComplete(true);
    testingService.testSession?.setTestCompleteTimestamp(Date.now());
    testingService.testSession?.setVideoName(
      testingS3Service.fileName?.split('/')[1]
    );

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.stop();
    }
    console.log("Waiting 7 seconds");
    console.time("Waiting");
    await new Promise(resolve => setTimeout(resolve, 7000)); // Wait for last chunk to finish
    console.timeEnd("Waiting");
    console.log("Completing video");
    await completeVideoUpload();
  }, [capturingVideo, testingService.testSession, testingS3Service, completeVideoUpload]);

  const { sendJsonMessage } = useWebSocket(
    process.env.REACT_APP_WEBHOOK_BASE_URL || '',
    {
      onOpen: (e) => {
        Sentry.captureMessage(
          `Testing websocket connection opened for test session ${testingService.testSession?.sessionId}`,
          'log'
        );
        console.log('opened', JSON.stringify(e));
      },
      onClose: (e) => {
        Sentry.captureMessage(
          `Testing websocket connection closed for test session ${testingService.testSession?.sessionId}.  Closed reason: ${e.reason}`,
          'log'
        );

        if (e.reason === 'Message too big') {
          Sentry.captureException(
            new Error(
              `Web socket for test session ${testingService.testSession?.sessionId} closed because message is to big`
            )
          );
        }
        console.log('closed event', JSON.stringify(e));
      },
      onError: (event) => {
        console.log('closed on error', JSON.stringify(event));
        Sentry.captureMessage(
          `Testing websocket connection closed on error for test session ${testingService.testSession?.sessionId}.`,
          'error'
        );
      },
      onReconnectStop: (numAttempted: number) =>
        console.log(`Stopped try reconnect after ${numAttempted} attempts`),
      onMessage: (message) =>
        testingService.onImageMessage(JSON.parse(message.data), user),
      shouldReconnect: (closeEvent) => true,
      share: false,
    },
    testingService.isPreTestState()
  );

  const takePhoto = useCallback(() => {
    // pause a second so the picture is not blurry
    setTimeout(() => {
      let photo = webcamRef.current.getScreenshot() as string;
      try {
        testingService.testSession?.setTestImage(photo);
      } catch (error: any) {
        Sentry.captureException(error);
        console.log(error.message);
      }
    }, 1000);
  }, [testingService.testSession]);

  const preventTouch = (e: Event) => {
    e.preventDefault();
  }

  useEffect(() => {
    disableBodyScroll(document.createElement("div"));
    document.addEventListener('touchstart', preventTouch, {
      passive: false
    });
    return function cleanup() {
      clearAllBodyScrollLocks();
      document.removeEventListener('touchstart', preventTouch);
      Sentry.captureMessage(
        `Test session complete.`,
        'log'
      );
    };
  }, []);

  useEffect(() => {
    if (testingService.currentState !== 'legal-disclaimer' && !capturingRef) {
      const i = processImage(sendJsonMessage, webcamRef, user, testingService);
      setCapturingRef(i);
    }
    if (testingService.hasShownUnopenedPacket) {
      testingService.testSession?.setTestPacketShown(true);
      testingService.testSession?.update().then(() => {
        user.clearTestPeriodStartHourAfterTestComplete();
      });
    }
    if (testingService.hasOpenedPacket) {
      testingService.testSession?.setTestPacketOpened(true);
      testingService.testSession?.update()
    }

    if (testingService.testReadyForCapture) {
      takePhoto();
      testingService.testSession?.setTestCubeType(testingService.detectedTestKit || "");
    }

    if (testingService.currentState === 'mouth-is-open' && !capturingVideo) {
      handleStartCapture();
    }

    if (
      testingService.currentState === 'test-confirmed' &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      console.log('stop capture');
      stopVideoRecording();
    }
  }, [
    capturingVideo,
    testingService.testReadyForCapture,
    testingService.testSession,
    testingService.currentState,
    user,
    webcamRef,
    sendJsonMessage,
    capturingRef,
    testingService,
    handleStartCapture,
    stopVideoRecording,
    takePhoto,
  ]);

  const videoConstraints = {
    facingMode: 'user',
    height: { max: 320 },
    aspectRatio: 16 / 9,
    frameRate: 10
  };

  const audioConstraints = {
    channelCount: 1,
    sampleSize: 8,
  };

  return (
    <>
      <Curtain />
      <Webcam
        style={{
          zIndex: 0,
          height: '100vh',
          width: "100vh",
        }}
        screenshotFormat='image/jpeg'
        mirrored
        screenshotQuality={.2}
        audioConstraints={audioConstraints}
        videoConstraints={videoConstraints}
        ref={webcamRef}
        audio={false}
        forceScreenshotSourceSize={true}
      />
      {children}
    </>
  );
};

export default observer(TestCamera);
