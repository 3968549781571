import { Auth } from 'aws-amplify';
import axios from 'axios';

export const AxiosInitializer = async () => {
    axios.interceptors.request.use(async (config) => {
        if (!config.baseURL) {
            return config;
        }

        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        if (!config.url?.startsWith('https')) {
            config.headers['Authorization'] = token;
        }
        return config;
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response) {
            error.message = error.response.data
        }
        console.log('Error from interceptor', JSON.stringify(error));
        return Promise.reject(error);
    });
};