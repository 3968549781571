import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import SquareWidget from '../MemberDashboard/SquareWidgetContainer/SquareWidget';

interface DashboardTileProps {
  text: string;
  image: string;
  onClick: () => void;
}

const DashboardTile: React.FC<DashboardTileProps> = (props) => {
  const { text, image, onClick } = props;
  const textArray = text.split(" ");
  const headerLineOne = [textArray[0]];
  const headerLineTwo = [];

  for (let i = 1; i < textArray.length; i++) {
    if (textArray.length > 2 && textArray[i].length <= 8 && headerLineOne.length < 2) {
      headerLineOne.push(textArray[i]);
    } else {
      headerLineTwo.push(textArray[i]);
    }
  }

  const styles = {
    header: { fontWeight: "bold", fontSize: "9pt", color: "var(--secondary-color)" },
    thumbNailTitle: {
      margin: "0px",
      display: "flex",
      padding: "5px 0px 0px 0px",
      flexDirection: "row",
      justifyContent: "center",
    },
  };

  return (
    <Segment
      draggable={false}
      textAlign={"center"}
      basic
      onClick={onClick}
      style={{
        cursor: "pointer",
        padding: "0px",
        width: "100%",
        height: "100%",
        maxWidth: 244,
      }}
    >
      <SquareWidget img={image} />
      <Segment style={styles.thumbNailTitle} basic textAlign={"center"}>
        <Header style={styles.header}>
          {headerLineOne.join(" ")}
          <br />
          {headerLineTwo.join(" ")}
        </Header>
      </Segment>
    </Segment>
  );
};

export default DashboardTile;
