import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';

const FaceDetailDisplay: React.FC<any> = () => {
  const { testingService } = useClearMindContext();
  const { currentImageMessage, currentState } = testingService;
  if (!currentImageMessage) {
    return null;
  }
  return (
    <Segment padded={'very'} style={{ height: '30%' }}>
      <b>Is user</b>
      {` ${currentImageMessage.found ? 'Yes' : 'No'}`}
      <Divider />
      <b>Test In Frame</b>
      {` ${currentImageMessage.testInFrame ? 'Yes' : 'No'}`}
      <Divider />
      <b>Smiling</b>
      {` ${currentImageMessage.isSmiling ? 'Yes' : 'No'}`}
      <Divider />
      <b>Alone</b>
      {` ${currentImageMessage.isOnlyFaceInFrame ? 'Yes' : 'No'}`}
      <Divider />
      <b>Emotion</b>
      {` ${currentImageMessage.strongestEmotion}`}
      <Divider />
      <b>In Frame</b>
      {` ${currentImageMessage.isCompletelyInFrame ? 'Yes' : 'No'}`}
      <Divider />
      <b>Mouth open</b>
      {` ${currentImageMessage.isMouthOpen ? 'Yes' : 'No'}`}
      <Divider />
      <b>Sunglasses</b>
      {` ${currentImageMessage.isWearingSunglasses ? 'Yes' : 'No'}`}
      <Divider />
      <b>Current Test State</b>
      {` ${currentState}`}
      <Divider />
      <b>Side A</b>
      {` ${currentImageMessage.testSideAInFrame}`}
      <Divider />
      <b>Side B</b>
      {` ${currentImageMessage.testSideBInFrame}`}
      <Divider />
    </Segment>
  );
};

export default observer(FaceDetailDisplay);
