import React from 'react';
import Bootstrap from './Components/Authenticator/AuthenticatorTheme';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ClearMindContext } from './ClearMindContext/ClearMindContext';
import * as Sentry from "@sentry/react";
import ErrorPage from './Components/Error/ErrorPage';
import ClearMindAuthenticator from './Components/Authenticator/ClearMindAuthenticator';
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';


const AppWithAuth: React.FC = () => {

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <I18nextProvider i18n={i18n}>
        <ClearMindContext>
          <ClearMindAuthenticator usernameAttributes={"email"} theme={Bootstrap}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ClearMindAuthenticator>
        </ClearMindContext>
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  );
};

export default AppWithAuth;
