import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import FooterBackButton from '../Components/BackButton/FooterBackButton';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import i18n from '../i18n';

const HowToVideoPage: React.FC = () => {
  const { user } = useClearMindContext();

  return (
    <>
      <FrostedFrame>
        <Segment basic textAlign="center">
          <Header style={{ color: "var(--secondary-color)" }} as={"h1"} textAlign="center">
            {user.isSupporter ? i18n.t("dashboard.tiles.howToVideoSupporter") : i18n.t("dashboard.tiles.howToVideoMember")}
          </Header>
          <iframe width="100%" title="How To Video" height="550" src="https://player.vimeo.com/video/846059745?h=2d1e9225c4" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen sandbox="allow-scripts allow-same-origin"></iframe>
          <FooterBackButton />
        </Segment>
      </FrostedFrame>
    </>
  );
};


export default HowToVideoPage;
