import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Segment } from 'semantic-ui-react';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import { useTranslation } from 'react-i18next';

const FooterBackButton: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('');
  const [previousPath, setPreviousPath] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    setPreviousPath(currentPath);
    setCurrentPath(location.pathname);
  }, [location.pathname, currentPath]);

  useEffect(() => {
    return history.listen((location) => {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    });
  }, [history, currentPath, location.pathname, previousPath]);

  const goBack = () => {
    if (!!previousPath) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <Segment basic textAlign='center'>
      <ClearMindButton opaque onClick={goBack}>{t("dashboard.back")}</ClearMindButton>
    </Segment>
  );
};
export default FooterBackButton;
