import { Header, Segment, Checkbox } from "semantic-ui-react";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import FrostedFrame from "../Controls/FrostedFrame/FrostedFrame";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import styles from "./Testing.module.css";
import { observer } from "mobx-react-lite";
import { usePromiseTracker } from "react-promise-tracker";
import SignatureComponent from "../Signature/SignatureComponent";
import { useState } from "react";
import ExpirationDatePicker from "../DatePicker/ExpirationDatePicker";
import { useTranslation } from "react-i18next";

const TestingVerificationStep: React.FC = () => {
  const { testingService } = useClearMindContext();
  const { testSession } = testingService;
  const { promiseInProgress } = usePromiseTracker();
  const [expirationDate, setExpirationDate] = useState({ day: undefined, month: undefined, year: undefined });
  const dateSelected = Object.values(expirationDate).every((value) => value !== undefined);

  const onNextClick = () => {
    testSession?.setTestCubeExpirationDate(`${expirationDate.year}-${expirationDate.month}-${expirationDate.day}`);
    testingService.onTestingVerificationAcknowledgment();
  };

  const onSignatureSave = (signatureBase64: string) => {
    testSession?.setMemberSignature(signatureBase64);
  };

  const onSignatureClear = () => {
    testSession?.setMemberSignature("");
  };

  const isDisabled = () => {
    return !testSession?.testVerificationComplete || !dateSelected;
  };

  const { t } = useTranslation();

  return (
    <FrostedFrame>
      <div className={styles.requirementsContainer}>
        <Header style={{ color: "var(--secondary-color)" }} as={"h2"} textAlign="center">
          {t("testing.verification.header")}
        </Header>
        <Header textAlign="center" as={"h3"}>
          {t("testing.verification.expirationDate")}
          <Header.Subheader style={{ textAlign: "center", paddingTop: 5 }}>{t("testing.verification.lookForDate")}</Header.Subheader>
        </Header>
        <Segment textAlign="center" basic>
          <ExpirationDatePicker onDateChange={setExpirationDate} selectedDate={expirationDate} />
        </Segment>
        <Checkbox label={<label className={styles.label}>{t("testing.verification.authorize")}</label>} onChange={(_, d) => testSession?.setTamperWarningAgreement(d.checked!)} />
        <br />
        <br />
        <SignatureComponent onSignatureSave={onSignatureSave} onSignatureClear={onSignatureClear} label={t("testing.verification.sign")} />
      </div>
      <div style={{ textAlign: "center" }}>
        <ClearMindButton opaque disabled={isDisabled()} onClick={onNextClick} loading={promiseInProgress}>
          {t("testing.verification.next")}
        </ClearMindButton>
      </div>
    </FrostedFrame>
  );
};

export default observer(TestingVerificationStep);
