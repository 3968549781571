import { getSnapshot } from 'mobx-state-tree';
import { Form, Grid, Header, Segment, SemanticWIDTHS } from 'semantic-ui-react';
import { IBehaviorContractQuestion } from '../../Models/BehaviorContractQuestion';
import RadioSelector from '../Controls/Selector/RadioSelector';
import Selector from '../Controls/Selector/Selector';
import HealthInsuranceSelector from './HealthInsuranceSelector';
import PrescriptionList from './PrescriptionList';
import DaysSoberInput from './DaysSoberInput';
import Ticker from './Ticker';
import InputList from '../InputList';
import i18n from '../../i18n';

const CreateControlForQuestion = (
  question: IBehaviorContractQuestion,
  index: number
) => {
  const renderCheckboxGroup = () => {
    if (!question.options) {
      return null;
    }

    const optionsSnapshot = getSnapshot(question).options || [];
    const options = [...optionsSnapshot];
    const optionBatches = [];
    while (options.length) {
      optionBatches.push(options.splice(0, 8));
    }

    return (
      <Grid columns={optionBatches.length as SemanticWIDTHS} stackable>
        <Grid.Row>
          {optionBatches.map((options) => (
            <>
              {options.map((option, i) => {
                const useRadio = option === 'None';
                return (
                  <>
                    {useRadio ? (
                      <>
                        <Selector
                          label={option}
                          key={`radio-selector-${i}`}
                          name={option}
                          onChange={question.onRadioChangeEvent}
                          selected={question.answer === 'None'}
                        />
                      </>
                    ) : (
                      <Selector
                        onChange={question.onSelectorChangeEvent}
                        label={option}
                        key={`checkbox-${i}`}
                        name={option}
                        selected={question.containsAnswer(option)}
                      />
                    )}
                  </>
                );
              })}
            </>
          ))}
        </Grid.Row>
      </Grid>
    );
  };

  const subHeaderResponsiveStyle =
    window.innerWidth > 600
      ? {
          padding: '0rem 4rem 0rem 4rem',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {
          alignItems: 'center',
          justifyContent: 'center',
        };

  return (
    <>
      <Segment
        basic
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
        key={`${question.id}-${index}`}
      >
        <Header textAlign="center" as={"h2"} icon color={"black"}>
          {i18n.t(question.question || "")}
        </Header>

        {question.inputType === "ticker" && (
          <>
            <Header
              as="h5"
              style={{
                color: "white",
                textAlign: "left",
                marginTop: "-30px",
              }}
            >
              {question.label}
            </Header>
            <Ticker value={question.answer} onChange={question.onInputChangeEvent} />
          </>
        )}

        {question.inputType === "label" && (
          <Header textAlign={"center"} as="h4" style={subHeaderResponsiveStyle}>
            {question.label}
          </Header>
        )}

        {question.inputType === "input" && <Form.Input placeholder={question.placeholder} fluid onChange={question.onInputChangeEvent} value={question.answer || ""}></Form.Input>}
        {question.inputType === "DaysSoberInput" && (
          <>
            <DaysSoberInput answer={question.answer} onChange={question.onInputChangeEvent} />
          </>
        )}
        {question.inputType === "prescriptionList" && <PrescriptionList answer={question.answer || ""} onAnswerChange={question.setAnswer} />}
        {question.inputType === "supplementList" && <InputList buttonText={i18n.t("onboarding.suplements.addButton")} inputPlaceholder={i18n.t(question.placeholder || "")} answer={question.answer || ""} onAnswerChange={question.setAnswer} />}
        {question.inputType === "healthInsuranceSelector" && (
          <>
            <Header textAlign="center" as="h5">
              {question.label}
            </Header>
            <HealthInsuranceSelector answer={question.answer || ""} onAnswerChange={question.setAnswer} />
          </>
        )}

        {question.inputType === "radioButton" && <RadioSelector options={question.options} selectedIndex={question.selectedOptionIndex} onChange={question.onRadioChangeEvent} />}

        {question.inputType === "textArea" && (
          <>
            <Header
              as="h5"
              style={{
                textAlign: "left",
              }}
            >
              {question.label}
            </Header>
            <Form.TextArea
              style={{
                width: "100%",
                height: "100px",
                resize: "none",
              }}
              onChange={question.onInputChangeEvent}
              placeholder={question.placeholder}
              value={question.answer || ""}
            />
            <Header
              style={{
                paddingTop: "5px",
                margin: 0,
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {question.lowerLabel}
            </Header>
          </>
        )}
        {question.inputType === "checkboxGroup" && <>{renderCheckboxGroup()}</>}
      </Segment>
    </>
  );
};

export default CreateControlForQuestion;
