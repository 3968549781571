import React, { useEffect, useState } from "react";
import { Authenticator, View } from "@aws-amplify/ui-react";
import logo from "../../logo.png";
import { I18n } from "aws-amplify";
import IdleTimer from "react-idle-timer";
import LogoFadeInContainer from "../../Components/Controls/LogoFadeContainer";
import { Checkbox } from "semantic-ui-react";
import { clearAllTokens, getAuthTokensFromStorage, getBiometricIdEnabled, getEnrolledBiometrics, setBiometricIdEnabled } from "../../Services/NativeBridgeEventsService";
import TokenStorage from "./TokenStorage";
import BiometricAuthenticator from "./BiometricAuthenticator";
import ErrorDisplay from "../Messaging/ErrorDisplay";
import authStorage from "../../AuthenticationStorage";
import { Auth } from "aws-amplify";
import { SignUpParams } from "@aws-amplify/auth";
import { ConfirmUser } from "../../Services/UserService";
import { IFormDataAttributes } from "./AuthenticatorInterface";

const SignOutContext = React.createContext({});

export const useSignOutContext = (): any => {
  return React.useContext(SignOutContext);
};

const ClearMindAuthenticator: React.FC<any> = (props) => {
  const [biometricIdEnabled, setLocalBiometricIdEnabled] = useState<boolean | null>(false);
  const [enrolledBiometrics, setEnrolledBiometrics] = useState<string[]>([]);
  const [biometricAuthComplete, setBiometricAuthComplete] = useState(false);
  const [biometricAuthError, setBiometricAuthError] = useState(false);
  const [tokens, setTokens] = useState<string | null>(null);

  useEffect(() => {
    getEnrolledBiometrics()?.then((biometricEnrolled: string[]) => setEnrolledBiometrics(biometricEnrolled ?? []));
    getBiometricIdEnabled()?.then((enabled: boolean | null) => setLocalBiometricIdEnabled(enabled));
    getAuthTokensFromStorage()?.then((tokens: string) => setTokens(tokens));
  }, []);

  I18n.putVocabulariesForLanguage("en", {
    "Sign in to your account": "Welcome Back!",
    "  a new account": "Create Account",
    Email: "Enter your email", // Username label
    Password: "Enter your password", // Password label,
    Birthdate: "Birthday",
    "Family Name": "Last Name",
    "Given Name": "First Name",
    "Reset your password": "Forgot your password?",
  });

  const components = {
    Header() {
      return (
        <View textAlign="center">
          <LogoFadeInContainer>
            <img src={logo} alt="clearlee-logo" style={imageStyle} />
          </LogoFadeInContainer>
        </View>
      );
    },
    SignIn: {
      Footer() {
        const [version, setVersion] = useState("");

        useEffect(() => {
          // Replace 'metaName' with the name or property of the meta tag you are interested in
          const versionTag = document.querySelector("meta[build-version]");
          if (versionTag) {
            setVersion(versionTag.getAttribute("build-version") ?? "");
          }
        }, []);

        const [rememberEmail, setRememberEmail] = useState(localStorage.getItem("remember-email") === "true");

        const onRememberMeChange = (_: any, data: any) => {
          if (!data.checked) {
            localStorage.removeItem("email");
            localStorage.removeItem("remember-email");
            formFields.signIn.username.defaultValue = "";
            setRememberEmail(false);
          } else {
            setRememberEmail(true);
            localStorage.setItem("remember-email", data.checked);
          }
        };

        const onBiometricIdChange = (_: any, data: any) => {
          setBiometricIdEnabled(data.checked);
          setLocalBiometricIdEnabled(data.checked);
        };

        const isEnrolledInBiometrics = () => {
          return enrolledBiometrics.indexOf("face") >= 0 || enrolledBiometrics.indexOf("fingerprint") >= 0 || enrolledBiometrics.indexOf("iris") >= 0 || enrolledBiometrics.indexOf("strong") >= 0;
        };

        return (
          <>
            <Checkbox
              toggle
              label={
                <label
                  style={{
                    color: "#333",
                    fontSize: "12px",
                    fontFamily: '"Comfortaa", sans-serif',
                    position: "relative",
                    top: -20,
                    left: 28,
                  }}
                >
                  Remember my email
                </label>
              }
              checked={rememberEmail}
              onChange={onRememberMeChange}
              style={{
                fontSize: "12px",
                fontFamily: '"Comfortaa", sans-serif',
              }}
            />
            {isEnrolledInBiometrics() && (
              <>
                <br />
                <br />
                <Checkbox
                  toggle
                  label={
                    <label
                      style={{
                        color: "#333",
                        fontSize: "12px",
                        fontFamily: '"Comfortaa", sans-serif',
                        position: "relative",
                        top: -20,
                        left: 28,
                      }}
                    >
                      Enable biometric login
                    </label>
                  }
                  checked={biometricIdEnabled ?? false}
                  onChange={onBiometricIdChange}
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Comfortaa", sans-serif',
                  }}
                />
                <div style={{ fontSize: 10, marginLeft: 25, marginRight: 10 }}>
                  By enabling biometric authentication, you acknowledge that your device’s biometric data (like fingerprint or facial recognition) will be used to grant access to Clearlee.
                </div>
                {biometricAuthError && <ErrorDisplay message="Unable to login with biometrics.  Please login with username and password." />}
              </>
            )}
            <Authenticator.SignIn.Footer />
            {!!version && <div style={{ float: "right", fontSize: "10px", color: "#BEBEBE", marginTop: 5 }}>{version}</div>}
          </>
        );
      },
    },
  };

  const validateSignUpForm = (formData: IFormDataAttributes): string[] | null => {
    const errors: string[] = [];

    if (!formData.attributes.given_name) {
      errors.push("First Name is required");
    }
    if (!formData.attributes.family_name) {
      errors.push("Last Name is required");
    }
    if (!formData.attributes.phone_number) {
      errors.push("Phone number is required");
    }

    return errors.length === 0 ? null : errors;
  };

  const imageStyle = { maxWidth: 270, margin: "auto", padding: "20px 0px", display: "flex", marginTop: 40 };
  const authProps = { ...props };
  delete authProps.children;

  const formFields = {
    signIn: {
      username: {
        defaultValue: localStorage.getItem("email") ?? "",
      },
    },
    signUp: {
      given_name: {
        placeholder: "First Name",
      },
      family_name: {
        placeholder: "Last Name",
      },
      phone_number: {
        placeholder: "Phone Number",
      },
    },
  };

  if (biometricIdEnabled && !biometricAuthComplete && !biometricAuthError && tokens) {
    return (
      <BiometricAuthenticator
        onComplete={() => {
          setBiometricAuthComplete(true);
        }}
        onError={() => setBiometricAuthError(true)}
        tokens={tokens}
      />
    );
  }

  const services = {
    handleSignUp: async (formData: SignUpParams) => {
      try {
        const formDataAttributes = formData as { attributes: { given_name: string; family_name?: string; phone_number?: string; email: string } };
        const formErrors = validateSignUpForm(formDataAttributes);
        if (formErrors) {
          formErrors.forEach((error) => {
            throw new Error(error);
          });
        }
        const firstName = formDataAttributes.attributes.given_name;
        const email = formDataAttributes.attributes.email;

        const userResponse = await Auth.signUp(formData);
        const userId = userResponse.userSub;

        await ConfirmUser(userId, email, firstName);
        return userResponse;
      } catch (error) {
        console.error("Error signing up:", error);
        throw error;
      }
    },
  };

  return (
    <>
      <Authenticator formFields={formFields} loginMechanisms={["email"]} components={components} hideSignUp={true} {...authProps} services={services}>
        {(p) => {
          const { signOut, user } = p as any;

          if (localStorage.getItem("remember-email") === "true") {
            formFields.signIn.username.defaultValue = user.attributes.email;
            localStorage.setItem("email", user.attributes.email);
          } else {
            localStorage.removeItem("email");
            formFields.signIn.username.defaultValue = "";
          }
          authStorage.setUserLoggedIn(true);
          const signOutAll = () => {
            authStorage.setUserLoggedIn(false);
            clearAllTokens();
            signOut();
          };

          return (
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <IdleTimer onIdle={signOutAll} timeout={1000 * 60 * 30} startOnMount />
              <SignOutContext.Provider value={{ signOut: signOutAll }}>
                {biometricIdEnabled && <TokenStorage />} {/* this isn't right, need to check biometrics id at the storage level and store tokens there */}
                {props.children}
              </SignOutContext.Provider>
            </div>
          );
        }}
      </Authenticator>
    </>
  );
};

export default ClearMindAuthenticator;
