import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AxiosInitializer } from "../AxiosInitializer";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import * as Sentry from "@sentry/browser";
import i18n from "../i18n";
import FadeInLoader from "../Components/FadeInLoader";

interface UserInitializerProps {
  setIsInitialized: (value: boolean) => void;
}

export const UserInitializer: React.FC<UserInitializerProps> = observer((props) => {
  const { setIsInitialized } = props;
  const [isInitializing, setIsInitializing] = useState(false);
  const { user, behaviorContract, scheduledEventsStore } = useClearMindContext();

  useEffect(() => {
    if (!isInitializing) {
      setIsInitializing(true);
      AxiosInitializer()
        .then(() => {
          return user.load();
        })
        .then(() => {
          Sentry.setUser({ id: user.userId, email: user.email });
          i18n.changeLanguage(user.language || "en");
          if (user.isMember) {
            return Promise.all([behaviorContract.load(), behaviorContract.loadDefaultQuestions(user), scheduledEventsStore.load(user.userId || "")]).then(() => console.log("done"));
          } else {
            return Promise.all([behaviorContract.load(), behaviorContract.loadDefaultQuestions(user)]).then(() => {
              scheduledEventsStore.load(behaviorContract.member?.userId || "");
            });
          }
        })
        .then(() => {
          setIsInitialized(true);
        });
    }
  }, [behaviorContract, isInitializing, scheduledEventsStore, setIsInitialized, user]);

  return <FadeInLoader />;
});
