import { Auth } from "aws-amplify";
import axios from "axios";
import { IUser } from "../Models/User";
import { IUserSubscriptionService } from "./IUserSubscriptionService";

class UserSubscriptionService implements IUserSubscriptionService {
    public createCustomer = async (user: IUser): Promise<string> => {
        const response = await axios.post('/stripe-customer', {
            userId: user.userId,
            email: user.email,
            billingAddress: user.billingAddress,
            shippingAddress: user.shippingAddress
        });

        return response.data.customerId;
    };

    public createSubscription = async (
        paymentMethodId: string,
        customerId: string,
        promotionCode: string,
        priceId: string
    ): Promise<any> => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const response = await axios.post('/subscription', {
            userId: currentUser.username,
            customerId: customerId,
            paymentMethodId: paymentMethodId,
            promotionCode,
            priceId
        });

        return {
            customerId: response.data.customerId,
            paymentMethodId: response.data.paymentMethodId,
            subscriptionId: response.data.subscriptionId,
            subscriptionItemId: response.data.subscriptionItemId,
            paymentMethodLastFour: response.data.paymentMethodLastFour ?? undefined,
            userId: response.data.userId,
        };
    };

    public checkSubscription = async (): Promise<boolean> => {
        const currentUser = await Auth.currentAuthenticatedUser();

        const result = await axios.get(`/subscription/status/${currentUser.username}`)

        return result.data.isActive;
    };
}

export const userSubscriptionService = new UserSubscriptionService();
