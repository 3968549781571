import axios from 'axios';
import * as Sentry from "@sentry/react";

export interface FacilityPartnerResponse {
    monitoringPartnerId: string;
    facilityName: string;
    facilityCode: string;
    facilityAddress: string;
    facilityPhoneNumber: string;
    contactName: string;
    contactEmail: string;
    governmentIdRequired: boolean;
}

export const GetFacilityPartner = async (facilityCode: string) => {
    try {
        const response = await axios.get<FacilityPartnerResponse>(
            `/monitoring-partners?facilityCode=${facilityCode}`
        );
        return response.data;
    } catch (error: any) {
        if (error.response?.status === 404) {
            return undefined;
        } else {
            Sentry.captureException(error);
        }
        throw error;
    }
};