import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorDisplay: React.FunctionComponent<{ message: string }> = (props) => {
  const { message } = props;

  if (!message) {
    return null;
  }

  return (
    <Message negative>
      {message}
    </Message>
  );
};

export default ErrorDisplay;
