import { Instance, flow, getParent, types } from "mobx-state-tree";
import { DeleteScheduledEvent, SaveScheduledEvent, UpdateScheduledEvent } from "../Services/ScheduledEventService";


export const ScheduledEvent = types
  .model("Scheduled Event", {
    id: types.string,
    memberId: types.maybe(types.string),
    eventType: types.string,
    eventDate: types.string,
    eventDetails: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get dateKey() {
      return new Date(self.eventDate).toDateString().replace("/", "");
    },
    get localDateString() {
      return new Date(self.eventDate).toLocaleDateString();
    },
    get localeTimeString() {
      return new Date(self.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
    },
  }))
  .actions((self) => {
    return {
      setEventType: (value: string) => {
        self.eventType = value;
      },
      setId: (value: string) => {
        self.id = value;
      },
      setDetails: (value: string) => {
        self.eventDetails = value;
      },
      setDate: (value: string) => {
        self.eventDate = value;
      },
      saveEvent: flow(function* () {
        var result = yield SaveScheduledEvent(self);
        self.id = result.id;
      }),
      deleteEvent: flow(function* () {

        if (self.id) {
          yield DeleteScheduledEvent(self.id);
        }
        (getParent(self, 2) as any).remove(self);
      }),
      updateEvent: flow(function* () {
        yield UpdateScheduledEvent(self);
      }),
    };
  });


export default ScheduledEvent;

export interface IScheduledEvent extends Instance<typeof ScheduledEvent> { }
