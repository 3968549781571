import { Auth } from "aws-amplify"
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import type { CognitoUser } from "amazon-cognito-identity-js";
import authStorage from '../../AuthenticationStorage';
import { useCallback, useEffect, useState } from "react";

export const useTokenLogin = (tokens: any) => {
    const [status, setStatus] = useState<{
        loading: boolean;
        error?: Error;
        data?: any;
    }>({
        loading: false
    });

    const DoTokenThing = useCallback(() => {
        if (!tokens) {
            return
        }
        const prefix = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USERPOOL_CLIENT_ID}`
        const lastUserId = tokens[`${prefix}.LastAuthUser`]

        storeObjects(tokens);

        const authData = {
            idToken: tokens[`${prefix}.${lastUserId}.idToken`] as string,
            accessToken: tokens[`${prefix}.${lastUserId}.accessToken`] as string,
            refreshToken: tokens[`${prefix}.${lastUserId}.refreshToken`] as string
        }

        const userConfig = {
            userPoolId: process.env.REACT_APP_USERPOOL_ID ?? "", clientId: process.env.REACT_APP_USERPOOL_CLIENT_ID ?? ""
        }

        setStatus({ loading: true });
        authorizeByToken(authData, userConfig, lastUserId)
            .then(data => setStatus({ data, loading: false }))
            .catch(error => setStatus({ error, loading: false }));
    }, [tokens])

    useEffect(() => {
        DoTokenThing()
    }, [DoTokenThing])

    return { ...status }
}


const storeObjects = (obj: { [key: string]: any }) => {
    Object.keys(obj).forEach(key => {
        try {
            const val = typeof obj[key] === 'object' ? JSON.stringify(obj[key]) : obj[key];
            authStorage.setItem(key, val);

        } catch (e) {
            console.error(`Error storing item ${key}:`, e);
        }
    });
}

const authorizeByToken = async (
    authData: {
        idToken: string,
        accessToken: string,
        refreshToken: string,
    },
    userConfig: {
        userPoolId: string,
        clientId: string
    },
    userId: string
): Promise<CognitoUser | any> => {
    const AccessToken = new
        AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: authData.accessToken,
        });

    // create a CognitoIdToken using the response idToken   
    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: authData.idToken,
    });

    // create a RefreshToken using the response refreshToken 
    const RefreshToken = new
        AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: authData.refreshToken,
        });

    // create a session object with all the tokens
    const sessionData = {
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
    };

    // create the CognitoUserSession using the sessionData
    const session = new AmazonCognitoIdentity.CognitoUserSession(
        sessionData
    );

    // create an object with the UserPoolId and ClientId 
    let poolData = {
        UserPoolId: userConfig.userPoolId,
        ClientId: userConfig.clientId,
    };

    // pass the poolData object to CognitoUserPool 
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(
        poolData
    );

    // create an object containing the username and user pool. 
    // You can get the username from CognitoAccessToken object 
    // we created above.
    let userData = {
        Username: userId,
        Pool: userPool,
    };

    // create a cognito user using the userData object 
    let cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        userData
    );

    // set the cognito user session w/ the CognitoUserSession
    cognitoUser.setSignInUserSession(session);

    return Auth.currentAuthenticatedUser();
};