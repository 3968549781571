import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import {
  changePhoneNumber,
  verifyPhoneNumber,
  isUserPhoneNumberVerified,
} from '../../Services/UserProfileService';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import SuccessDisplay from '../Messaging/SuccessDisplay';
import MaskedInput from 'react-text-mask';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import * as Sentry from "@sentry/react";
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import i18n from '../../i18n';

interface ChangePhoneNumberProps {
  setPhoneNumberChanged?: any;
}
const ChangePhoneNumber: React.FunctionComponent<ChangePhoneNumberProps> = (
  props
) => {
  const { setPhoneNumberChanged } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changePhoneNumberSuccessMessage, setChangePhoneNumberSuccessMessage] = useState('');
  const [verificationSuccessMessage, setVerificationSuccessMessage] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState('');
  const { user } = useClearMindContext();

  const { promiseInProgress: changePhoneNumberPromiseInProgress } = usePromiseTracker({ area: "change-phone-number" });
  const { promiseInProgress: checkPhoneNumberPromiseInProgress } = usePromiseTracker({ area: "check-phone-number-verified" });
  const { promiseInProgress: verifyPhoneNumberPromiseInProgress } = usePromiseTracker({ area: "verify-phone-number" });

  const onChangePhoneNumberClick = async () => {
    if (phoneNumber) {
      try {
        setErrorMessage("");
        setChangePhoneNumberSuccessMessage("");
        setVerificationSuccessMessage("");
        const cleanedPhone = phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
        await trackPromise(changePhoneNumber(cleanedPhone, user.userId!), "change-phone-number");
        setShowVerify(true);
        setChangePhoneNumberSuccessMessage(i18n.t("onboarding.verifyPhone.changePhoneNumberSuccess"));
      } catch (error: any) {
        Sentry.captureException(error);
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage(i18n.t("onboarding.verifyPhone.enterValidNumber"));
    }
  };

  const onVerifyClick = async () => {
    if (code) {
      try {
        setErrorMessage("");
        setVerificationSuccessMessage("");
        await trackPromise(verifyPhoneNumber(code), "verify-phone-number");

        if ((await trackPromise(isUserPhoneNumberVerified(), "check-phone-number-verified")) === "true") {
          if (setPhoneNumberChanged) {
            setPhoneNumberChanged(true);
          }
          setShowVerify(false);
          setVerificationSuccessMessage(i18n.t("onboarding.verifyPhone.thankYou"));
        } else {
          setErrorMessage(i18n.t("onboarding.verifyPhone.unsuccessful"));
        }
      } catch (error: any) {
        Sentry.captureException(error);
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage(i18n.t("onboarding.verifyPhone.enterCode"));
    }
  };

  return (
    <>
      <>
        <Header>{i18n.t("onboarding.verifyPhone.changePhoneNumber")}</Header>
        <Form>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label style={{ color: "#333" }}>{i18n.t("onboarding.verifyPhone.newPhoneNumber")}</label>
                  <Input fluid>
                    <MaskedInput mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]} placeholder={i18n.t("onboarding.verifyPhone.newPhoneNumber")} onChange={(e) => setPhoneNumber(`+1${e.target.value}`)} name="phone" />
                  </Input>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <ClearMindButton opaque style={{ width: 153 }} onClick={onChangePhoneNumberClick} loading={changePhoneNumberPromiseInProgress}>
                  <Button.Content visible>{i18n.t("onboarding.verifyPhone.changePhoneNumber")}</Button.Content>
                </ClearMindButton>
              </Grid.Column>
            </Grid.Row>
            {showVerify && (
              <>
                <Grid.Column>
                  <SuccessDisplay message={changePhoneNumberSuccessMessage} />
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Input placeholder={i18n.t("onboarding.verifyPhone.code")} onChange={(e) => setCode(e.target.value)} fluid />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button style={{ width: 153 }} onClick={onVerifyClick} loading={checkPhoneNumberPromiseInProgress || verifyPhoneNumberPromiseInProgress}>
                      <Button.Content visible>{i18n.t("onboarding.verifyPhone.verify")}</Button.Content>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            {errorMessage || verificationSuccessMessage ? (
              <Grid.Row>
                <Grid.Column>
                  <ErrorDisplay message={errorMessage} />
                  <SuccessDisplay message={verificationSuccessMessage} />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <></>
            )}
          </Grid>
        </Form>
      </>
    </>
  );
};

export default ChangePhoneNumber;
