import { Instance, destroy, flow, onPatch, types } from 'mobx-state-tree';
import { ImageMessage, IImageMessage } from '../Models/ImageMessage';
import { TestSession } from '../Models/TestSession';
import { ImageStateTransitionsDictionary } from './ImageStateTransitions';
import { testMessageFactory } from "./TestMessageFactory";
import { timeStateTransitioner } from './TimedStateTransitions';
import { v4 as uuidv4 } from 'uuid';
import User, { IUser } from '../Models/User';
import i18n from 'i18next';

const preTestStates = [
  'mobile-device-required',
  'introduction',
  'consent-release',
  'required-materials',
  'legal-disclaimer',
  'how-to-video',
  'phone-orientation-check',
  'test-checklist',
  'test-checklist-success',
  'follow-instructions-prompt',
  "testing-verification"
];

const isPreTestState = preTestStates.reduce((prev: any, curr) => {
  prev[curr] = true;

  return prev;
}, {})

const testStates = [
  "mouth-is-open",
  "tongue-check-complete",

  "validate-unopened-test-kit",
  "validate-test-cube",
  "test-cube-stay-in-frame",
  "cube-wrapper-removal",
  "cube-and-stick-in-view",

  "insert-test-stick-in-mouth-1",
  "insert-test-stick-in-mouth-2",
  "insert-test-stick-in-mouth-3",
  "insert-test-stick-in-mouth-4",

  "show-green-label",
  "remove-green-label",
  "cube-and-face-in-frame",

  "test-stick-process-message-1",
  "test-stick-process-message-2",
  "test-stick-process-message-3",
  "test-stick-process-message-4",
  "test-stick-process-message-5",

  "place-stick-in-cube-1",
  "place-stick-in-cube-2",

  "confirm-test-side-a-alcohol",
  "test-side-a-alcohol-confirmed",
  "confirm-test-side-b-alcohol",
  "test-side-b-alcohol-confirmed",
  "alcohol-check-complete",
  "confirm-test-side-a",
  "confirm-test-side-b",
  "test-side-a-confirmed",
  "test-side-b-confirmed",
  "test-confirmed",
  "upload-failure",

  "cube-on-mat-prompt",
  "2-min-reminder-alcohol",
  "last-reminder-alcohol",
  "cube-on-mat-reminder",
  "remain-in-view",
  "remain-in-view-reminder",
  "test-complete",
  "retry-upload-success",
];

const finalTestStates = ['test-passed', 'test-failed'];
const debugStates = ['dummy-state'];

const TestingService = types
  .model('TestingService', {
    currentState: types.enumeration([...preTestStates, ...testStates, ...finalTestStates, ...debugStates]),
    currentImageMessage: types.optional(ImageMessage, {
      responseType: '',
      isSmiling: false,
      isOnlyFaceInFrame: false,
      strongestEmotion: '',
      isCompletelyInFrame: false,
      isMouthOpen: false,
      isWearingSunglasses: false,
      found: false,
      testInFrame: false,
      packetInFrame: false,
      testSideAInFrame: false,
      testSideBInFrame: false,
      testSideACentered: false,
      testSideBCentered: false,
    }),
    testSession: types.maybe(TestSession),
    testTimerEndTime: types.maybe(types.Date),
    testSwabTimerEndTime: types.maybe(types.Date),
    detectedTestKit: types.maybe(types.enumeration(["Saliva Confirm 12", 'Saliva Confirm 14', '']))
  })
  .views((self) => ({
    isPreTestState() {
      return !!isPreTestState[self.currentState];
    },
    get hasShownUnopenedPacket() {
      return self.currentState === 'validate-test-cube';
    },
    get hasOpenedPacket() {
      return self.currentState === "test-cube-stay-in-frame";
    },
    get isCapturingTestSide() {
      return (
        self.currentState === 'confirm-test-side-a' ||
        self.currentState === 'confirm-test-side-a-alcohol' ||
        self.currentState === 'confirm-test-side-b' ||
        self.currentState === 'confirm-test-side-b-alcohol' ||
        self.currentState === 'test-side-a-confirmed' ||
        self.currentState === 'test-side-b-confirmed' ||
        self.currentState === 'test-side-a-alcohol-confirmed' ||
        self.currentState === 'test-side-b-alcohol-confirmed'
      );
    },
    get testReadyForCapture() {
      return (self as any).sideAReadyForCapture || (self as any).sideBReadyForCapture;
    },
    get sideAReadyForCapture() {
      return self.currentImageMessage.testSideACentered && (self.currentState === 'confirm-test-side-a' || self.currentState === 'confirm-test-side-a-alcohol');
    },
    get sideBReadyForCapture() {
      return self.currentImageMessage.testSideBCentered && (self.currentState === 'confirm-test-side-b' || self.currentState === 'confirm-test-side-b-alcohol');
    },
  }))
  .actions((self: any) => {
    let previousState = '';

    onPatch(self, (patch) => {
      if (patch.path === '/currentState') {
        timeStateTransitioner(previousState, patch.value, self);
        previousState = patch.value;
      }
    });

    const imageStateTransitioner = async () => {
      const imageTransition = ImageStateTransitionsDictionary[self.currentState];

      if (imageTransition?.imageMessageDelegate(self.currentImageMessage)) {
        await self.onStateTransition(imageTransition.nextState);
      }
    };

    return {
      initializeNewTest: (memberId: string, facilityCode: string[] | string) => {
        // if (!window.matchMedia('(hover: none)').matches) {
        //   self.onStateTransition('mobile-device-required');
        // } else {
        self.testSession = TestSession.create({
          memberId,
          facilityCode: Array.isArray(facilityCode) ? facilityCode.join(",") : facilityCode,
          sessionId: uuidv4(),
          dateTestTaken: new Date().toISOString(),
        });
        self.testTimerEndTime = undefined;
        self.testSwabTimerEndTime = undefined;
        // }
      },
      currentTestMessage: (user: IUser) => {
        if (!self.currentImageMessage.found && self.currentState === "test-checklist") {
          return i18n.t("testing.checkList.centerYourself");
        }

        if (self.currentImageMessage.isWearingSunglasses) {
          return i18n.t("testing.checkList.removeSunglasses");
        }

        return testMessageFactory(self.currentState, user);
      },

      reset: () => {
        self.currentState = "legal-disclaimer";
        destroy(self.testSession);
      },
      onIntroductionAcknowledgement: () => {
        self.onStateTransition("testing-verification");
      },
      onTestingVerificationAcknowledgment: () => {
        self.onStateTransition("required-materials");
      },
      onConsentComplete: () => {
        self.onStateTransition("required-materials");
      },
      onPretestComplete: () => {
        self.onStateTransition("test-checklist-success");
      },
      checkMouth: () => {
        self.onStateTransition("mouth-is-open");
      },
      onRequiredMaterialsComplete: () => {
        setTimeout(() => {
          self.onStateTransition("phone-orientation-check");
        }, 2000);
      },
      onLegalDisclaimerComplete: (isNewMember: boolean) => {
        if (isNewMember) {
          self.onStateTransition("how-to-video");
        } else {
          self.onStateTransition("introduction");
        }
      },
      onHowToComplete: () => {
        self.onStateTransition("introduction");
      },
      onStateTransition: flow(function* (newState: string) {
        if (newState === "insert-test-stick-in-mouth-2") {
          self.testSwabTimerEndTime = new Date(new Date().getTime() + 1000 * 60 * 5);
        }
        if (newState === "cube-on-mat-prompt") {
          self.testTimerEndTime = new Date(new Date().getTime() + 1000 * 60 * 10);
        }

        self.currentState = newState;

        if (self.testSession) {
          self.testSession.setTimestampForState(newState);
          yield self.testSession.update();
        }
      }),
      onImageMessage: flow(function* (imageMessage: IImageMessage, currentUser: Instance<typeof User>) {
        if (imageMessage?.responseType === "face-search-response") {
          self.currentImageMessage.found = imageMessage.found || currentUser.disableFacialRecognition;
        } else if (imageMessage?.responseType === "detect-face-response") {
          self.currentImageMessage.isSmiling = imageMessage.isSmiling;
          self.currentImageMessage.isOnlyFaceInFrame = imageMessage.isOnlyFaceInFrame;
          self.currentImageMessage.strongestEmotion = imageMessage.strongestEmotion;
          self.currentImageMessage.isCompletelyInFrame = imageMessage.isCompletelyInFrame;
          self.currentImageMessage.isMouthOpen = imageMessage.isMouthOpen;
          self.currentImageMessage.isWearingSunglasses = imageMessage.isWearingSunglasses;
        } else {
          console.log("invalid image message", JSON.stringify(imageMessage));
        }

        yield imageStateTransitioner();
      }),
      onTensorflowPrediction: flow(function* (tensorflowPrediction: any) {
        const boundingBoxes = tensorflowPrediction[0];
        const confidences = tensorflowPrediction[1];
        const detectedIndexes = tensorflowPrediction[2];
        const detectedLabels = new Set();

        for (let i = 0; i < confidences.length; i++) {
          if (confidences[i] > 0.7) {
            const detectedLabel = indexToLabel(detectedIndexes[i]);

            if (detectedLabel === "sc-12-panel-side-a" || detectedLabel === "sc-12-panel-side-b" || detectedLabel === "sc-14-panel-side-a" || detectedLabel === "sc-14-panel-side-b") {
              const boundingBox = boundingBoxes[i] as number[];
              if (boundingBox[0] < 0.35 && boundingBox[1] < 0.35 && boundingBox[2] > 0.65 && boundingBox[3] > 0.65) {
                detectedLabels.add(`${detectedLabel}-centered`);
              }
            }
            detectedLabels.add(detectedLabel);
            console.log(detectedLabels, detectedIndexes, confidences);
          }
        }

        self.currentImageMessage.testInFrame = detectedLabels.has("test") || detectedLabels.has("sc-12-panel-side-a") || detectedLabels.has("sc-12-panel-side-b") || detectedLabels.has("sc-14-panel-side-a") || detectedLabels.has("sc-14-panel-side-b");
        self.currentImageMessage.packetInFrame = detectedLabels.has("packet");
        self.currentImageMessage.testSideAInFrame = detectedLabels.has("sc-12-panel-side-a") || detectedLabels.has("sc-14-panel-side-a");
        self.currentImageMessage.testSideBInFrame = detectedLabels.has("sc-12-panel-side-b") || detectedLabels.has("sc-14-panel-side-b");
        self.currentImageMessage.testSideACentered = detectedLabels.has("sc-12-panel-side-a-centered") || detectedLabels.has("sc-14-panel-side-a-centered");
        self.currentImageMessage.testSideBCentered = detectedLabels.has("sc-12-panel-side-b-centered") || detectedLabels.has("sc-14-panel-side-b-centered");

        if (detectedLabels.has("sc-12-panel-side-a-centered") || detectedLabels.has("sc-12-panel-side-b-centered")) {
          self.detectedTestKit = "Saliva Confirm 12";
        } else if (detectedLabels.has("sc-14-panel-side-a-centered") || detectedLabels.has("sc-14-panel-side-b-centered")) {
          self.detectedTestKit = "Saliva Confirm 14";
        }

        yield imageStateTransitioner();
      }),
    };
  });

const indexToLabel = (index: number) => {

  if (index === 0) {
    return 'sc-12-panel-side-a';
  }

  if (index === 1) {
    return 'sc-12-panel-side-b';
  }

  if (index === 2) {
    return 'sc-14-panel-side-a'
  }

  if (index === 3) {
    return 'sc-14-panel-side-b'
  }

  if (index === 4) {
    return 'packet';
  }

  return 'other';
};

export default TestingService;
