import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useTranslation } from "react-i18next";

const FooterNavMobile: React.FunctionComponent = () => {
  const location = useLocation();
  const { user } = useClearMindContext();
  const { t } = useTranslation();

  if (
    location.pathname.indexOf("onboarding") >= 0 ||
    location.pathname.indexOf("user-photo") >= 0 ||
    location.pathname.indexOf("language-select") >= 0 ||
    location.pathname.indexOf("welcome") >= 0 ||
    location.pathname.indexOf("demographics") >= 0
  ) {
    return null;
  }

  const getHomePath = () => {
    return user.isMember ? "/member-dashboard" : "/supporter-dashboard";
  };

  return (
    <>
      <Menu
        widths={3}
        fixed={"bottom"}
        compact
        icon="labeled"
        inverted
        style={{
          background: "linear-gradient(to right, #3bcbbd, #3b91cb)",
          opacity: 0.95,
          fontSize: 10,
          height: "85px",
        }}
      >
        <Menu.Item as={Link} to={getHomePath()}>
          <Icon color={"grey"} name="home" size="tiny" />
          {t("dashboard.footer.home")}
        </Menu.Item>
        <Menu.Item as={Link} to="/supporters">
          <Icon color={"grey"} name="users" size="tiny" />
          {t("dashboard.footer.support")}
          <br /> {t("dashboard.footer.network")}
        </Menu.Item>
        <Menu.Item as={Link} to="/help">
          <Icon color={"grey"} name="heart" />
          {t("dashboard.footer.help")}
        </Menu.Item>
      </Menu>
    </>
  );
};

export default FooterNavMobile;
