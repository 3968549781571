import React, { useState } from 'react';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import ChangeEmail from '../../src/Components/UserDashboard/ChangeEmail';
import ChangePassword from '../../src/Components/UserDashboard/ChangePassword';
import ChangePhoneNumber from '../../src/Components/UserDashboard/ChangePhoneNumber';
import TimeZoneModal from '../Components/BehaviorContract/TimeZoneStep/TimeZoneModal';
import ClearMindButton from '../Components/Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import DeleteUserProfile from '../Components/MemberDashboard/DeleteUserProfile';
import LanguageUpdater from './LanguageUpdater';
import { useTranslation } from "react-i18next";

const UserProfileDashboard: React.FunctionComponent = () => {
  const [timeZoneModalOpen, setTimeZoneModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const headerMsg = i18n.t("dashboard.profile.deleteAccount.areYouSure");
  const timeZoneUpdatedSuccessMsg = i18n.t("dashboard.profile.deleteAccount.areYouSure");
  const contentMsg = i18n.t("dashboard.profile.deleteAccount.warning");
  return (
    <>
      <Grid.Row>
        <Segment padded textAlign={"center"} basic>
          <Header style={{ color: "var(--secondary-color)" }} as={"h1"} textAlign="center">
            {t("dashboard.profile.header")}
          </Header>
        </Segment>
      </Grid.Row>
      <FrostedFrame>
        <Grid stackable style={{ marginBottom: "2rem", flex: 1 }}>
          <Grid.Row centered={true}>
            <Grid.Column width={6}>
              <Header>{t("dashboard.profile.timeZone.updateTimeZone")}</Header>
              <TimeZoneModal
                open={timeZoneModalOpen}
                setOpen={setTimeZoneModalOpen}
                successMessage={timeZoneUpdatedSuccessMsg}
                successMessageTimeout={3000}
                trigger={<ClearMindButton opaque>{t("dashboard.profile.timeZone.updateTimeZone")}</ClearMindButton>}
              />
              <Divider />
              <ChangeEmail />
              <Divider />
              <Header>{t("dashboard.profile.updateLanguage")}</Header>
              <LanguageUpdater />
              <Divider />
              <ChangePassword />
              <Divider />
              <ChangePhoneNumber />
              <Divider />
              <DeleteUserProfile header={headerMsg} content={contentMsg} />
            </Grid.Column>
            <Grid.Column width={8}>
              <Divider />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FrostedFrame>
    </>
  );
};

export default UserProfileDashboard;
