import {
  flow,
  Instance,
  types,
  cast,
  clone,
  applySnapshot,
  destroy,
} from 'mobx-state-tree';
import BehaviorContractQuestion, {
  IBehaviorContractQuestion,
} from './BehaviorContractQuestion';
import {
  BehaviorContractResponse,
  GetBehaviorContract,
  SaveBehaviorContract,
} from '../Services/BehaviorContractService';
import User, { IUser } from './User';
import { inviteNewUser } from '../Services/UserService';
import GetBehaviorContractTemplate from '../Services/GetBehaviorContractTemplate';
import * as Sentry from '@sentry/react';

const BehaviorContract = types
  .model("BehaviorContractModel", {
    id: types.maybe(types.string),
    member: types.maybe(User),
    governmentIdRequired: types.optional(types.boolean, false),
    questions: types.array(types.array(BehaviorContractQuestion)),
    supporters: types.array(User),
    partners: types.array(User),
    idVerified: types.optional(types.boolean, false),
    idVerificationSessionComplete: types.optional(types.boolean, false),
    facilityCode: types.maybe(types.array(types.string)),
    onboardingStepComplete: types.optional(types.number, 0),
    onboardingComplete: types.optional(types.boolean, false),
    onboardingCompleteTimestamp: types.maybe(types.number),
    supporterOnboardingComplete: types.optional(types.boolean, false),
    numberOfTests: types.maybe(types.number),
    testFrequency: types.string,
    testingStartDate: types.maybe(types.Date),
    isLoading: types.optional(types.boolean, false),
    priceId: types.maybe(types.string),
    memberSignature: types.maybe(types.string),
    supporterSignature: types.maybe(types.string),
    eventsVisibleToSupporter: types.optional(types.boolean, false),
    eventsVisibleToMonitoringPartner: types.optional(types.boolean, false),
    onboardingLanguageSelect: types.optional(types.boolean, false),
    race: types.maybe(types.string),
    sex: types.maybe(types.string),
    ethnicity: types.maybe(types.string),
    isNewMember: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get hasMember() {
      return !!self.member;
    },
    get isGovernmentIdRequired() {
      return self.governmentIdRequired;
    },
    get medication() {
      const flattendQuestions = self.questions.flatMap((step) => step);

      const medication = flattendQuestions.find((question) => question.id === "allowable-substances") as IBehaviorContractQuestion;

      return medication;
    },
    get supplements() {
      const flattendQuestions = self.questions.flatMap((step) => step);

      const supplements = flattendQuestions.find((question) => question.id === "herbal-supplements") as IBehaviorContractQuestion;

      return supplements;
    },
    get hasFacilityPartner() {
      return !!self.facilityCode;
    },
    get isOnboardingLanguageSelected() {
      return self.onboardingLanguageSelect;
    },
    get isDemographicsSet() {
      return self.ethnicity && self.race && self.sex;
    },
  }))
  .actions((self) => {
    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        const result = yield serviceAction();
        self.isLoading = false;
        return result;
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        self.isLoading = false;
        throw err;
      }
    });
    return {
      load: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result: BehaviorContractResponse = yield GetBehaviorContract();

            if (!result) {
              return;
            }

            const answers = result.questionsAndAnswers?.reduce((prev: any, qa) => {
              prev[qa.id] = qa.answer;
              return prev;
            }, {});

            if (!!answers) {
              self.questions?.forEach((question) => {
                question.forEach((q) => {
                  q.answer = answers[q.id];
                });
              });
            } else {
              self.questions = NullBehaviorContract.questions;
            }

            self.onboardingStepComplete = result.onboardingStepComplete;
            self.member = result.member;
            self.supporters = cast(result.supporters);
            self.onboardingComplete = result.onboardingComplete;
            self.supporterOnboardingComplete = result.supporterOnboardingComplete;
            self.memberSignature = result.memberSignature;
            self.supporterSignature = result.supporterSignature;
            self.facilityCode = cast(result.facilityCode);
            self.eventsVisibleToSupporter = result.eventsVisibleToSupporter;
            self.eventsVisibleToMonitoringPartner = result.eventsVisibleToMonitoringPartner;
            self.numberOfTests = result.numberOfTests;
            self.isNewMember = result.isNewMember;
            self.idVerified = result.idVerified;
            self.idVerificationSessionComplete = result.idVerificationSessionComplete;
            self.onboardingCompleteTimestamp = result.onboardingCompleteTimestamp;
          })
        );
      }),
      loadDefaultQuestions: flow(function* (user: IUser) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const [memberBehaviorContractTemplateSnapshot, supporterBehaviorContractTemplateSnapshot] = yield GetBehaviorContractTemplate();
            if (user.isMember) {
              applySnapshot(self, memberBehaviorContractTemplateSnapshot);
            } else {
              applySnapshot(self, supporterBehaviorContractTemplateSnapshot);
            }
          })
        );
      }),
      setEventsVisibleToSupporter: (value: boolean) => {
        self.eventsVisibleToSupporter = value;
      },
      setEventsVisibleToMonitoringPartner: (value: boolean) => {
        self.eventsVisibleToMonitoringPartner = value;
      },
      save: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            yield SaveBehaviorContract(self);
          })
        );
      }),
      saveSupporters: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result: IUser[] = yield Promise.all(self.supporters.map((supporter) => inviteNewUser(supporter)));
            self.supporters = cast(result);
          })
        );
      }),
      saveMember: flow(function* (member: IUser) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result = yield inviteNewUser(member);
            self.member = cast(result);
          })
        );
      }),
      setOnboardingComplete: (value: boolean) => {
        self.onboardingComplete = value;
      },
      setGovernmentIdRequired: (value: boolean) => {
        self.governmentIdRequired = value;
      },
      setIdVerified: (value: boolean) => {
        self.idVerified = value;
      },
      setIdVerificationSessionComplete: (value: boolean) => {
        self.idVerificationSessionComplete = value;
      },
      setOnboardingCompleteTimestamp: (value: number) => {
        self.onboardingCompleteTimestamp = value;
      },
      setSupporterOnboardingComplete: (value: boolean) => {
        self.supporterOnboardingComplete = value;
      },
      setFacilityCode: (value: string[]) => {
        self.facilityCode = cast([...value]);
      },
      addSupporter: (newUser: any) => {
        self.supporters.push(newUser);
      },
      removeSupporter: (index: number) => {
        self.supporters.splice(index, 1);
      },
      clearMember: () => {
        destroy(self.member);
      },
      alreadyAdded: (newUser: any): boolean => {
        return self.supporters.some((user) => user.email === newUser.email);
      },
      setStepCompleted: (stepCompleted: number) => {
        self.onboardingStepComplete = stepCompleted;
      },
      setMember: (user: IUser) => {
        self.member = clone(user);
      },
      setTestFrequency: (numberOfTests: number, frequency: string) => {
        self.numberOfTests = numberOfTests;
        self.testFrequency = frequency;
      },
      setPriceId: (priceId: string) => {
        self.priceId = priceId;
      },
      setMemberSignature: (signatureBase64: string) => {
        self.memberSignature = signatureBase64;
      },
      setSupporterSignature: (signatureBase64: string) => {
        self.supporterSignature = signatureBase64;
      },
      setTestingDate: (date: Date) => {
        self.testingStartDate = date;
      },
      setOnboardingLanguage: () => {
        self.onboardingLanguageSelect = !self.onboardingLanguageSelect;
      },
      setSelectedSex: (selectedSex: string) => {
        self.sex = selectedSex;
      },
      setSelectedRace: (selectedRace: string) => {
        self.race = selectedRace;
      },
      setSelectedEthnicity: (selectedEthnicity: string) => {
        self.ethnicity = selectedEthnicity;
      },
      setIsFirstTest: (value: boolean) => {
        self.isNewMember = value;
      },
    };
  });

export const NullBehaviorContract = BehaviorContract.create({
  id: '-1',
  testFrequency: '',
  numberOfTests: 0,
  questions: [[]],
});

export default BehaviorContract;
export interface IBehaviorContract extends Instance<typeof BehaviorContract> { }
