import React, { useEffect } from "react";
import { Header, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import LanguageUpdater from "../../Pages/LanguageUpdater";
import i18n from "../../i18n";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import { useHistory } from "react-router-dom";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";

const LanguageSelectStep: React.FC = () => {
  const { behaviorContract, user } = useClearMindContext();
  const history = useHistory();

  useEffect(() => {
  }, [user.language]);

  const onClick = () => {
    behaviorContract.setOnboardingLanguage();
    history.push("/");
  };
  const styles = {
    radio: { display: "flex", justifyContent: "center", alignItems: "center" },
  };
  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h2"}>
        {i18n.t("onboarding.languageSelect.english")}
      </Header>

      <Header as={"h4"}>({i18n.t("onboarding.languageSelect.spanish")})</Header>
      <br />

      <div style={styles.radio}>
        <LanguageUpdater />
      </div>
      <br />
      <ClearMindButton opaque onClick={onClick}>
        {i18n.t("onboarding.languageSelect.continue")}
      </ClearMindButton>
    </Segment>
  );
};

export default observer(LanguageSelectStep);
