import { Instance, types } from 'mobx-state-tree';

export const ImageMessage = types.model('ImageMessage', {
  responseType: types.string,
  isSmiling: types.boolean,
  isOnlyFaceInFrame: types.boolean,
  strongestEmotion: types.string,
  isCompletelyInFrame: types.boolean,
  isMouthOpen: types.boolean,
  isWearingSunglasses: types.boolean,
  found: types.boolean,
  testInFrame: types.boolean,
  packetInFrame: types.boolean,
  testSideAInFrame: types.boolean,
  testSideBInFrame: types.boolean,
  testSideACentered: types.boolean,
  testSideBCentered: types.boolean,
}).views(self => ({
  eitherSideIsCentered() {
    return self.testSideACentered || self.testSideBCentered;
  }
}));

export interface IImageMessage extends Instance<typeof ImageMessage> { }
