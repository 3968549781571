import React from 'react';
import { Segment } from 'semantic-ui-react';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import WellnessAgreement from '../Components/PdfComponent/WellnessAgreementComponent';

const WellnessAgreementPage: React.FC = () => {
  return (
    <>
      <FrostedFrame>
        <Segment basic>
          <WellnessAgreement />
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default WellnessAgreementPage;
