import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Dimmer } from 'semantic-ui-react';

interface CircularProgressProps {
    percentComplete: number;
    active: boolean
}
const CircularProgress: React.FC<CircularProgressProps> = (props) => {
    const { active } = props;
    const percentComplete = props.percentComplete;

    return <Dimmer active={active} page>
        <CircularProgressbar value={percentComplete} text={`${percentComplete}%`} />
    </Dimmer>;
}

export default CircularProgress;