import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useHistory } from "react-router";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import DashboardTileComponent from "../Components/CarouselComponent/DashboardTileComponent";
const SupporterDashboardPage: React.FC = () => {
  const history = useHistory();
  const { user, behaviorContract } = useClearMindContext();

  if (user.isMember) {
    behaviorContract.onboardingComplete
      ? history.push('member-dashboard')
      : history.push('member-onboarding');
  }
  if (!behaviorContract.supporterOnboardingComplete) {
    history.push('supporter-onboarding');
  }

  return (
    <Grid padded={"vertically"}textAlign="center">
      <DashboardTileComponent />
    </Grid>
  );
};

export default SupporterDashboardPage;
