import React, { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps, Segment } from 'semantic-ui-react';

const options = [
  {
    key: 'Blue Cross Blue Shield',
    text: 'Blue Cross Blue Shield',
    value: 'Blue Cross Blue Shield',
  },
  { key: 'Aetna', text: 'Aetna', value: 'Aetna' },
  { key: 'Humana', text: 'Humana', value: 'Humana' },
  { key: 'United Health', text: 'United Health', value: 'United Health' },
  { key: 'CVS Health', text: 'CVS Health', value: 'CVS Health' },
  {
    key: 'Kaiser Permanente',
    text: 'Kaiser Permanente',
    value: 'Kaiser Permanente',
  },
  { key: 'Cigna', text: 'Cigna', value: 'Cigna' },
  {
    key: 'MCNA Health Care',
    text: 'MCNA Health Care',
    value: 'MCNA Health Care',
  },
  { key: 'HCSC', text: 'HCSC', value: 'HCSC' },
  { key: 'Centene', text: 'Centene', value: 'Centene' },
  {
    key: 'Molina Healthcare',
    text: 'Molina Healthcare',
    value: 'Molina Healthcare',
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'Other',
  },
  { key: 'Does not Apply', text: 'Does not Apply', value: 'Does not Apply' },
];
interface HealthInsuranceProps {
  onAnswerChange: (newAnswer: string) => void;
  answer: string;
}

const HealthInsuranceSelector: React.FC<HealthInsuranceProps> = (props) => {
  const { onAnswerChange, answer } = props;

  const onChange = (
    _: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    const value: any = data.value || [];
    onAnswerChange(value);
  };

  return (
    <>
      <Segment basic textAlign={'center'}>
        <Dropdown
          onChange={onChange}
          placeholder='Insurance Provider'
          search
          selection
          defaultValue={answer.split(',')}
          options={options}
        />
      </Segment>
    </>
  );
};

export default HealthInsuranceSelector;
