import React from 'react';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import SupporterResources from '../Components/SupporterDashboardComponents/SupporterResources';

const SupporterResourcesPage: React.FC = () => {
  return (
    <>
      <FrostedFrame>
        <SupporterResources />
      </FrostedFrame>
    </>
  );
};

export default SupporterResourcesPage;
