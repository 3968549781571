import React, { useEffect, useState } from 'react';
import { Grid, Loader, Segment } from "semantic-ui-react";
import CalendarComponent from "../MemberCalendar/CalendarComponent";
import ResultsTrackerHeader from "../MemberCalendar/ResultsTrackerHeader";
import TestResultsCardList from "./TestResultsCardList";
import { testResults } from "../../Services/UserService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import TestResult from "../../Models/TestResult";
import FooterBackButton from "../BackButton/FooterBackButton";
import FrostedFrame from "../Controls/FrostedFrame/FrostedFrame";
import { observer } from 'mobx-react';
import EventPrivacy from "../MemberCalendar/EventPrivacy";

const ResultsTrackerComponent: React.FC = () => {
  const [memberTestResults, setMemberTestResults] = useState([]);
  const { behaviorContract, user } = useClearMindContext();
  const memberId = behaviorContract.member?.userId;
  const { promiseInProgress } = usePromiseTracker({ area: "results-download" });

  useEffect(() => {
    trackPromise(
      testResults(memberId).then((returnedTestResults) => {
        const mappedTestResults = returnedTestResults.map((tr: any) => TestResult.create(tr));
        setMemberTestResults(mappedTestResults);
      }),
      "results-download"
    );
  }, [behaviorContract.member?.userId, memberId, user.isMember, user.isSupporter]);

  const [list, setList] = useState(false);

  return (
    <>
      {promiseInProgress ? (
        <div style={{ height: 500 }}>
          <Loader active />
        </div>
      ) : (
        <Grid>
          <Grid.Column>
            <FrostedFrame>
              <Segment textAlign={"left"} basic>
                <ResultsTrackerHeader list={list} setList={setList} />
                {list ? <TestResultsCardList testResults={memberTestResults} /> : <CalendarComponent testResults={memberTestResults} />}
                {user.isMember && <EventPrivacy />}
              </Segment>
            </FrostedFrame>
            <FooterBackButton />
          </Grid.Column>
        </Grid>
      )}
    </>
  );
};

export default observer(ResultsTrackerComponent);
