import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import ClearMindButton from '../../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../../Controls/FrostedFrame/FrostedFrame';
import styles from '../Testing.module.css';
import pretestStyles from './SupporterPretestStep.module.css'

const SupporterPretestStep: React.FC = () => {
    const history = useHistory();


    const handleReturnHomeButton = () => {
        history.push('/supporter-dashboard');
    };

    return (
        <>
            <FrostedFrame>
                <Grid textAlign='center' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <h1 className={styles.header} style={{marginTop: '12px'}}>Testing Unavailable</h1>
                            <Divider />
                            <Segment
                                className={pretestStyles.segment}
                                basic
                                textAlign='left'
                            >
                                <Header style={{ paddingBottom: '1em' }} textAlign='left'>
                                    You are currently signed in with a supporter account.
                                    <br />
                                    <br />
                                    Taking a Clearlee test is unavailable to supporters.
                                    <br /> 
                                    <br /> 
                                    Click the "Return to Dashboard" button to return to your dashbord. 
                                    From there, you can view past test results for your member and view supporter resources.
                                </Header>
                            </Segment>
                            <ClearMindButton opaque
                                onClick={handleReturnHomeButton}
                            >
                                Return to Dashboard
                            </ClearMindButton>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </FrostedFrame>
        </>
    );
};

export default observer(SupporterPretestStep);
