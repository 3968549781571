import { IUser } from "../Models/User";

export const testMessageFactory = (state: string, user: IUser) => { 

  switch (user.language) 
  { 
    case "es": 
      return testMessageFactoryEs(state, user); 
    case "en": 
      return testMessageFactoryEn(state, user); 
    default: 
    return testMessageFactoryEn(state, user);
  }
}
  
 const testMessageFactoryEn = (state: string, user: IUser) => {
   switch (state) {
     case "test-checklist-success":
       return `Hi ${user.firstName}, Identity confirmed! Please keep your face in view of the camera to pass your test.`;

     case "follow-instructions-prompt":
       return "From this point forward, you must follow these instructions in order to pass your test.";

     case "phone-orientation-check":
       return "Please ensure your phone is in an upright position so that you and your test can remain in view of the camera for the duration of your test.";

     case "validate-unopened-test-kit":
       return "Show your unopened TestPACKET to the camera.";

     case "mouth-is-open":
       return "Please show your mouth is empty by leaning into camera showing the top and bottom of your tongue.";

     case "tongue-check-complete":
     case "alcohol-check-complete":
       return "Great! Thank you!";

     case "validate-test-cube":
       return "Open your test packet to remove the test cube and test stick.";

     case "test-cube-stay-in-frame":
       return "You must always be able to see the test cube and test stick in the camera to avoid failing the test.";

     case "cube-wrapper-removal":
       return "Keeping the test cube in view of the camera, remove the wrapper from the test stick.";

     case "cube-and-stick-in-view":
       return "Keep both the test cube and test stick in view of the camera for the whole test to avoid receiving a fail.";

     case "insert-test-stick-in-mouth-1":
       return "You may now place test stick in your mouth for 5 minutes.";

     case "insert-test-stick-in-mouth-2":
       return "Swab each cheek 10 times, swab your tongue 10 times, and then place test sponge comfortably under your tongue.";

     case "insert-test-stick-in-mouth-3":
       return "Test cube must stay in view of the camera at all times.";

     case "insert-test-stick-in-mouth-4":
       return "Rotate the test sponge as it sits under your tongue to collect as much saliva as possible for an accurate result.";

     case "show-green-label":
       return "Show the green label on both sides of the test cube to the camera.";

     case "remove-green-label":
       return "Now remove the green label from both sides of the test cube.";

     case "cube-and-face-in-frame":
       return "Continue keeping your test cube and face in view of the camera to avoid a fail.";

     case "test-stick-process-message-1":
       return "You must collect as much saliva as possible for an accurate test result. Squeezing the test sponge under your tongue helps.";

     case "test-stick-process-message-2":
       return "Touching your tongue to the roof of your mouth can assist in gathering saliva faster. You are doing great!";

     case "test-stick-process-message-3":
       return "As your test continues to process, you will feel the sponge on the test stick softening. This is a good sign.";

     case "test-stick-process-message-4":
       return "You are almost there...";

     case "test-stick-process-message-5":
       return "When prompted, please remove the test stick with as much saliva on sponge as possible.";

     case "place-stick-in-cube-1":
       return "Take test stick out of your mouth and insert into test cube. Push down to close.";

     case "place-stick-in-cube-2":
       return "Your results are now processing.";

     case "cube-on-mat-prompt":
       return "Please do not touch or remove the test cube from view of the camera. Images are collected at 3 minutes and at the end of the 10 minute timer. Prepare in 3 minutes to capture pictures of both sides of the test cube.";

     case "2-min-reminder-alcohol":
       return "Prepare in 2 minutes to show both sides of the test cube to the camera to ensure for an accurate reading of the Alcohol panel.";

     case "last-reminder-alcohol":
       return "In 15 seconds, show both sides of the test cube to the camera when prompted to ensure for an accurate reading of the Alcohol panel.";

     case "cube-on-mat-reminder":
       return "Please continue to keep test cube in view of the camera and wait until all results are finalized. We will guide you through remaining steps.";

     case "remain-in-view":
     case "remain-in-view-reminder":
       return "Almost there! Please remember to remain in view of the camera for a valid test.";

     case "confirm-test-side-a-alcohol":
     case "confirm-test-side-a":
       return "Please hold and position Test Cube in camera guide, with the alcohol side showing, to ensure a successful image is captured.";

     case "test-side-a-alcohol-confirmed":
     case "test-side-a-confirmed":
       return 'Side "A" Captured! Thanks. ';

     case "confirm-test-side-b-alcohol":
     case "confirm-test-side-b":
       return "Please show the other side of the test cube for image capture.";

     case "test-side-b-alcohol-confirmed":
       return 'Side "B" Captured! Let\'s move on. ';

     case "test-side-b-confirmed":
       return 'Side "B" Captured!';

     case "test-confirmed":
       return "Please wait while we finalize your test photos.  Your test is not complete.  This may take up to a minute to complete.";

     case "test-complete":
       return "Please wait while we finalize your test photos.  Your test is not complete.  This may take up to a minute to complete.";

     default:
       return "You're good! Let's move on.";
   }
 };

 const testMessageFactoryEs = (state: string, user: IUser) => {
   switch (state) {
     case "test-checklist-success":
       return `Hola, ${user.firstName}, ¡Identidad confirmada! Por favor mantenga su cara a la vista a la cámara para pasar su prueba.`;

     case "follow-instructions-prompt":
       return "A partir de este momento, usted debe de seguir esta instrucción para poder pasar su prueba.";

     case "phone-orientation-check":
       return "Por favor asegúrese que su teléfono este en posición vertical para que usted y su prueba pueda permanecer en la vista de la cámara durante la duración de la prueba.";

     case "validate-unopened-test-kit":
       return "Muestre su TestPACKET (paquete de prueba) sin abrir a la cámara.";

     case "mouth-is-open":
       return "Por favor muestre que su boca este vacía inclinándose hacia la cámara mostrando la parte superior e inferior de su lengua.";

     case "tongue-check-complete":
     case "alcohol-check-complete":
       return "¡Excelente! ¡Gracias!";

     case "validate-test-cube":
       return "Abra su paquete para remover el cubo de prueba y la barra de prueba.";

     case "test-cube-stay-in-frame":
       return "Usted siempre debe poder ver el cubo de prueba y la barra de prueba para wvitar un fallo en la prueba.";

     case "cube-wrapper-removal":
       return "Manteniendo el cubo de prueba a la vista de la cámara, remueve el empaque de la barra de prueba.";

     case "cube-and-stick-in-view":
       return "Mantenga tanto el cubo de prueba y la barra de prueba a la vista de la cámara durante todo el tiempo de prueba para evitar recibir un fallo.";

     case "insert-test-stick-in-mouth-1":
       return "Usted debe de colocar la barra de prueba en su boca durante 5 minutos.";

     case "insert-test-stick-in-mouth-2":
       return "Frote cada mejilla 10 veces, frote su lengua 10 veces, y luego coloque la esponja de prueba cómodamente bajo su lengua.";

     case "insert-test-stick-in-mouth-3":
       return "La barra de prueba debe permanecer a la vista de la cámara todo el tiempo.";

     case "insert-test-stick-in-mouth-4":
       return "Gire la esponja de prueba mientras se encuentra debajo de su lengua para recolectar la mayor cantidad de saliva que sea posible para un resultado preciso.";

     case "show-green-label":
       return "Muestre la etiqueta verde en ambos lados de la barra de prueba a la cámara.";

     case "remove-green-label":
       return "Ahora remueva la etiqueta verde de ambos lados de la barra de prueba.";

     case "cube-and-face-in-frame":
       return "Continúe manteniendo el cubo de prueba y la cara a la visita de la cámara para evitar un fallo.";

     case "test-stick-process-message-1":
       return "Debe recolectar la mayor cantidad de saliva posible para obtener un resultado preciso de la prueba. Apretando la esponja de prueba debajo de la lengua ayuda.";

     case "test-stick-process-message-2":
       return "Tocar el paladar con la lengua puede ayudar a recoger la saliva de manera más rápida. Lo está haciendo muy bien.";

     case "test-stick-process-message-3":
       return "A medida que su prueba continúa procesándose, usted sentirá que la esponja de la barra de prueba se ablanda. Esta es una buena señal.";

     case "test-stick-process-message-4":
       return "Ya casi termina...";

     case "test-stick-process-message-5":
       return "Cuando se le pida, por favor retire la barra de prueba con la mayor cantidad de saliva que sea posible en la esponja.";

     case "place-stick-in-cube-1":
       return "Quítese la barra de prueba de su boca e inserte el cubo de prueba. Empuje hacia abajo para cerrar.";

     case "place-stick-in-cube-2":
       return "Sus resultados ahora se están procesando.";

     case "cube-on-mat-prompt":
       return "Por favor, no toque ni retire el cubo de prueba del campo de visión de la cámara. Se recogen imágenes a los 3 minutos y al final del temporizador de 10 minutos. Prepárese en 3 minutos para capturar imágenes de ambos lados del cubo de prueba.";

     case "2-min-reminder-alcohol":
       return "Prepárese en 2 minutos para mostrar ambos lados del cubo de prueba a la cámara para asegurar una lectura precisa del panel de alcohol.";

     case "last-reminder-alcohol":
       return "En 15 segundos, muestre ambos lados del cubo de prueba a la cámara cuando se le indique para asegurar una lectura precisa del panel de alcohol.";

     case "cube-on-mat-reminder":
       return "Por favor continúe manteniendo la barra de prueba a la vista de la cámara y espere hasta que se terminen los resultados. Nosotros le guiaremos en los pasos restantes.";

     case "remain-in-view":
     case "remain-in-view-reminder":
       return "¡Ya casi terminamos! Por favor recuerde de permanecer a la vista de la cámara para una prueba valida.";

     case "confirm-test-side-a-alcohol":
     case "confirm-test-side-a":
       return "Por favor sostenga y coloque la barra de prueba en la guía de la cámara, con el lado de alcohol a la vista, para asegurarse de que una imagen exitosa sea capturada.";

     case "test-side-a-alcohol-confirmed":
     case "test-side-a-confirmed":
       return "¡Lado “A” capturado! Gracias.";

     case "confirm-test-side-b-alcohol":
     case "confirm-test-side-b":
       return "Por favor muestre el otro lado de la barra de prueba para la captura de imagen.";

     case "test-side-b-alcohol-confirmed":
       return "¡Lado “B” capturado! Sigamos adelante.";

     case "test-side-b-confirmed":
       return "¡Lado “B” capturado!";

     case "test-confirmed":
       return "Por favor espere mientras finalizamos sus fotos de prueba. Su prueba no está completa. Esto puede tomar hasta un minuto para completar.";

     case "test-complete":
       return "Por favor espere mientras finalizamos sus fotos de prueba. Su prueba no está completa. Esto puede tomar hasta un minuto para completar.";

     default:
       return "¡Estas bien! Vamonos.";
   }
 };