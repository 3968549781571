import { IImageMessage } from "../Models/ImageMessage";

interface ImageStateTransition {
  currentState: string;
  nextState: string;
  imageMessageDelegate: (imageMessage: IImageMessage) => boolean;
}

const imageStateTransitions: ImageStateTransition[] = [
  { currentState: 'validate-unopened-test-kit', nextState: 'validate-test-cube', imageMessageDelegate: (imageMessage) => imageMessage.packetInFrame },
  // { currentState: 'validate-test-cube', nextState: 'validate-panel-seal', imageMessageDelegate: (imageMessage) => imageMessage.testInFrame },  Temporarily Disable DJM 6/16/2023
]

export const ImageStateTransitionsDictionary = imageStateTransitions.reduce((prev: { [key: string]: ImageStateTransition }, current) => {
  prev[current.currentState] = current;
  return prev;
}, {})
