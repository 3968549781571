import React, { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Header } from 'semantic-ui-react';
import styles from '../Controls/ClearMindButton/ClearMindButtonStyles.module.css';
import { useTranslation } from "react-i18next";

interface SignatureComponentProps {
  onSignatureSave: (signatureBase64: string) => void;
  onSignatureClear: () => void;
  label: string;
}

const SignatureComponent: React.FC<SignatureComponentProps> = (props) => {
  const { onSignatureSave, onSignatureClear, label } = props;
  const { t } = useTranslation();

  const onClearClick = () => {
    signaturePadRef.current.clear();
    onSignatureClear();
  };

  const onEnd = () => {
    const dataUrl: string = signaturePadRef.current.toDataURL();
    const signatureBase64 = dataUrl.substring(22);

    onSignatureSave(signatureBase64);
  };

  const signaturePadRef = useRef<any>({});

  return (
    <div style={{ textAlign: "center" }}>
      <Header as="h5">{label}</Header>
      <SignaturePad
        onEnd={onEnd}
        ref={signaturePadRef}
        canvasProps={{
          width: -1, // do not change, this is intentional DJM 8/1/2022
          height: 125,
          style: { backgroundColor: "white", display: "block", height: 125, width: "100%" },
        }}
      />
      <br />
      <button className={styles.clearMindButtonOpaque} style={{ fontSize: 12 }} onClick={onClearClick}>
        {t("testing.verification.clear")}
      </button>
    </div>
  );
};

export default SignatureComponent;
