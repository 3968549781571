import { observer } from 'mobx-react-lite';
import React from 'react';
import { IBehaviorContractQuestion } from '../../Models/BehaviorContractQuestion';
import CreateControlForQuestion from './StepControlFactory';

interface TreatmentHistoryProps {
  treatmentStep: IBehaviorContractQuestion[];
}

const TreatmentHistoryStep: React.FC<TreatmentHistoryProps> = (props) => {
  const { treatmentStep } = props;

  const treatmentHistoryQuestion = treatmentStep.find(
    (question) => question.id === 'treatment-history'
  ) as IBehaviorContractQuestion;

  const hasTreatmentHistory = treatmentHistoryQuestion.answer === 'Yes';

  return (
    <>
      {hasTreatmentHistory
        ? treatmentStep.map(CreateControlForQuestion)
        : CreateControlForQuestion(treatmentHistoryQuestion, 1)}
    </>
  );
};

export default observer(TreatmentHistoryStep);
