import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import styles from "./ExpDate.module.css";
import { useTranslation } from "react-i18next";

interface IExpirationDatePicker {
  onDateChange: Dispatch<SetStateAction<any>>;
  selectedDate: any;
}

const ExpirationDatePicker: React.FC<IExpirationDatePicker> = (props) => {
  const { t, i18n } = useTranslation();

  const monthLang = () => {
    return i18n.language === 'en' ? 'en-US' : 'es-ES';
  };
  
  const { onDateChange, selectedDate } = props;
  const [days, setDays] = useState<Array<{ key: number; text: string; value: string }>>([]);

  const generateDaysArray = (month: number) => {
    const currentYear = new Date().getFullYear();
    const lastDayOfMonth = new Date(currentYear, month, 0).getDate();
    const daysArray = Array.from({ length: lastDayOfMonth }, (_, i) => ({
      key: i + 1,
      text: `${i + 1}`,
      value: `${i + 1 < 10 ? 0 : ""}${i + 1}`,
    }));
    setDays(daysArray);
  };

  const years = Array.from({ length: 6 }, (_, i) => {
    const year = new Date().getFullYear() + 5 - i;
    return { key: year, text: year.toString(), value: year };
  });

  const months = Array.from({ length: 12 }, (_, i) => ({
    key: i + 1,
    text: new Date(2023, i, 1).toLocaleDateString(monthLang(), { month: "long" }),
    value: `${i + 1 < 10 ? 0 : ""}${i + 1}`,
  }));

  useEffect(() => {
    generateDaysArray(selectedDate.month);
  }, [selectedDate.month]);

  const onDaySelect = (day: string) => onDateChange((prevDate: any) => ({ ...prevDate, day: day }));

  const onMonthSelect = (month: string) => {
    onDateChange((prevDate: any) => ({ ...prevDate, month: month }));
    generateDaysArray(Number(month));
  };

  const onYearSelect = (year: string) => {
    onDateChange((prevDate: any) => ({ ...prevDate, year: year }));
  };

  const DayDropdown = () => (
    <>
      <select className={styles.select} disabled={!selectedDate.month} value={selectedDate.day} onChange={(e) => onDaySelect(e.target.value)}>
        <option value="" disabled selected hidden>
          {t("testing.verification.day")}
        </option>

        {days.map((day, i) => (
          <option value={day.value} key={`day-select-${i}`}>
            {day.text}
          </option>
        ))}
      </select>
    </>
  );

  const MonthDropdown = () => (
    <>
      <select className={styles.select} value={selectedDate.month} onChange={(e) => onMonthSelect(e.target.value)}>
        <option value="" disabled selected hidden>
          {t("testing.verification.month")}
        </option>

        {months.map((month, i) => (
          <option value={month.value} key={`month-select-${i}`}>
            {month.text}
          </option>
        ))}
      </select>
    </>
  );

  const YearDropdown = () => (
    <>
      <select className={styles.select} value={selectedDate.year} onChange={(e) => onYearSelect(e.target.value)}>
        <option style={{ fontWeight: "2pt" }} value="" disabled selected hidden>
          {t("testing.verification.year")}
        </option>

        {years.map((year, i) => (
          <option style={{ fontSize: "5pt" }} value={year.value} key={`year-select-${i}`}>
            {year.text}
          </option>
        ))}
      </select>
    </>
  );

  const columnStyles = {
    dayColumn: { width: "85px", padding: "14px 7px" },
    monthColumn: { width: "118px", padding: "14px 7px" },
    yearColumn: { width: "87px", padding: "14px 7px" },
  };

  return (
    <>
      <div className="ui three column grid centered">
        <div style={columnStyles.monthColumn} className="column">
          <MonthDropdown />
        </div>
        <div style={columnStyles.dayColumn} className="column">
          <DayDropdown />
        </div>
        <div style={columnStyles.yearColumn} className="column">
          <YearDropdown />
        </div>
      </div>
    </>
  );
};

export default ExpirationDatePicker;
