import { observer } from 'mobx-react-lite';
import React from 'react';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import styles from './UserInFrameOverlay.module.css';

const UserInFrameOverlay: React.FC = () => {
  const { testingService } = useClearMindContext();
  const { currentImageMessage } = testingService;
  const testingStateRequiresOverlay = testingService.currentState !== 'test-checklist' && testingService.currentState !== 'test-checklist-success' && testingService.currentState !== 'phone-orientation-check';

  if (testingStateRequiresOverlay) {
    return null;
  }

  const faceDetectionClassName = () => {
    if (currentImageMessage.found && testingService.currentState !== 'phone-orientation-check') {
      return styles.faceDetected;
    }

    return styles.faceUnDetected;
  };

  return <div className={faceDetectionClassName()}></div>;
};

export default observer(UserInFrameOverlay);
