import { View } from "@aws-amplify/ui-react";
import { Loader } from "semantic-ui-react";
import LogoFadeInContainer from "./Controls/LogoFadeContainer";
import logo from "../logo.png";
import { CSSProperties } from "react";

const FadeInLoader: React.FC = () => {
  const styles: { [key: string]: CSSProperties } = {
    logo: { maxWidth: 270, margin: "auto", padding: "20px 0px", display: "flex", marginTop: 40 },
    container: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" },
  };

  return (
    <div style={styles.container}>
      <View textAlign="center">
        <LogoFadeInContainer>
          <img src={logo} alt="clearlee-logo" style={styles.logo} />
          <Loader inline active />
        </LogoFadeInContainer>
      </View>
    </div>
  );
};

export default FadeInLoader;
