import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Menu, Icon } from 'semantic-ui-react';
import {
  hideBackArrowArray,
} from './BackButtonRoutesServices';

const BackButton: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('');
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    setPreviousPath(currentPath);
    setCurrentPath(location.pathname);
  }, [location.pathname, currentPath]);

  useEffect(() => {
    return history.listen((location) => {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    });
  }, [history, currentPath, location.pathname])

  const goBack = () => {
    if (!!previousPath) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const pageStyles = {
    item: { margin: '0 -8px 0 0', paddingRight: 0 },
    icon: {
      color: 'var(--primary-color',
      margin: '0 -7px 0 -12px',
      padding: 0,
    },
  };

  return (
    <>
      {!hideBackArrowArray.includes(location.pathname) && (
        <Menu.Item style={pageStyles.item}>
          <Icon
            onClick={() => {
              goBack();
            }}
            style={pageStyles.icon}
            size='big'
            name='chevron left'
          />
        </Menu.Item>
      )}
    </>
  );
};
export default BackButton;
