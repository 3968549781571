import * as tf from '@tensorflow/tfjs';
import { Rank, Tensor } from '@tensorflow/tfjs';
import { getIsShell, getModelBinFile, getModelJsonFile } from '../../Services/NativeBridgeEventsService';

const processImage = async (sendJsonMessage: (p: any) => void, webcamRef: React.MutableRefObject<any>, user: any, testingService: any) => {
    let count = 0;

    const isShell = await getIsShell();

    const customIOHandler = {
        load: async () => {


            const modelJson = await getModelJsonFile();

            const weightsBinResponse = await getModelBinFile();

            const modelArtifacts = {
                modelTopology: modelJson.modelTopology, // This is the model topology
                format: modelJson.format,
                weightSpecs: modelJson.weightsManifest[0].weights, // Weight specifications
                weightData: weightsBinResponse, // The actual weight data
                trainingConfig: undefined,
                userDefinedMetadata: undefined
            };

            if (modelJson.trainingConfig != null) {
                modelArtifacts.trainingConfig = modelJson.trainingConfig;
            }

            if (modelJson.userDefinedMetadata != null) {
                modelArtifacts.userDefinedMetadata = modelJson.userDefinedMetadata;
            }

            return modelArtifacts
        }
    };

    const model = await tf.loadGraphModel(isShell ? customIOHandler : "/model/model.json");

    const interval = setInterval(async () => {
        if (!webcamRef.current) {
            return;
        }

        const image = webcamRef.current.getScreenshot({ height: 320, width: 320 });

        if (!webcamRef.current.canvas || !image) {
            return;
        }

        const base64Image: string = image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

        const input = tf.tidy(() => {
            const tensorf = tf.browser.fromPixels(webcamRef.current.canvas, 3).expandDims().toFloat() as Tensor<Rank.R3>;

            return tensorf;
        })

        const outputs = await model.executeAsync(input, ['detected_boxes', 'detected_scores', 'detected_classes']) as Tensor<Rank>[];
        tf.dispose(input);
        const predictions = await Promise.all(outputs.map(t => t.array()));
        tf.dispose(outputs);

        testingService.onTensorflowPrediction(predictions);

        if (testingService.isPreTestState() && count >= 2) {

            sendJsonMessage({
                action: 'image',
                image: base64Image,
                faceId: user.faceId,
            });

            sendJsonMessage({
                action: 'search-face',
                image: base64Image,
                faceId: user.faceId,
            });
            count = 0;
        } else {
            count++;
        }
    }, 500);

    return interval;
};

export default processImage;