import React from 'react';
import { Loader } from 'semantic-ui-react';
import styles from './ClearMindButtonStyles.module.css';

interface ClearMindButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  style?: object;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  opaque?: true;
}

const ClearMindButton: React.FC<ClearMindButtonProps> = (props) => {
  const { children, onClick, opaque, style, loading, disabled, size } = props;

  const buttonSize = () => {
    if (size === 'small') {
      return { padding: '1em .7em' };
    } else if (size === 'medium') {
      return { padding: '1.1em 1.2em' };
    } else {
      return { padding: '1.3em 1.6em' };
    }
  };

  return (
    <button
      onClick={onClick}
      style={style}
      className={opaque ? styles.clearMindButtonOpaque : styles.clearMindButton}
      disabled={disabled}
    >
      {loading ? (
        <p style={buttonSize()}>
          <Loader as='span' active inline size='tiny' />
        </p>
      ) : (
        <p style={buttonSize()}>{children}</p>
      )}
    </button>
  );
};

export default ClearMindButton;
