import { getSpeech } from "./SpeechRetrieverService";
import * as Sentry from "@sentry/react";
import i18n from "../i18n";
import { IPromptAudioProvider } from "./Audio/IPromptAudioProvider";
import { PromptAudioProviderShellDecorator } from "./Audio/PromptAudioProviderShellDecorator";
import { SpanishPromptAudioProvider } from "./Audio/SpanishPromptAudioProvider";
import { EnglishPromptAudioProvider } from "./Audio/EnglishPromptAudioProvider";

let audio: HTMLAudioElement;
let canPlay: boolean = true;
let personalizedPrompt: string;
let lastPrompt: any;

const spanishAudioProvider = new PromptAudioProviderShellDecorator(new SpanishPromptAudioProvider())
const enlishAudioProvider = new PromptAudioProviderShellDecorator(new EnglishPromptAudioProvider());

export const textToSpeech = async (text: string | undefined) => {
  switch (i18n.language) {
    case "es":
      return audioFactory(text, spanishAudioProvider);
    case "en":
    default:
      return audioFactory(text, enlishAudioProvider);
  }
};
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const playPrompt = async (audioPromptPromise: any) => {
  const audioPrompt = await audioPromptPromise;

  if (audioPrompt === lastPrompt || !audioPrompt) {
    return;
  }

  while (!canPlay) {
    await sleep(250);
  }

  if (!audio) {
    audio = new Audio(audioPrompt);
  } else {
    audio.src = audioPrompt;
  }

  try {
    canPlay = false;
    await audio.play();
    lastPrompt = audioPrompt;
    audio.addEventListener("ended", () => {
      canPlay = true;
    });
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    canPlay = true;
  }

  return;
};

export const getPersonalizedPrompt = async (text: string) => {
  personalizedPrompt = await getSpeech(text);
};

const audioFactory = (text: string | undefined, promptAudioProvider: IPromptAudioProvider) => {
  if (text === i18n.t("testing.getStarted")) {
    playPrompt(promptAudioProvider.letsGetStarted);
    return;
  }

  if (text === i18n.t("testing.checkList.centerYourself")) {
    playPrompt(promptAudioProvider.centerYourself);
    return;
  }

  if (text === i18n.t("testing.checkList.removeSunglasses")) {
    playPrompt(promptAudioProvider.pleaseRemoveSunglasses);
    return;
  }

  if (text?.toLowerCase().includes(i18n.t("testing.identityConfirm").toLowerCase())) {
    if (!!personalizedPrompt) {
      playPrompt(personalizedPrompt);
    } else {
      // If call fails, just play the prompt without the person's name
      playPrompt(promptAudioProvider.testCheckListSuccess);
    }
    return;
  }

  switch (text) {
    case "phone-orientation-check":
      playPrompt(promptAudioProvider.phoneOrientationCheck);
      break;

    case "validate-unopened-test-kit":
      playPrompt(promptAudioProvider.validateUnopenedTestKit);
      break;

    case "mouth-is-open":
      playPrompt(promptAudioProvider.mouthIsOpen);
      break;

    case "follow-instructions-prompt":
      playPrompt(promptAudioProvider.followInstructions);
      break;

    case "tongue-check-complete":
      playPrompt(promptAudioProvider.tongueCheckComplete);
      break;

    case "alcohol-check-complete":
      playPrompt(promptAudioProvider.tongueCheckComplete);
      break;

    case "validate-test-cube":
      playPrompt(promptAudioProvider.validateTestCube);
      break;

    case "test-cube-stay-in-frame":
      playPrompt(promptAudioProvider.testCubeInFrame);
      break;

    case "cube-wrapper-removal":
      playPrompt(promptAudioProvider.wrapperRemoval);
      break;

    case "cube-and-stick-in-view":
      playPrompt(promptAudioProvider.cubeAndStickInView);
      break;

    case "insert-test-stick-in-mouth-1":
      playPrompt(promptAudioProvider.insertTestStickInMouth1);
      break;

    case "insert-test-stick-in-mouth-2":
      playPrompt(promptAudioProvider.insertTestStickInMouth2);
      break;

    case "insert-test-stick-in-mouth-3":
      playPrompt(promptAudioProvider.insertTestStickInMouth3);
      break;

    case "insert-test-stick-in-mouth-4":
      playPrompt(promptAudioProvider.insertTestStickInMouth4);
      break;
    case "show-green-label":
      playPrompt(promptAudioProvider.showGreenLabel);
      break;
    case "remove-green-label":
      playPrompt(promptAudioProvider.removeGreenLabel);
      break;
    case "cube-and-face-in-frame":
      playPrompt(promptAudioProvider.cubeAndFaceInFrame);
      break;

    case "test-stick-process-message-1":
      playPrompt(promptAudioProvider.testStickProcessMessage1);
      break;

    case "test-stick-process-message-2":
      playPrompt(promptAudioProvider.testStickProcessMessage2);
      break;

    case "test-stick-process-message-3":
      playPrompt(promptAudioProvider.testStickProcessMessage3);
      break;

    case "test-stick-process-message-4":
      playPrompt(promptAudioProvider.testStickProcessMessage4);
      break;

    case "test-stick-process-message-5":
      playPrompt(promptAudioProvider.testStickProcessMessage5);
      break;

    case "place-stick-in-cube-1":
      playPrompt(promptAudioProvider.placeStickInCube1);
      break;

    case "place-stick-in-cube-2":
      playPrompt(promptAudioProvider.placeStickInCube2);
      break;

    case "cube-on-mat-prompt":
      playPrompt(promptAudioProvider.cubeOnMatPrompt);
      break;

    case "2-min-reminder-alcohol":
      playPrompt(promptAudioProvider.twoMinReminderAlcohol);
      break;

    case "last-reminder-alcohol":
      playPrompt(promptAudioProvider.lastReminderAlcohol);
      break;

    case "cube-on-mat-reminder":
      playPrompt(promptAudioProvider.cubeOnMatReminder);
      break;

    case "remain-in-view":
      playPrompt(promptAudioProvider.remainInView);
      break;

    case "remain-in-view-reminder":
      playPrompt(promptAudioProvider.remainInViewReminder);
      break;

    case "confirm-test-side-a-alcohol":
      playPrompt(promptAudioProvider.bell)
        .then(() => sleep(1000))
        .then(() => playPrompt(promptAudioProvider.confirmTestSideA));
      break;

    case "confirm-test-side-a":
      playPrompt(promptAudioProvider.confirmTestSideA);
      break;

    case "test-side-a-alcohol-confirmed":
      playPrompt(promptAudioProvider.testSideAConfirmed);
      break;

    case "test-side-a-confirmed":
      playPrompt(promptAudioProvider.testSideAConfirmed);
      break;

    case "confirm-test-side-b-alcohol":
      playPrompt(promptAudioProvider.confirmTestSideB);
      break;

    case "confirm-test-side-b":
      playPrompt(promptAudioProvider.confirmTestSideB);
      break;

    case "test-side-b-alcohol-confirmed":
      playPrompt(promptAudioProvider.testSideBAlcoholConfirmed);
      break;

    case "test-side-b-confirmed":
      playPrompt(promptAudioProvider.testSideBConfirmed);
      break;

    case "test-confirmed":
      playPrompt(promptAudioProvider.testConfirmed);
      break;

    default:
      break;
  }
};
