import { Form, FormField, Radio } from "semantic-ui-react";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import { observer } from "mobx-react-lite";

const LanguageUpdater: React.FC = () => {
  const { user } = useClearMindContext();

  const styles = {
    form: { textAlign: "left", width: "100px" },
  };

  return (
    <Form style={styles.form}>
      <FormField>
        <Radio label="English" name="radioGroup" value="English" checked={user.language === "en"} onClick={() => user.setUserLanguage("en")} />
      </FormField>
      <FormField>
        <Radio label="Spanish" name="radioGroup" value="Spanish" checked={user.language === "es"} onClick={() => user.setUserLanguage("es")} />
      </FormField>
    </Form>
  );
};

export default observer(LanguageUpdater);
