import React, { useState } from 'react';
import { Header, Checkbox, Segment } from 'semantic-ui-react';
import StepButtons from './StepButtons';
import i18n from "../../i18n";

interface TermsAndConditionsProps {
  termsLink: string;
  previousStep?: () => void;
  nextStep?: () => void;
  isFinalStep?: boolean;
  beforeStepChange: () => Promise<void>;
}

const TermsAndConditionsStep: React.FC<TermsAndConditionsProps> = (props) => {
  const { termsLink, isFinalStep, nextStep, beforeStepChange } = props
  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <Segment basic>
      <Header textAlign="center" as="h3" icon>
        {i18n.t("onboarding.termsAndConditions.header")}
      </Header>
      <Segment
        basic
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
        }}
      >
        <iframe
          style={{
            marginBottom: "16px",
            height: "420px",
          }}
          title="eula"
          src={termsLink}
          sandbox=""
        />
        <Checkbox
          label={<label style={{ color: "#333" }}> {i18n.t("onboarding.termsAndConditions.agree")}</label>}
          onChange={(_, d) => setTermsAccepted(!!d.checked)}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></Checkbox>
      </Segment>
      <StepButtons {...props} nextEnabled={termsAccepted} nextStep={nextStep} isFinalStep={isFinalStep} beforeStepChange={beforeStepChange} />
    </Segment>
  );
}

export default TermsAndConditionsStep
