import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useSignOutContext } from '../Authenticator/ClearMindAuthenticator';
import { useTranslation } from "react-i18next";

const MobileSliderMenu: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useClearMindContext();
  const { signOut } = useSignOutContext();
  const { t } = useTranslation();

  const logout = async () => {
    signOut();
    history.push("/");
  };

  const getHomePath = () => {
    return user.isMember ? "/member-dashboard" : "/supporter-dashboard";
  };

  return (
    <>
      <br />
      <br />
      <Menu.Item as={Link} active={location.pathname === getHomePath()} to={getHomePath()}>
        <Icon color="grey" name="home" />
        {t("dashboard.menu.home")}
      </Menu.Item>
      <Menu.Item as={Link} active={location.pathname === "/user-profile"} to="/user-profile">
        <Icon color="grey" name="settings" />
        {t("dashboard.menu.profile")}
      </Menu.Item>

      <Menu.Item as="a" target="#" href="https://clearlee.com/about-us/">
        <Icon color={"grey"} name="info circle" />
        {t("dashboard.menu.about")}

      </Menu.Item>

      <Menu.Item active={location.pathname === "/help"} as={Link} to="/help">
        <Icon color={"grey"} name="heart" />
        {t("dashboard.menu.help")}

      </Menu.Item>
      <Menu.Item name="sign-out" onClick={logout}>
        <Icon color="grey" name="sign-out" />
        {t("dashboard.menu.signOut")}
      </Menu.Item>
    </>
  );
};

export default MobileSliderMenu;
