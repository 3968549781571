import { types, Instance } from 'mobx-state-tree';
import IDrugPanelResults from './DrugPanelResults';
const TestResult = types
  .model('TestResults model', {
    testSessionId: types.string,
    alcoholResult: types.string,
    panelResults: types.array(IDrugPanelResults),
    dateTestTaken: types.string,
    details: types.maybe(types.string),
    testMethod: types.maybe(types.enumeration(['ClearMind', ''])),
    failedSubstances: types.maybe(types.array(types.string)),
    testResult: types.maybeNull(types.string),
  })
  .views((self) => ({
    get hasFailedSubstances() {
      return self.failedSubstances && self.failedSubstances.length > 0;
    },
    get isNotSelfReported() {
      return self.testResult !== 'Self Reported';
    },
    get failedSubstancesMaxIndex() {
      if (!self.failedSubstances) {
        return -1;
      }

      return self.failedSubstances.length - 1;
    },
    get dateKey() {
      return new Date(self.dateTestTaken).toDateString().replaceAll('/', '');
    },
    get day() {
      return new Date(self.dateTestTaken).getDate();
    },
    get localDateString() {
      return new Date(self.dateTestTaken).toLocaleDateString();
    },
    get year() {
      return new Date(self.dateTestTaken).getFullYear();
    },
    get passFailIcon() {
      if (self.testResult === 'Pass' || self.testResult === 'Pass - Allowable Substance' || self.testResult === 'Self Reported') {
        return 'check circle';
      }
      if (self.testResult === 'Detection') {
        return 'minus circle';
      }
      return 'ellipsis horizontal';
    },
    get passFailColor() {
      if (self.testResult === 'Pass' || self.testResult === 'Pass - Allowable Substance') {
        return '#00afb9';
      }
      if (self.testResult === 'Self Reported') {
        return '#4cc9f0';
      }
      if (self.testResult === 'Detection') {
        return '#bb4430';
      }
      return '#E5AC2E';
    },
  }));

export default TestResult;

export interface ITestResult extends Instance<typeof TestResult> { }
export interface ITestResultSnapshot extends Instance<typeof TestResult> { }

