import React from 'react';
import { Form, Header, Input, Segment } from 'semantic-ui-react';

interface DaysSoberProps {
  onChange: (e: any) => void;
  answer: any;
}
const DaysSoberInput: React.FC<DaysSoberProps> = (props) => {
  const { onChange, answer } = props;
  return (
    <Segment basic textAlign={'center'}>
      <Form style={{ margin: 'auto' }}>
        <Form.Field inline>
          <Input type={'number'} style={{ width: '90px' }} onChange={onChange} required value={answer || ''} />
          <label>
            <Header textAlign='center' as={'h3'} icon>
              days sober.
            </Header>
          </label>
        </Form.Field>
      </Form>
    </Segment>
  );
};

export default DaysSoberInput;
