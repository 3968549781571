import { observer } from 'mobx-react-lite';
import React from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Grid, Divider, Segment, Header } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import CircularProgress from '../CircularProgress/CircularProgress';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import styles from './Testing.module.css';
import { useTranslation } from "react-i18next";

const UploadFailure: React.FC = () => {
    const { promiseInProgress } = usePromiseTracker();
    const { testingS3Service, testingService } = useClearMindContext();
    const { t } = useTranslation();

    const retryUpload = async () => {
        try {
            testingService.testSession?.setTestCompleted(true);
            await trackPromise(Promise.all([testingS3Service.retryFailedUploads(), testingService.testSession?.update()]));

            if (testingS3Service.allVideoChunksUploadedSuccessfully) {
                testingService.onStateTransition("retry-upload-success");
            }
        } catch {
            console.log('Retry upload failed');
        }
    }

    return (
      <>
        <CircularProgress active={promiseInProgress} percentComplete={testingS3Service.percentComplete} />
        <FrostedFrame>
          <Grid textAlign="center" stackable>
            <Grid.Row>
              <Grid.Column>
                <h1 className={styles.header}>{t("testing.uploadFailure.header")}</h1>
                <Divider />
                <Segment style={{ maxWidth: "650px", margin: "auto" }} basic textAlign="left">
                  <Header style={{ paddingBottom: "1em" }} textAlign="center">
                    {t("testing.uploadFailure.description")}
                  </Header>
                </Segment>
                <ClearMindButton onClick={retryUpload} opaque loading={promiseInProgress}>
                  {t("testing.uploadFailure.retryButton")}
                </ClearMindButton>
                <Segment style={{ maxWidth: "650px", margin: "auto" }} basic textAlign="left">
                  {t("testing.uploadFailure.avoidance")}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FrostedFrame>
      </>
    );
}

export default observer(UploadFailure);