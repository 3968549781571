import React, { useState } from 'react';
import { Form, Icon, Input, Segment } from 'semantic-ui-react';

interface tickerProps {
  onChange: (e: any) => void;
  value: any;
}
const Ticker: React.FC<tickerProps> = (props) => {
  const { onChange, value } = props;
  let [tickerValue, setTickerValue] = useState(+value);

  const onIconClick = (step: number) => {
    const newTickerValue = tickerValue + step;
    if (newTickerValue < 0) {
      return;
    }
    setTickerValue(newTickerValue);
    onChange({ target: { value: newTickerValue.toString() } });
  };

  const onInputChange = (event: any) => {
    const inputValue = +event.target.value;
    if (isNaN(inputValue) || inputValue <= 0) {
      setTickerValue(0);
      onChange({ target: { value: '' } });
      event.cancel = true;
      return;
    }
    setTickerValue(inputValue);
    onChange({ target: { value: inputValue.toString() } });
  };

  return (
    <Segment padded={'very'} basic textAlign={'center'}>
      <Form style={{ margin: 'auto' }}>
        <Form.Field inline>
          <label>
            <Icon
              style={{ padding: '.5rem' }}
              name='minus'
              onClick={() => onIconClick(-1)}
            />
          </label>
          <Input
            type={'number'}
            style={{ width: '60px' }}
            onChange={onInputChange}
            value={tickerValue === 0 ? '' : tickerValue}
          />
          <label>
            &nbsp;&nbsp;
            <Icon name='plus' onClick={() => onIconClick(1)} />
          </label>
        </Form.Field>
      </Form>
    </Segment>
  );
};

export default Ticker;
