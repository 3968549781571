import React from 'react';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import { observer } from 'mobx-react-lite';
import ConsentAndReleaseStep from '../Components/Testing/ConsentAndReleaseStep';
import RequiredMaterialsStep from '../Components/Testing/RequiredMaterialStep';
import TestingIntroduction from '../Components/Testing/TestingIntroduction';
import ResponsiveContainer from '../Components/Layout/ResponsiveContainer';
import MobileDeviceRequired from '../Components/Testing/MobileDeviceRequired'
import SupporterPretestStep from '../Components/Testing/PretestStep/SupporterPretestStep';
import LegalDisclaimer from '../Components/Testing/LegalDisclaimer';
import TestingVerificationStep from "../Components/Testing/TestingVerificationStep";
import PreTestingHowToVideoPage from "../Components/Testing/HowToVideoPreTestingPage";

const PretestingPage: React.FC = () => {
  const { testingService, user } = useClearMindContext();
  if (user.isMember) {
    if (testingService.currentState === "mobile-device-required") {
      return <ResponsiveContainer children={<MobileDeviceRequired />} title={""} />;
    }
    if (testingService.currentState === "introduction") {
      return <ResponsiveContainer children={<TestingIntroduction />} title={""} />;
    }
    if (testingService.currentState === "legal-disclaimer") {
      return <ResponsiveContainer children={<LegalDisclaimer />} title={""} />;
    }
    if (testingService.currentState === "how-to-video") {
      return <ResponsiveContainer children={<PreTestingHowToVideoPage />} title={""} />;
    }
    if (testingService.currentState === "required-materials") {
      return <ResponsiveContainer children={<RequiredMaterialsStep />} title={""} />;
    }
    if (testingService.currentState === "testing-verification") {
      return <ResponsiveContainer children={<TestingVerificationStep />} title={""} />;
    }
    if (testingService.currentState === "consent-release") {
      return <ResponsiveContainer children={<ConsentAndReleaseStep />} title={""} />;
    }
  }
  return <ResponsiveContainer children={<SupporterPretestStep />} title={""} />;
};

export default observer(PretestingPage);
