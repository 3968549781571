import { Instance, types } from 'mobx-state-tree';

const Step = types.model('Step', {
  index: types.number,
  timestamp: types.number,
  text: types.string,
});
export default Step;

export interface IStep extends Instance<typeof Step> {}
export interface IStepMarks extends Instance<typeof Step[]> {}
