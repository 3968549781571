import React from 'react';
import { Segment } from 'semantic-ui-react';
import styles from './RectangleQuoteBox.module.css';

interface RectangleWidgetProps {
  image?: string;
}
const RectangleWidget: React.FC<RectangleWidgetProps> = (props) => {
  const { children } = props;
  return (
    <Segment basic>
      <div className={styles.rectangleFrame}>
        <div className={styles.rectangleFrameContents}>{children}</div>
      </div>
    </Segment>
  );
};

export default RectangleWidget;
