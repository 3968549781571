// Sent when the user is about to take a test.
// Its purpose is to let the mobile shell that
// that it needs to check for the camera permission.
export const sendCameraPermissionCheckEvent = () => {
    if (window.flutter_inappwebview?.callHandler) {
        window.flutter_inappwebview.callHandler("cameraPermissionCheck");
    }
}

export const putAuthTokensInStorage = (tokens: any) => {
    if (window.flutter_inappwebview?.callHandler) {
        window.flutter_inappwebview.callHandler("putAuthTokensInStorage", JSON.stringify(tokens));
    }
}

export const getAuthTokensFromStorage = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("getAuthTokensFromStorage");
    }
}

export const clearAllTokens = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("clearAllTokens");
    }
}

export const biometricAuthenticateUser = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("biometricAuthenticateUser");
    }
}

export const getBiometricIdEnabled = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("getBiometricIdEnabled");
    }
}

export const getEnrolledBiometrics = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("getEnrolledBiometrics")
    }
}

export const setBiometricIdEnabled = (value: boolean) => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("setBiometricIdEnabled", value.toString());
    }
}

export const getIsShell = async () => {
    if (window.flutter_inappwebview?.callHandler) {
        const isShell = await window.flutter_inappwebview.callHandler("getIsShell")
        console.log("IS SHELL", isShell)
        return !!isShell;
    }

    return false;
}

export const getModelBinFile = async () => {
    if (window.flutter_inappwebview?.callHandler) {
        const base64 = await window.flutter_inappwebview.callHandler("getBinaryAsset", 'assets/model/weights.bin');
        return receiveDataFromFlutter(base64);
    }
}

export const playAudioPrompt = async (path: string) => {
    if (window.flutter_inappwebview?.callHandler) {
        await window.flutter_inappwebview.callHandler("playAudioPrompt", path);
    }
}

export const getModelJsonFile = () => {
    if (window.flutter_inappwebview?.callHandler) {
        return window.flutter_inappwebview.callHandler("getModelJsonFile").then((response: string) => JSON.parse(response));
    }
}

const receiveDataFromFlutter = async (base64Data: string) => {
    const binaryData = atob(base64Data);
    const bytes = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
        bytes[i] = binaryData.charCodeAt(i);
    }

    return typedArrayToBuffer(bytes);
}

function typedArrayToBuffer(array: Uint8Array): ArrayBuffer {
    return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
}
