import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { IBehaviorContract } from '../../../Models/BehaviorContract';
import StepButtons from '../StepButtons';
import styles from './FinalStep.module.css';
import i18n from '../../../i18n';

const FinalStep: React.FC<{
  behaviorContract: IBehaviorContract;
  message: string;
  route: string;

  submit?: () => void;
}> = (props) => {
  const history = useHistory();
  const { submit, route, message } = props;
  const submitHandler = async () => {
    if (submit) {
      submit();
    }
    history.push(route);
  };

  return (
    <>
      <Segment textAlign={"center"} basic>
        <Header style={{ fontSize: "12vw" }} className={styles.finished}>
          {i18n.t("onboarding.finalStep.congrats")}
        </Header>
        <Header as={"h3"}>{message}</Header>

        <br />
        <StepButtons {...props} submit={submitHandler} isFinalStep={true} nextEnabled={true} />
      </Segment>
    </>
  );
};

export default observer(FinalStep);
