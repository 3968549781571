import axios from 'axios';
import * as Sentry from "@sentry/react";
import { IOnSiteTestingModel } from '../Models/OnSiteTestingModel';

export const getTestingSites = async (zipCode: string) => {
    try {
        const response = await axios.get<IOnSiteTestingModel[]>(`/testing-sites?zipCode=${zipCode}`);
        return response.data;
    } 
    catch (error: any) {
        if (error.response?.status === 404) {
            return undefined;
        } else {
            Sentry.captureException(error);
        }
        throw error;
    }
};

export const createOnSiteTestingOrder = async (userId: string, collectionSiteId: string) => {
    try {
        const response = await axios.post('/create-on-site-test-order',
            {
                userId: userId,
                collectionSiteId: collectionSiteId
            }
        )
        return response.status
    } 
    catch (error: any) {
        if (error.response?.status === 404) {
            return undefined;
        } else {
            Sentry.captureException(error);
        }
        throw error;
    }

}