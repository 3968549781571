import testCheckListSuccess from "../../Assets/SpanishPrompts/test-checklist-success.mp3";
import letsGetStarted from "../../Assets/SpanishPrompts/lets-get-started.mp3";
import phoneOrientationCheck from "../../Assets/SpanishPrompts/phone-orientation-check.mp3";
import centerYourself from "../../Assets/SpanishPrompts/center-yourself.mp3";
import mouthIsOpen from "../../Assets/SpanishPrompts/mouth-is-open.mp3";
import tongueCheckComplete from "../../Assets/SpanishPrompts/tongue-check-complete.mp3";
import validateTestCube from "../../Assets/SpanishPrompts/validate-test-cube.mp3";
import testCubeInFrame from "../../Assets/SpanishPrompts/test-cube-stay-in-frame.mp3";
import wrapperRemoval from "../../Assets/SpanishPrompts/cube-wrapper-removal.mp3";
import cubeAndStickInView from "../../Assets/SpanishPrompts/cube-and-stick-in-view.mp3";
import validateUnopenedTestKit from "../../Assets/SpanishPrompts/validate-unopened-test-kit.mp3";
import insertTestStickInMouth1 from "../../Assets/SpanishPrompts/insert-test-stick-in-mouth-1.mp3";
import followInstructions from "../../Assets/SpanishPrompts/follow-instructions-prompt.mp3";
import insertTestStickInMouth2 from "../../Assets/SpanishPrompts/insert-test-stick-in-mouth-2.mp3";
import insertTestStickInMouth3 from "../../Assets/SpanishPrompts/insert-test-stick-in-mouth-3.mp3";
import insertTestStickInMouth4 from "../../Assets/SpanishPrompts/insert-test-stick-in-mouth-4.mp3";
import showGreenLabel from "../../Assets/SpanishPrompts/show-green-label.mp3";
import removeGreenLabel from "../../Assets/SpanishPrompts/remove-green-label.mp3";
import cubeAndFaceInFrame from "../../Assets/SpanishPrompts/cube-and-face-in-frame.mp3";
import testStickProcessMessage1 from "../../Assets/SpanishPrompts/test-stick-process-message-1.mp3";
import testStickProcessMessage2 from "../../Assets/SpanishPrompts/test-stick-process-message-2.mp3";
import testStickProcessMessage3 from "../../Assets/SpanishPrompts/test-stick-process-message-3.mp3";
import testStickProcessMessage4 from "../../Assets/SpanishPrompts/test-stick-process-message-4.mp3";
import testStickProcessMessage5 from "../../Assets/SpanishPrompts/test-stick-process-message-5.mp3";
import placeStickInCube1 from "../../Assets/SpanishPrompts/place-stick-in-cube-1.mp3";
import placeStickInCube2 from "../../Assets/SpanishPrompts/place-stick-in-cube-2.mp3";
import cubeOnMatPrompt from "../../Assets/SpanishPrompts/cube-on-mat-prompt.mp3";
import cubeOnMatReminder from "../../Assets/SpanishPrompts/cube-on-mat-reminder.mp3";
import remainInView from "../../Assets/SpanishPrompts/remain-in-view.mp3";
import remainInViewReminder from "../../Assets/SpanishPrompts/remain-in-view-reminder.mp3";
import confirmTestSideA from "../../Assets/SpanishPrompts/confirm-test-side-a.mp3";
import testSideAConfirmed from "../../Assets/SpanishPrompts/test-side-a-confirmed.mp3";
import confirmTestSideB from "../../Assets/SpanishPrompts/confirm-test-side-b.mp3";
import testSideBAlcoholConfirmed from "../../Assets/SpanishPrompts/test-side-b-alcohol-confirmed.mp3";
import testSideBConfirmed from "../../Assets/SpanishPrompts/test-side-b-confirmed.mp3";
import testConfirmed from "../../Assets/SpanishPrompts/test-confirmed.mp3";
import pleaseRemoveSunglasses from "../../Assets/SpanishPrompts/please-remove-sunglasses.mp3";
import { IPromptAudioProvider } from "./IPromptAudioProvider";
import twoMinReminderAlcohol from "../../Assets/SpanishPrompts/2-min-reminder-alcohol.mp3";
import lastReminderAlcohol from "../../Assets/SpanishPrompts/last-reminder-alcohol.mp3";
import bell from "../../Assets/EnglishPrompts/cowbell.mp3";
export class SpanishPromptAudioProvider implements IPromptAudioProvider {
    shellLanguageFolder = "spanish";
    public get testCheckListSuccess() { return Promise.resolve(testCheckListSuccess); }
    public get letsGetStarted() { return Promise.resolve(letsGetStarted); }
    public get phoneOrientationCheck() { return Promise.resolve(phoneOrientationCheck); }
    public get centerYourself() { return Promise.resolve(centerYourself); }
    public get mouthIsOpen() { return Promise.resolve(mouthIsOpen); }
    public get tongueCheckComplete() { return Promise.resolve(tongueCheckComplete); }
    public get validateTestCube() { return Promise.resolve(validateTestCube); }
    public get testCubeInFrame() { return Promise.resolve(testCubeInFrame); }
    public get wrapperRemoval() { return Promise.resolve(wrapperRemoval); }
    public get cubeAndStickInView() { return Promise.resolve(cubeAndStickInView); }
    public get validateUnopenedTestKit() { return Promise.resolve(validateUnopenedTestKit); }
    public get insertTestStickInMouth1() { return Promise.resolve(insertTestStickInMouth1); }
    public get followInstructions() { return Promise.resolve(followInstructions); }
    public get insertTestStickInMouth2() { return Promise.resolve(insertTestStickInMouth2); }
    public get insertTestStickInMouth3() { return Promise.resolve(insertTestStickInMouth3); }
    public get insertTestStickInMouth4() { return Promise.resolve(insertTestStickInMouth4); }
    public get showGreenLabel() { return Promise.resolve(showGreenLabel); }
    public get removeGreenLabel() { return Promise.resolve(removeGreenLabel); }
    public get cubeAndFaceInFrame() { return Promise.resolve(cubeAndFaceInFrame); }
    public get testStickProcessMessage1() { return Promise.resolve(testStickProcessMessage1); }
    public get testStickProcessMessage2() { return Promise.resolve(testStickProcessMessage2); }
    public get testStickProcessMessage3() { return Promise.resolve(testStickProcessMessage3); }
    public get testStickProcessMessage4() { return Promise.resolve(testStickProcessMessage4); }
    public get testStickProcessMessage5() { return Promise.resolve(testStickProcessMessage5); }
    public get placeStickInCube1() { return Promise.resolve(placeStickInCube1); }
    public get placeStickInCube2() { return Promise.resolve(placeStickInCube2); }
    public get cubeOnMatPrompt() { return Promise.resolve(cubeOnMatPrompt); }
    public get cubeOnMatReminder() { return Promise.resolve(cubeOnMatReminder); }
    public get remainInView() { return Promise.resolve(remainInView); }
    public get remainInViewReminder() { return Promise.resolve(remainInViewReminder); }
    public get confirmTestSideA() { return Promise.resolve(confirmTestSideA); }
    public get testSideAConfirmed() { return Promise.resolve(testSideAConfirmed); }
    public get confirmTestSideB() { return Promise.resolve(confirmTestSideB); }
    public get testSideBAlcoholConfirmed() { return Promise.resolve(testSideBAlcoholConfirmed); }
    public get testSideBConfirmed() { return Promise.resolve(testSideBConfirmed); }
    public get testConfirmed() { return Promise.resolve(testConfirmed); }
    public get pleaseRemoveSunglasses() { return Promise.resolve(pleaseRemoveSunglasses); }
    public get twoMinReminderAlcohol() { return Promise.resolve(twoMinReminderAlcohol); }
    public get lastReminderAlcohol() { return Promise.resolve(lastReminderAlcohol); }
    public get bell() { return Promise.resolve(bell); }
}
