import React, { useEffect, useState } from 'react';
import { ITestResult } from '../../Models/TestResult';
import styles from './CalendarStyles.module.css';
import { Button, Confirm, Divider, Header, Modal, Popup } from "semantic-ui-react";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import { GetResultsReportPdf } from "../../Services/GetResultsReportPdf";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import PdfViewer from "../PdfComponent/PdfViewer";
import SharePdfButton from "../SharePdfButtonComponent/SharePdfButton";
import { IScheduledEvent } from "../../Models/ScheduledEvent";
import EventDisplay from "./EventDisplay";
import EventScheduleComponent from "./EventScheduleComponent";
import { observer } from 'mobx-react-lite';
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";

interface DayComponentProps {
  date: Date;
  events?: IScheduledEvent[];
  testResultsForDate?: ITestResult[];
}
const DayComponent: React.FC<DayComponentProps> = (props) => {
  const { testResultsForDate = [], date, events } = props;
  const { user, behaviorContract } = useClearMindContext();
  const { promiseInProgress } = usePromiseTracker({ area: "pdf-download" });
  const { promiseInProgress: deleteInProgress } = usePromiseTracker({ area: "delete" });

  const [editEventVisible, setEditEventVisible] = useState(false);
  const [updateEvent, setUpdateEvent] = useState<IScheduledEvent>();
  const [hasEvents, setHasEvents] = useState<boolean>(false);
  const [hasTestSessions, setHasTestSessions] = useState<boolean>(false);
  const [testSessionsOrderedByTime, setTestSessionsOrderedByTime] = useState<ITestResult[]>([]);
  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<IScheduledEvent>();
  const { t } = useTranslation();

  const [pdf, setPdf] = useState("");

  useEffect(() => {
    const eventsPermissions = () => {
      const hasEvents = !!events && events.length > 0;
      if (user.isSupporter) {
        if (!hasEvents) {
          return false;
        }
        return behaviorContract.eventsVisibleToSupporter;
      }

      return hasEvents;
    };
    setHasEvents(eventsPermissions);

    const testResultsFound = () => {
      const hasTestSession = !!testResultsForDate && testResultsForDate.length > 0;
      return hasTestSession;
    }
    setHasTestSessions(testResultsFound)
    const sortTestResultsByDate = (testResults: ITestResult[]): ITestResult[] => {
      return testResults.sort((a, b) => {
        const dateA = new Date(a.dateTestTaken).getTime();
        const dateB = new Date(b.dateTestTaken).getTime();
        return dateB - dateA; // Descending order: most recent first
      })
    };
    
    if (testResultsFound()) {
      const sessions = sortTestResultsByDate(testResultsForDate)
      setTestSessionsOrderedByTime(sessions);
    }

  }, [behaviorContract.eventsVisibleToSupporter, events, user.isSupporter, testResultsForDate]);


  const viewPdf = async (testResult: ITestResult) => {
    if (!testResult) {
      return;
    }
    var pdfBase64 = await trackPromise(GetResultsReportPdf(testResult.testSessionId), "pdf-download");

    setPdf(pdfBase64);
  };

  const deleteEvent = async () => {
    if (!eventToDelete) {
      return;
    }

    await trackPromise(eventToDelete.deleteEvent(), "delete");
    setEventToDelete(undefined);
    setShowDeleteConfirm(false);
  };

  const onDeleteConfirmCancel = () => {
    setShowDeleteConfirm(false);
    setEventToDelete(undefined);
  };

  const getPopupContent = () => {
    if (testResultsForDate.length <= 0 && !events) {
      return <p style={{ color: "#333" }}>No test on this date.</p>;
    }

    return (
      <React.Fragment key={`popup-${date.getMonth()}-${date.getDay()}`}>
        <Header color="blue">
          {date.toLocaleDateString()}
          <Divider />
          {hasTestSessions && testSessionsOrderedByTime?.map((testResult, index) => (
            <div key={`testResult-${index}`}>
              {testResult?.isNotSelfReported && t("results.cards.result")}
              <span style={{ color: `${testResult?.passFailColor}` }}>{t(testResult?.testResult ?? "")}</span>
              {testResult?.hasFailedSubstances && (
                <div>
                  {t("results.cards.failedSubstances")}
                  <Header color="blue" className={styles.header}>
                    {testResult.failedSubstances?.map((drug, index) => (
                      <React.Fragment key={`substance-${index}`}>
                        {drug}
                        {index !== testResult.failedSubstancesMaxIndex && ","}
                        <br />
                      </React.Fragment>
                    ))}
                  </Header>
                  <Divider />
                </div>
              )}
        {testResult?.isNotSelfReported && (
                <React.Fragment>
                  <div style={{ display: "inline-block", whiteSpace: "nowrap", marginBottom: "8px" }}>
                    <ClearMindButton size={"small"} opaque onClick={() => viewPdf(testResult)} loading={promiseInProgress}>
                      {t("results.cards.viewPDF")}
                    </ClearMindButton>{" "}
                    <SharePdfButton opaque testResult={testResult} />
                  </div>
                  {hasEvents && <Divider />}
                </React.Fragment>
              )}
            </div>
          ))}
        </Header>

        {hasEvents && (
          <div style={{ minWidth: 200 }}>
            {events?.map((event, index) => (
              <React.Fragment key={`event-${event.dateKey}-${index}`}>
                <EventDisplay event={event} />

                {user.isMember && (
                  <React.Fragment>
                    <Button
                      onClick={() => {
                        setUpdateEvent(event);
                        setEditEventVisible(!editEventVisible);
                      }}
                      size="mini"
                    >
                      Edit
                    </Button>
                    <Button
                      size="mini"
                      color="red"
                      floated="right"
                      onClick={() => {
                        setShowDeleteConfirm(true);
                        setEventToDelete(event);
                      }}
                    >
                      Delete
                    </Button>
                  </React.Fragment>
                )}

                {index !== events.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {window.innerWidth < 500 ? (
        <Popup
          basic
          trigger={
            <Button style={{ backgroundColor: `${testSessionsOrderedByTime![0] ? testSessionsOrderedByTime![0].passFailColor : "transparent"}` }} id={styles.dayRoundedBox}>
              {hasEvents && <div style={{ backgroundColor: "#ff4800", height: "5px", width: "5px", borderRadius: "50%", position: "absolute", top: 5, left: 5 }}></div>}

              <div className={styles.dayRoundContent}>
                <p style={{ color: `${testSessionsOrderedByTime![0] ? "#fff" : "#333"}` }}>{date.getDate()}</p>
              </div>
            </Button>
          }
          content={getPopupContent()}
          offset={[0, 0]}
          position="bottom center"
        />
      ) : (
        <Popup
          position="bottom center"
          content={getPopupContent()}
          on="click"
          pinned
          trigger={
            <Button style={{ backgroundColor: `${testSessionsOrderedByTime![0] ? testSessionsOrderedByTime![0].passFailColor : "transparent"}` }} id={styles.dayRoundedBox}>
              {hasEvents && <div style={{ backgroundColor: "#ff4800", height: "8px", width: "8px", borderRadius: "50%", position: "absolute", top: 5, left: 6 }}></div>}
              <div className={styles.dayRoundContent}>
                <p style={{ color: `${testSessionsOrderedByTime![0] ? "#fff" : "#333"}` }}>{date.getDate()}</p>
              </div>
            </Button>
          }
        />
      )}
      <Confirm
        open={showDeleteConfrim}
        confirmButton={{ loading: deleteInProgress, content: "Yes" }}
        cancelButton="No"
        content="Are you sure you want to delete your event?"
        onCancel={onDeleteConfirmCancel}
        onConfirm={deleteEvent}
      />
      <Modal
        basic
        style={{ marginTop: -80, height: "80%" }}
        open={!!pdf}
        content={<PdfViewer pdfBase64={pdf} />}
        actions={[
          {
            key: "done",
            content: "Done",
            positive: true,
            onClick: () => setPdf(""),
          },
        ]}
      />
      <EventScheduleComponent updateEvent={updateEvent} setVisible={setEditEventVisible} visible={editEventVisible} />
    </>
  );
};

export default observer(DayComponent);
