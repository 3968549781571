import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import {
  Divider,
  Header,
  HeaderContent,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { IBehaviorContractQuestion } from '../../Models/BehaviorContractQuestion';
import { IBehaviorContract } from '../../Models/BehaviorContract';
import { IUser } from '../../Models/User';
import StepButtons from './StepButtons';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import i18n from "../../i18n";

interface ReviewStepProps {
  behaviorContract: IBehaviorContract;
  inviteStep: number;
  goToStep?: (step: number) => void;
  beforeStepChange: () => Promise<void>;
  nextStep?: () => void;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isActive?: boolean;
}

const ReviewStep: React.FC<ReviewStepProps> = (props) => {
  const { user } = useClearMindContext();
  const { behaviorContract, goToStep, inviteStep, nextStep, beforeStepChange, setIsEdit, isActive } = props;
  const headers = { color: '#00CCCB' };

  if (!isActive) {
    return null;
  }

  if (!goToStep) {
    throw Error('Review Step not configured correctly');
  }

  const isDisplayableStepType = (step: IBehaviorContractQuestion) => {
    return step.inputType !== 'label';
  };

  const renderStep = (step: IBehaviorContractQuestion[], index: number) => {
    return (
      <>
        {step.map(
          (step) =>
            isDisplayableStepType(step) && (
              <Header as="h4">
                <Header.Content style={headers}>
                  {i18n.t(`${step.question}`)}
                  <Header.Subheader>
                    <Icon
                      link
                      size="small"
                      name="edit"
                      onClick={() => {
                        setIsEdit(true);
                        goToStep(index + 2);
                      }}
                    />
                    {!!step.answer ? step.answer : "None"}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            )
        )}
        <Divider />
      </>
    );
  };

  const renderSupporters = (supporters: IUser[]) => {
    return (
      <>
        <Header>
          <HeaderContent style={headers}>{i18n.t("onboarding.reviewStep.supporters")}</HeaderContent>
        </Header>
        {supporters.map((supporter: IUser, index: number) => (
          <Header as="h4" key={`supporter-${index}`}>
            <Header.Content>
              {user.isMember && user.isInitialUser && (
                <Icon
                  size="small"
                  link
                  name="edit"
                  onClick={() => {
                    setIsEdit(true);
                    goToStep(inviteStep);
                  }}
                />
              )}
              {supporter.name}
              <Header.Subheader>{supporter.email}</Header.Subheader>
            </Header.Content>
          </Header>
        ))}
      </>
    );
  };

  const renderMember = () => {
    return (
      <>
        <Header>
          <Header.Content style={headers}>
            {i18n.t("onboarding.reviewStep.member")}
            <Header.Subheader>
              {user.isSupporter && user.isInitialUser && (
                <Icon
                  size="small"
                  link
                  name="edit"
                  onClick={() => {
                    setIsEdit(true);
                    goToStep(inviteStep);
                  }}
                />
              )}
              {behaviorContract.member?.name}
            </Header.Subheader>
            <Header.Subheader>{behaviorContract.member?.email}</Header.Subheader>
          </Header.Content>
        </Header>
      </>
    );
  };

  return (
    <Segment padded basic>
      <Header style={headers} as={"h2"}>
        {i18n.t("onboarding.reviewStep.header")}
      </Header>
      {renderMember()}
      {behaviorContract.questions.map(renderStep)}
      {renderSupporters(behaviorContract.supporters)}
      <StepButtons {...props} nextEnabled={true} nextStep={nextStep} beforeStepChange={beforeStepChange} />
    </Segment>
  );
};

export default observer(ReviewStep);
