import axios from 'axios';
import * as Sentry from "@sentry/react";
import i18n from '../i18n';

export const getSpeech = async (text: string): Promise<string> => {
    let url: string = "";

    try {
        const response = await axios.get(`/speech-synthesis?text=${encodeURIComponent(text)}&language=${i18n.language}`);
        console.log(response.status);
        console.log(response.statusText);

        if (response.status > 299) {
            return url;
        }

        const encodedMediaString = response.data;
        if (!encodedMediaString) {
            return url;
        }

        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });

        url = URL.createObjectURL(blob);
    } catch (e) {
        Sentry.captureException(e);
    }

    return url;
}
