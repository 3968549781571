import React, { useEffect, useState } from "react";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { Button, Card, Confirm, Divider, Header, Icon, Modal } from "semantic-ui-react";
import { ITestResult } from "../../Models/TestResult";
import { GetResultsReportPdf } from "../../Services/GetResultsReportPdf";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import PdfViewer from "../PdfComponent/PdfViewer";
import SharePdfButton from "../SharePdfButtonComponent/SharePdfButton";
import styles from "./testResultCardStyle.module.css";
import { IScheduledEvent } from "../../Models/ScheduledEvent";
import EventDisplay from "../MemberCalendar/EventDisplay";
import EventScheduleComponent from "../MemberCalendar/EventScheduleComponent";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";

interface TestResultsCardProps {
  testResult?: ITestResult;
  events?: IScheduledEvent[];
  date: Date;
}

const TestResultsCard: React.FC<TestResultsCardProps> = (props) => {
  const { testResult, events, date } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [pdf, setPdf] = useState("");
  const [hasEvents, setHasEvents] = useState<boolean>(false);
  const [editEventVisible, setEditEventVisible] = useState(false);
  const [updateEvent, setUpdateEvent] = useState<IScheduledEvent>();
  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<IScheduledEvent>();
  const { promiseInProgress: deleteInProgress } = usePromiseTracker({ area: "delete" });
  const { user, behaviorContract } = useClearMindContext();
  const { t } = useTranslation();

  useEffect(() => {
    const eventsPermissions = () => {
      const hasEvents = !!events && events.length > 0;

      if (user.isSupporter) {
        if (!hasEvents) {
          return false;
        }
        return behaviorContract.eventsVisibleToSupporter;
      }
      return hasEvents;
    };

    setHasEvents(eventsPermissions);
  }, [behaviorContract.eventsVisibleToSupporter, events, user.isSupporter]);

  const { promiseInProgress } = usePromiseTracker({ area: `pdf-download-${testResult?.testSessionId}` });

  const viewPdf = async (e: any) => {
    e.stopPropagation();
    if (!testResult) {
      return;
    }
    var pdfBase64 = await trackPromise(GetResultsReportPdf(testResult.testSessionId), `pdf-download-${testResult.testSessionId}`);
    setPdf(pdfBase64);
  };

  const getResult = () => {
    return (
      <Header style={{ margin: "auto 0" }} color="grey">
        {testResult?.isNotSelfReported && t("results.cards.result")} <span style={{ color: `${testResult?.passFailColor}` }}>{t(testResult?.testResult ?? "")}</span>
      </Header>
    );
  };

  const onDeleteConfirmCancel = () => {
    setShowDeleteConfirm(false);
    setEventToDelete(undefined);
  };

  const deleteEvent = async () => {
    if (!eventToDelete) {
      return;
    }

    await trackPromise(eventToDelete.deleteEvent(), "delete");
    setEventToDelete(undefined);
    setShowDeleteConfirm(false);
  };

  const getFailedSubstances = () => {
    if (testResult?.failedSubstances !== undefined) {
      return (
        <>
          <Divider />
          <Header style={{ margin: "auto 0" }} color="grey">
            {t("results.cards.failedSubstances")}
          </Header>
          {testResult?.failedSubstances.map((drug) => (
            <span>{drug}, </span>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <Card
        className={styles.cardStyle}
        onClick={() => setShowDetails(!showDetails)}
        style={{
          background: "var(--secondary-color)",
          borderRadius: 15,
          borderWidth: "10px",
          backdropFilter: "blur(150px)",
          border: "1.5px solid #53A8B1",
          padding: 0,
        }}
      >
        <Card.Content>
          {hasEvents && <div className={styles.eventDot} />}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button onClick={() => setShowDetails(!showDetails)} className={styles.linkButton}>
              {t("results.cards.details")}
            </button>
            <p style={{ margin: "0px" }}>{date.toLocaleDateString()}</p>
            <Icon name={testResult?.passFailIcon} style={{ color: `${testResult?.passFailColor}` }} size="big" />
          </div>
          {showDetails && (
            <>
              <Card.Description style={{ color: "#fff" }}>
                {getResult()}
                {getFailedSubstances()}
              </Card.Description>
              {testResult?.isNotSelfReported && (
                <>
                  <Divider />
                  <ClearMindButton size={"small"} loading={promiseInProgress} onClick={viewPdf}>
                    {t("results.cards.viewPDF")}
                  </ClearMindButton>
                  <SharePdfButton testResult={testResult} />
                </>
              )}
              {hasEvents && (
                <div style={{ minWidth: 200 }}>
                  <Divider />
                  {events?.map((event, index) => (
                    <React.Fragment key={`event-${event.dateKey}-${index}`}>
                      <EventDisplay darkMode event={event} />
                      {user.isMember && (
                        <>
                          <Button
                            onClick={() => {
                              setUpdateEvent(event);
                              setEditEventVisible(!editEventVisible);
                            }}
                            size="mini"
                          >
                            {t("results.cards.edit")}
                          </Button>
                          <Button
                            size="mini"
                            color="red"
                            floated="right"
                            onClick={() => {
                              setShowDeleteConfirm(true);
                              setEventToDelete(event);
                            }}
                          >
                            {t("results.cards.delete")}
                          </Button>
                        </>
                      )}

                      {index !== events.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </>
          )}
        </Card.Content>
        <Confirm
          open={showDeleteConfrim}
          confirmButton={{ loading: deleteInProgress, content: t("results.cards.yes") }}
          cancelButton={t("results.cards.no")}
          content={t("results.cards.deleteConfirm")}
          onClose={() => console.log("On Closing")}
          onCancel={onDeleteConfirmCancel}
          onConfirm={deleteEvent}
        />
      </Card>
      <Modal
        basic
        style={{ marginTop: -80, height: "80%" }}
        open={!!pdf}
        content={<PdfViewer pdfBase64={pdf} />}
        actions={[
          {
            key: "done",
            content: t("results.cards.done"),
            positive: true,
            onClick: () => setPdf(""),
          },
        ]}
      />
      <EventScheduleComponent updateEvent={updateEvent} setVisible={setEditEventVisible} visible={editEventVisible} />
    </>
  );
};

export default TestResultsCard;
