import axios from 'axios';
import { flow, types, Instance } from 'mobx-state-tree';
import { BlobBuilder } from './BlobBuilder';

const fiveMegabytes = 5242880;
const chunkMinSize = fiveMegabytes + 1000;

const Chunk = types.model('Chunk',
    {
        eTag: types.maybe(types.string),
        partNumber: types.number,
        contentType: types.string,
        failed: types.optional(types.boolean, false),
        totalUploaded: types.optional(types.number, 0)
    }).volatile(_ => ({
        blob: new Blob(),
        uploadPromise: Promise.resolve()
    })).actions(self => ({
        setBlob(chunk: Blob) {
            self.blob = chunk;
        },
        setEtag(eTag: string | undefined) {
            self.eTag = eTag
        },
        setUploadPromise(promise: Promise<any>) {
            self.uploadPromise = promise;
        },
        setFailed(failed: boolean) {
            self.failed = failed;
        },
        setTotalUploaded(uploaded: number) {
            self.totalUploaded = uploaded;
        }
    }));

const TestingS3Service = types
    .model('TestingS3Service', {
        presignedUrl: types.string,
        fileName: types.string,
        uploadId: types.maybe(types.string),
        chunks: types.array(Chunk),
        currentPartNumber: types.optional(types.number, 0),
        videoCaptureComplete: types.optional(types.boolean, false),
        videoUploadComplete: types.optional(types.boolean, false),
        stopAddingChunks: types.optional(types.boolean, false)

    })
    .views((self) => ({
        get allVideoChunksUploadedSuccessfully() {
            return self.chunks.every(chunk => !chunk.failed);
        },
        get chunksUploadPromise() {
            return Promise.all(self.chunks.map(chunk => chunk.uploadPromise));
        },
        get percentComplete() {
            const total = self.chunks.reduce((total, current) => {
                total += current.blob.size;
                return total;
            }, 0);

            const totalUploaded = self.chunks.reduce((totalUploaded, current) => {
                totalUploaded += current.totalUploaded;
                return totalUploaded;
            }, 0);

            return Math.trunc(((totalUploaded / total) * 100));
        },

    }))
    .volatile((self) => ({
        blobBuilder: new BlobBuilder(),
        uploadBlob: async (contentType: string, chunk: IChunk, partNumber: number) => {
            try {
                const config = {
                    headers: {
                        'Content-Type': contentType,
                    },
                    timeout: 60000,
                    onUploadProgress: (event: ProgressEvent) => {
                        chunk.setTotalUploaded(event.loaded);
                    }
                }

                const urlStringBuilder = ['test-session-video-upload-url?content-type=', contentType];

                urlStringBuilder.push('&part-number=');
                urlStringBuilder.push(partNumber.toString());
                if (self.uploadId) {
                    urlStringBuilder.push('&upload-id=');
                    urlStringBuilder.push(self.uploadId);
                    urlStringBuilder.push('&blob-name=');
                    urlStringBuilder.push(self.fileName)
                }

                const result = await axios.get(urlStringBuilder.join(''));

                (self as any).setUploadData(result.data)

                const uploadResult = await axios.put(self.presignedUrl, chunk.blob, config);

                const eTag = uploadResult.headers['etag']?.replaceAll('"', '');
                chunk.setFailed(false);
                chunk.setEtag(eTag);

            } catch (exception) {
                chunk.setFailed(true);
                chunk.setTotalUploaded(0)
            }
        }
    }))
    .actions((self) => {
        return {
            setUploadData: (uploadData: any) => {
                self.uploadId = uploadData.uploadId;
                self.presignedUrl = uploadData.presignedUrl;
                self.fileName = uploadData.fileName
            },
            retryFailedUploads: flow(function* () {
                const failedChunks = self.chunks.filter(chunk => !chunk.eTag)

                for (const chunk of failedChunks) {
                    yield self.uploadBlob(chunk.contentType, chunk, chunk.partNumber);
                }

                yield (self as any).completeVideoUpload();

            }),
            reset: () => {
                self.uploadId = undefined;
                self.chunks.clear();
                self.currentPartNumber = 0;
                self.videoCaptureComplete = false;
                self.presignedUrl = "";
                self.fileName = "";
                self.videoUploadComplete = false;
            },
            setVideoCaptureComplete(videoCaptureComplete: boolean) {
                self.videoCaptureComplete = videoCaptureComplete;
            },
            completeVideoUpload: flow(function* () {
                const body = {
                    uploadId: self.uploadId,
                    key: self.fileName,
                    eTags: self.chunks.map(chunk => ({ eTag: chunk.eTag, partNumber: chunk.partNumber }))
                };

                console.log('Completing video upload', JSON.stringify(body));
                yield axios.post('complete-session-video-upload', body);
                self.videoUploadComplete = true;
            }),
            addVideoChunkToBucket: flow(function* (videoBlob: Blob, contentType: string) {
                if (self.stopAddingChunks) {
                    return;
                }

                self.blobBuilder.append(videoBlob);

                self.stopAddingChunks = self.videoCaptureComplete;

                if (self.blobBuilder.size() < chunkMinSize && !self.videoCaptureComplete) {
                    return;
                }

                console.log("chunk video capture complete", self.videoCaptureComplete);
                self.currentPartNumber = self.currentPartNumber + 1;

                const chunk = Chunk.create({
                    partNumber: self.currentPartNumber,
                    contentType
                });

                self.chunks.push(chunk);

                const blob = self.blobBuilder.get();

                chunk.setBlob(blob);

                try {
                    const uploadPromise = self.uploadBlob(contentType, chunk, self.currentPartNumber)
                    chunk.setUploadPromise(uploadPromise);
                    yield uploadPromise;
                } catch (exception) {
                    console.error(exception);
                }
            })
        };
    });

export default TestingS3Service;
export interface ITestingS3Service extends Instance<typeof TestingS3Service> { }
interface IChunk extends Instance<typeof Chunk> { }

