import { observer } from 'mobx-react-lite';
import React from 'react';
import { List } from 'semantic-ui-react';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import CheckListCheckIcon from '../CheckListCheckIcon';
import styles from './PretestChecklist.module.css';
import { useTranslation } from "react-i18next";

const PretestCheckList: React.FC = (props) => {
  const { children } = props;
  const { testingService } = useClearMindContext();
  const { currentImageMessage } = testingService;
  const { t } = useTranslation();

if (testingService.currentState !== "test-checklist" && testingService.currentState !== "test-checklist-success" && testingService.currentState !== "phone-orientation-check") {
  return null;
}

  const renderListItem = (text: any, active: boolean) => {
    return (
      <List.Item>
        <CheckListCheckIcon active={active} />
        {text}
      </List.Item>
    );
  };

  return ( 
    <>
      <div className={styles.checklist}>
        <List relaxed inverted style={{ color: "white" }}>
          {renderListItem(t("testing.checkList.user"), currentImageMessage.found)}
          {renderListItem(t("testing.checkList.sunglasses"), !currentImageMessage.isWearingSunglasses)}
        </List>
      </div>
      {children}
    </>
  );
};

export default observer(PretestCheckList);
