import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styles from './Styles.module.css';

interface SelectorProps {
  label?: string;
  name?: string;
  color?: string;
  onChange?: (e: IsSelectedChangeEvent) => void;
  icon?: () => any;
  selected?: boolean;
  key?: string;
  backgroundDark?: true;
}

export interface IsSelectedChangeEvent {
  label(label: any): unknown;
  isSelected: boolean;
  name: string;
}
const Selector: React.FC<SelectorProps> = (props, context) => {
  const { onChange, name, icon, selected, children, backgroundDark } = props;
  const [isSelected, setIsSelected] = useState(selected);
  const { key } = context;

  useEffect(() => {
    if (selected !== undefined && selected !== isSelected) {
      setIsSelected(selected);
    }
  }, [selected, isSelected]);

  const onClick = () => {
    setIsSelected(!isSelected);

    if (onChange) {
      onChange({ isSelected: !isSelected, name: name || "" });
    }
  };

  const getColor = () => {
    if (isSelected || backgroundDark) {
      return "white";
    } else {
      return "#333";
    }
  };

  return (
    <div
      key={key}
      className={isSelected ? styles.selectedGradientBorder : styles.gradientBorder}
      style={{
        display: "flex",
        alignItems: "center",
        backgroundImage: "linear-gradient(to right,#3bcbbd,#3b91cb)",
        margin: "auto",
        maxWidth: "300px",
        width: "100%",
        minHeight: "65px",
        // paddingTop: 5,
        fontWeight: 800,
        color: getColor(),
      }}
      onClick={onClick}
    >
      {icon && icon()}
      {props.label || children}
    </div>
  );
};

export default observer(Selector);
