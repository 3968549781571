import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Form, Header, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import StepButtons from '../BehaviorContract/StepButtons';
import i18n from '../../i18n';
import ErrorDisplay from "../Messaging/ErrorDisplay";

interface BirthdateStepProps {
  nextStep?: () => void;
  isEdit?: boolean;
  beforeStepChange: () => Promise<void>;
  isActive?: boolean;
}

const BirthdateStep: React.FC<BirthdateStepProps> = (props) => {
  const { nextStep, beforeStepChange, isEdit } = props;
  const [birthdate, setBirthdate] = useState<Date | undefined>(undefined);
  const [hasBirthdate, setHasBirthdate] = useState<boolean>(false);
  const { user } = useClearMindContext();
  const [errorMessage, setErrorMessage] = useState("");
  const today = new Date();
  const maxDateString = today.toISOString().split("T")[0];

  const pageStyles = {
    body: {
      margin: "0em",
      maxWidth: "100%",
      flex: "1 0 auto",
      outline: "none",
      textAlign: "left" as "left",
      lineHeight: "1.21428571em",
      fontFamily: "'Comfortaa', 'Helvetica Neue', Arial, Helvetica, sans-serif",
      padding: "0.67857143em 1em",
      background: "#FFFFFF",
      border: "1px solid rgba(34, 36, 38, 0.15)",
      color: "rgba(0, 0, 0, 0.87)",
      borderRadius: "0.28571429rem",
      transition: "box-shadow 0.1s ease, border-color 0.1s ease",
      boxShadow: "none",
    },
  };
  const EditYearToFourDigits = (e: React.ChangeEvent<HTMLInputElement>) => {
    let date = e.target.value;
    if (date) {
      let dateArr = date.split("-");
      if (dateArr[0] && dateArr[0].length > 4) {
        dateArr[0] = dateArr[0].substring(0, dateArr[0].length - 1);
        date = dateArr.join("-");
        e.target.value = date;
      }
    }
  };

  useEffect(() => {
    if (user.hasBirthdate) {
      setHasBirthdate(user.hasBirthdate);
    }
  }, [user.hasBirthdate]);

  const nextEnabled = () => {
      return hasBirthdate; 
  };

  const onNextStep = () => {
    if (hasBirthdate) {
      nextStep!();
    }
  };
  const onBeforeStepChange = async () => {
    if (hasBirthdate) {
      await trackPromise(user.saveBirthdate(birthdate!));
    }
    await beforeStepChange!();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    EditYearToFourDigits(e);

    if (e.target.value.length === 10) {
      var date = new Date(e.target.value);
      const isInvalidDate = date.getFullYear() === today.getFullYear() || date > today;

      if (isInvalidDate) {
        setErrorMessage(i18n.t("onboarding.dateOfBirthStep.errorMessage"));
        setTimeout(() => {
          setErrorMessage("");
        }, 10000);
        setHasBirthdate(false);
      } else {
        setErrorMessage("");
        setBirthdate(date);
        setHasBirthdate(true);
      }
    } else {
      setHasBirthdate(false);
    }
  };

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h2"}>
        {i18n.t("onboarding.dateOfBirthStep.dob")}
      </Header>
      <Header textAlign="center" as={"h4"}>
        {user.hasBirthdate ? i18n.t("onboarding.dateOfBirthStep.confirmDob") : i18n.t("onboarding.dateOfBirthStep.dob")}
      </Header>
      <Form>
        <input
          type="date"
          defaultValue={user.hasBirthdate ? user.shortBirthdateString : undefined}
          style={pageStyles.body}
          onChange={(e) => {
            onChange(e);
          }}
          max={maxDateString}
        />
      </Form>
      <ErrorDisplay message={errorMessage} />
      <StepButtons {...props} nextStep={onNextStep} isFinalStep={false} nextEnabled={nextEnabled()} beforeStepChange={onBeforeStepChange} isEdit={isEdit} />
    </Segment>
  );
};

export default BirthdateStep;
