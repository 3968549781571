import React, { useEffect, useState } from 'react'
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react'
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import RadioSelector from '../../Controls/Selector/RadioSelector';
import { IsSelectedChangeEvent } from '../../Controls/Selector/Selector';
import { useTranslation } from 'react-i18next';

interface TimeZoneProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    open: boolean
    trigger?: any
    successMessage?: string
    successMessageTimeout?: number
}
const TimeZoneModal: React.FC<TimeZoneProps> = (props) => {
    const { open, setOpen, trigger, successMessage, successMessageTimeout } = props
    const [showTimeZoneModalSuccessMessage, setShowTimeZoneModalSuccessMessage] = useState(false)
    const [radioValue, setRadioValue] = useState('')
    const [loader, setLoader] = useState(false)
    const [timeZoneNotSelected, setTimeZoneNotSelected] = useState<boolean>(false);
    const { user } = useClearMindContext();
    const onChange = (e: IsSelectedChangeEvent) => {
        setRadioValue(e.name);
    }
    const { t, i18n } = useTranslation();

    useEffect(() => {
     if(user.hasTimeZone)
        setRadioValue(user.timeZone!)
        if (showTimeZoneModalSuccessMessage) {
            setTimeout(() => setShowTimeZoneModalSuccessMessage(false), successMessageTimeout)
        }
    }, [showTimeZoneModalSuccessMessage, successMessageTimeout, user.hasTimeZone, user.timeZone])
    
  
    const options = [
      { name: "Eastern Standard Time", label: i18n.t("dashboard.profile.timeZone.est") },
      { name: "Central Standard Time", label: i18n.t("dashboard.profile.timeZone.cst") },
      { name: "Mountain Standard Time", label: i18n.t("dashboard.profile.timeZone.mst") },
      { name: "Pacific Standard Time", label: i18n.t("dashboard.profile.timeZone.pst") },
    ];
  
    const getIndex = options.findIndex((option) => {
        return option.name === radioValue;
    });
  
    const saveTimeZone = async () => {
        setLoader(true)
        if (!!radioValue) {
            await user.setUserTimeZone(radioValue)
            setOpen(false)
            setLoader(false)
            if(!!successMessage){
                setShowTimeZoneModalSuccessMessage(true)
            }
        }
        else {
            setTimeZoneNotSelected(true)
            setLoader(false)
        }
    }

    return (
      <>
        <Modal basic onClose={() => setOpen(false)} onOpen={() => setOpen(true)} closeOnEscape={false} closeOnDimmerClick={false} open={open} trigger={trigger}>
          <Modal.Header style={{ textAlign: "center" }}>{t("dashboard.profile.timeZone.selectATimeZone")}</Modal.Header>
          <Modal.Content>
            {user.hasTimeZone ? <div style={{ marginBottom: "8px", textAlign: "center" }}>{t("dashboard.profile.timeZone.updateTimeZone")}</div> : <Header>{t("dashboard.profile.timeZone.required")}</Header>}
            <RadioSelector backgroundDark options={options} onChange={onChange} selectedIndex={getIndex} />
            {timeZoneNotSelected && (
              <Message icon="warning" negative style={{ textAlign: "left" }}>
                {t("dashboard.profile.timeZone.noTimeZoneSelected")}
              </Message>
            )}
          </Modal.Content>
          <Modal.Actions>
            {user.hasTimeZone && <Button inverted content={t("dashboard.profile.timeZone.goBack")} labelPosition="right" icon="arrow alternate circle left outline" onClick={() => setOpen(false)} />}
            <Button inverted content={t("dashboard.profile.timeZone.saveTimezone")} labelPosition="right" icon={loader ? <Icon loading name="spinner" /> : "checkmark"} onClick={saveTimeZone} color="green" />
          </Modal.Actions>
        </Modal>
        {showTimeZoneModalSuccessMessage && (
          <Message icon="warning" positive style={{ textAlign: "left" }}>
            {successMessage}
          </Message>
        )}
      </>
    );
}

export default TimeZoneModal