import { observer } from 'mobx-react-lite';
import React from 'react';
import { Header, Segment, Grid, Icon, Card } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import UserCard from '../UserCardComponent/UserCard';
import UserCardMinimal from '../UserCardComponent/UserCardMinimal';

const ConsentAndReleaseStep: React.FC = () => {
  const { behaviorContract, testingService } = useClearMindContext();

  const handleNext = () => {
    testingService.onConsentComplete();
  };

  return (
    <FrostedFrame>
      <Grid textAlign='center' stackable>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign='center' as='h3' icon>
              Consent and Release Reminder
            </Header>
            <Segment
              basic
              style={{
                color: 'white',
                fontSize: '16px',
                lineHeight: '1.4',
              }}
              textAlign='left'
            >
              Hello, before your test begins, please read and check the consent
              to test and release results with your designated team.
            </Segment>

            <Segment basic>
              <Header icon textAlign='center'>
                <Icon name='users' circular />
                <Header.Content>Supporters</Header.Content>
              </Header>
              <Card.Group centered>
                {behaviorContract.supporters.map((supporter) => (
                  <UserCardMinimal user={supporter} />
                ))}
              </Card.Group>
            </Segment>
            {behaviorContract.partners.length > 0 && (
              <Segment basic>
                <Header icon textAlign='center'>
                  <Icon name='user' circular />
                  Monitoring Partners
                </Header>
                <Card.Group centered>
                  {behaviorContract.partners.map((partner) => (
                    <UserCard user={partner} />
                  ))}
                </Card.Group>
              </Segment>
            )}
            <ClearMindButton onClick={handleNext}>Continue</ClearMindButton>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FrostedFrame>
  );
};

export default observer(ConsentAndReleaseStep);
