import React, { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Button, Grid, Header, Loader, Segment } from "semantic-ui-react";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import { observer } from 'mobx-react';
import i18n from '../../i18n';
import { useHistory } from "react-router-dom";
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';

const IdVerificationStep: React.FC = () => {
    const { behaviorContract } = useClearMindContext();
    const [loader, setLoader] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!)
    const history = useHistory();
    const userId = behaviorContract.member?.userId
    if (behaviorContract.idVerified) {
        history.push("/");
    }
    if (behaviorContract.idVerificationSessionComplete) {
        history.push("/");
    }
    if (!behaviorContract.governmentIdRequired) {
        history.push("/");
    }

    const verifyButtonClick = async () => {
        setLoader(true)
        const stripe = await stripePromise;
        if (!stripe) {
            console.error('Error initializing Stripe.');
            return;
        }
        try {
            const sessionKey = await axios.post(`/verification-sessions/${userId}`).then((res) => res.data)
            const { error } = await stripe.verifyIdentity(sessionKey.client_secret);
            if (!error) {
                behaviorContract.setIdVerificationSessionComplete(true);
                await behaviorContract.save();
                setLoader(false);
                history.push("/");

            } else {
                setLoader(false);
            }
        }
        catch (e: any) {
            setLoader(false);
        }
    }
    return (
        <>
            <Segment padded style={{ margin: "auto" }} basic textAlign="center">
                <Header textAlign="center" as={"h2"}>
                    {i18n.t("onboarding.verifyIdentity.header")}
                </Header>
                <Header textAlign="center" as={"h4"}>
                    {i18n.t("onboarding.verifyIdentity.content")}
                </Header>
                <Grid.Row>
                    <Grid.Column>
                        <ClearMindButton opaque style={{ width: 211 }} onClick={verifyButtonClick}>
                            <Button.Content visible>
                                {loader && <Loader content="Loading" active inline size='small' />}
                                {!loader && i18n.t("onboarding.verifyIdentity.button")}
                            </Button.Content>
                        </ClearMindButton>
                    </Grid.Column>
                </Grid.Row>
            </Segment>
        </>
    );
}

export default observer(IdVerificationStep);