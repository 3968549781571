import React from 'react';

import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { Card, Segment } from 'semantic-ui-react';

const HelloWelcome: React.FC = () => {
  const { user } = useClearMindContext();
  let date = new Date();
  let currentHour = date.getHours();

  const partOfDay = () => {
    if (currentHour < 12) {
      return 'Good morning,';
    } else if (currentHour < 18) {
      return 'Good afternoon,';
    } else {
      return 'Good evening,';
    }
  };

  return (
    <>
      <Segment
        basic
        style={{ margin: "0", padding: "0 0 10px 0" }}
      >
        <Card.Content>
          <Card.Header style={{ margin: 0, padding: 0 }}>
            <p style={{ color: "var(--secondary-color)" }}>
              {partOfDay()} {user.firstName}.
            </p>
          </Card.Header>
          <Card.Description></Card.Description>
        </Card.Content>
      </Segment>
    </>
  );
};

export default HelloWelcome;
