import React, { useEffect, useState } from 'react';
import { GetBehaviorContractPdf } from '../../Services/GetBehaviorContractPdf';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import PdfViewer from './PdfViewer';
import { Loader } from 'semantic-ui-react';

const WellnessAgreement: React.FC = () => {
  const [pdf, setPdf] = useState('');
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    if (!pdf) {
      trackPromise(GetBehaviorContractPdf().then(behaviorContractPdf => {
        setPdf(behaviorContractPdf)
      }));
    }
  }, [pdf]);

  if (promiseInProgress) {
    return <>
      <br />
      <br />
      <br />
      <br />
      <Loader active />
    </>
  }

  return (
    <div style={{ height: 425 }}>
      <PdfViewer pdfBase64={pdf} />
    </div>
  );
};

export default WellnessAgreement;
