import { observer } from 'mobx-react-lite';
import React from 'react';
import Selector, { IsSelectedChangeEvent } from './Selector';

interface RadioSelectorProps {
  options?: { name: string; label: string }[] | string[];
  onChange: (e: IsSelectedChangeEvent, index: number) => void;
  selectedIndex: number;
  backgroundDark?: true;
}
const RadioSelector: React.FC<RadioSelectorProps> = (props) => {
  const { options, onChange, selectedIndex, backgroundDark } = props;

  return (
    <>
      {options?.map((option, i) => (
        <Selector backgroundDark={backgroundDark} label={typeof option === "string" ? option : option.label} key={`radio-selector-${i}`} name={typeof option === "string" ? option : option.name} onChange={(e) => onChange(e, i)} selected={selectedIndex === i} />
      ))}
    </>
  );
};

export default observer(RadioSelector);
