import React from 'react'

interface SpinnerProps {
    spinnerText: string
}
const Spinner:React.FC<SpinnerProps> = (props) => {
    const {spinnerText} = props
    return (
        <div className="ui active dimmer">
            <div className="ui big text loader">
                {spinnerText}
            </div>
        </div>
    );
};
export default Spinner;