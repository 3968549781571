import React from 'react';
import AllowableSubstancesComponent from '../Components/MemberDashboard/AllowableSubstancesComponent';

const MemberAllowableSubstancesPage: React.FC = () => {
  return (
    <>
      <AllowableSubstancesComponent />
    </>
  );
};

export default MemberAllowableSubstancesPage;
