import React from 'react';
import { Header, Divider, Image } from 'semantic-ui-react';
import ResultsImg from '../Images/DashboardPhotos/MamberSliderPhotos/MyResultsV2.png';
import PinPadImg from '../Images/DashboardPhotos/AllowableSubstancesIcon.png';
import TravelImg from '../Images/DashboardPhotos/UnderstandingResultsImg1.png';
import HandsImg from '../Images/DashboardPhotos/UnderstandigResultsImg2.png';
import i18n from '../i18n';

const UnderstandingResultsMemberContent: React.FC = () => {
  const pageStyles = {
    redSpan: { color: '#ff4e4e', fontWeight: 'bold' },
    tealSpan: { color: '#47d4d9', fontWeight: 'bold' },
    yellowSpan: { color: '#F1C232', fontWeight: 'bold' },
    link: { color: '#3EBABF', fontWeight: 'bold' },
  };
  return (
    <>
      <Header style={{ color: "var(--secondary-color)" }} as={'h1'} textAlign='center'>
        {i18n.t("dashboard.understandingResults.header")}
      </Header>
      <Image centered src={ResultsImg} />
      <Header>{i18n.t("dashboard.understandingResults.header")}</Header>
      {i18n.t("dashboard.understandingResults.resultTypes")}
      <br />
      <br />
      {i18n.t("dashboard.understandingResults.testAccurate")}
      <br />
      <br />
      {i18n.t("dashboard.understandingResults.designedFor")}
      <br />
      <br />
      {i18n.t("dashboard.understandingResults.workHard")}
      <Header>
      {i18n.t("dashboard.understandingResults.twoWays")}
      </Header>
      <span style={pageStyles.tealSpan}>{i18n.t("dashboard.understandingResults.pass")} </span>
      {i18n.t("dashboard.understandingResults.thisMeans")}
      <br />
      <br />{' '}
      <span style={pageStyles.tealSpan}>{i18n.t("dashboard.understandingResults.passSpan")} </span>
      {i18n.t("dashboard.understandingResults.thisProgram")} {''}
      <Image size='small' floated='left' src={PinPadImg} />
      {i18n.t("dashboard.understandingResults.medicationBy")}
      <Header>
        {i18n.t("dashboard.understandingResults.threeTypes")}
      </Header>{i18n.t("dashboard.understandingResults.a")}{' '}
      <span style={pageStyles.redSpan}> {i18n.t("dashboard.understandingResults.detection")} </span>
      {i18n.t("dashboard.understandingResults.meansA")}
      <Divider hidden />{i18n.t("dashboard.understandingResults.a2")}{' '}
      <span style={pageStyles.redSpan}> {i18n.t("dashboard.understandingResults.misstep")} </span> {i18n.t("dashboard.understandingResults.indicatesAMember")}
      <br />
      <br /> {i18n.t("dashboard.understandingResults.lastlyA")}<span style={pageStyles.redSpan}> {i18n.t("dashboard.understandingResults.decline")} </span>
      {i18n.t("dashboard.understandingResults.meansATest")}
      <br />
      <br /> <Image size='small' floated='left' src={TravelImg} />
      <Header verticalAlign='top'>
        {i18n.t("dashboard.understandingResults.bePrepared")}
      </Header>
      <br />
      <br />
      <br />
      <Header>{i18n.t("dashboard.understandingResults.inEventOfFail")}</Header>
      {i18n.t("dashboard.understandingResults.yourMP")}
      <br />
      <br />{' '}
      <Header>
      {i18n.t("dashboard.understandingResults.whatDoes")} <span style={pageStyles.yellowSpan}>{i18n.t("dashboard.understandingResults.invalidResult")}</span>{' '}
      {i18n.t("dashboard.understandingResults.mean")}
      </Header>
      {i18n.t("dashboard.understandingResults.invalidMeaning")}
      <Header>{i18n.t("dashboard.understandingResults.inSummary")}</Header>
      {i18n.t("dashboard.understandingResults.weAdvocate")}{' '}
      <a style={pageStyles.link} href='mailto:support@clearlee.com'>
      {i18n.t("dashboard.understandingResults.supportEmail")}{' '}
      </a>
      {i18n.t("dashboard.understandingResults.letUsHelp")}
      <br />
      <br /> {i18n.t("dashboard.understandingResults.atTheEnd")}
      <br />
      <br /> <Image centered src={HandsImg} />
    </>
  );
};

export default UnderstandingResultsMemberContent;
