export const Medications = [
  'PAREDRINE',
  'SULFAPYRIDINE',
  'LIQUAEMIN SODIUM',
  'HEPARIN SODIUM',
  'HISTAMINE PHOSPHATE',
  'BUTISOL SODIUM',
  'DOCA',
  'VERARD',
  'GUANIDINE HYDROCHLORIDE',
  'MENADIONE',
  'INULIN AND SODIUM CHLORIDE',
  'AMINOPHYLLIN',
  'BENSULFOID',
  'ORETON METHYL',
  'METANDREN',
  'PITRESSIN TANNATE',
  'DRISDOL',
  'SYNKAYVITE',
  'THEELIN',
  'DIETHYLSTILBESTROL',
  'SULFADIAZINE',
  'SULFADIAZINE SODIUM',
  'STILBESTROL',
  'STILBETIN',
  'PREMARIN',
  'DEMEROL',
  'PERCORTEN',
  'HYCODAN',
  'PANHEPRIN',
  'HEPARIN LOCK FLUSH',
  'HEPARIN SODIUM PRESERVATIVE FREE',
  'ESTINYL',
  'PANTOPAQUE',
  'DESOXYN',
  'LYNORAL',
  'DICUMAROL',
  'AMINOHIPPURATE SODIUM',
  'MANNITOL 25%',
  'TUBOCURARINE CHLORIDE',
  'VERTAVIS',
  'KAPPADIONE',
  'SULTRIN',
  'FURACIN',
  'BENADRYL',
  'TRIDIONE',
  'FOLVITE',
  'PBZ',
  'D.H.E. ',
  'AMINOSOL 5%',
  'BAL',
  'SODIUM SULAMYD',
  'SOTRADECOL',
  'ARALEN',
  'ARALEN HYDROCHLORIDE',
  'MESANTOIN',
  'FOLVRON',
  'METHERGINE',
  'DIASONE SODIUM',
  'BEROCCA PN',
  'DIENESTROL',
  'DOLOPHINE HYDROCHLORIDE',
  'FOLIC ACID',
  'HYPROTIGEN 5%',
  'PROPYLTHIOURACIL',
  'HEAVY SOLUTION NUPERCAINE',
  'GAMOPHEN',
  'KWELL',
  'SULFONAMIDES DUPLEX',
  'NEOTRIZINE',
  'ISUPREL',
  'SYNOPHYLATE',
  'METHADONE HYDROCHLORIDE',
  'PRISCOLINE',
  'DECAPRYN',
  'CAMOQUIN HYDROCHLORIDE',
  'HETRAZAN',
  'PROTAMINE SULFATE',
  'CALCIUM GLUCEPTATE',
  'XYLOCAINE',
  'XYLOCAINE W/ EPINEPHRINE',
  'BENYLIN',
  'GANTRISIN',
  'AVC',
  'URECHOLINE',
  'CAFERGOT',
  'METUBINE IODIDE',
  'REDISOL',
  'MUSTARGEN',
  'VASOXYL',
  'ARTANE',
  'BERUBIGEN',
  'RUBRAMIN PC',
  'PARADIONE',
  'PARASAL',
  'PARASAL SODIUM',
  'AQUASOL A',
  'PHISOHEX',
  'TERFONYL',
  'NORISODRINE',
  'TRIPLE SULFAS',
  'CHLOR-TRIMETON',
  'EURAX',
  'SYNCURINE',
  'VI-TWEL',
  'AZULFIDINE',
  'CYANOCOBALAMIN',
  'CORTONE',
  'AEROLONE',
  'TEEBACIN',
  'PRONESTYL',
  'VERILOID',
  'PERCODAN-DEMI',
  'PERCODAN',
  'BANTHINE',
  'SECONAL SODIUM',
  'BENTYL',
  'ACTHAR',
  'LEVOPHED',
  'TAPAZOLE',
  'QUINIDINE GLUCONATE',
  'SURITAL',
  'PHENURONE',
  'NEOTHYLLINE',
  'FLAXEDIL',
  'POTASSIUM CHLORIDE',
  'ANTABUSE',
  'BENEMID',
  'PHENERGAN',
  'SELSUN',
  'SUS-PHRINE SULFITE FREE',
  'PREFRIN-A',
  'TENSILON',
  'TENSILON PRESERVATIVE FREE',
  'AMBODRYL',
  'TELEPAQUE',
  'EVANS BLUE',
  'METHOTREXATE SODIUM',
  'TACE',
  'LEUCOVORIN CALCIUM',
  'PRANTAL',
  'CORTISONE ACETATE',
  'BENOQUIN',
  'HYDROCORTONE',
  'WYAMINE SULFATE',
  'REGITINE',
  'APRESOLINE',
  'PHENERGAN W/ CODEINE',
  'PHENERGAN VC W/ CODEINE',
  'PRIMAQUINE',
  'ACTH',
  'BUTAZOLIDIN',
  'GEMONIL',
  'BENTYL PRESERVATIVE FREE',
  'ACTHAR GEL',
  'TPN',
  'PROMETHAZINE HYDROCHLORIDE PLAIN',
  'NYDRAZID',
  'RIMIFON',
  'ISONIAZID',
  'ANECTINE',
  'CYCLAINE',
  'ANTRENYL',
  'THIOSULFIL',
  'DARAPRIM',
  'RAVOCAINE AND NOVOCAIN W/ LEVOPHED',
  'RAVOCAINE AND NOVOCAIN W/ NEO-COBEFRIN',
  'PHENERGAN VC',
  'FURADANTIN',
  'CORTEF',
  'DIBENZYLINE',
  'LEVO-DROMORAN',
  'PRO-BANTHINE',
  'DILANTIN',
  'HEDULIN',
  'M.V.I.-12 ADULT',
  'UNITENSEN',
  'RAUDIXIN',
  'QUELICIN PRESERVATIVE FREE',
  'QUELICIN',
  'SUCOSTRIN',
  'PAMINE',
  'PAMINE FORTE',
  'MILONTIN',
  'RAUWILOID',
  'DICURIN PROCAINE',
  'OPHTHAINE',
  'CLISTIN',
  'CORTEF ACETATE',
  'CALCIUM DISODIUM VERSENATE',
  'DIAMOX',
  'PAGITANE',
  'PURIFIED CORTROPHIN GEL',
  'ARFONAD',
  'SALPIX',
  '8-MOP',
  'OXSORALEN',
  'REZIPAS',
  'PURINETHOL',
  'PARSIDOL',
  'HYDERGINE',
  'ANTEPAR',
  'RAUVAL',
  'SERPASIL',
  'CORTRIL',
  'THORAZINE',
  'DELATESTRYL',
  'HYDROXYSTILBAMIDINE ISETHIONATE',
  'MYSOLINE',
  'GANTRISIN PEDIATRIC',
  'LIPO GANTRISIN',
  'LIPIODOL',
  'COGENTIN',
  'RAUTENSIN',
  'COUMADIN',
  'PROGESTERONE',
  'WOLFINA',
  'CHOLEDYL',
  'RAUWOLFIA SERPENTINA',
  'HIWOLFIA',
  'KOGLUCOID',
  'CEDILANID-D',
  'SERPASIL-APRESOLINE',
  'DIONOSIL AQUEOUS',
  'DIONOSIL OILY',
  'AMBENYL',
  'CHOLOGRAFIN SODIUM',
  'CHOLOGRAFIN MEGLUMINE',
  'LANOXIN',
  'LANOXIN PEDIATRIC',
  'HISTALOG',
  'RAU-SED',
  'ANSOLYSEN',
  'SANDRIL',
  'MYLERAN',
  'SERPANRAY',
  'PASKALIUM',
  'DELESTROGEN',
  'LOTUSATE',
  'NESACAINE',
  'NESACAINE-MPF',
  'ACYLANID',
  'MULTIFUGE',
  'SERPATE',
  'XYLOCAINE VISCOUS',
  'BENADRYL PRESERVATIVE FREE',
  'PATHILON',
  'MAREZINE',
  'ARAMINE',
  'DORIDEN',
  'HYPAQUE',
  'HYPAQUE SODIUM 20%',
  'CORT-DOME',
  'RESERPINE',
  'HISERPIA',
  'HYDROCORTISONE ACETATE',
  'SERPIVITE',
  'HYDROCORTISONE',
  'NOLUDAR',
  'MILTOWN',
  'VALMID',
  'METICORTEN',
  'PLAQUENIL',
  'MYLAXEN',
  'KEMADRIN',
  'MESTINON',
  'CORTROPHIN-ZINC',
  'SOLU-CORTEF',
  'DYCLONE',
  'RAUSERPIN',
  'DELTASONE',
  'DELTA-CORTEF',
  'STERANE',
  'STILPHOSTROL',
  'PLACIDYL',
  'EQUANIL',
  'RENOGRAFIN',
  'RENO-DIP',
  'RENO',
  'DIATRIZOATE MEGLUMINE',
  'CYSTOGRAFIN',
  'RENOVIST II',
  'RENOVIST',
  'CYSTOGRAFIN DILUTE',
  'FLORINEF',
  'BIPHETAMINE ',
  'MEPHYTON',
  'SERPALAN',
  'MYTELASE',
  'RITALIN',
  'METI-DERM',
  'METIMYD',
  'INVERSINE',
  'METICORTELONE',
  'DELALUTIN',
  'SPARINE',
  'QUARZAN',
  'MEDIHALER-EPI',
  'MEDIHALER-ISO',
  'ISOPROTERENOL HYDROCHLORIDE',
  'CYTOMEL',
  'ATARAX',
  'XYLOCAINE 4% PRESERVATIVE FREE',
  'LORFAN',
  'PRELUDIN',
  'XYLOCAINE 5% W/ GLUCOSE 7.5%',
  'LERITINE',
  'PBZ-SR',
  'MAGNACORT',
  'HYDELTRA-TBA',
  'COMPAZINE',
  'HYSERPIN',
  'CELONTIN',
  'BENDECTIN',
  'TRAL',
  'HALOTESTIN',
  'DISIPAL',
  'FLOROPRYL',
  'LEUKERAN',
  'ORINASE',
  'CANTIL',
  'MODERIL',
  'ANTIVERT',
  'DARBID',
  'ESTRADURIN',
  'TRILAFON',
  'IRON DEXTRAN',
  'RUBIVITE',
  'HARMONYL',
  'DIMETANE',
  'CORTICOTROPIN',
  'PEGANONE',
  'NORLUTIN',
  'MIRADON',
  'BUCLADIN-S',
  'PHOSPHOTOPE',
  'IODOTOPE',
  'PARACORT',
  'PMB ',
  'ENOVID',
  'ENOVID-E',
  'ENOVID-E 21',
  'DARVON W/ ASA',
  'DARVON',
  'ROBAXIN',
  'HYDELTRASOL',
  'MILPREM',
  'CARDRASE',
  'WAMPOCAP',
  'ACTIDIL',
  'VISTARIL',
  'VESPRIN',
  'DIURIL',
  'MEDROL',
  'ARISTOCORT',
  'DEPINAR',
  'TESSALON',
  'LIQUAMAR',
  'GASTROGRAFIN',
  'PROMETHAZINE HYDROCHLORIDE AND DEXTROMETHORPHAN HYDROBROMIDE',
  'FUROXONE',
  'KENACORT',
  'MEPROSPAN',
  'KAYEXALATE',
  'PARAFLEX',
  'TEMARIL',
  'SINOGRAFIN',
  'FLUOTHANE',
  'CERUMENEX',
  'ORA-TESTRYL',
  'DARANIDE',
  'KEVEYIS',
  'DIMETANE-TEN',
  'TRANCOPAL',
  'SYNALGOS-DC',
  'ADDERALL ',
  'IC-GREEN',
  'PARAFON FORTE DSC',
  'STELAZINE',
  'ANTURANE',
  'BREVITAL SODIUM',
  'TRIAMCINOLONE ACETONIDE',
  'KENALOG',
  'DARICON',
  'IONAMIN',
  'CARDIOGRAFIN',
  'DIUPRES',
  'DIABINESE',
  'CARDIOQUIN',
  'BETAPRONE',
  'DECADRON',
  'TEPANIL',
  'PENTOTHAL',
  'THIOTEPA',
  'PHENY-PAS-TEBAMIN',
  'EQUAGESIC',
  'OPANA',
  'METHOTREXATE ',
  'NEPTAZANE',
  'TENUATE',
  'MEPERGAN',
  'NUMORPHAN',
  'KONAKION',
  'OXYLONE',
  'PROLIXIN',
  'DEPO-MEDROL',
  'ATHROMBIN',
  'ATHROMBIN-K',
  'SODIUM PHOSPHATE P 32',
  'SOMA',
  'ESIDRIX',
  'MELLARIL',
  'DISOMER',
  'HYDRODIURIL',
  'PRESAMINE',
  'TOFRANIL',
  'PROVERA',
  'SOLU-MEDROL',
  'HUMORSOL',
  'SERPASIL-ESIDRIX ',
  'DURABOLIN',
  'NARDIL',
  'VIRAC REX',
  'HISPRIL',
  'SALURON',
  'TACARYL',
  'HYDROPRES ',
  'MARPLAN',
  'PHOSPHOLINE IODIDE',
  'POVAN',
  'ORETIC',
  'AKINETON',
  'APRESOLINE-ESIDRIX',
  'PERMITIL',
  'KENALOG-10',
  'ISORDIL',
  'ORINASE DIAGNOSTIC',
  'KENALOG IN ORABASE',
  'MEDIHALER ERGOTAMINE',
  'MYDRIACYL',
  'GLUCAGON',
  'CARBOCAINE W/ NEO-COBEFRIN',
  'CARBOCAINE',
  'ULO',
  'FOVANE',
  'URESE',
  'CYTOXAN',
  'CYTOXAN (LYOPHILIZED)',
  'LYOPHILIZED CYTOXAN',
  'ORETICYL ',
  'ORETICYL FORTE',
  'ALDACTONE',
  'UREAPHIL',
  'RELA',
  'NORFLEX',
  'NATURETIN',
  'VOSOL',
  'NORLUTATE',
  'SER-AP-ES',
  'FLUOROURACIL',
  'AQUAMEPHYTON',
  'PLEGINE',
  'LIBRIUM',
  'TINDAL',
  'ALPHADROL',
  'NAQUA',
  'NAQUIVAL',
  'ROBAXISAL',
  'HYGROTON',
  'SYNTOCINON',
  'CHOLOXIN',
  'ISMELIN',
  'BRONKOMETER',
  'BRONKOSOL',
  'PARNATE',
  'SALUTENSIN',
  'SALUTENSIN-DEMI',
  'SOMA COMPOUND',
  'SOMA COMPOUND W/ CODEINE',
  'ZARONTIN',
  'LARGON',
  'COLBENEMID',
  'DISOPHROL',
  'IVADANTIN',
  'MEDROL ACETATE',
  'DIDREX',
  'THIOGUANINE',
  'LOMOTIL',
  'AKRINOL',
  'TARACTAN',
  'EXNA',
  'SANSERT',
  'ENDURON',
  'DEPO-PROVERA',
  'TANDEARIL',
  'TENUATE DOSPAN',
  'ACTIFED W/ CODEINE',
  'OPHTHETIC',
  'METAHYDRIN',
  'ALDACTAZIDE',
  'FLAGYL',
  'PERIACTIN',
  'CELESTONE',
  'VELBAN',
  'HEXADROL',
  'TOLBUTAMIDE',
  'TRISORALEN',
  'ELAVIL',
  'DIUTENSEN-R',
  'GANTANOL',
  'GANTANOL-DS',
  'ORTHO-NOVUM ',
  'DECASPRAY',
  'WILPO',
  'LIBRAX',
  'TORECAN',
  'PROKETAZINE',
  'VOSOL HC',
  'HALDRONE',
  'ENDURONYL',
  'ENDURONYL FORTE',
  'SYNALAR',
  'QUINIDEX',
  'CORDRAN',
  'CORDRAN SP',
  'BLEPHAMIDE',
  'ROBINUL',
  'ROBINUL FORTE',
  'TRAVASE',
  'PERSANTINE',
  'RENESE',
  'WINSTROL',
  'URACIL MUSTARD',
  'METOPIRONE',
  'NOVRAD',
  'DROLBAN',
  'ORAGRAFIN SODIUM',
  'ORAGRAFIN CALCIUM',
  'METATENSIN #2',
  'METATENSIN #4',
  'THAM-E',
  'THAM',
  'TRECATOR',
  'PENTHRANE',
  'SULFABID',
  'DECA-DURABOLIN',
  'ANHYDRON',
  'DYRENIUM',
  'SKELAXIN',
  'VALIUM',
  'HYDROMOX',
  'AZO GANTANOL',
  'CONRAY',
  'CONRAY 43',
  'DUO-MEDIHALER',
  'ANGIO-CONRAY',
  'DECADRON W/ XYLOCAINE',
  'DYMELOR',
  'ALDOMET',
  'ALDORIL ',
  'DEXACORT',
  'NORGESIC',
  'NORGESIC FORTE',
  'TREST',
  'MAXIDEX',
  'VALPIN 50',
  'EUTONYL',
  'DRIXORAL',
  'DECADERM',
  'ESIMIL',
  'MUCOMYST',
  'QUIDE',
  'PERTOFRANE',
  'NORINYL 1+50 21-DAY',
  'NORINYL',
  'RENESE-R',
  'OSMITROL ',
  'OXANDRIN',
  'BILOPAQUE',
  'HYDROMOX R',
  'STOXIL',
  'HERPLEX',
  'CHROMITOPE SODIUM',
  'MAXIBOLIN',
  'ONCOVIN',
  'PROTOPAM CHLORIDE',
  'DENDRID',
  'NEGGRAM',
  'MAOLATE',
  'CONRAY ',
  'MEPROBAMATE',
  'DELFEN',
  'NEURAMATE',
  'NORPRAMIN',
  'CELESTONE SOLUSPAN',
  'AVENTYL HYDROCHLORIDE',
  'AVENTYL',
  'ALKERAN',
  'PRE-SATE',
  'ETRAFON-A',
  'ETRAFON 2-25',
  'ETRAFON-FORTE',
  'ETRAFON 2-10',
  'TRIAVIL ',
  'MANNITOL 20%',
  'MENRIUM ',
  'CITANEST',
  'CITANEST PLAIN',
  'CITANEST FORTE',
  'ARALEN PHOSPHATE W/ PRIMAQUINE PHOSPHATE',
  'DOPRAM',
  'PONSTEL',
  'REGROTON',
  'DEMI-REGROTON',
  'AMICAR',
  'HIPPUTOPE',
  'TOLINASE',
  'SERAX',
  'LEVOPROME',
  'ALUPENT',
  'HALDOL',
  'SULLA',
  'AQUATAG',
  'VIVACTIL',
  'ALDOCLOR',
  'SYMMETREL',
  'OVULEN',
  'OVULEN-21',
  '8-HOUR BAYER',
  'MEASURIN',
  'VONTROL',
  'DYAZIDE',
  'EUTRON',
  'INNOVAR',
  'BETAPAR',
  'INDOCIN',
  'MEPRIAM',
  'MANNITOL',
  'ZYLOPRIM',
  'EDECRIN',
  'MINTEZOL',
  'ATROMID-S',
  'PROLIXIN ENANTHATE',
  'TESLAC',
  'PRIMATENE MIST',
  'BRONITIN MIST',
  'CLOMID',
  'ETHAMIDE',
  'UREX',
  'SYNALAR-HP',
  'SORBITRATE',
  'TALWIN',
  'CORTENEMA',
  'MIOCHOL',
  'ROBENGATOPE',
  'VERCYTE',
  'TRANMEP',
  'DESFERAL',
  'LASIX',
  'HYDREA',
  'DROXIA',
  'XYLOCAINE 1.5% W/ DEXTROSE 7.5%',
  'MYAMBUTOL',
  'VALISONE',
  'IMURAN',
  'SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER',
  'LOCORTEN',
  'NEOPAP',
  'HYPAQUE-CYSTO',
  'INDERAL',
  'PROPRANOLOL HYDROCHLORIDE',
  'ARISTOSPAN',
  'SODIUM IODIDE I 131',
  'NAVANE',
  'TEGRETOL',
  'SUBLIMAZE PRESERVATIVE FREE',
  'MACRODANTIN',
  'HMS',
  'NARCAN',
  'QUESTRAN',
  'QUINAGLUTE',
  'FEMINONE',
  'NORINYL 1+50 28-DAY',
  'HIPPURAN I 131',
  'OVRAL',
  'DECADRON-LA',
  'EUTHROID',
  'RESECTISOL',
  'OVULEN-28',
  'SODIUM CHROMATE CR 51',
  'TRAVERT 10% IN PLASTIC CONTAINER',
  'DALMANE',
  'NORLESTRIN 28 1/50',
  'NORINYL 1+80 21-DAY',
  'NORINYL 1+80 28-DAY',
  'PROLIXIN DECANOATE',
  'FERROUS CITRATE FE 59',
  'TALWIN 50',
  'STERILE WATER IN PLASTIC CONTAINER',
  'SORBITOL 3.3% IN PLASTIC CONTAINER',
  'NORLESTRIN 21 1/50',
  'CORTROSYN',
  'DIAPID',
  'SULFAMYLON',
  'FLUOROPLEX',
  'NORLESTRIN FE 1/50',
  'ESTROVIS',
  'URISPAS',
  'RESECTISOL IN PLASTIC CONTAINER',
  'SERENTIL',
  'LITHONATE',
  'VASCORAY',
  'GLYCINE 1.5% IN PLASTIC CONTAINER',
  'MATULANE',
  'SURMONTIL',
  'CYTARABINE',
  'INAPSINE',
  'SINEQUAN',
  'XYLOCAINE PRESERVATIVE FREE',
  'BRONKAID MIST',
  'OVRAL-28',
  'THYROLAR',
  'TRICLOS',
  'KETALAR',
  'VAPO-ISO',
  'NORISODRINE AEROTROL',
  'EMETE-CON',
  'FREAMINE ',
  'DARVON-N W/ ASA',
  'EFUDEX',
  'CYLERT',
  'LITHIUM CARBONATE',
  'DARVOCET',
  'ISOPAQUE 440',
  'ANADROL',
  'FML',
  'NORLESTRIN 21 2.5/50',
  'NORLESTRIN FE 2.5/50',
  'ESKALITH',
  'DARVON-N',
  'LYSODREN',
  'TALWIN COMPOUND',
  'LIDEX',
  'LIDEX-E',
  'LARODOPA',
  'DOPAR',
  'RETIN-A',
  'TESULOID',
  'DEMULEN ',
  'FUDR',
  'R-GENE 10',
  'HALOTEX',
  'BENDOPA',
  'LIMBITROL',
  'LIMBITROL DS',
  'MICRONOR',
  'MARCAINE HYDROCHLORIDE',
  'MARCAINE HYDROCHLORIDE W/ EPINEPHRINE',
  'MIOSTAT',
  'MEGACE',
  'LITHOTABS',
  'CONRAY 30',
  'DOXEPIN HYDROCHLORIDE',
  'INTAL',
  'HYPERSTAT',
  'UTICORT',
  'ANCOBON',
  'DESONIDE',
  'PRED FORTE',
  'SODIUM CHLORIDE',
  'PAVULON',
  'CHORIONIC GONADOTROPIN',
  'PANWARFIN',
  'LIPO-HEPIN',
  'HEPARIN SODIUM IN PLASTIC CONTAINER',
  'OPILL',
  'SODIUM HEPARIN',
  'FLUORINE F-18',
  'RENOTEC',
  'KAON CL',
  'KAON CL-10',
  'SETHOTOPE',
  'PEPTAVLON',
  'A.P.L.',
  'CYSTO-CONRAY',
  'CYSTO-CONRAY II',
  'TECHNECOLL',
  'NOR-QD',
  'ISMOTIC',
  'DEXEDRINE',
  'HALDOL SOLUTAB',
  'PHOSPHOCOL P32',
  'ETHRANE',
  'TOFRANIL-PM',
  'SELENOMETHIONINE SE 75',
  'PRED MILD',
  'TRANXENE',
  'TRANXENE SD',
  'ATROPEN',
  'WESTADONE',
  'PREDNISONE',
  'MOBAN',
  'METHADOSE',
  'CHOLEBRINE',
  'ULTRA-TECHNEKOW FM',
  'SANOREX',
  'MPI DTPA KIT - CHELATE',
  'XENON XE 133',
  'XENEISOL',
  'TECHNETIUM TC-99M PENTETATE KIT',
  'CHLORMERODRIN HG 197',
  'GLOFIL-125',
  'IODOHIPPURATE SODIUM I 131',
  'SODIUM ROSE BENGAL I 131',
  'SODIUM PERTECHNETATE TC 99M',
  'MINITEC',
  'ACTIN-N',
  'CORTIFOAM',
  'FASTIN',
  'LOESTRIN FE 1/20',
  'LOESTRIN FE 1.5/30',
  'AQUATENSEN',
  'MUCOMYST W/ ISOPROTERENOL',
  'TELDRIN',
  'FLUOCINONIDE',
  'DORMATE',
  'SEPTRA',
  'SEPTRA DS',
  'BACTRIM',
  'BACTRIM DS',
  'SILVADENE',
  'ZAROXOLYN',
  'GYNOREST',
  'INTROPIN',
  'REGONOL',
  'SOY-DOME',
  'DICOPAC KIT',
  'CATAPRES',
  'CU-7',
  'HEXA-GERM',
  'GERMA-MEDICA',
  'GERMA-MEDICA "MG"',
  'SCRUBTEAM SURGICAL SPONGEBRUSH',
  'CENTRAX',
  'DIAL',
  'BICNU',
  'SEPTISOL',
  'PROGLYCEM',
  'STERILE WATER',
  'OCUSERT PILO-20',
  'PRE-OP',
  'PRE-OP II',
  'PROSTIN F2 ALPHA',
  'PLASMA-LYTE R IN PLASTIC CONTAINER',
  'HYDROXYPROGESTERONE CAPROATE',
  'INFED',
  'MINIPRESS',
  'DANTRIUM',
  'PHISO-SCRUB',
  'NORPACE',
  'MONISTAT 7',
  'E-Z SCRUB',
  'OSTEOSCAN',
  'TECHNETIUM TC 99M SULFUR COLLOID',
  'SEPTI-SOFT',
  'MOTRIN',
  'BRICANYL',
  'ECONOPRED',
  'OMNIPRED',
  'ORAP',
  'SLOW-K',
  'GALLIUM CITRATE GA 67',
  'VERMOX',
  'MODICON 21',
  'ORTHO-NOVUM 1/35-21',
  'TRAVASOL W/ ELECTROLYTES',
  'RENAMIN W/O ELECTROLYTES',
  'MONISTAT-DERM',
  'CALCIMAR',
  'MICRONASE',
  'COMBIPRES',
  'ISOPAQUE 280',
  'LIDOCAINE HYDROCHLORIDE',
  'YTTERBIUM YB 169 DTPA',
  'LOXITANE',
  'TIGAN',
  'DIABETA',
  'KLONOPIN',
  'FIORINAL',
  'LORELCO',
  'DIPROSONE',
  'TECHNESCAN PYP KIT',
  'LUDIOMIL',
  'NIPRIDE',
  'OCUSERT PILO-40',
  'LIDOPEN',
  'PERCHLORACAP',
  'PROGESTASERT',
  'SINEMET',
  'HALOG',
  'DANOCRINE',
  'PROVENTIL',
  'BACTRIM PEDIATRIC',
  'TECHNETIUM TC 99M DIPHOSPHONATE-TIN KIT',
  'FLAVORED COLESTID',
  'COLESTID',
  'NORINYL ',
  'BREVICON 21-DAY',
  'RENOQUID',
  'VANCERIL',
  'DTIC-DOME',
  'OVCON-50',
  'DITROPAN',
  'NAPROSYN',
  'LIDOCAINE HYDROCHLORIDE PRESERVATIVE FREE',
  'NORMOSOL-R IN PLASTIC CONTAINER',
  'GLEOSTINE',
  'SOLATENE',
  'CAPITROL',
  'SEPTRA GRAPE',
  'OPTIMINE',
  'NOVAFED',
  'NALFON',
  'XYLO-PFAN',
  'LO/OVRAL',
  'LOTRIMIN',
  'FORANE',
  'TOLECTIN',
  'TOLECTIN 600',
  'SODIUM IODIDE I 123',
  'SORBITOL-MANNITOL IN PLASTIC CONTAINER',
  'PHYSIOSOL IN PLASTIC CONTAINER',
  'THYPINONE',
  'INTRALIPID 10%',
  'PERGONAL',
  'CINTICHEM TECHNETIUM 99M HEDSPA',
  'NEOSCAN',
  'ACETIC ACID 0.25% IN PLASTIC CONTAINER',
  'CEPHULAC',
  'LOXITANE C',
  'TAVIST',
  'TAVIST-1',
  'SODIUM POLYPHOSPHATE-TIN KIT',
  'MPI STANNOUS DIPHOSPHONATE',
  'SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER',
  'AMINOSYN',
  'TENATHAN',
  'PHOSPHOTEC',
  'HIPREX',
  'PYROLITE',
  'CONRAY 325',
  'XENON XE 133-V.S.S.',
  'IMODIUM',
  'PREGNYL',
  'TECHNETIUM TC 99M GENERATOR',
  'KINEVAC',
  'STERILE UREA',
  'MPI INDIUM DTPA IN 111',
  'AN-DTPA',
  'OVCON-35',
  'GYNE-LOTRIMIN',
  'MODICON 28',
  'PAXIPAM',
  'FLORONE',
  'BREVICON 28-DAY',
  'MOTOFEN HALF-STRENGTH',
  'MOTOFEN',
  'DURANEST',
  'TYLENOL',
  'CLODERM',
  'NEPHRAMINE 5.4%',
  'HIBICLENS',
  'TECHNELITE',
  'PULMOLITE',
  'GLUCOTROL',
  'TECHNETIUM TC 99M TSC',
  'INJECTAPAP',
  'RIMSO-50',
  'AMINOSYN W/ ELECTROLYTES',
  'AMINOSYN ',
  'ATIVAN',
  'BRYREL',
  'LO/OVRAL-28',
  'THALLOUS CHLORIDE TL 201',
  'PROFERDEX',
  'MIACALCIN',
  'PROSTIN E2',
  'LIBRELEASE',
  'DOBUTREX',
  'FLEXERIL',
  'LODOSYN',
  'DIDRONEL',
  'MACROTEC',
  'CHROMALBIN',
  'JEANATOPE',
  'MEGATOPE',
  'BRETHINE',
  'KLOTRIX',
  'LIORESAL',
  'REGLAN',
  'TOPICORT',
  'STADOL PRESERVATIVE FREE',
  'STADOL',
  'AN-SULFUR COLLOID',
  'SORBITOL 3% IN PLASTIC CONTAINER',
  'AMINOACETIC ACID 1.5% IN PLASTIC CONTAINER',
  'FUNDUSCEIN-25',
  'DEMSER',
  'TRANSDERM SCOP',
  'LOESTRIN 21 1.5/30',
  'LOESTRIN 21 1/20',
  'TECHNETIUM TC 99M ALBUMIN AGGREGATED KIT',
  'CHRONULAC',
  'HALCION',
  'JANIMINE',
  'RENOVUE-65',
  'RENOVUE-DIP',
  'LACTULOSE',
  'GLUCOSCAN',
  'CLINORIL',
  'TRIDESILON',
  'DURAQUIN',
  'ORTHO-NOVUM 1/35-28',
  'TAGAMET',
  'DDAVP',
  'DDAVP (NEEDS NO REFRIGERATION)',
  'MELLARIL-S',
  'SUDAFED 12 HOUR',
  'PROLOPRIM',
  'MPI DMSA KIDNEY REAGENT',
  'NORMINEST FE',
  'WESTCORT',
  'TRIMPEX',
  'TRIMPEX 200',
  'BRETYLOL',
  'TEPANIL TEN-TAB',
  'VEINAMINE 8%',
  'NOVAMINE ',
  'ADRUCIL',
  'PARLODEL',
  'LOPRESSOR',
  'DEPO-TESTADIOL',
  'NOLVADEX',
  'OSTEOLITE',
  'MAZANOR',
  'AMIPAQUE',
  'MINIZIDE',
  'HEMABATE',
  'PSORCON E',
  'DEXTROSE 60%',
  'MECLOMEN',
  'SARENIN',
  'PAMELOR',
  'DOPAMINE HYDROCHLORIDE',
  'BLOCADREN',
  'ASENDIN',
  'NUBAIN',
  'FUROSEMIDE',
  'LITHOBID',
  'RITALIN-SR',
  'INDERIDE-40/25',
  'INDERIDE-80/25',
  'DRAXIMAGE MDP-10',
  'DRAXIMAGE MDP-25',
  'LOXITANE IM',
  'MONISTAT',
  'ROCALTROL',
  'EMCYT',
  'CORSYM',
  'BUPIVACAINE HYDROCHLORIDE',
  'DEXTROSE 3.3% AND SODIUM CHLORIDE 0.3% IN PLASTIC CONTAINER',
  'PLATINOL',
  'PLATINOL-AQ',
  'CISPLATIN',
  'ACEPHEN',
  'TIMOLIDE 10-25',
  'CORGARD',
  'UNISOM',
  'CINOBAC',
  'VANSIL',
  'PHENDIMETRAZINE TARTRATE',
  'CHOLOVUE',
  'DEPAKENE',
  'TOLECTIN DS',
  'TIMOPTIC',
  'THYREL TRH',
  'MPI KRYPTON 81M GENERATOR',
  'HYLOREL',
  'TECHNETIUM TC-99M MEDRONATE KIT',
  'TRIAMINIC-12',
  'CYCLOCORT',
  'AZMACORT',
  'LANOXICAPS',
  'FACTREL',
  'CIS-MDP',
  'TECHNETIUM TC 99M MPI MDP',
  'FELDENE',
  'NASALIDE',
  'ESKALITH CR',
  'BECLOVENT',
  'LONITEN',
  'OPTICROM',
  'DEMULEN 1/35-28',
  'RESTORIL',
  'ANAPROX',
  'ANAPROX DS',
  'TRASICOR',
  'DEMULEN 1/35-21',
  'FLUIDIL',
  'VALRELEASE',
  'MYCELEX',
  'MYCELEX-7',
  'INDOCIN SR',
  'AFRINOL',
  'IBU',
  'RUFEN',
  'MIDAMOR',
  'MODURETIC 5-50',
  'CYTADREN',
  'LIPOSYN 10%',
  'TATUM-T',
  'LO/OVRAL FE',
  'DESYREL',
  'BUMEX',
  'AMIDATE',
  'DEXTROSE 10% AND SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER',
  'SOMOPHYLLIN',
  'STERILE WATER FOR INJECTION IN PLASTIC CONTAINER',
  'HALOG-E',
  'CALCIPARINE',
  'MICRO-K',
  'MICRO-K 10',
  'PROPINE',
  'TENORMIN',
  'ALLOPURINOL',
  'SULFAMETHOXAZOLE AND TRIMETHOPRIM',
  'OXYTOCIN',
  'SODIUM CHLORIDE IN PLASTIC CONTAINER',
  'ISOLYTE S IN PLASTIC CONTAINER',
  'TONOCARD',
  'PITOCIN',
  'MICROLITE',
  'TECHNESCAN GLUCEPTATE',
  'XANAX',
  'K-TAB',
  'VISKEN',
  'NEPHROFLOW',
  'SECRETIN-FERRING',
  'TYMTRAN',
  'LOPURIN',
  'VIROPTIC',
  'HIBISTAT',
  'LOPRESSOR HCT',
  'SENSORCAINE',
  'THYRO-BLOCK',
  'TOPICORT LP',
  'LYMPHAZURIN',
  'CALDEROL',
  'TECHNESCAN',
  'CARAFATE',
  'AMERSCAN MDP KIT',
  'TIS-U-SOL IN PLASTIC CONTAINER',
  'ACETAMINOPHEN',
  'AEROBID',
  'WELLCOVORIN',
  'CAPOTEN',
  'FLAGYL I.V.',
  'FLAGYL I.V. RTU IN PLASTIC CONTAINER',
  'ORTHO-NOVUM 10/11-21',
  'ORTHO-NOVUM 10/11-28',
  'SEROPHENE',
  'HEXASCRUB',
  'CHYMEX',
  'TURGEX',
  'ULTRALENTE',
  'BUPRENEX',
  'AYGESTIN',
  'PHYSIOSOL PH 7.4 IN PLASTIC CONTAINER',
  'LITHIUM CITRATE',
  'LOPID',
  'AMINOSYN-RF 5.2%',
  'MVC PLUS',
  'M.V.C. 9+3',
  'DOLOBID',
  'INTRALIPID 20%',
  'NITROPRESS',
  'TALACEN',
  'SOYACAL 10%',
  'HEPATOLITE',
  'BSS PLUS',
  'CIBACALCIN',
  'OCUCLEAR',
  'VENTOLIN',
  'PROCARDIA',
  'PROSTIN VR PEDIATRIC',
  'ISOPTIN',
  'TECHNESCAN HIDA',
  'TRINALIN',
  'TIS-U-SOL',
  'BAROS',
  'DRAXIMAGE DTPA',
  'CHENIX',
  'LOCOID',
  'METRONIDAZOLE',
  'IRRIGATING SOLUTION G IN PLASTIC CONTAINER',
  'VANCENASE',
  'NITROGLYCERIN',
  'NIZORAL',
  'DIULO',
  'XENON XE 127',
  'TRIDIL',
  'LOZOL',
  'RIMADYL',
  'POTASSIUM IODIDE',
  'INDERAL LA',
  'EULEXIN',
  'YUTOPAR',
  'FANSIDAR',
  'DURAMORPH PF',
  'INFUMORPH',
  'SSD',
  'SSD AF',
  'SODIUM NITROPRUSSIDE',
  'PROCALAMINE',
  'BECONASE',
  'WYTENSIN',
  'NITROSTAT',
  'AMINOCAPROIC ACID',
  'MONISTAT 5',
  'SULFAMETHOXAZOLE AND TRIMETHOPRIM DOUBLE STRENGTH',
  'CARDIZEM',
  'ZOVIRAX',
  'PLEGISOL IN PLASTIC CONTAINER',
  'NICORETTE',
  'NICORETTE (MINT)',
  'OVIDE',
  'LIPOSYN 20%',
  'SULFATRIM PEDIATRIC',
  'SULFATRIM',
  'NITRO-BID',
  'TRENTAL',
  'BETADINE',
  'BETA-VAL',
  'WELLBUTRIN',
  'CORZIDE',
  'THEOPHYLLINE AND DEXTROSE 5% IN PLASTIC CONTAINER',
  'MARINOL',
  'VERSED',
  'NORPACE CR',
  'DELSYM',
  'TRAVAMULSION 10%',
  'ACCUTANE',
  'CHYMODIACTIN',
  'IOSAT',
  'NORDETTE-21',
  'NICLOCIDE',
  'NITRONAL',
  'NITRO IV',
  'METRO I.V.',
  'HEPATAMINE 8%',
  'CESAMET',
  'BRANCHAMIN 4%',
  'TRIMETHOPRIM',
  'PARAGARD T 380A',
  'TZ-3',
  'TODAY',
  'BRANCHAMIN 4% IN PLASTIC CONTAINER',
  'GAVISCON',
  'NORMODYNE',
  'RIDAURA',
  'INDOMETHACIN',
  'MARCAINE',
  'INOCOR',
  'ACLOVATE',
  'ZANTAC 150',
  'ZANTAC 300',
  'NITROLINGUAL',
  'NITROLINGUAL PUMPSPRAY',
  'HYDERGINE LC',
  'DORAL',
  'CAPOZIDE ',
  'BILTRICIDE',
  'TRANDATE',
  'MODRASTANE',
  'DEPAKOTE',
  'ACETATED RINGER\'S IN PLASTIC CONTAINER',
  'BUSPAR',
  'TALWIN NX',
  'ISOVUE',
  'EXELDERM',
  'DIPROLENE',
  'VASOCON-A',
  'ISOCLOR',
  'LOPROX',
  'LITHOSTAT',
  'SPECTAZOLE',
  'ORUDIS',
  'CALCIBIND',
  'TRAVAMULSION 20%',
  'TENORETIC 100',
  'TENORETIC 50',
  'BRETHAIRE',
  'ANSAID',
  'VEPESID',
  'TORNALATE',
  'LACRISERT',
  'NITROL',
  'NORCURON',
  'HUMULIN ',
  'NORDETTE-28',
  'SOYACAL 20%',
  'NEOPHAM 6.4%',
  'COLD CAPSULE IV',
  'COLD CAPSULE V',
  'PILOPINE HS',
  'PRALIDOXIME CHLORIDE',
  'THERMAZENE',
  'CALAN',
  'DOPAMINE HYDROCHLORIDE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'LOTRISONE',
  'TAMBOCOR',
  'TRACRIUM PRESERVATIVE FREE',
  'TRACRIUM',
  'LITHANE',
  'BETADERM',
  'DAYPRO',
  'PSEUDOEPHEDRINE HYDROCHLORIDE AND CHLORPHENIRAMINE MALEATE',
  'XYLOSE',
  'VIRAZOLE',
  'BETAMETHASONE VALERATE',
  'BETATREX',
  'NIMOTOP',
  'PROTOSTAT',
  'VISKAZIDE',
  'MEXITIL',
  'CALCIJEX',
  'EMBOLEX',
  'MONISTAT 3',
  'CATAPRES-TTS',
  'SODIUM ACETATE',
  'TOLAZAMIDE',
  'POTASSIUM ACETATE',
  'AMINESS 5.2% ESSENTIAL AMINO ACIDS W/ HISTADINE',
  'HEXABRIX',
  'SECTRAL',
  'M.V.I. PEDIATRIC',
  'LODINE',
  'VERAPAMIL HYDROCHLORIDE',
  'NORQUEST FE',
  'PENNTUSS',
  'REVIA',
  'M.V.I.-12 LYOPHILIZED',
  'METHYLDOPA',
  'CODIMAL-L.A. 12',
  'PROZAC',
  'SARAFEM',
  'CARNITOR',
  'OMNIPAQUE ',
  'CHOLETEC',
  'LIPOSYN III',
  'CORDARONE',
  'LEVATOL',
  'TRI-NORINYL 21-DAY',
  'TRI-NORINYL 28-DAY',
  'COLYTE',
  'ORTHO-NOVUM',
  'VASOCIDIN',
  'ADVIL',
  'LIPOSYN II ',
  'ACTIFED',
  'VASOTEC',
  'BEPADIN',
  'VASCOR',
  'MAXAIR',
  'LUPRON',
  'GOLYTELY',
  'NUPRIN',
  'MOTRIN IB',
  'MOTRIN MIGRAINE PAIN',
  'TROPHAMINE',
  'PERIDEX',
  'BRETYLIUM TOSYLATE',
  'TENEX',
  'DILAUDID-HP',
  'DILAUDID',
  'CIS-PYRO',
  'INDIUM IN 111 OXYQUINOLINE',
  'NORMOZIDE',
  'DESOWEN',
  'SUFENTA PRESERVATIVE FREE',
  'HYTRIN',
  'INDERIDE LA ',
  'MYCELEX-G',
  'UROCIT-K',
  'FLAREX',
  'PROSOM',
  'ESTRADERM',
  'DALGAN',
  'ATROVENT',
  'ZANTAC',
  'ISMO',
  'TUSSIONEX PENNKINETIC',
  'FENTANYL CITRATE',
  'KLOR-CON',
  'EXIDINE',
  'MAXZIDE',
  'MAXZIDE-25',
  'BETAMETHASONE DIPROPIONATE',
  'ALPHATREX',
  'RYTHMOL',
  'CALAN SR',
  'LAC-HYDRIN',
  'PEDIAPRED',
  'TRANDATE HCT',
  'GASTROCROM',
  'TRIPHASIL-28',
  'TRIPHASIL-21',
  'PROVOCHOLINE',
  'SYPRINE',
  'VOLTAREN',
  'CARTROL',
  'FEMSTAT',
  'FML FORTE',
  'BETAGAN',
  'VASERETIC',
  'MANGANESE SULFATE',
  'ZINC SULFATE',
  'E-Z SCRUB 201',
  'CARNITOR SF',
  'CIDA-STAT',
  'CHG SCRUB',
  'FLORONE E',
  'PSORCON',
  'PAREMYD',
  'PENTAM',
  'CYTOTEC',
  'BETOPTIC',
  'CHROMIC CHLORIDE',
  'DIMETANE-DX',
  'CYKLOKAPRON',
  'OCL',
  'DIZAC',
  'MD-76R',
  'NOVANTRONE',
  'LIPIDIL',
  'TRICOR (MICRONIZED)',
  'MAGNESIUM SULFATE',
  'TEMOVATE',
  'SELEGILINE HYDROCHLORIDE',
  'CUPRIC SULFATE',
  'ALFENTA',
  'NAFTIN',
  'ETHAMOLIN',
  'AZO GANTRISIN',
  'MOCTANIN',
  'TEGISON',
  'AMINOSYN-HBC 7%',
  'TEN-K',
  'E-Z PREP',
  'E-Z PREP 220',
  'NOROXIN',
  'PERMAX',
  'BREVIBLOC',
  'PROFENAL',
  'BECONASE AQ',
  'AMINOSYN-PF 7%',
  'PSEUDO-12',
  'OCUFEN',
  'VISINE L.R.',
  'HEAD & SHOULDERS CONDITIONER',
  'CARDIOGEN-82',
  'METRODIN',
  'FERTINEX',
  'FIORINAL W/CODEINE',
  'EPIPEN',
  'EPIPEN JR.',
  'EPIPEN E Z PEN',
  'EPI E Z PEN JR',
  'SPECTAMINE',
  'TAGAMET HYDROCHLORIDE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER',
  'NIX',
  'PRIMACOR',
  'AMINOSYN II ',
  'LOPRESSIDONE',
  'DERMA-SMOOTHE/FS',
  'DERMOTIC',
  'DRIXORAL PLUS',
  'PINDAC',
  'PEPCID',
  'TIMOPTIC IN OCUDOSE',
  'CARDIZEM SR',
  'E-Z SCRUB 241',
  'ADALAT',
  'RENACIDIN',
  'IMODIUM A-D',
  'CARDENE',
  'VENTOLIN ROTACAPS',
  'CHLORHEXIDINE GLUCONATE',
  'PARATHAR',
  'LAMPRENE',
  'ROGAINE (FOR MEN)',
  'ROGAINE (FOR WOMEN)',
  'KERLONE',
  'AXID',
  'PEPCID PRESERVATIVE FREE',
  'MS CONTIN',
  'POVIDONE IODINE',
  'CYSTEINE HYDROCHLORIDE',
  'FML-S',
  'UCEPHAN',
  'NUTRILIPID 10%',
  'NUTRILIPID 20%',
  'MYKROX',
  'CIPRO',
  'ELOCON',
  'DYNACIRC',
  'DIPROLENE AF',
  'PRINIVIL',
  'MICRO-K LS',
  'DERMATOP',
  'THIOLA',
  'THALITONE',
  'MEFLOQUINE HYDROCHLORIDE',
  'TERAZOL 7',
  'OSMOVIST 190',
  'OSMOVIST 240',
  'RELAFEN',
  'VANCENASE AQ',
  'LARIAM',
  'ZANTAC IN PLASTIC CONTAINER',
  'ACTIGALL',
  'MAGNEVIST',
  'OXSORALEN-ULTRA',
  'VOLMAX',
  'SILDAFLO',
  'VERELAN',
  'PENETREX',
  'PREPIDIL',
  'ROWASA',
  'SFROWASA',
  'DEXTROSE 7.7% IN PLASTIC CONTAINER',
  'DIPRIVAN',
  'ARDUAN',
  'HUMATROPE',
  'TERAZOL 3',
  'MEVACOR',
  'TORADOL',
  'POTASSIUM CITRATE',
  'FLUMADINE',
  'ASACOL',
  'ORTHO CYCLEN-21',
  'ORTHO CYCLEN-28',
  'RETROVIR',
  'CLARITIN',
  'CLARITIN HIVES RELIEF',
  'TILADE',
  'CYTOVENE',
  'SANDOSTATIN',
  'CARDURA',
  'QUESTRAN LIGHT',
  'CLARITIN-D',
  'NUTROPIN',
  'ENLON-PLUS',
  'PROCARDIA XL',
  'LUTREPULSE KIT',
  'DECABID',
  'ORTHO TRI-CYCLEN',
  'ACULAR',
  'OPTIRAY ',
  'DIPENTUM',
  'NASCOBAL',
  'ZOLADEX',
  'LUPRON DEPOT',
  'FLOXIN',
  'METROGEL',
  'EFIDAC 24 CHLORPHENIRAMINE MALEATE',
  'ETHMOZINE',
  'CHIBROXIN',
  'CLOZARIL',
  'TESTODERM',
  'IFEX',
  'IFEX/MESNEX KIT',
  'SAIZEN',
  'ZOCOR',
  'ADVIL COLD AND SINUS',
  'BIO-TROPIN',
  'ZOMACTON',
  'MINIPRESS XL',
  'CONCENTRAID',
  'ZESTRIL',
  'PRINZIDE',
  'IOPIDINE',
  'PROMETRIUM',
  'CARDIOLITE',
  'MIRALUMA',
  'NORVASC',
  'DILATRATE-SR',
  'PHARMASEAL SCRUB CARE',
  'DEPAKOTE CP',
  'CONDYLOX',
  'NULYTELY',
  'NASACORT',
  'SEMPREX-D',
  'KERLEDEX',
  'PRILOSEC',
  'PROAMATINE',
  'ORUVAIL',
  'IV PERSANTINE',
  'ADAGEN',
  'SORIATANE',
  'BIOSCRUB',
  'OXISTAT',
  'CERETEC',
  'CHILDREN\'S ADVIL',
  'PLENDIL',
  'ZYRTEC ALLERGY',
  'ZYRTEC HIVES RELIEF',
  'SUPPRELIN',
  'ZOLOFT',
  'INDIUM IN 111 CHLORIDE',
  'BETOPTIC S',
  'DAPIPRAZOLE HYDROCHLORIDE',
  'LOTENSIN',
  'CUPRIMINE',
  'DEPEN',
  'ELIMITE',
  'SINEMET CR',
  'INDICLOR',
  'GEREF',
  'BETAPACE',
  'ORNIDYL',
  'PARAPLATIN',
  'BUTOCONAZOLE NITRATE',
  'TECHNESCAN MAG3',
  'MESNEX',
  'ACCUPRIL',
  'SYNAREL',
  'NEBUPENT',
  'ZESTORETIC',
  'NORPLANT',
  'PRAVACHOL',
  'SINE-AID IB',
  'ALTACE',
  'ANAFRANIL',
  'OPTIPRANOLOL',
  'AMBIEN',
  'BRONCHO SALINE',
  'MONOPRIL',
  'MORPHINE SULFATE',
  'OCUFLOX',
  'CORLOPAM',
  'HEXALEN',
  'CARDIOTEC',
  'KLARON',
  'ADENOCARD',
  'NOVOLIN R',
  'ACTINEX',
  'EMLA',
  'INTRALIPID 30%',
  'NUROMAX',
  'DIFLUCAN',
  'CUTIVATE',
  'NOVOLIN N',
  'GANITE',
  'TOPROL-XL',
  'RENOVA',
  'ULTRAVATE',
  'NITROGLYCERIN IN DEXTROSE 5%',
  'OCUPRESS',
  'PHOSLO',
  'ORAMORPH SR',
  'BUPIVACAINE HYDROCHLORIDE KIT',
  'TICLID',
  'ULTRATAG',
  'ZEBETA',
  'PROSTEP',
  'NOVOLIN 70/30',
  'CILOXAN',
  'CHEMET',
  'CAPEX',
  'CARDENE SR',
  'ZOFRAN',
  'ZOFRAN PRESERVATIVE FREE',
  'MAXAQUIN',
  'SUDAFED 24 HOUR',
  'PAXIL',
  'SURVANTA',
  'LOTENSIN HCT',
  'ERGAMISOL',
  'AREDIA',
  'FLUDARA',
  'EXOSURF NEONATAL',
  'SHADE UVAGUARD',
  'PENTASA',
  'GLYNASE',
  'GLYBURIDE (MICRONIZED)',
  'ATROPINE SULFATE',
  'THIOPLEX',
  'ADENOSCAN',
  'CARDIZEM CD',
  'RBC-SCAN',
  'MACROBID',
  'OPCON-A',
  'EC-NAPROSYN',
  'FOSCAVIR',
  'COGNEX',
  'DESOGEN',
  'ROMAZICON',
  'HALOPERIDOL',
  'HABITROL',
  'IMITREX',
  'IMITREX STATDOSE',
  'SPORANOX',
  'IOBENGUANE SULFATE I 131',
  'IMAGENT',
  'DILACOR XR',
  'MIVACRON',
  'TRIOSTAT',
  'ASTELIN',
  'SUPRANE',
  'VUMON',
  'ALLERNAZE',
  'FLONASE',
  'NIPENT',
  'OMNISCAN',
  'DIAZEPAM',
  'ACCURETIC',
  'ZONALON',
  'ESTROSTEP 21',
  'ESTROSTEP FE',
  'PROHANCE',
  'METASTRON',
  'DEMADEX',
  'FUSILEV',
  'CATAFLAM',
  'TRANSDERM-NITRO',
  'NITRO-DUR',
  'MIGRANAL',
  'EFFEXOR',
  'SERZONE',
  'VIDEX',
  'ACTHREL',
  'LOVENOX (PRESERVATIVE FREE)',
  'LOVENOX',
  'NICODERM CQ',
  'SODIUM THIOSULFATE',
  'ESTRADIOL',
  'NILANDRON',
  'VITAPED',
  'PROSCAR',
  'LIPOSYN III 30%',
  'ACEON',
  'ZIAC',
  'FELBATOL',
  'ALOMIDE',
  'LAMISIL',
  'ELMIRON',
  'FENTANYL',
  'ADALAT CC',
  'HIVID',
  'NALBUPHINE HYDROCHLORIDE',
  'DOBUTAMINE HYDROCHLORIDE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'ALEVE',
  'METROGEL-VAGINAL',
  'PROPULSID',
  'ZINECARD',
  'MIOCHOL-E',
  'ZEMURON',
  'MONOKET',
  'LIVOSTIN',
  'ULTRAVIST ',
  'ETHYOL',
  'IMDUR',
  'NAPHCON-A',
  'NORMIFLO',
  'LEUSTATIN',
  'COLGATE TOTAL',
  'FIORICET W/ CODEINE',
  'RHINOCORT',
  'TEGRETOL-XR',
  'NEURONTIN',
  'SEREVENT',
  'SALAGEN',
  'TAGAMET HB',
  'KYTRIL',
  'RENORMAX',
  'LAMICTAL',
  'LUVOX',
  'HALFAN',
  'ZANTAC 25',
  'VOLTAREN-XR',
  'NEUROLITE',
  'MEPRON',
  'LESCOL',
  'TAXOL',
  'LUPRON DEPOT-PED KIT',
  'RISPERDAL',
  'DOVONEX',
  'DERMATOP E EMOLLIENT',
  'GENOTROPIN PRESERVATIVE FREE',
  'GENOTROPIN',
  'ULTRAM',
  'MONOPRIL-HCT',
  'FRAGMIN',
  'MONISTAT 7 COMBINATION PACK',
  'GYNE-LOTRIMIN COMBINATION PACK',
  'COMBIVENT',
  'FERRISELTZ',
  'COREG',
  'ALOPRIM',
  'ORTHO-CEPT',
  'PREMPRO (PREMARIN;CYCRIN)',
  'PREMPHASE (PREMARIN;CYCRIN 14/14)',
  'TRASYLOL',
  'FLUDEOXYGLUCOSE F18',
  'MAGNESIUM SULFATE IN PLASTIC CONTAINER',
  'NIZORAL ANTI-DANDRUFF',
  'UNIVASC',
  'OCTREOSCAN',
  'ORLAAM',
  'OXILAN-300',
  'OXILAN-350',
  'VIVELLE',
  'PEPCID AC',
  'NEUTREXIN',
  'HUMEGON',
  'GLUCOTROL XL',
  'TIMOPTIC-XE',
  'AGRYLIN',
  'DYNACIRC CR',
  'DIFFERIN',
  'TEMOVATE E',
  'PRIMACOR IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'AMINOSYN-HF 8%',
  'ZYRTEC',
  'VISIPAQUE 270',
  'VISIPAQUE 320',
  'NAPRELAN',
  'STIMATE',
  'STIMATE (NEEDS NO REFRIGERATION)',
  'SULAR',
  'GLUCOPHAGE',
  'WELLBUTRIN SR',
  'HEPATASOL 8%',
  'FAMVIR',
  'LOTREL',
  'CEREZYME',
  'MYOVIEW',
  'MYOVIEW 30ML',
  'INPERSOL-LC/LM W/ DEXTROSE 1.5% IN PLASTIC CONTAINER',
  'INPERSOL-LC/LM W/ DEXTROSE 2.5% IN PLASTIC CONTAINER',
  'INPERSOL-LC/LM W/ DEXTROSE 3.5% IN PLASTIC CONTAINER',
  'INPERSOL-LC/LM W/ DEXTROSE 4.25% IN PLASTIC CONTAINER',
  'CLIMARA',
  'MENOSTAR',
  'GONAL-F',
  'CAVERJECT',
  'NIASPAN',
  'NIASPAN TITRATION STARTER PACK',
  'NICOTROL',
  'COZAAR',
  'HYZAAR',
  'NAVELBINE',
  'MYCELEX-7 COMBINATION PACK',
  'CYSTAGON',
  'ZANAFLEX',
  'AVITA',
  'TIAZAC',
  'ADVIL LIQUI-GELS',
  'ADVIL MIGRAINE LIQUI-GELS',
  'ZOFRAN AND DEXTROSE IN PLASTIC CONTAINER',
  'PREVACID',
  'TRUSOPT',
  'NASAREL',
  'GASTROMARK',
  'CERVIDIL',
  'ZERIT',
  'PYRIDOSTIGMINE BROMIDE',
  'REMERON',
  'FERIDEX I.V.',
  'FEMPATCH',
  'GENESA',
  'FEMSTAT 3',
  'SABRIL',
  'AZELEX',
  'ORUDIS KT',
  'ORGARAN',
  'VESANOID',
  'BETIMOL',
  'PULMICORT',
  'FLOLAN',
  'IMODIUM A-D EZ CHEWS',
  'TAXOTERE',
  'CEREBYX',
  'PHOTOFRIN',
  'PANDEL',
  'ETOPOPHOS PRESERVATIVE FREE',
  'GALZIN',
  'REVEX',
  'NASALCROM',
  'NASACORT ALLERGY 24 HOUR',
  'CLARITIN-D 24 HOUR',
  'ZYFLO',
  'ESTRING',
  'VEXOL',
  'RETIN-A MICRO',
  'RETIN-A-MICRO',
  'ULTANE',
  'PRECOSE',
  'INNOHEP',
  'VISINE',
  'VANCERIL DOUBLE STRENGTH',
  'VALTREX',
  'MAGNESIUM SULFATE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'ANDRODERM',
  'ALPHAGAN',
  'CORVERT',
  'AMARYL',
  'FARESTON',
  'CASODEX',
  'ACTRON',
  'PROVENTIL-HFA',
  'MICROZIDE',
  'TOPAMAX',
  'TIAMATE',
  'TECZEM',
  'GEMZAR',
  'APHTHASOL',
  'CLINISOL 15% SULFITE FREE IN PLASTIC CONTAINER',
  'CHILDREN\'S MOTRIN',
  'CICLOPIROX',
  'ZANTAC 75',
  'INFASURF PRESERVATIVE FREE',
  'NUTROPIN AQ',
  'NUTROPIN AQ PEN',
  'NUTROPIN AQ NUSPIN',
  'MENTAX',
  'GYNE-LOTRIMIN 3',
  'GYNE-LOTRIMIN 3 COMBINATION PACK',
  'PREMPRO',
  'PREMPHASE 14/14',
  'MAVIK',
  'IONTOCAINE',
  'METROCREAM',
  'IVY BLOCK',
  'NAROPIN',
  'VIVELLE-DOT',
  'ARIMIDEX',
  'DOPAMINE HYDROCHLORIDE IN DEXTROSE 5%',
  'JADELLE',
  'PROCANBID',
  'ACCOLATE',
  'FLOVENT',
  'NIMBEX',
  'NIMBEX PRESERVATIVE FREE',
  'COVERA-HS',
  'AXID AR',
  'TRITEC',
  'FOSAMAX',
  'HUMALOG',
  'HUMALOG PEN',
  'HUMALOG KWIKPEN',
  'HUMALOG TEMPO PEN',
  'EPIVIR',
  'VITRASERT',
  'QUADRAMET',
  'CAMPTOSAR',
  'BUPHENYL',
  'DENTIPATCH',
  'CYSTADANE',
  'ELLIOTTS B SOLUTION',
  'FLOMAX',
  'COTAZYM',
  'FOLLISTIM',
  'LOTEMAX',
  'LODINE XL',
  'MERETEK UBT KIT (W/ PRANACTIN)',
  'BREATHTEK UBT FOR H-PYLORI',
  'SCLEROSOL',
  'CHILDREN\'S ADVIL-FLAVORED',
  'TARKA',
  'ZYPREXA',
  'DEPACON',
  'XALATAN',
  'RILUTEK',
  'TAZORAC',
  'JUNIOR STRENGTH MOTRIN',
  'SEROSTIM',
  'SEROSTIM LQ',
  'IMODIUM MULTI-SYMPTOM RELIEF',
  'ARTHROTEC',
  'COLAZAL',
  'LIDODERM',
  'DURACLON',
  'KADIAN',
  'PYTEST',
  'PYTEST KIT',
  'BETOPTIC PILO',
  'COPAXONE',
  'ANZEMET',
  'ALLEGRA',
  'LEVONORGESTREL',
  'INVIRASE',
  'DENAVIR',
  'ULTIVA',
  'MERIDIA',
  'LEVAQUIN',
  'LEVAQUIN IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'VIRAMUNE',
  'GLIADEL',
  'VISTIDE',
  'SEROQUEL',
  'AMMONUL',
  'GABITRIL',
  'ELDEPRYL',
  'DIASTAT',
  'DIASTAT ACUDIAL',
  'EDEX',
  'TESLASCAN',
  'ALORA',
  'REQUIP',
  'NORVIR',
  'DOSTINEX',
  'DIOVAN',
  'ALBENZA',
  'MIRAPEX',
  'LEXXEL',
  'MONISTAT 3 COMBINATION PACK',
  'HYCAMTIN',
  'URSO 250',
  'URSO FORTE',
  'VAGISTAT-1',
  'ZAGAM',
  'CLINIMIX E 2.75/5 SULFITE FREE W/ ELECT IN DEXTROSE 5% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 2.75/10 SULFITE FREE W/ ELECT IN DEXTROSE 10% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 2.75/25 SULFITE FREE W/ ELECT IN DEXTROSE 25% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 4.25/5 SULFITE FREE W/ ELECT IN DEXTROSE 5% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 4.25/10 SULFITE FREE W/ ELECT IN DEXTROSE 10% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 4.25/20 SULFITE FREE W/ ELECT IN DEXTROSE 20% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 4.25/25 SULFITE FREE W/ ELECT IN DEXTROSE 25% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 5/10 SULFITE FREE W/ ELECT IN DEXTROSE 10% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 5/15 SULFITE FREE W/ ELECT IN DEXTROSE 15% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 5/20 SULFITE FREE W/ ELECT IN 20% DEXTROSE W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 5/25 SULFITE FREE W/ ELECT IN DEXTROSE 25% W/ CALCIUM IN PLASTIC CONTAINER',
  'CLINIMIX E 5/35 SULFITE FREE W/ ELECT IN DEXTROSE 35% W/ CALCIUM IN PLASTIC CONTAINER',
  'GLYSET',
  'ALESSE',
  'CRIXIVAN',
  'LUMENHANCE',
  'MIFEPREX',
  'PATADAY TWICE DAILY RELIEF',
  'ARICEPT',
  'RAXAR',
  'ANTIZOL',
  'TASMAR',
  'MIRALAX',
  'EFFEXOR XR',
  'MUSE',
  'CRINONE',
  'LIPITOR',
  'CLARITIN REDITABS',
  'CLARITIN HIVES RELIEF REDITAB',
  'RESCRIPTOR',
  'EMADINE',
  'SKELID',
  'ZYBAN',
  'CARBATROL',
  'MIRCETTE',
  'TRELSTAR',
  'VICOPROFEN',
  'PROVIGIL',
  'INTEGRILIN',
  'PRELAY',
  'REZULIN',
  'ALDARA',
  'CREON',
  'FEMARA',
  'BIDIL',
  'UNIRETIC',
  'SUBUTEX',
  'SUBOXONE',
  'CLINIMIX 2.75/5 SULFITE FREE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'CLINIMIX 2.75/10 SULFITE FREE IN DEXTROSE 10% IN PLASTIC CONTAINER',
  'CLINIMIX 2.75/25 SULFITE FREE IN DEXTROSE 25% IN PLASTIC CONTAINER',
  'CLINIMIX 4.25/5 SULFITE FREE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'CLINIMIX 4.25/10 SULFITE FREE IN DEXTROSE 10% IN PLASTIC CONTAINER',
  'CLINIMIX 4.25/20 SULFITE FREE IN DEXTROSE 20% IN PLASTIC CONTAINER',
  'CLINIMIX 4.25/25 SULFITE FREE IN DEXTROSE 25% IN PLASTIC CONTAINER',
  'CLINIMIX 5/10 SULFITE FREE IN DEXTROSE 10% IN PLASTIC CONTAINER',
  'CLINIMIX 5/15 SULFITE FREE IN DEXTROSE 15% IN PLASTIC CONTAINER',
  'CLINIMIX 5/20 SULFITE FREE IN DEXTROSE 20% IN PLASTIC CONTAINER',
  'CLINIMIX 5/25 SULFITE FREE IN DEXTROSE 25% IN PLASTIC CONTAINER',
  'CLINIMIX 5/35 SULFITE FREE IN DEXTROSE 35% IN PLASTIC CONTAINER',
  'TEVETEN',
  'BAYCOL',
  'PRANDIN',
  'BSS',
  'NORITATE',
  'CUROSURF',
  'RHINOCORT ALLERGY',
  'ACTIQ',
  'PEPCID RPD',
  'AROMASIN',
  'AVAPRO',
  'AVALIDE',
  'TROVAN',
  'TROVAN PRESERVATIVE FREE',
  'NASONEX',
  'AMERGE',
  'LAMICTAL CD',
  'XENICAL',
  'PROPULSID QUICKSOLV',
  'ZOMIG',
  'LOCOID LIPOCREAM',
  'DETROL',
  'SUCRAID',
  'SONORX',
  'PERIOCHIP',
  'DAYPRO ALTA',
  'VIRACEPT',
  'ZOFRAN ODT',
  'DEPAKOTE ER',
  'NASACORT HFA',
  'THALOMID',
  'ALLEGRA-D 12 HOUR ALLERGY AND CONGESTION',
  'PROPECIA',
  'ZONEGRAN',
  'TESTODERM TTS',
  'CAFCIT',
  'COMTAN',
  'FLOXIN OTIC',
  'TWINJECT 0.3',
  'TWINJECT 0.15',
  'ADRENACLICK',
  'EXCEDRIN (MIGRAINE)',
  'ALREX',
  'CIPRO HC',
  'DICLOFENAC SODIUM',
  'ACULAR PRESERVATIVE FREE',
  'PEDIATRIC ADVIL',
  'INFANT\'S ADVIL',
  'KLONOPIN RAPIDLY DISINTEGRATING',
  'EVISTA',
  'AZOPT',
  'DIOVAN HCT',
  'ZEMPLAR',
  'CELEXA',
  'EXELON',
  'GEODON',
  'FORTOVASE',
  'SINGULAIR',
  'FORADIL',
  'CHLORAPREP ONE-STEP',
  'CHLORAPREP WITH TINT',
  'CHLORAPREP ONE-STEP FREPP',
  'FLOVENT DISKUS 50',
  'FLOVENT DISKUS 100',
  'FLOVENT DISKUS 250',
  'ROGAINE EXTRA STRENGTH (FOR MEN)',
  'ACTONEL',
  'XOPENEX',
  'ATACAND',
  'PLAVIX',
  'TOPAMAX SPRINKLE',
  'INOMAX',
  'ESCLIM',
  'PROSOL 20% SULFITE FREE IN PLASTIC CONTAINER',
  'MICARDIS',
  'COMBIVIR',
  'SONATA',
  'LEVLITE',
  'HECTOROL',
  'PLETAL',
  'MAXALT',
  'MAXALT-MLT',
  'CYCLOSET',
  'FLAGYL ER',
  'COSOPT',
  'COMBIPATCH',
  'CHILDREN\'S ALLEGRA ALLERGY',
  'CHILDREN\'S ALLEGRA HIVES',
  'ALLEGRA ALLERGY',
  'ALLEGRA HIVES',
  'ANGIOMAX',
  'LUNELLE',
  'ARGATROBAN',
  'AGGRENOX',
  'PANRETIN',
  'ACUTECT',
  'LOTRIMIN AF',
  'VALSTAR PRESERVATIVE FREE',
  'VIAGRA',
  'XELODA',
  'DITROPAN XL',
  'THYROGEN',
  'OPTISON',
  'PYLORI-CHEK BREATH TEST',
  'METROLOTION',
  'REBETOL',
  'ARAVA',
  'ACTIVELLA',
  'VAGIFEM',
  'QVAR 80',
  'QVAR 40',
  'AGGRASTAT',
  'GLUCAGEN',
  'NATRECOR',
  'SOLAGE',
  'RENAGEL',
  'PULMICORT RESPULES',
  'TIKOSYN',
  'ROXICODONE',
  'LUXIQ',
  'PAXIL CR',
  'OPTIMARK',
  'MOBIC',
  'DILTIAZEM HYDROCHLORIDE',
  'ABREVA',
  'VERELAN PM',
  'PREVEN EMERGENCY CONTRACEPTIVE KIT',
  'PENNSAID',
  'ACCUNEB',
  'DUONEB',
  'TAGAMET HB 200',
  'BUSULFEX',
  'FERRLECIT',
  'PEPCID COMPLETE',
  'VITRAVENE PRESERVATIVE FREE',
  'TIMOLOL MALEATE',
  'LEVULAN',
  'MONISTAT DUAL- PAK',
  'UVADEX',
  'SEPTOCAINE',
  'SUSTIVA',
  'ACIPHEX',
  'OPTIMARK IN PLASTIC CONTAINER',
  'ZIAGEN',
  'VENTOLIN HFA',
  'RAPLON',
  'CARAC',
  'NOVOLOG',
  'NOVOLOG PENFILL',
  'NOVOLOG FLEXPEN',
  'NOVOLOG INNOLET',
  'NOVOLOG FLEXTOUCH',
  'PROTONIX',
  'PROTONIX IV',
  'EVOXAC',
  'CENESTIN',
  'CHIROCAINE',
  'CELEBREX',
  'AXERT',
  'EPIVIR-HBV',
  'SOLARAZE',
  'FROVA',
  'AGENERASE',
  'SANDOSTATIN LAR',
  'ALOCRIL',
  'NEO TECT KIT',
  'TRILEPTAL',
  'ANDROGEL',
  'RELPAX',
  'HUMALOG MIX 75/25',
  'HUMALOG MIX 75/25 KWIKPEN',
  'HUMALOG MIX 75/25 PEN',
  'HUMALOG MIX 50/50',
  'HUMALOG MIX 50/50 KWIKPEN',
  'HUMALOG MIX 50/50 PEN',
  'PENLAC',
  'RESTASIS',
  'PRIFTIN',
  'VUSION',
  'TEMODAR',
  'KEPPRA',
  'RELENZA',
  'PRECEDEX',
  'PREFEST',
  'DEPOCYT',
  'VIOXX',
  'RID MOUSSE',
  'PALLADONE',
  'PLAN B',
  'REPRONEX',
  'TARGRETIN',
  'GANIRELIX ACETATE',
  'PRIALT',
  'DEFINITY',
  'DEFINITY RT',
  'FEMHRT',
  'ZADITOR',
  'ASMANEX TWISTHALER',
  'AVANDIA',
  'ACTOS',
  'AVAGARD',
  'ALEVE-D SINUS & COLD',
  'ADVAIR DISKUS 100/50',
  'ADVAIR DISKUS 250/50',
  'ADVAIR DISKUS 500/50',
  'MALARONE',
  'MALARONE PEDIATRIC',
  'ALAMAST',
  'LANTUS',
  'LANTUS SOLOSTAR',
  'TAVIST ALLERGY/SINUS/HEADACHE',
  'RAPAMUNE',
  'AVELOX',
  'ZYPREXA ZYDIS',
  'TAMIFLU',
  'VIADUR',
  'CYCLESSA',
  'HELICOSOL',
  'ATACAND HCT',
  'VISICOL',
  'YASMIN',
  'SULFAMETHOXAZOLE AND TRIMETHOPRIM AND PHENAZOPYRIDINE HYDROCHLORIDE',
  'SOMAVERT',
  'LOTRONEX',
  'TRI-LUMA',
  'PAMIDRONATE DISODIUM',
  'BETAXON',
  'THYRO-TABS',
  'CALCIUM CHLORIDE 10% IN PLASTIC CONTAINER',
  'VISUDYNE',
  'CONCERTA',
  'ULTRACET',
  'LAMISIL AT',
  'OPTIVAR',
  'CHILDREN\'S MOTRIN COLD',
  'ZYVOX',
  'VENOFER',
  'SECREFLO',
  'LEVOLET',
  'WELCHOL',
  'OLUX',
  'TRIVAGIZOLE 3',
  'KETEK',
  'VANIQA',
  'NORDITROPIN',
  'NORDITROPIN NORDIFLEX',
  'NORDITROPIN FLEXPRO',
  'OVIDREL',
  'ZYRTEC-D 12 HOUR',
  'BETAPACE AF',
  'NEXIUM',
  'FACTIVE',
  'PHOSLO GELCAPS',
  'MICARDIS HCT',
  'INFUVITE ADULT',
  'CLARINEX',
  'ESTROGEL',
  'RAZADYNE',
  'NOVOLOG MIX 70/30',
  'NOVOLOG MIX 70/30 PENFILL',
  'NOVOLOG MIX 70/30 FLEXPEN',
  'ATNAA',
  'GLUCOVANCE',
  'ORTHO EVRA',
  'VIDEX EC',
  'AVAGE',
  'NUVARING',
  'LESCOL XL',
  'XYREM',
  'CETROTIDE',
  'QUIXIN',
  'ZELNORM',
  'ASCLERA',
  'GLUCOPHAGE XR',
  'TRICOR',
  'STARLIX',
  'TRIZIVIR',
  'REMERON SOLTAB',
  'PORCINE SECRETIN',
  'UNITHROID',
  'FOLLISTIM AQ',
  'CAVERJECT IMPULSE',
  'RESCULA',
  'EXALGO',
  'SPECTRACEF',
  'ZOMETA',
  'MIRENA',
  'KALETRA',
  'CANCIDAS',
  'DETROL LA',
  'PRILOSEC OTC',
  'ZOMIG-ZMT',
  'ORFADIN',
  'FLECTOR',
  'PROZAC WEEKLY',
  'ORTHO TRI-CYCLEN LO',
  'AEROSPAN HFA',
  'TRISENOX',
  'CANASA',
  'ADVAIR HFA',
  'CHIRHOSTIM',
  'TRAVATAN',
  'CLIMARA PRO',
  'METADATE CD',
  'AVINZA',
  'MONISTAT 3 COMBINATION PACK (PREFILLED)',
  'ALPHAGAN P',
  'APOKYN',
  'INFUVITE PEDIATRIC',
  'INFUVITE PEDIATRIC (PHARMACY BULK PACKAGE)',
  'VFEND',
  'TEVETEN HCT',
  'CARDURA XL',
  'IPRIVASK',
  'REMODULIN',
  'LUMIGAN',
  'AVELOX IN SODIUM CHLORIDE 0.8% IN PLASTIC CONTAINER',
  'FOCALIN',
  'MUCINEX',
  'RITALIN LA',
  'BENICAR',
  'UROXATRAL',
  'BRAVELLE',
  'TRACLEER',
  'EUTHYROX',
  'PEXEVA',
  'LEVOXYL',
  'ELIDEL',
  'ADDERALL XR 10',
  'ADDERALL XR 20',
  'ADDERALL XR 30',
  'ADDERALL XR 25',
  'ADDERALL XR 5',
  'ADDERALL XR 15',
  'VALCYTE',
  'HICON',
  'BUTRANS',
  'LOTRIMIN ULTRA',
  'MONISTAT 1 COMBINATION PACK',
  'CLARINEX-D 12 HOUR',
  'IDKIT:HP',
  'ALTOPREV',
  'BAYER EXTRA STRENGTH ASPIRIN FOR MIGRAINE PAIN',
  'FORTEO',
  'AVODART',
  'PLENAXIS',
  'EXTRANEAL',
  'LEXAPRO',
  'ENTOCORT EC',
  'SYMLIN',
  'MINIRIN',
  'GLEEVEC',
  'EMSAM',
  'INVANZ',
  'IONSYS',
  'BEXTRA',
  'LEVO-T',
  'ELIGARD KIT',
  'FASLODEX',
  'ARIXTRA',
  'RISPERDAL CONSTA',
  'ZAVESCA',
  'TRIGLIDE',
  'OXYTROL',
  'ANGELIQ',
  'VIREAD',
  'MULTIHANCE',
  'MULTIHANCE MULTIPACK',
  'RECTIV',
  'XIFAXAN',
  'CRESTOR',
  'FEMRING',
  'CIALIS',
  'CODEPREX',
  'ESTRASORB',
  'ALOXI',
  'CHILDREN\'S ADVIL COLD',
  'ALAVERT',
  'COMBUNOX',
  'XYLOCAINE DENTAL',
  'XYLOCAINE DENTAL WITH EPINEPHRINE',
  'CITANEST PLAIN DENTAL',
  'CITANEST FORTE DENTAL',
  'ERTACZO',
  'PRAVIGARD PAC (COPACKAGED)',
  'TALC',
  'CARDIZEM LA',
  'ADVIL PM',
  'SPIRIVA',
  'PREMPRO/PREMPHASE',
  'COMBIGAN',
  'IRESSA',
  'LEVITRA',
  'SYNTHROID',
  'FORTICAL',
  'MENTAX-TC',
  'AVANDAMET',
  'STRATTERA',
  'TOVALT ODT',
  'METVIXIA',
  'RYTHMOL SR',
  'METHYLIN',
  'ULTRAVIST (PHARMACY BULK)',
  'OMNITROPE',
  'CYMBALTA',
  'CAMPRAL',
  'FLOVENT HFA',
  'XANAX XR',
  'AMVAZ',
  'ABILIFY',
  'INSPRA',
  'INNOPRAN XL',
  'ADVIL ALLERGY SINUS',
  'ENJUVIA',
  'ZETIA',
  'LYRICA',
  'HEPSERA',
  'ORAQIX',
  'ZERIT XR',
  'TESTIM',
  'BONIVA',
  'PROAIR HFA',
  'METAGLIP',
  'ALIMTA',
  'FORTESTA',
  'FOSRENOL',
  'FINACEA',
  'ANTHELIOS 20',
  'MIDOL LIQUID GELS',
  'CIPRO XR',
  'LUNESTA',
  'ZELAPAR',
  'FUZEON',
  'STALEVO ',
  'LIDOCAINE HYDROCHLORIDE AND EPINEPHRINE',
  'NAMENDA',
  'PROHANCE MULTIPACK',
  'FEMCON FE',
  'ELOXATIN',
  'ZYMAR',
  'DUOCAINE',
  'ALINIA',
  'EMTRIVA',
  'CAPITAL SOLEIL 15',
  'ANTHELIOS SX',
  'LIDOSITE TOPICAL SYSTEM KIT',
  'MYCAMINE',
  'PREVACID NAPRAPAC 250 (COPACKAGED)',
  'PREVACID NAPRAPAC 375 (COPACKAGED)',
  'PREVACID NAPRAPAC 500 (COPACKAGED)',
  'COPEGUS',
  'LORATADINE',
  'ENABLEX',
  'DAYTRANA',
  'WELLBUTRIN XL',
  'ISTALOL',
  'VESICARE',
  'SYMBYAX',
  'PREVANTICS SWAB',
  'RANEXA',
  'ATROVENT HFA',
  'ACULAR LS',
  'IMPLANON',
  'NEXPLANON',
  'BENICAR HCT',
  'CLOBEX',
  'LEVEMIR',
  'LEVEMIR FLEXPEN',
  'LEVEMIR INNOLET',
  'LEVEMIR PENFILL',
  'LEVEMIR FLEXTOUCH',
  'CIPRODEX',
  'ACCRETROPIN',
  'ACETADOTE',
  'CADUET',
  'STRIANT',
  'SEASONALE',
  'PATADAY ONCE DAILY RELIEF',
  'LEXIVA',
  'EMEND',
  'HALFLYTELY',
  'CHLORAPREP ONE-STEP SEPP',
  'ZORTRESS',
  'ELESTAT',
  'PREVACID IV',
  'REYATAZ',
  'IQUIX',
  'CUBICIN',
  'CUBICIN RF',
  'FORTAMET',
  'DEPO-SUBQ PROVERA 104',
  'MUCINEX D',
  'DURAPREP',
  'CHILDREN\'S ADVIL ALLERGY SINUS',
  'KEMSTRO',
  'FAZACLO ODT',
  'RIOMET',
  'FORADIL CERTIHALER',
  'AMIODARONE HYDROCHLORIDE',
  'SANCTURA',
  'ZORBTIVE',
  'VIGAMOX',
  'VELCADE',
  'CHILDREN\'S ELIXSURE',
  'CLARINEX D 24 HOUR',
  'OPANA ER',
  'LIPOFEN',
  'RAZADYNE ER',
  'TINDAMAX',
  'MUCINEX DM',
  'CHILDREN\'S ZYRTEC ALLERGY',
  'CHILDREN\'S ZYRTEC HIVES RELIEF',
  'SYNERA',
  'M.V.I. ADULT',
  'RADIOGARDASE (PRUSSIAN BLUE)',
  'APIDRA',
  'APIDRA SOLOSTAR',
  'ERAXIS',
  'FEMTRACE',
  'ZEGERID',
  'VITRASE',
  'AZILECT',
  'DIGOXIN',
  'EPZICOM',
  'LOVAZA',
  'ALVESCO',
  'ABRAXANE',
  'MENOPUR',
  'XIBROM',
  'BROMDAY',
  'AMPHADASE',
  'NUTRESTORE',
  'VISIONBLUE',
  'DEPODUR',
  'CLOLAR',
  'YAZ',
  'GONAL-F RFF REDI-JECT',
  'VYTORIN',
  'SENSIPAR',
  'NEXIUM IV',
  'ORTHO TRI-CYCLEN 21',
  'ORTHO TRI-CYCLEN 28',
  'ULTRAM ER',
  'RYBIX ODT',
  'ANTARA (MICRONIZED)',
  'VAPRISOL',
  'AVANDARYL',
  'ALLEGRA-D 24 HOUR ALLERGY AND CONGESTION',
  'EQUETRO',
  'ABLAVAR',
  'FLUXID',
  'AMLODIPINE MALEATE; BENAZEPRIL HYDROCHLORIDE',
  'HYDASE',
  'PLIAGLIS',
  'ARICEPT ODT',
  'NIRAVAM',
  'AMLEXANOX',
  'XOPENEX HFA',
  'VANTAS',
  'TERCONAZOLE',
  'RETISERT',
  'EXTINA',
  'BYSTOLIC',
  'TARCEVA',
  'PROQUIN XR',
  'RYZOLT',
  'SURFAXIN',
  'COMBIVENT RESPIMAT',
  'GLUMETZA',
  'PENTETATE CALCIUM TRISODIUM',
  'PENTETATE ZINC TRISODIUM',
  'TRUVADA',
  'MACUGEN',
  'VANOS',
  'FOSAMAX PLUS D',
  'CITALOPRAM HYDROBROMIDE',
  'QOLIANA',
  'GONAL-F RFF',
  'BYETTA',
  'AMBIEN CR',
  'ENTEREG',
  'AMRIX',
  'MEGACE ES',
  'VENTAVIS',
  'NITROMIST',
  'ROZEREM',
  'SYNTHETIC CONJUGATED ESTROGENS A',
  'DACOGEN',
  'REGLAN ODT',
  'ACZONE',
  'DESMOPRESSIN ACETATE',
  'BARACLUDE',
  'QUALAQUIN',
  'FOCALIN XR',
  'VANDAZOLE',
  'SOLTAMOX',
  'NOVOLOG MIX 50/50',
  'MEN\'S ROGAINE',
  'WOMEN\'S ROGAINE',
  'ELESTRIN',
  'APTIVUS',
  'RECLAST',
  'TYGACIL',
  'ACTONEL WITH CALCIUM (COPACKAGED)',
  'FERRIPROX',
  'NEUPRO',
  'ASACOL HD',
  'LEVOFLOXACIN',
  'STAVUDINE; LAMIVUDINE',
  'INCRELEX',
  'SEASONIQUE',
  'ACTOPLUS MET',
  'DESONATE',
  'REVATIO',
  'KINLYTIC',
  'TACLONEX',
  'OMACOR',
  'STAVUDINE; LAMIVUDINE W/NEVIRAPINE',
  'LOPERAMIDE HYDROCHLORIDE',
  'ULORIC',
  'HYLENEX RECOMBINANT',
  'PATANASE',
  'NEVANAC',
  'LYBREL',
  'LOESTRIN 24 FE',
  'NUVIGIL',
  'DICLEGIS',
  'ARRANON',
  'NUEDEXTA',
  'REVLIMID',
  'MOVIPREP',
  'EXJADE',
  'DALVANCE',
  'IPLEX',
  'ALLI',
  'CHILDREN\'S CLARITIN',
  'OSMOPREP',
  'XENAZINE',
  'VIVITROL',
  'VEREGEN',
  'NEOPROFEN',
  'AMITIZA',
  'NORMOCARB HF 25',
  'NORMOCARB HF 35',
  'BANZEL',
  'BROVANA',
  'CETRAXAL',
  'NAPROXEN SODIUM',
  'NEXAVAR',
  'TIROSINT',
  'DUETACT',
  'TREXIMET',
  'CHANTIX',
  'SYMBICORT',
  'SPIRIVA RESPIMAT',
  'ATRIPLA',
  'SUTENT',
  'MAKENA',
  'MAKENA PRESERVATIVE FREE',
  'MAKENA (AUTOINJECTOR)',
  'XOLEGEL',
  'FENTORA',
  'PULMICORT FLEXHALER',
  'ABSORICA',
  'DUTOPROL',
  'ORAPRED ODT',
  'SIMVASTATIN',
  'RELISTOR',
  'PREZISTA',
  'VYVANSE',
  'VERDESO',
  'FLUORESCITE',
  'DUODOTE',
  'TEKTURNA',
  'SPRYCEL',
  'EXFORGE',
  'ZOLINZA',
  'PRISTIQ',
  'TRAVATAN Z',
  'JANUVIA',
  'ALAWAY',
  'EDLUAR',
  'PLAN B ONE-STEP',
  'INVEGA',
  'LIALDA',
  'NOXAFIL',
  'OMNARIS',
  'PERFOROMIST',
  'REQUIP XL',
  'ANTHELIOS 40',
  'TYZEKA',
  'COREG CR',
  'OLUX E',
  'EVAMIST',
  'LAMIVUDINE AND ZIDOVUDINE',
  'ACTOPLUS MET XR',
  'TOTECT',
  'AMLODIPINE BESYLATE',
  'COSYNTROPIN',
  'SALONPAS',
  'TOVIAZ',
  'OMEPRAZOLE',
  'LUVOX CR',
  'SILENOR',
  'INTUNIV',
  'DIVIGEL',
  'CYANOKIT',
  'JANUMET',
  'BUPIVACAINE HYDROCHLORIDE AND EPINEPHRINE',
  'SEROQUEL XR',
  'TRIESENCE',
  'FLONASE SENSIMIST ALLERGY RELIEF',
  'ZYFLO CR',
  'ALTABAX',
  'ENDOMETRIN',
  'SUPPRELIN LA',
  'TYKERB',
  'LAMIVUDINE; NEVIRAPINE; ZIDOVUDINE',
  'MYDAYIS',
  'XYZAL',
  'IXEMPRA KIT',
  'FLO-PRED',
  'TASIGNA',
  'ATRALIN',
  'SOMATULINE DEPOT',
  'NOURIANZ',
  'LETAIRIS',
  'VECTICAL',
  'TORISEL',
  'EOVIST',
  'AZOR',
  'CALOMIST',
  'SANCTURA XR',
  'VENLAFAXINE HYDROCHLORIDE',
  'DORIBAX',
  'TEKTURNA HCT',
  'APLENZIN',
  'VIBATIV',
  'ADVIL ALLERGY AND CONGESTION RELIEF',
  'ADVIL MULTI-SYMPTOM COLD & FLU',
  'ZINGO',
  'LAMICTAL XR',
  'SAPHRIS',
  'FENOGLIDE',
  'AMMONIA N 13',
  'VOLTAREN ARTHRITIS PAIN',
  'RENVELA',
  'SELZENTRY',
  'ULESFIA',
  'LASTACAFT',
  'FLUDARABINE PHOSPHATE',
  'CIMDUO',
  'SYMFI',
  'ISENTRESS',
  'ISENTRESS HD',
  'FIRAZYR',
  'STAVZOR',
  'CLEVIPREX',
  'ORAVERSE',
  'OXALIPLATIN',
  'LEXISCAN',
  'CAMBIA',
  'ZYPREXA RELPREVV',
  'PERTZYE',
  'FERAHEME',
  'KUVAN',
  'AK-FLUOR 10%',
  'AK-FLUOR 25%',
  'INTELENCE',
  'FANAPT',
  'NAVSTEL',
  'ZOLPIMIST',
  'SANCUSO',
  'BYDUREON',
  'BYDUREON PEN',
  'FIRMAGON',
  'ZIPSOR',
  'ASTEPRO',
  'GELNIQUE',
  'GIAZO',
  'RAPAFLO',
  'ZENPEP',
  'ZIRGAN',
  'DUREZOL',
  'VALTURNA',
  'AVEED',
  'TRIVARIS',
  'AKTEN',
  'TRILIPIX',
  'BRIDION',
  'PANTOPRAZOLE SODIUM',
  'DOCETAXEL',
  'SUMAVEL DOSEPRO',
  'LUSEDRA',
  'METOZOLV ODT',
  'DUAVEE',
  'TREANDA',
  'AMPYRA',
  'LAMICTAL ODT',
  'NATAZIA',
  'VIMPAT',
  'SAVELLA',
  'TOLAK',
  'VELETRI',
  'LOSEASONIQUE',
  'INVEGA SUSTENNA',
  'ONSOLIS',
  'COARTEM',
  'NESINA',
  'OXYCONTIN',
  'OFORTA',
  'SAMSCA',
  'NICARDIPINE HYDROCHLORIDE',
  'MEMBRANEBLUE',
  'HYCOFENIX',
  'ZEGERID OTC',
  'ATAZANAVIR SULFATE AND RITONAVIR',
  'KEPPRA XR',
  'DEXILANT',
  'BEPREVE',
  'ADREVIEW',
  'PROMACTA',
  'ABACAVIR SULFATE',
  'ZIDOVUDINE',
  'ABACAVIR SULFATE; LAMIVUDINE',
  'NEVIRAPINE TABLETS FOR ORAL SUSPENSION',
  'APRISO',
  'NUCYNTA',
  'PUR-WASH',
  'SOTALOL HYDROCHLORIDE',
  'EFFIENT',
  'BESIVANCE',
  'MOZOBIL',
  'EXFORGE HCT',
  'OZURDEX',
  'EPIDUO',
  'EMBEDA',
  'NEXTERONE',
  'PREVACID 24 HR',
  'INTERMEZZO',
  'JENLOGA',
  'KAPVAY',
  'ADCIRCA',
  'AFINITOR',
  'VICTOZA',
  'POTIGA',
  'CALDOLOR',
  'ONGLYZA',
  'COLCRYS',
  'LIVALO',
  'ARIDOL KIT',
  'ARIDOL',
  'LATISSE',
  'CONZIP',
  'SUPREP BOWEL PREP KIT',
  'ALSUMA',
  'SPRIX',
  'ARCAPTA NEOHALER',
  'PRANDIMET',
  'TYVASO',
  'ISTODAX',
  'QUTENZA',
  'DYLOJECT',
  'HORIZANT',
  'TWYNSTA',
  'CODEINE SULFATE',
  'ORAVIG',
  'CAPRELSA',
  'XARELTO',
  'NATROBA',
  'OLEPTRO',
  'APTIOM',
  'FIBRICOR',
  'EFAVIRENZ 200MG SCORED TABLETS',
  'MIRAPEX ER',
  'FLOWTUSS',
  'MULTAQ',
  'OSENI',
  'ACUVAIL',
  'MOXEZA',
  'CETIRIZINE HYDROCHLORIDE ALLERGY',
  'LYSTEDA',
  'H.P. ACTHAR GEL',
  'BRILINTA',
  'ARGATROBAN IN SODIUM CHLORIDE',
  'XERESE',
  'ZUTRIPRO',
  'REZIRA',
  'SUSTOL',
  'OFIRMEV',
  'TOPOTECAN HYDROCHLORIDE',
  'DATSCAN',
  'ELELYSO',
  'JALYN',
  'EFAVIRENZ',
  'GABLOFEN',
  'VOTRIENT',
  'ORABLOC',
  'FOLOTYN',
  'NEXCEDE',
  'AFREZZA',
  'ELLA',
  'LAMIVUDINE AND STAVUDINE',
  'ZYCLARA',
  'ONMEL',
  'SODIUM FLUORIDE F 18',
  'EXPAREL',
  'FORFIVO XL',
  'CLONIDINE',
  'NEXICLON XR',
  'LO LOESTRIN FE',
  'METAXALONE',
  'AXIRON',
  'EGRIFTA',
  'ABSTRAL',
  'VIMOVO',
  'PRADAXA',
  'NOCDURNA',
  'DULERA',
  'DUEXIS',
  'DALIRESP',
  'PANCREAZE',
  'ZUPLENZ',
  'NAMENDA XR',
  'ADDYI',
  'GILENYA',
  'BEYAZ',
  'DOCEFREZ',
  'ESBRIET',
  'VIOKACE',
  'GRALISE',
  'TEKAMLO',
  'ZYMAXID',
  'ADASUVE',
  'CYSVIEW KIT',
  'KARBINAL ER',
  'ATELVIA',
  'MAVENCLAD',
  'CARBAGLU',
  'SORILUX',
  'ADVIL CONGESTION RELIEF',
  'VIIBRYD',
  'LAZANDA',
  'CUVPOSA',
  'MITOSOL',
  'SAFYRAL',
  'VPRIV',
  'QSYMIA',
  'PHOSLYRA',
  'METHAZOLAMIDE',
  'ISOSORBIDE DINITRATE',
  'HYDROXYZINE HYDROCHLORIDE',
  'NEOSAR',
  'DEXFERRUM',
  'PROMETHAZINE HYDROCHLORIDE',
  'KIONEX',
  'CYCLOPHOSPHAMIDE',
  'THEOPHYLLINE',
  'FLUOCINOLONE ACETONIDE',
  'EDROPHONIUM CHLORIDE',
  'QUINIDINE SULFATE',
  'LEUCOVORIN CALCIUM PRESERVATIVE FREE',
  'PROCHLORPERAZINE',
  'ROXILOX',
  'TROPICAMIDE',
  'PREDNISOLONE SODIUM PHOSPHATE',
  'SULFACETAMIDE SODIUM',
  'DEXAMETHASONE SODIUM PHOSPHATE',
  'NAFAZAIR',
  'PROPARACAINE HYDROCHLORIDE',
  'PENTOLAIR',
  'THIAMINE HYDROCHLORIDE',
  'HYDROXYCHLOROQUINE SULFATE',
  'PHENTERMINE HYDROCHLORIDE',
  'HYDROCODONE BITARTRATE AND ACETAMINOPHEN',
  'ESGIC-PLUS',
  'ACETAZOLAMIDE SODIUM',
  'DIGOXIN PEDIATRIC',
  'HYDROCORTISONE AND ACETIC ACID',
  'ACETAMINOPHEN AND CODEINE PHOSPHATE',
  'NORCO',
  'LORTAB',
  'PROCHLORPERAZINE MALEATE',
  'BENZTROPINE MESYLATE',
  'OXYCODONE AND ACETAMINOPHEN',
  'MEPERIDINE HYDROCHLORIDE',
  'PROCAINAMIDE HYDROCHLORIDE',
  'CHLORZOXAZONE',
  'CARISOPRODOL AND ASPIRIN',
  'VICODIN HP',
  'THIORIDAZINE HYDROCHLORIDE',
  'ESTROPIPATE',
  'HYDRALAZINE HYDROCHLORIDE',
  'PROPOXYPHENE HYDROCHLORIDE AND ACETAMINOPHEN',
  'DIPHENHYDRAMINE HYDROCHLORIDE',
  'WARFARIN SODIUM',
  'FLUPHENAZINE HYDROCHLORIDE',
  'PROMETHAZINE HYDROCHLORIDE AND CODEINE PHOSPHATE',
  'TRIFLUOPERAZINE HYDROCHLORIDE',
  'HYDROXYZINE PAMOATE',
  'MEDROXYPROGESTERONE ACETATE',
  'DICYCLOMINE HYDROCHLORIDE',
  'MEPERIDINE HYDROCHLORIDE PRESERVATIVE FREE',
  'AKPENTOLATE',
  'ACETIC ACID',
  'DOXYLAMINE SUCCINATE',
  'TRIHEXYPHENIDYL HYDROCHLORIDE',
  'MECLIZINE HYDROCHLORIDE',
  'METHYLPREDNISOLONE',
  'PREDNISOLONE',
  'ACETAZOLAMIDE',
  'GYNODIOL',
  'AMITRIPTYLINE HYDROCHLORIDE',
  'METHYLPHENIDATE HYDROCHLORIDE',
  'CHLORPROMAZINE HYDROCHLORIDE',
  'PERPHENAZINE',
  'PHENTOLAMINE MESYLATE',
  'COMPRO',
  'ORPHENADRINE CITRATE',
  'ETHOSUXIMIDE',
  'OXYCODONE AND ASPIRIN',
  'BUTALBITAL, ACETAMINOPHEN AND CAFFEINE',
  'PROCOMP',
  'CHLOROPROCAINE HYDROCHLORIDE',
  'HYDROCODONE BITARTRATE AND HOMATROPINE METHYLBROMIDE',
  'ZYDONE',
  'EXTENDED PHENYTOIN SODIUM',
  'PHENYTEK',
  'DEXTROAMPHETAMINE SULFATE',
  'METADATE ER',
  'INNOFEM',
  'TROPICACYL',
  'PERCOCET',
  'PHENYTOIN',
  'SULFASALAZINE',
  'METHIMAZOLE',
  'SPIRONOLACTONE',
  'DIPHENOXYLATE HYDROCHLORIDE AND ATROPINE SULFATE',
  'NIACOR',
  'TREXALL',
  'MICORT-HC',
  'ANEXSIA 7.5/325',
  'ANEXSIA 5/325',
  'HYDROCHLOROTHIAZIDE',
  'METHOCARBAMOL',
  'JANTOVEN',
  'DEXTROAMP SACCHARATE, AMP ASPARTATE, DEXTROAMP SULFATE AND AMP SULFATE',
  'FLUDROCORTISONE ACETATE',
  'CODRIX',
  'CARBINOXAMINE MALEATE',
  'DIHYDROERGOTAMINE MESYLATE',
  'NOREPINEPHRINE BITARTRATE',
  'MERCAPTOPURINE',
  'DICYCLOMINE HYDROCHLORIDE (PRESERVATIVE FREE)',
  'ANEXSIA',
  'BETHANECHOL CHLORIDE',
  'DEXAMETHASONE SODIUM PHOSPHATE PRESERVATIVE FREE',
  'PROCHLORPERAZINE EDISYLATE',
  'ERGOTAMINE TARTRATE AND CAFFEINE',
  'CHLOROQUINE PHOSPHATE',
  'DIMENHYDRINATE',
  'METHAMPHETAMINE HYDROCHLORIDE',
  'TESTOSTERONE CYPIONATE',
  'CYPROHEPTADINE HYDROCHLORIDE',
  'DIPYRIDAMOLE',
  'METHYLPREDNISOLONE ACETATE',
  'GLYCOPYRROLATE',
  'PROPOXYPHENE HYDROCHLORIDE',
  'PHENYTOIN SODIUM',
  'TESTOSTERONE ENANTHATE',
  'BENZPHETAMINE HYDROCHLORIDE',
  'METHYLPREDNISOLONE SODIUM SUCCINATE',
  'PRIMIDONE',
  'BENZONATATE',
  'PROBENECID AND COLCHICINE',
  'METHSCOPOLAMINE BROMIDE',
  'ESTRADIOL VALERATE',
  'TRANYLCYPROMINE SULFATE',
  'PROMETHAZINE DM',
  'PROMETHAZINE WITH CODEINE',
  'PHENYLEPHRINE HYDROCHLORIDE AND PROMETHAZINE HYDROCHLORIDE',
  'A-METHAPRED',
  'A-HYDROCORT',
  'DEXAMETHASONE',
  'LIDOCAINE HYDROCHLORIDE VISCOUS',
  'IMIPRAMINE HYDROCHLORIDE',
  'MEPIVACAINE HYDROCHLORIDE',
  'INDOCYANINE GREEN',
  'DIETHYLPROPION HYDROCHLORIDE',
  'CHLORPHENIRAMINE MALEATE',
  'ERGOCALCIFEROL',
  'PHENTERMINE RESIN COMPLEX',
  'METHYLERGONOVINE MALEATE',
  'PROMETHAZINE HYDROCHLORIDE AND PHENYLEPHRINE HYDROCHLORIDE',
  'KALEXATE',
  'SODIUM POLYSTYRENE SULFONATE',
  'LIDOCAINE',
  'VIBRAMYCIN',
  'ILOSONE',
  'PATHOCIL',
  'PENBRITIN',
  'MAXITROL',
  'GARAMYCIN',
  'GRISACTIN',
  'PRINCIPEN ',
  'VERSAPEN',
  'PENBRITIN-S',
  'POLY-PRED',
  'CHLOROPTIC',
  'POLYCILLIN',
  'CAPASTAT SULFATE',
  'COLY-MYCIN M',
  'MITHRACIN',
  'UNIPEN',
  'PRE-PEN',
  'STAPHCILLIN',
  'PROSTAPHLIN',
  'BICILLIN',
  'BICILLIN L-A',
  'BICILLIN C-R',
  'CHLOROMYCETIN',
  'CLEOCIN HYDROCHLORIDE',
  'POLYSPORIN',
  'CORTISPORIN',
  'NEOSPORIN',
  'ERYTHROCIN',
  'TEGOPEN',
  'OXACILLIN SODIUM',
  'OPHTHOCORT',
  'CHLOROMYCETIN HYDROCORTISONE',
  'CHLOROMYXIN',
  'E.E.S.',
  'ERYPED',
  'TETREX',
  'KAFOCIN',
  'CANDEX',
  'NYSTAFORM',
  'NEO-CORT-DOME',
  'DECLOMYCIN',
  'ACHROMYCIN V',
  'ACHROMYCIN',
  'MYCIFRADIN',
  'TERRAMYCIN',
  'ELASE-CHLOROMYCETIN',
  'PYOPEN',
  'NILSTAT',
  'GEOPEN',
  'POLYCILLIN-N',
  'FUNGIZONE',
  'MINOCIN',
  'LINCOCIN',
  'UNIPEN IN PLASTIC CONTAINER',
  'NEODECADRON',
  'TAO',
  'DYNAPEN',
  'ALBAMYCIN',
  'STATROL',
  'CORDRAN N',
  'TROBICIN',
  'COLY-MYCIN S',
  'ILOTYCIN',
  'ILOTYCIN GLUCEPTATE',
  'NEO-HYDELTRASOL',
  'AUREOMYCIN',
  'KEFLEX',
  'LUMI-SPORYN',
  'RIFADIN',
  'RIMACTANE',
  'GEOCILLIN',
  'KEFLET',
  'CLEOCIN PHOSPHATE',
  'BLENOXANE',
  'CEFADYL',
  'GRIFULVIN V',
  'MUTAMYCIN',
  'MINOCYCLINE HYDROCHLORIDE',
  'POLYCILLIN-PRB',
  'AMOXIL',
  'LAROTID',
  'ANCEF',
  'DOXORUBICIN HYDROCHLORIDE',
  'GRIS-PEG',
  'NEBCIN',
  'NYSERT',
  'KEFLIN',
  'CERUBIDINE',
  'VIRA-A',
  'PRINCIPEN W/ PROBENECID',
  'TOPICYCLINE',
  'AMIKIN',
  'TICAR',
  'MANDOL',
  'CYCLAPEN-W',
  'DURICEF',
  'NATACYN',
  'MEFOXIN',
  'MECLAN',
  'SPECTROBID',
  'CECLOR',
  'STATICIN',
  'PEDIAZOLE',
  'VELOSEF',
  'ERYTHROMYCIN',
  'VIBRA-TABS',
  'ERYC',
  'CLEOCIN T',
  'CLEOCIN',
  'TOBREX',
  'NETROMYCIN',
  'PIPRACIL',
  'CLAFORAN',
  'VELOSEF \'250\'',
  'VELOSEF \'500\'',
  'MEZLIN',
  'MOXAM',
  'CEFOBID',
  'PRECEF',
  'BENZAMYCIN',
  'ZINACEF',
  'CEFIZOX',
  'AZLIN',
  'AUGMENTIN \'250\'',
  'AUGMENTIN \'500\'',
  'COACTIN',
  'POLYTRIM',
  'CEFMAX',
  'SANDIMMUNE',
  'AUGMENTIN \'125\'',
  'ZANOSAR',
  'FORTAZ',
  'MONOCID',
  'AZACTAM',
  'DORYX',
  'AKNE-MYCIN',
  'ROCEPHIN',
  'ROCEPHIN KIT',
  'PRED-G',
  'PRIMAXIN',
  'CEFOTAN',
  'TIMENTIN',
  'BACTROBAN',
  'TOBRADEX',
  'ERYC SPRINKLES',
  'ERYCETTE',
  'LANABIOTIC',
  'ILOSONE SULFA',
  'CERADON',
  'CEFTIN',
  'VANCOCIN HYDROCHLORIDE',
  'UNASYN',
  'PCE',
  'KEFTAB',
  'ERYGEL',
  'MYCOSTATIN',
  'SUPRAX',
  'TOBRASONE',
  'CEFPIRAMIDE SODIUM',
  'ZEFAZONE',
  'MONODOX',
  'CEPTAZ',
  'ACTISITE',
  'IDAMYCIN',
  'BIAXIN',
  'CEFZIL',
  'LORABID',
  'ZITHROMAX',
  'CECLOR CD',
  'VANTIN',
  'DYNABAC',
  'MAXIPIME',
  'COSMEGEN',
  'ZOSYN',
  'CEDAX',
  'BANAN',
  'MYCOBUTIN',
  'BIAXIN XL',
  'DAUNOXOME',
  'RIFATER',
  'MERREM',
  'PROGRAF',
  'NEORAL',
  'MONUROL',
  'DOXIL (LIPOSOMAL)',
  'HELIDAC',
  'AUGMENTIN ',
  'CELLCEPT',
  'ABELCET',
  'AMPHOTEC',
  'DAUNORUBICIN HYDROCHLORIDE',
  'IDAMYCIN PFS',
  'OMNICEF',
  'AMBISOME',
  'DUAC',
  'STROMECTOL',
  'PERIOSTAT',
  'SYNERCID',
  'ATRIDOX',
  'TOBI',
  'BENZACLIN',
  'PREVPAC (COPACKAGED)',
  'TROVAN/ZITHROMAX COMPLIANCE PAK',
  'MITOZYTREX',
  'AKTIPAK',
  'PROTOPIC',
  'ELLENCE',
  'CEFAZOLIN AND DEXTROSE',
  'ARESTIN',
  'CLINDAGEL',
  'PYLERA',
  'CENTANY',
  'TOBRAMYCIN SULFATE',
  'RESTASIS MULTIDOSE',
  'MYFORTIC',
  'CLINDESSE',
  'VIDAZA',
  'DORYX MPC',
  'ZMAX',
  'EVOCLIN',
  'ZIANA',
  'VELTIN',
  'ZYLET',
  'ORACEA',
  'EPIRUBICIN HYDROCHLORIDE',
  'SOLODYN',
  'AZITHROMYCIN',
  'AZASITE',
  'MOXATAG',
  'CAYSTON',
  'TOBRADEX ST',
  'ACANYA',
  'ONEXTON',
  'V-CILLIN',
  'V-CILLIN K',
  'PEN-VEE K',
  'PERMAPEN',
  'TETRACYCLINE HYDROCHLORIDE',
  'TOTACILLIN',
  'PENICILLIN G POTASSIUM',
  'PFIZERPEN G',
  'STREPTOMYCIN SULFATE',
  'TETRACYN',
  'DURACILLIN A.S.',
  'PENICILLIN G PROCAINE',
  'PRINCIPEN \'125\'',
  'MYCHEL-S',
  'CHLORAMPHENICOL',
  'LEDERCILLIN VK',
  'OXY-KESSO-TETRA',
  'COR-OTICIN',
  'DYCILL',
  'E-MYCIN',
  'BACITRACIN',
  'PFIZERPEN-AS',
  'NEOMYCIN SULFATE',
  'PENICILLIN',
  'PENICILLIN-2',
  'TETRACHEL',
  'PANMYCIN',
  'ERYTHROCIN STEARATE',
  'PENTIDS ',
  'SUMYCIN',
  'NEO-POLYCIN',
  'PENICILLIN-VK',
  'NEOBIOTIC',
  'KANTREX',
  'PENICILLIN V POTASSIUM',
  'HUMATIN',
  'FULVICIN-U/F',
  'MYCOLOG-II',
  'SEROMYCIN',
  'NEO-CORTEF',
  'NEO-MEDROL ACETATE',
  'CASPORYN HC',
  'OMNIPEN (AMPICILLIN)',
  'OMNIPEN-N',
  'OXYTETRACYCLINE HYDROCHLORIDE',
  'RONDOMYCIN',
  'NEO-MEDROL',
  'PFIZERPEN',
  'TOTACILLIN-N',
  'NEO-SYNALAR',
  'NEOSPORIN G.U. IRRIGANT',
  'POLYMYXIN B SULFATE',
  'OTOCORT',
  'BACIGUENT',
  'AMPICILLIN TRIHYDRATE',
  'MYCHEL',
  'TERRAMYCIN-POLYMYXIN',
  'TERRAMYCIN W/ POLYMYXIN B SULFATE',
  'TERRA-CORTRIL',
  'NEO-DELTA-CORTEF',
  'PENICILLIN G SODIUM',
  'MYCITRACIN',
  'VIOCIN SULFATE',
  'BETAPEN-VK',
  'VEETIDS ',
  'CHLOROPTIC S.O.P.',
  'CHLOROPTIC-P S.O.P.',
  'VEETIDS \'125\'',
  'OPHTHOCHLOR',
  'BRISTAMYCIN',
  'BACTOCILL',
  'PRINCIPEN',
  'AMPICILLIN SODIUM',
  'VERSAPEN-K',
  'VEETIDS',
  'RETET',
  'CLOXACILLIN SODIUM',
  'DICLOXACILLIN SODIUM',
  'ERYTHROMYCIN STEARATE',
  'TETRAMED',
  'POLY-RX',
  'NEO-RX',
  'BACI-RX',
  'VANOBID',
  'ETHRIL ',
  'PYOCIDIN',
  'ROBIMYCIN',
  'E.E.S. 200',
  'E.E.S. 400',
  'ECONOCHLOR',
  'UTICILLIN VK',
  'BRISTACYCLINE',
  'R-P MYCIN',
  'WYAMYCIN S',
  'DOXYCYCLINE HYCLATE',
  'KOROSTATIN',
  'DOXYCHEL',
  'CYCLOPAR',
  'ROBITET',
  'ZIBA-RX',
  'PROBAMPACIN',
  'VELOSEF ',
  'KEFZOL',
  'PFIZER-E',
  'CLOXAPEN',
  'PFIZERPEN VK',
  'OTOBIONE',
  'TERRAMYCIN W/ POLYMYXIN',
  'POLYMOX',
  'ANSPOR',
  'RIFAMATE',
  'TRIMOX',
  'ERYTHROMYCIN ETHYLSUCCINATE',
  'AMOXICILLIN',
  'AMOXICILLIN PEDIATRIC',
  'DOXYCHEL HYCLATE',
  'MYCO-TRIACET II',
  'NYSTATIN',
  'CEPHALEXIN',
  'NAFCILLIN SODIUM',
  'FULVICIN ',
  'NALLPEN',
  'PENAPAR-VK',
  'AMCILL',
  'ERYPAR',
  'AEROSPORIN',
  'PFIZERPEN-A',
  'ERYTHROMYCIN ESTOLATE',
  'UTIMOX',
  'WYMOX',
  'WYAMYCIN E',
  'PENTIDS \'200\'',
  'PENTIDS \'250\'',
  'BACITRACIN-NEOMYCIN-POLYMYXIN',
  'KLEBCIL',
  'OPTOMYCIN',
  'GRISACTIN ULTRA',
  'NYSTATIN AND TRIAMCINOLONE ACETONIDE',
  'GENTAMICIN SULFATE',
  'E-MYCIN E',
  'CEFACLOR',
  'U-GENCIN',
  'BEEPEN-VK',
  'CHLORAMPHENICOL SODIUM SUCCINATE',
  'BRISTAGEN',
  'APOGEN',
  'ERYDERM',
  'CEFADROXIL',
  'ERY-TAB',
  'OTOBIOTIC',
  'PEDIAMYCIN',
  'PEDIAMYCIN 400',
  'KANAMYCIN',
  'GENTAMICIN',
  'MYKACET',
  'ULTRACEF',
  'NEOMYCIN & POLYMYXIN B SULFATES & BACITRACIN ZINC & HYDROCORTISONE',
  'NEOMYCIN SULFATE AND POLYMYXIN B SULFATE GRAMICIDIN',
  'BACITRACIN ZINC-NEOMYCIN SULFATE-POLYMYXIN B SULFATE',
  'MYKINAC',
  'ZINC BACITRACIN,NEOMYCIN SULFATE,POLYMYXIN B SULFATE & HYDROCORTISONE',
  'NEOMYCIN SULFATE-POLYMYXIN B SULFATE-HYDROCORTISONE',
  'OTICAIR',
  'A/T/S',
  'DEXASPORIN',
  'CEPHALOTHIN SODIUM W/ SODIUM CHLORIDE IN PLASTIC CONTAINER',
  'CEPHALOTHIN SODIUM W/ DEXTROSE IN PLASTIC CONTAINER',
  'NEOMYCIN AND POLYMYXIN B SULFATES AND HYDROCORTISONE',
  'CEPHALOTHIN',
  'OCUMYCIN',
  'SEFFIN',
  'T-STAT',
  'CHLOROFAIR',
  'GENTAFAIR',
  'DOXYCYCLINE',
  'GENOPTIC',
  'CEPHALOTHIN SODIUM',
  'KANAMYCIN SULFATE',
  'E-SOLVE 2',
  'C-SOLVE-2',
  'DOXY 100',
  'DOXY 200',
  'GENTACIDIN',
  'BARSTATIN 100',
  'DOXY-LEMMON',
  'ERYMAX',
  'NYSTEX',
  'SANSAC',
  'NEOMYCIN SULFATE-DEXAMETHASONE SODIUM PHOSPHATE',
  'DEXACIDIN',
  'KEFUROX',
  'NEOMYCIN SULFATE-TRIAMCINOLONE ACETONIDE',
  'NYSTATIN TRIAMCINOLONE ACETONIDE',
  'MYTREX F',
  'MYTREX A',
  'NYSTATIN-TRIAMCINOLONE ACETONIDE',
  'ERYTHROMYCIN LACTOBIONATE',
  'NEOMYCIN SULFATE, POLYMYXIN B SULFATE & HYDROCORTISONE',
  'CEFTAZIDIME',
  'ULTRAGRIS-165',
  'ULTRAGRIS-330',
  'ERYC 125',
  'TAZIDIME',
  'TAZICEF',
  'VANCOMYCIN HYDROCHLORIDE',
  'NEOMYCIN AND POLYMYXIN B SULFATE',
  'VANCOLED',
  'CEPHRADINE',
  'ERYTHRA-DERM',
  'CEFAZOLIN SODIUM',
  'CEPHAPIRIN SODIUM',
  'NEOMYCIN AND POLYMYXIN B SULFATES AND DEXAMETHASONE',
  'AMPHOTERICIN B',
  'TAZIDIME IN PLASTIC CONTAINER',
  'CLINDAMYCIN PHOSPHATE',
  'ERYZOLE',
  'ERYTHROMYCIN ETHYLSUCCINATE AND SULFISOXAZOLE ACETYL',
  'LINCOMYCIN HCL',
  'NEOMYCIN AND POLYMYXIN B SULFATES AND GRAMICIDIN',
  'PEDIOTIC',
  'VANCOMYCIN HCL',
  'BACITRACIN ZINC-POLYMYXIN B SULFATE',
  'CYCLACILLIN',
  'RUBEX',
  'VANCOR',
  'E-BASE',
  'CLINDAMYCIN HYDROCHLORIDE',
  'DYNACIN',
  'EMGEL',
  'ADRIAMYCIN PFS',
  'AMIKACIN SULFATE',
  'TOBRAMYCIN',
  'LINCOMYCIN HYDROCHLORIDE',
  'PENTACEF',
  'CLINDA-DERM',
  'BACITRACIN ZINC AND POLYMYXIN B SULFATE',
  'CEFUROXIME SODIUM',
  'BLEOMYCIN SULFATE',
  'AKTOB',
  'ERYTHRO-STATIN',
  'MITOMYCIN',
  'NYSTOP',
  'TRIMETHOPRIM SULFATE AND POLYMYXIN B SULFATE',
  'TOBRAMYCIN AND DEXAMETHASONE',
  'CLINDETS',
  'RIFAMPIN',
  'BACIIM',
  'GENTAK',
  'PAROMOMYCIN SULFATE',
  'CEFOTAXIME',
  'COLISTIMETHATE SODIUM',
  'GENGRAF',
  'CYCLOSPORINE',
  'E-GLADES',
  'NEO-FRADIN',
  'CEFOXITIN',
  'IDARUBICIN HYDROCHLORIDE',
  'CEFUROXIME AXETIL',
  'CEFUROXIME',
  'AMOXICILLIN AND CLAVULANATE POTASSIUM',
  'AMPICILLIN AND SULBACTAM',
  'DISPERMOX',
  'CEFPODOXIME PROXETIL',
  'MUPIROCIN',
  'RANICLOR',
  'DEMECLOCYCLINE HYDROCHLORIDE',
  'PANIXINE DISPERDOSE',
  'ERYTHROMYCIN AND BENZOYL PEROXIDE',
  'PIPERACILLIN',
  'CEFOTAXIME SODIUM',
  'CEFTRIAXONE',
  'CLARITHROMYCIN',
  'CEFPROZIL',
  'GRISEOFULVIN',
  'RIFAMPIN AND ISONIAZID',
  'CEFDINIR',
  'AZTREONAM',
  'PIPERACILLIN AND TAZOBACTAM',
  'CEFEPIME HYDROCHLORIDE',
  'CEFOTETAN',
  'MYCOPHENOLATE MOFETIL',
  'CLINDAMYCIN PHOSPHATE AND BENZOYL PEROXIDE',
  'TACROLIMUS',
  'SULFAMETHOPRIM',
  'SATRIC',
  'SULFAMETHOXAZOLE AND TRIMETHOPRIM SINGLE STRENGTH',
  'SULFAMETHOPRIM-DS',
  'COTRIM',
  'IBUPROFEN',
  'COTRIM D.S.',
  'VALNAC',
  'SULMEPRIM',
  'SULMEPRIM PEDIATRIC',
  'SULFATRIM-SS',
  'SULFATRIM-DS',
  'DISOPYRAMIDE PHOSPHATE',
  'STERI-STAT',
  'MAXOLON',
  'PROPACET 100',
  'PROPOXYPHENE NAPSYLATE AND ACETAMINOPHEN',
  'DIPHEN',
  'DOXY-SLEEP-AID',
  'NALOXONE HYDROCHLORIDE',
  'METHYLDOPA AND HYDROCHLOROTHIAZIDE',
  'METOCLOPRAMIDE HYDROCHLORIDE',
  'FLUOR-OP',
  'NALOXONE',
  'VALPROIC ACID',
  'LORAZ',
  'HYDRAMINE',
  'CARBAMAZEPINE',
  'NITROFURANTOIN MACROCRYSTALLINE',
  'INDO-LEMMON',
  'NALIDIXIC ACID',
  'CONSTULOSE',
  'METHYLDOPATE HYDROCHLORIDE',
  'CARBACHOL',
  'CLOPRA',
  'METRONIDAZOLE HYDROCHLORIDE',
  'PERPHENAZINE AND AMITRIPTYLINE HYDROCHLORIDE',
  'PROPRANOLOL HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'CLONIDINE HYDROCHLORIDE',
  'FLURAZEPAM HYDROCHLORIDE',
  'AMILORIDE HYDROCHLORIDE',
  'HYDRO-RIDE',
  'TEMAZEPAM',
  'MECLOFENAMATE SODIUM',
  'VINCRISTINE SULFATE',
  'Q-PAM',
  'PRAZEPAM',
  'IBUPROHM',
  'LORAZEPAM',
  'MEDIPREN',
  'CHLORDIAZEPOXIDE AND AMITRIPTYLINE HYDROCHLORIDE',
  'TRAZODONE HYDROCHLORIDE',
  'VICKS FORMULA 44',
  'CLOFIBRATE',
  'EPITOL',
  'TEMAZ',
  'MUCOSIL-10',
  'MUCOSIL-20',
  'AMANTADINE HYDROCHLORIDE',
  'MIDOL',
  'THIOTHIXENE',
  'CLOPRA-"YELLOW"',
  'ZAXOPAM',
  'MEGESTROL ACETATE',
  'METHYLDOPA AND CHLOROTHIAZIDE',
  'METOCLOPRAMIDE HCL',
  'VERAPAMIL HCL',
  'NALOXONE HCL',
  'OXAZEPAM',
  'NORETHINDRONE AND ETHINYL ESTRADIOL',
  'NALBUPHINE',
  'RITODRINE HCL',
  'OXYBUTYNIN CHLORIDE',
  'ACETOHEXAMIDE',
  'NORETHINDRONE AND MESTRANOL',
  'FLUPHENAZINE',
  'DISOBROM',
  'AMILORIDE HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'ENFLURANE',
  'METAPROTERENOL SULFATE',
  'DACARBAZINE',
  'SUCRALFATE',
  'VINCREX',
  'TAMOXIFEN CITRATE',
  'TRIALODINE',
  'THIOTHIXENE HYDROCHLORIDE',
  'DROPERIDOL',
  'SYMADINE',
  'METHYLDOPATE HCL',
  'ASTRAMORPH PF',
  'CONSTILAC',
  'FLOXURIDINE',
  'AZATHIOPRINE',
  'IBU-TAB 200',
  'IBU-TAB',
  'ACHES-N-PAIN',
  'PROPRANOLOL HYDROCHLORIDE & HYDROCHLOROTHIAZIDE',
  'CLONIDINE HYDROCHLORIDE AND CHLORTHALIDONE',
  'RITODRINE HYDROCHLORIDE',
  'CLORAZEPATE DIPOTASSIUM',
  'TRIAMTERENE AND HYDROCHLOROTHIAZIDE',
  'BACLOFEN',
  'E-Z-EM PREP LYTE',
  'TRIMIPRAMINE MALEATE',
  'ANTITUSSIVE',
  'ATROPINE',
  'COLOVAGE',
  'CLORPRES',
  'CHOLAC',
  'MINOXIDIL',
  'ACETYLCYSTEINE',
  'MECLODIUM',
  'ORALONE',
  'PROPRANOLOL HYDROCHLORIDE INTENSOL',
  'FLUPHENAZINE DECANOATE',
  'DIAZEPAM INTENSOL',
  'BRIAN CARE',
  'VINCASAR PFS',
  'RITODRINE HYDROCHLORIDE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'NORETHIN ',
  'VINCRISTINE SULFATE PFS',
  'DIAZOXIDE',
  'MINODYL',
  'NORCEPT-E',
  'ENULOSE',
  'DANAZOL',
  'DESIPRAMINE HYDROCHLORIDE',
  'CYCLOBENZAPRINE HYDROCHLORIDE',
  'CHOLYBAR',
  'FENOPROFEN CALCIUM',
  'PRAZOSIN HYDROCHLORIDE',
  'IBUPRIN',
  'GEN-XENE',
  'FOAMCOAT',
  'SULINDAC',
  'TRIPROLIDINE AND PSEUDOEPHEDRINE HYDROCHLORIDES',
  'UROPLUS SS',
  'UROPLUS DS',
  'GENERLAC',
  'MAPROTILINE HYDROCHLORIDE',
  'KETAMINE HCL',
  'FENTANYL CITRATE AND DROPERIDOL',
  'PROMETA',
  'DERMABET',
  'HALOPERIDOL INTENSOL',
  'PANCURONIUM BROMIDE',
  'LOXAPINE SUCCINATE',
  'TAB-PROFEN',
  'CAP-PROFEN',
  'ALBUTEROL SULFATE',
  'MILOPHENE',
  'MICRODERM',
  'ALBUTEROL',
  'TRIMETH/SULFA',
  'PREVACARE R',
  'ATENOLOL AND CHLORTHALIDONE',
  'ATENOLOL',
  'GLYCOPREP',
  'DUPHALAC',
  'PORTALAC',
  'NIFEDIPINE',
  'AMOXAPINE',
  'K-LEASE',
  'FLUOCINONIDE EMULSIFIED BASE',
  'CLOTRIMAZOLE',
  'SILPHEN',
  'FOAMICON',
  'NORTREL ',
  'GENCEPT ',
  'ZOVIA ',
  'LORAZEPAM INTENSOL',
  'GEMFIBROZIL',
  'METOCLOPRAMIDE INTENSOL',
  'CINOXACIN',
  'CLEMASTINE FUMARATE',
  'PEG-LYTE',
  'GYNIX',
  'PREVALITE',
  'METOPROLOL TARTRATE',
  'TOLMETIN SODIUM',
  'CARBIDOPA AND LEVODOPA',
  'CO-LAV',
  'GO-EVAC',
  'DESOXIMETASONE',
  'PENTACARINAT',
  'PENTAMIDINE ISETHIONATE',
  'THIOTHIXENE HYDROCHLORIDE INTENSOL',
  'EVALOSE',
  'HEPTALAC',
  'MICONAZOLE NITRATE',
  'KETOPROFEN',
  'DOXAPRAM HYDROCHLORIDE',
  'PIROXICAM',
  'NORTRIPTYLINE HYDROCHLORIDE',
  'DIFLUNISAL',
  'EMVERM',
  'LEVORA ',
  'PINDOLOL',
  'SULFACETAMIDE SODIUM AND PREDNISOLONE SODIUM PHOSPHATE',
  'DIPIVEFRIN HYDROCHLORIDE',
  'NITROFURANTOIN',
  'CARBASTAT',
  'ACILAC',
  'LAXILOSE',
  'CALCITONIN-SALMON',
  'PERIOGARD',
  'CIMETIDINE HYDROCHLORIDE',
  'ACEBUTOLOL HYDROCHLORIDE',
  'RANITIDINE HYDROCHLORIDE',
  'GUANABENZ ACETATE',
  'TRIAZOLAM',
  'ALPRAZOLAM',
  'DOBUTAMINE HYDROCHLORIDE',
  'CLOBETASOL PROPIONATE',
  'ISOFLURANE',
  'CIMETIDINE',
  'NAPROXEN',
  'CIPROFLOXACIN HYDROCHLORIDE',
  'BUPRENORPHINE HYDROCHLORIDE',
  'GUANFACINE HYDROCHLORIDE',
  'BUMETANIDE',
  'MICONAZOLE 7',
  'TOPOSAR',
  'NADOLOL',
  'CROMOLYN SODIUM',
  'CORMAX',
  'EMBELINE',
  'GLIPIZIDE',
  'ETOPOSIDE',
  'BUSPIRONE HYDROCHLORIDE',
  'LEVORPHANOL TARTRATE',
  'INDAPAMIDE',
  'LEVOBUNOLOL HYDROCHLORIDE',
  'TRIFLURIDINE',
  'TERAZOSIN HYDROCHLORIDE',
  'HYDROMORPHONE HYDROCHLORIDE',
  'CAPTOPRIL',
  'VECURONIUM BROMIDE',
  'PASER',
  'CHOLESTYRAMINE',
  'CHOLESTYRAMINE LIGHT',
  'FLURBIPROFEN',
  'CLOMIPRAMINE HYDROCHLORIDE',
  'PRIMSOL',
  'MEXILETINE HYDROCHLORIDE',
  'AKPRO',
  'GLYBURIDE',
  'SUFENTANIL CITRATE',
  'AZATHIOPRINE SODIUM',
  'PENTOXIFYLLINE',
  'CROLOM',
  'FLURBIPROFEN SODIUM',
  'HYDROXYUREA',
  'HYDROCORTISONE VALERATE',
  'MINOXIDIL (FOR MEN)',
  'NICOTINE POLACRILEX',
  'SULSTER',
  'METROMIDOL',
  'KETAMINE HYDROCHLORIDE',
  'HALOPERIDOL LACTATE',
  'TRIVORA-21',
  'TRIVORA-28',
  'CLOZAPINE',
  'ACYCLOVIR',
  'LOCHOLEST',
  'LOCHOLEST LIGHT',
  'IBUPROHM COLD AND SINUS',
  'CLONAZEPAM',
  'DESMOPRESSIN ACETATE PRESERVATIVE FREE',
  'M-ZOLE 7 DUAL PACK',
  'ETOMIDATE',
  'ACYCLOVIR SODIUM',
  'NICOTINE',
  'AMRINONE LACTATE',
  'BUTORPHANOL TARTRATE PRESERVATIVE FREE',
  'IOPAMIDOL',
  'BROMOCRIPTINE MESYLATE',
  'ATRACURIUM BESYLATE',
  'ATRACURIUM BESYLATE PRESERVATIVE FREE',
  'IOPAMIDOL-300',
  'ORPHENADRINE CITRATE, ASPIRIN, AND CAFFEINE',
  'FLUCONAZOLE',
  'ACETAMINOPHEN AND PENTAZOCINE HYDROCHLORIDE',
  'PROPOFOL',
  'KLOR-CON ',
  'LEUPROLIDE ACETATE',
  'MINOXIDIL (FOR WOMEN)',
  'NALOXONE HYDROCHLORIDE AND PENTAZOCINE HYDROCHLORIDE',
  'CARTIA XT',
  'KETOROLAC TROMETHAMINE',
  'IPRATROPIUM BROMIDE',
  'AKBETA',
  'LABETALOL HYDROCHLORIDE',
  'CAPTOPRIL AND HYDROCHLOROTHIAZIDE',
  'RANITIDINE',
  'FLUOXETINE HYDROCHLORIDE',
  'FLUNISOLIDE',
  'PERMETHRIN',
  'HALOPERIDOL DECANOATE',
  'ALPROSTADIL',
  'INVAGESIC',
  'INVAGESIC FORTE',
  'ESTAZOLAM',
  'ETODOLAC',
  'TRETINOIN',
  'IOPAMIDOL-200',
  'PHRENILIN WITH CAFFEINE AND CODEINE',
  'NALTREXONE HYDROCHLORIDE',
  'M-ZOLE 3 COMBINATION PACK',
  'CHILDREN\'S IBUPROFEN',
  'PENTOXIL',
  'KETOCONAZOLE',
  'PEMOLINE',
  'ISOSORBIDE MONONITRATE',
  'BUTORPHANOL TARTRATE',
  'ENALAPRIL MALEATE',
  'FAMOTIDINE',
  'CROMOPTIC',
  'TICLOPIDINE HYDROCHLORIDE',
  'ETHAMBUTOL HYDROCHLORIDE',
  'CODAMINE',
  'ORAPRED',
  'AFEDITAB CR',
  'PACERONE',
  'ORPHENGESIC',
  'ORPHENGESIC FORTE',
  'DICLOFENAC POTASSIUM',
  'PSEUDOEPHEDRINE HYDROCHLORIDE',
  'MIDAZOLAM HYDROCHLORIDE',
  'COLOCORT',
  'NABUMETONE',
  'PACLITAXEL',
  'DIFLORASONE DIACETATE',
  'PROPAFENONE HYDROCHLORIDE',
  'CYTOSAR-U',
  'ALFENTANIL',
  'AZASAN',
  'DESOGESTREL AND ETHINYL ESTRADIOL',
  'LOW-OGESTREL',
  'FLUTAMIDE',
  'LOVASTATIN',
  'BUPROPION HYDROCHLORIDE',
  'CARDIOPLEGIC IN PLASTIC CONTAINER',
  'EMBELINE E',
  'MICONAZOLE NITRATE COMBINATION PACK',
  'GABAPENTIN',
  'DOXAZOSIN MESYLATE',
  'PAROXETINE HYDROCHLORIDE',
  'JUNIOR STRENGTH IBUPROFEN',
  'BETAXOLOL HYDROCHLORIDE',
  'TAZTIA XT',
  'CLADRIBINE',
  'OGESTREL 0.5/50-21',
  'OGESTREL 0.5/50-28',
  'CLOBETASOL PROPIONATE (EMOLLIENT)',
  'MINOXIDIL EXTRA STRENGTH (FOR MEN)',
  'FLECAINIDE ACETATE',
  'ENALAPRILAT',
  'NIZATIDINE',
  'BISOPROLOL FUMARATE AND HYDROCHLOROTHIAZIDE',
  'BISOPROLOL FUMARATE',
  'CARTEOLOL HYDROCHLORIDE',
  'NORTREL 7/7/7',
  'FAMOTIDINE PRESERVATIVE FREE',
  'SORINE',
  'CLOTRIMAZOLE AND BETAMETHASONE DIPROPIONATE',
  'BALANCED SALT',
  'QUINAPRIL HYDROCHLORIDE',
  'URSODIOL',
  'CLOMIPHENE CITRATE',
  'MILRINONE LACTATE',
  'METOLAZONE',
  'MICROGESTIN FE ',
  'MICROGESTIN ',
  'LEVOCARNITINE',
  'AMMONIUM LACTATE',
  'IBUPROFEN AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'FAMOTIDINE PRESERVATIVE FREE IN PLASTIC CONTAINER',
  'PODOFILOX',
  'AMINOCAPROIC',
  'BUTALBITAL, ACETAMINOPHEN, CAFFEINE AND CODEINE PHOSPHATE',
  'ENALAPRIL MALEATE AND HYDROCHLOROTHIAZIDE',
  'METHYLIN ER',
  'KETOZOLE',
  'MICROGESTIN FE 1/20',
  'MICROGESTIN 1/20',
  'FLUOXETINE',
  'LORATADINE AND PSEUDOEPHEDRINE SULFATE',
  'ALENDRONATE SODIUM',
  'SERTRALINE HYDROCHLORIDE',
  'METIPRANOLOL',
  'MESNA',
  'LISINOPRIL',
  'CALCITRIOL',
  'FENOFIBRATE (MICRONIZED)',
  'OFLOXACIN',
  'NEFAZODONE HYDROCHLORIDE',
  'LISINOPRIL AND HYDROCHLOROTHIAZIDE',
  'ACETAMINOPHEN, ASPIRIN AND CAFFEINE',
  'AVIANE',
  'ETIDRONATE DISODIUM',
  'LESSINA',
  'SPRINTEC',
  'TRI-SPRINTEC',
  'ENPRESSE',
  'CRYSELLE',
  'OXAPROZIN',
  'MIDAZOLAM HYDROCHLORIDE PRESERVATIVE FREE',
  'LEVONORGESTREL AND ETHINYL ESTRADIOL',
  'KARIVA',
  'PORTIA',
  'IFOSFAMIDE/MESNA KIT',
  'TERBUTALINE SULFATE',
  'FLUVOXAMINE MALEATE',
  'SEVOFLURANE',
  'FELODIPINE',
  'TIOCONAZOLE',
  'RIMANTADINE HYDROCHLORIDE',
  'MOMETASONE FUROATE',
  'STRONTIUM CHLORIDE SR-89',
  'AMNESTEEM',
  'NORETHINDRONE ACETATE',
  'OCTREOTIDE ACETATE',
  'TRAMADOL HYDROCHLORIDE',
  'METFORMIN HYDROCHLORIDE',
  'VINORELBINE TARTRATE',
  'OLANZAPINE',
  'ECONAZOLE NITRATE',
  'BUPIVACAINE HYDROCHLORIDE PRESERVATIVE FREE',
  'DEFEROXAMINE MESYLATE',
  'HYDROCODONE BITARTRATE AND IBUPROFEN',
  'LOPERAMIDE HYDROCHLORIDE AND SIMETHICONE',
  'SOTRET',
  'PRAVASTATIN SODIUM',
  'PERGOLIDE MESYLATE',
  'ZOLPIDEM TARTRATE',
  'JUNEL FE ',
  'AMCINONIDE',
  'DEXRAZOXANE HYDROCHLORIDE',
  'IFOSFAMIDE',
  'FLUCONAZOLE IN SODIUM CHLORIDE 0.9%',
  'CIPROFLOXACIN',
  'MISOPROSTOL',
  'CARBOPLATIN',
  'ITRACONAZOLE',
  'TRI-LEGEST FE',
  'TORSEMIDE',
  'BENAZEPRIL HYDROCHLORIDE',
  'MIRTAZAPINE',
  'VOSPIRE ER',
  'CLARAVIS',
  'FOSINOPRIL SODIUM',
  'DILT-CD',
  'OXYCODONE HYDROCHLORIDE',
  'FEXOFENADINE HYDROCHLORIDE',
  'RILUZOLE',
  'CAMILA',
  'ONDANSETRON HYDROCHLORIDE',
  'DIMETHYL SULFOXIDE',
  'LEVOTHYROXINE SODIUM',
  'RIBAVIRIN',
  'BALZIVA-21',
  'RIBASPHERE',
  'MOEXIPRIL HYDROCHLORIDE',
  'LATANOPROST',
  'NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL',
  'GANCICLOVIR SODIUM',
  'ERRIN',
  'RISPERIDONE',
  'FLAVOXATE HYDROCHLORIDE',
  'FEXOFENADINE HYDROCHLORIDE AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'BALZIVA-28',
  'THEROXIDIL',
  'NIACIN',
  'BRIMONIDINE TARTRATE',
  'FLUMAZENIL',
  'CLOPIDOGREL BISULFATE',
  'TIZANIDINE HYDROCHLORIDE',
  'LIDOCAINE AND PRILOCAINE',
  'VALPROATE SODIUM',
  'FLUTICASONE PROPIONATE',
  'FLUCONAZOLE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER',
  'FLUCONAZOLE IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'TOPIRAMATE',
  'CABERGOLINE',
  'OCTREOTIDE ACETATE (PRESERVATIVE FREE)',
  'FAMOTIDINE PRESERVATIVE FREE (PHARMACY BULK)',
  'ESMOLOL HYDROCHLORIDE',
  'PREVIFEM',
  'TRI-PREVIFEM',
  'CYCLAFEM ',
  'FINASTERIDE',
  'BENAZEPRIL HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'GLYBURIDE AND METFORMIN HYDROCHLORIDE',
  'THYROSAFE',
  'MICONAZOLE 3 COMBINATION PACK',
  'HYDROCORTISONE BUTYRATE',
  'CARVEDILOL',
  'QUINARETIC',
  'TERBINAFINE HYDROCHLORIDE',
  'JUNEL ',
  'LAMOTRIGINE',
  'DILTZAC',
  'ADENOSINE',
  'TRI-LEGEST 21',
  'METHENAMINE HIPPURATE',
  'ANAGRELIDE HYDROCHLORIDE',
  'DARVOCET A500',
  'FENOFIBRATE',
  'PAROEX',
  'FEXOFENADINE HYDROCHLORIDE ALLERGY',
  'MIDODRINE HYDROCHLORIDE',
  'VELIVET',
  'DESOGESTREL; ETHINYL',
  'GANCICLOVIR',
  'TRAMADOL HYDROCHLORIDE AND ACETAMINOPHEN',
  'ATORVASTATIN CALCIUM',
  'MYORISAN',
  'TRILYTE',
  'NAPROXEN SODIUM AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'TERIL',
  'DEXTROAMP SACCHARATE,AMP ASPARTATE,DEXTROAMP SULFATE AND AMP SULFATE',
  'FEXOFENADINE HCL; PSEUDOEPHEDRINE HCL',
  'TRIMETHOBENZAMIDE HYDROCHLORIDE',
  'RAMIPRIL',
  'SUMATRIPTAN SUCCINATE',
  'FENOLDOPAM MESYLATE',
  'MICONAZOLE 7 COMBINATION PACK',
  'ALCLOMETASONE DIPROPIONATE',
  'VALACYCLOVIR HYDROCHLORIDE',
  'MODAFINIL',
  'CETIRIZINE HYDROCHLORIDE',
  'FOSINOPRIL SODIUM AND HYDROCHLOROTHIAZIDE',
  'MITOXANTRONE HYDROCHLORIDE',
  'NORGESTIMATE AND ETHINYL ESTRADIOL',
  'METOPROLOL SUCCINATE',
  'REPREXAIN',
  'CARBILEV',
  'NITROFURANTOIN (MONOHYDRATE/MACROCRYSTALS)',
  'GLYCOLAX',
  'EMOQUETTE',
  'CETIRIZINE HYDROCHLORIDE HIVES',
  'KIMIDESS',
  'ENOXAPARIN SODIUM (PRESERVATIVE FREE)',
  'DANTROLENE SODIUM',
  'ONDANSETRON',
  'PARCOPA',
  'DESMOPRESSIN ACETATE (NEEDS NO REFRIGERATION)',
  'ALBUTEROL SULFATE AND IPRATROPIUM BROMIDE',
  'ORVATEN',
  'TESTOSTERONE',
  'NIMODIPINE',
  'PILOCARPINE HYDROCHLORIDE',
  'ROSIGLITAZONE MALEATE',
  'ROSIGLITAZONE',
  'ALBUTEROL SULFATE; IPRATROPIUM BROMIDE',
  'MESALAMINE',
  'CIPROFLOXACIN IN DEXTROSE 5%',
  'OXANDROLONE',
  'ESCITALOPRAM OXALATE',
  'MICONAZOLE 3',
  'ARANELLE',
  'TRI LO SPRINTEC',
  'KELNOR',
  'DONEPEZIL HYDROCHLORIDE',
  'METOPROLOL TARTRATE AND HYDROCHLOROTHIAZIDE',
  'PIOGLITAZONE HYDROCHLORIDE',
  'PIOGLITAZONE',
  'GLIMEPIRIDE',
  'ESTRADIOL AND NORGESTIMATE',
  'RABEPRAZOLE SODIUM',
  'ALLOPURINOL SODIUM',
  'HALOBETASOL PROPIONATE',
  'FOSPHENYTOIN SODIUM',
  'CELECOXIB',
  'LEVETIRACETAM',
  'LIOTHYRONINE SODIUM',
  'BICALUTAMIDE',
  'MOXIFLOXACIN HYDROCHLORIDE',
  'DIVALPROEX SODIUM',
  'TOLTERODINE',
  'LOSARTAN POTASSIUM',
  'MOEXIPRIL HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'ESCITALOPRAM',
  'TOLTERODINE TARTRATE',
  'CILOSTAZOL',
  'CALCIPOTRIENE',
  'GILDESS FE 1.5/30',
  'GILDESS 1.5/30',
  'GILDESS FE 1/20',
  'GILDESS 1/20',
  'LEFLUNOMIDE',
  'QUINAPRIL HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'ORSYTHIA',
  'QUASENSE',
  'DEXMETHYLPHENIDATE HYDROCHLORIDE',
  'AMIFOSTINE',
  'RIVASTIGMINE TARTRATE',
  'RISEDRONATE SODIUM',
  'MILRINONE LACTATE IN DEXTROSE 5%',
  'LORATADINE REDIDOSE',
  'LOSARTAN POTASSIUM AND HYDROCHLOROTHIAZIDE',
  'IRBESARTAN',
  'DIDANOSINE',
  'ISRADIPINE',
  'CETIRIZINE HYDROCHLORIDE AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'FOSCARNET SODIUM',
  'GRANISETRON HYDROCHLORIDE',
  'AMLODIPINE BESYLATE AND BENAZEPRIL HYDROCHLORIDE',
  'DESOGESTREL; ETHINYL ESTRADIOL',
  'THYROSHIELD',
  'IRINOTECAN HYDROCHLORIDE',
  'LAMIVUDINE',
  'CAFFEINE CITRATE',
  'PREDNICARBATE',
  'ZALEPLON',
  'LANSOPRAZOLE',
  'TRANDOLAPRIL',
  'RIZATRIPTAN BENZOATE',
  'GLIPIZIDE AND METFORMIN HYDROCHLORIDE',
  'TUSSICAPS',
  'COLESTIPOL HYDROCHLORIDE',
  'ROSIGLITAZONE MALEATE AND METFORMIN HYDROCHLORIDE',
  'SILDENAFIL CITRATE',
  'KETOTIFEN FUMARATE',
  'RIVASTIGMINE',
  'IRBESARTAN AND HYDROCHLOROTHIAZIDE',
  'QUETIAPINE FUMARATE',
  'SODIUM BICARBONATE',
  'NEVIRAPINE',
  'EXEMESTANE',
  'POLYETHYLENE GLYCOL 3350',
  'ROPINIROLE HYDROCHLORIDE',
  'NATEGLINIDE',
  'FAMCICLOVIR',
  'VALSARTAN',
  'MYZILRA',
  'BUDESONIDE',
  'DROSPIRENONE AND ETHINYL ESTRADIOL',
  'OLANZAPINE AND FLUOXETINE HYDROCHLORIDE',
  'ACARBOSE',
  'TIAGABINE HYDROCHLORIDE',
  'ZIPRASIDONE HYDROCHLORIDE',
  'REPAGLINIDE',
  'GALANTAMINE HYDROBROMIDE',
  'ZONISAMIDE',
  'TAMSULOSIN HYDROCHLORIDE',
  'THRIVE',
  'STAVUDINE',
  'CALCIUM ACETATE',
  'CIPROFLOXACIN EXTENDED RELEASE',
  'HYDROCODONE',
  'PRAMIPEXOLE DIHYDROCHLORIDE',
  'OXCARBAZEPINE',
  'LEVALBUTEROL HYDROCHLORIDE',
  'APRACLONIDINE HYDROCHLORIDE',
  'BALSALAZIDE DISODIUM',
  'NADOLOL AND BENDROFLUMETHIAZIDE',
  'PENTOSTATIN',
  'DORZOLAMIDE HYDROCHLORIDE',
  'DORZOLAMIDE HYDROCHLORIDE AND TIMOLOL MALEATE',
  'SOJOURN',
  'MELOXICAM',
  'AZELASTINE HYDROCHLORIDE',
  'GEMCITABINE HYDROCHLORIDE',
  'FEXOFENADINE HYDROCHLORIDE; PSEUDOEPHEDRINE HYDROCHLORIDE',
  'ESOMEPRAZOLE MAGNESIUM',
  'VALSARTAN AND HYDROCHLOROTHIAZIDE',
  'ALMOTRIPTAN MALATE',
  'ANASTROZOLE',
  'LINEZOLID',
  'TECHNETIUM TC 99M SESTAMIBI',
  'PERINDOPRIL ERBUMINE',
  'BUTALBITAL, ASPIRIN AND CAFFEINE',
  'LETROZOLE',
  'RALOXIFENE HYDROCHLORIDE',
  'NAPHAZOLINE HYDROCHLORIDE AND PHENIRAMINE MALEATE',
  'SODIUM FERRIC GLUCONATE COMPLEX IN SUCROSE',
  'TECHNETIUM TC-99M MEBROFENIN',
  'NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL AND FERROUS FUMARATE',
  'OLMESARTAN MEDOXOMIL',
  'DRONABINOL',
  'OXYCODONE HYDROCHLORIDE AND IBUPROFEN',
  'ESTRADIOL AND NORETHINDRONE ACETATE',
  'GRANISOL',
  'IMATINIB MESYLATE',
  'OLOPATADINE HYDROCHLORIDE',
  'DESLORATADINE',
  'DESLORATADINE AND PSEUDOEPHEDRINE SULFATE 24 HOUR',
  'FOMEPIZOLE',
  'GILDAGIA',
  'REVONTO',
  'EPOPROSTENOL SODIUM',
  'FLUVASTATIN SODIUM',
  'POTASSIUM CHLORIDE 0.149% IN SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER',
  'GEMCITABINE',
  'AMLODIPINE BESYLATE; BENAZEPRIL HYDROCHLORIDE',
  'ZOLEDRONIC ACID',
  'EPLERENONE',
  'ELIPHOS',
  'ROCURONIUM BROMIDE',
  'IMIQUIMOD',
  'EZETIMIBE',
  'MIVACURIUM CHLORIDE',
  'LEVOFLOXACIN IN DEXTROSE 5% IN PLASTIC CONTAINER',
  'ARIPIPRAZOLE',
  'ROPIVACAINE HYDROCHLORIDE',
  'MONTELUKAST SODIUM',
  'DEXBROMPHENIRAMINE MALEATE AND PSEUDOEPHEDRINE SULFATE',
  'ENOXAPARIN SODIUM',
  'CHILDREN\'S CETIRIZINE HYDROCHLORIDE ALLERGY',
  'CANDESARTAN CILEXETIL',
  'ROSIGLITAZONE MALEATE AND GLIMEPIRIDE',
  'TELMISARTAN',
  'INDOMETHACIN SODIUM',
  'ARSENIC TRIOXIDE',
  'MALATHION',
  'NARATRIPTAN',
  'ATAZANAVIR SULFATE',
  'TENOFOVIR DISOPROXIL FUMARATE',
  'ASPIRIN AND DIPYRIDAMOLE',
  'OLMESARTAN MEDOXOMIL AND HYDROCHLOROTHIAZIDE',
  'SCOPOLAMINE',
  'LEVONORGESTREL AND ETHINYL ESTRADIOL AND ETHINYL ESTRADIOL',
  'OMEPRAZOLE MAGNESIUM',
  'TEMOZOLOMIDE',
  'GUAIFENESIN',
  'PROTRIPTYLINE HYDROCHLORIDE',
  'ATOMOXETINE HYDROCHLORIDE',
  'ENTACAPONE',
  'IBANDRONATE SODIUM',
  'PRILOCAINE HYDROCHLORIDE AND EPINEPHRINE BITARTRATE',
  'OMEPRAZOLE AND SODIUM BICARBONATE',
  'ALFUZOSIN HYDROCHLORIDE',
  'OXYMORPHONE HYDROCHLORIDE',
  'NISOLDIPINE',
  'INTROVALE',
  'LOPINAVIR; RITONAVIR',
  'GATIFLOXACIN',
  'CARBIDOPA, LEVODOPA AND ENTACAPONE',
  'ALTAVERA',
  'IBUPROFEN AND DIPHENHYDRAMINE CITRATE',
  'TRANDOLAPRIL AND VERAPAMIL HYDROCHLORIDE',
  'ARTICAINE HYDROCHLORIDE AND EPINEPHRINE BITARTRATE',
  'ROSUVASTATIN CALCIUM',
  'EMTRICITABINE',
  'LORYNA',
  'MEMANTINE HYDROCHLORIDE',
  'LAX-LYTE WITH FLAVOR PACKS',
  'PRILOCAINE HYDROCHLORIDE',
  'ABACAVIR SULFATE AND LAMIVUDINE',
  'MONOBASIC SODIUM PHOSPHATE AND DIBASIC SODIUM PHOSPHATE',
  'SULFOSE',
  'FURALAN',
  'ISOPTO CETAMIDE',
  'CETAMIDE',
  'SULFACEL',
  'SULF',
  'ALCAINE',
  'BLEPH',
  'SOSOL',
  'SULSOXIN',
  'PROPOXYPHENE COMPOUND 65',
  'NEOPASALATE',
  'SULFA-TRIPLE #2',
  'SULFISOXAZOLE',
  'TRIPLE SULFA',
  'TRIPLE SULFOID',
  'SODIUM AMINOSALICYLATE',
  'POTASSIUM AMINOSALICYLATE',
  'SULFALOID',
  'LANTRISUL',
  'STANOZIDE',
  'HYZYD',
  'SODIUM P.A.S.',
  'LANIAZID',
  'PYRAZINAMIDE',
  'TESTOSTERONE PROPIONATE',
  'METHYLTESTOSTERONE',
  'SERVISONE',
  'SORBITOL-MANNITOL',
  'NAPHCON FORTE',
  'VASOCON',
  'ALBALON',
  'PROMPT PHENYTOIN SODIUM',
  'PROKLAR',
  'ORETON',
  'DELTA-DOME',
  'CORTALONE',
  'DOW-ISONIAZID',
  'SOXAZOLE',
  'LIDOCAINE HYDROCHLORIDE W/ EPINEPHRINE',
  'BAMATE',
  'TREMIN',
  'PROCAINE HYDROCHLORIDE',
  'LTA II KIT',
  'TEXACORT',
  'CETACORT',
  'ANESTACON',
  'METARAMINOL BITARTRATE',
  'HC #1',
  'HC #4',
  'NUTRACORT',
  'ELDECORT',
  'HYTONE',
  'HC (HYDROCORTISONE)',
  'HI-COR',
  'CARMOL HC',
  'SODIUM SUCCINATE',
  'DOLENE',
  'VIBISONE',
  'RUVITE',
  'PYRIDOXINE HYDROCHLORIDE',
  'DIPHENHYDRAMINE HYDROCHLORIDE PRESERVATIVE FREE',
  'OCUSULF',
  'PIPERAZINE CITRATE',
  'VITAMIN A',
  'VITAMIN D',
  'ALA-CORT',
  'TRIPELENNAMINE HYDROCHLORIDE',
  'ARISTOCORT A',
  'INFLAMASE MILD',
  'INFLAMASE FORTE',
  'PROCAINE HYDROCHLORIDE W/ EPINEPHRINE',
  'ALPHAREDISOL',
  'PYRILAMINE MALEATE',
  'HALOTHANE',
  'VITAMIN A PALMITATE',
  'DEL-VI-A',
  'PHENETRON',
  'BETALIN S',
  'HEXA-BETALIN',
  'BETALIN 12',
  'DIPHENYLAN SODIUM',
  'ALPHALIN',
  'DELTALIN',
  'TESTOPEL',
  'VITAMIN A SOLUBILIZED',
  'ISOCAINE HYDROCHLORIDE',
  'PROPANTHELINE BROMIDE',
  'INH',
  'P.A.S. SODIUM',
  'PROBALAN',
  'VI-DOM-A',
  'SONAZINE',
  'VERMIDOL',
  'SUCCINYLCHOLINE CHLORIDE',
  'STRIFON FORTE DSC',
  'THEO-24',
  'ACETAMINOPHEN, ASPIRIN, AND CODEINE PHOSPHATE',
  'ERGOLOID MESYLATES',
  'AMINOPHYLLINE',
  'METHOCARBAMOL AND ASPIRIN',
  'CYCRIN',
  'FOLEX PFS',
  'HEMSOL-HC',
  'ESTRACE',
  'ORASONE',
  'PROCTOCORT',
  'DERMACORT',
  'COBAVITE',
  'SODIUM SULFACETAMIDE',
  'DODEX',
  'PREDNISOLONE ACETATE',
  'PROPOXYPHENE COMPOUND-65',
  'CHLORDIAZEPOXIDE HYDROCHLORIDE',
  'BROMPHENIRAMINE MALEATE',
  'AMMONIUM CHLORIDE',
  'REMSED',
  'AFAXIN',
  'ORABASE HCA',
  'BENZTHIAZIDE',
  'ESTRATAB',
  'PRAMOSONE',
  'EPICORT',
  'OGEN ',
  'PROBENECID W/ COLCHICINE',
  'ALA-SCALP',
  'GLUTETHIMIDE',
  'SODIUM PENTOBARBITAL',
  'AMEN',
  'NEMBUTAL',
  'NEMBUTAL SODIUM',
  'SECOBARBITAL SODIUM',
  'AMNESTROGEN',
  'PENTOBARBITAL SODIUM',
  'AEROSEB-DEX',
  'BUTABARBITAL SODIUM',
  'PREDNISOLONE TEBUTATE',
  'FERNISONE',
  'EVEX',
  'ARISTOGEL',
  'ANTAGONATE',
  'DIUCARDIN',
  'ESTRONE',
  'ESTERIFIED ESTROGENS',
  'TRICHLOREX',
  'S.A.S.-500',
  'VENTAIRE',
  'TRICHLORMETHIAZIDE',
  'SODIUM BUTABARBITAL',
  'ESTROGENIC SUBSTANCE',
  'GVS',
  'DV',
  'PROPHENE 65',
  'KESSO-GESIC',
  'PROCAPAN',
  'DELCOBESE',
  'HYDROCHLOROTHIAZIDE W/ RESERPINE',
  'QUINORA',
  'NAPHAZOLINE HYDROCHLORIDE',
  'KLOROMIN',
  'ENDEP',
  'CAPITAL AND CODEINE',
  'ADPHEN',
  'PHYTONADIONE',
  'PYRIDAMAL 100',
  'DEXAMPEX',
  'PROBENECID',
  'TRIAMCINOLONE',
  'METRA',
  'HYDROCHLOROTHIAZIDE W/ RESERPINE AND HYDRALAZINE',
  'PROPOXYPHENE HYDROCHLORIDE 65',
  'MELFIAT',
  'NICOLAR',
  'PRAMINE',
  'METRETON',
  'HYDROSERPINE PLUS (R-H-H)',
  'METHAMPEX',
  'HYDRO-D',
  'EXSEL',
  'BENZEDRINE',
  'AMPHETAMINE SULFATE',
  'LIDOCAINE HYDROCHLORIDE 5% AND DEXTROSE 7.5%',
  'ELIXOPHYLLIN',
  'CAM-METRAZINE',
  'ZIDE',
  'AMITRIL',
  'FERNISOLONE-P',
  'CALMURID HC',
  'EMPRACET W/ CODEINE PHOSPHATE ',
  'TESTRED',
  'ZIPAN',
  'FERNDEX',
  'CHLOROTHIAZIDE',
  'AMOSENE',
  'TORA',
  'OCTOCAINE',
  'DEXTROSTAT',
  'TRIAMCINOLONE DIACETATE',
  'VELTANE',
  'CRYSTODIGIN',
  'CYCLOGYL',
  'SULFISOXAZOLE DIOLAMINE',
  'BENOXINATE HYDROCHLORIDE',
  'CYCLOPENTOLATE HYDROCHLORIDE',
  'MYOTONACHOL',
  'COL-PROBENECID',
  'HYDRALAZINE HYDROCHLORIDE, HYDROCHLOROTHIAZIDE AND RESERPINE',
  'CYCLOMYDRIL',
  'DRALZINE',
  'DEXAMETHASONE ACETATE',
  'PHENYLBUTAZONE',
  'DEXACEN-4',
  'NITROFURAZONE',
  'SELENIUM SULFIDE',
  'PROMAPAR',
  'ESTRAGUARD',
  'PHENAPHEN W/ CODEINE ',
  'ETHCHLORVYNOL',
  'DOMEBORO',
  'PROMAZINE HYDROCHLORIDE',
  'DILOR',
  'BENZYL BENZOATE',
  'COMPOUND 65',
  'THEOLIXIR',
  'LUFYLLIN',
  'LANOPHYLLIN',
  'RESERPINE AND HYDROCHLOROTHIAZIDE',
  'DRALSERP',
  'HYDROXOMIN',
  'CHLORDIAZACHEL',
  'CHLOROTRIANISENE',
  'PREDNICEN-M',
  'CHLORPROPAMIDE',
  'ISOCAINE HYDROCHLORIDE W/ LEVONORDEFRIN',
  'OGEN',
  'HYDRO-RESERP',
  'SARISOL NO. 1',
  'SARISOL NO. 2',
  'ALPHACAINE HYDROCHLORIDE W/ EPINEPHRINE',
  'ALPHACAINE HYDROCHLORIDE',
  'SARISOL',
  'THEOPHYL-225',
  'LIDOCATON',
  'APRESAZIDE',
  'CINNASIL',
  'HYDROCORTISONE SODIUM SUCCINATE',
  'DILOR-400',
  'PROMETH FORTIS',
  'ARESTOCAINE HYDROCHLORIDE',
  'HYDRO-SERP "25"',
  'MEPIVACAINE HYDROCHLORIDE W/ LEVONORDEFRIN',
  'CHLOROTHIAZIDE W/ RESERPINE',
  'CIRCANOL',
  'MERSALYL-THEOPHYLLINE',
  'HYDRAP-ES',
  'CAM-AP-ES',
  'PROMETHACON',
  'TRIACET',
  'HYDROXOCOBALAMIN',
  'CIN-QUIN',
  'ALPHACAINE',
  'MENEST',
  'SULFALAR',
  'MICRAININ',
  'GAMENE',
  'DEXONE ',
  'WYGESIC',
  'FEMOGEN',
  'LYGEN',
  'ARESTOCAINE HYDROCHLORIDE W/ LEVONORDEFRIN',
  'TRIMEPRAZINE TARTRATE',
  'GENAPAX',
  'DEAPRIL-ST',
  'ALPHAZINE',
  'SULFAMETHOXAZOLE',
  'TYLENOL W/ CODEINE',
  'AEROLATE SR',
  'AEROLATE JR',
  'AEROLATE III',
  'PROCAN',
  'TRIPROLIDINE HYDROCHLORIDE',
  'STATOBEX-G',
  'DOLENE AP-65',
  'MEPERIDINE AND ATROPINE SULFATE',
  'ADIPEX-P',
  'FORBAXIN',
  'ASBRON',
  'ALKERGOT',
  'SLO-PHYLLIN',
  'LOW-QUEL',
  'HYDRO-SERP "50"',
  'NATURAL ESTROGENIC SUBSTANCE-ESTRONE',
  'TRICHLORMETHIAZIDE W/ RESERPINE',
  'BRONKODYL',
  'DI-ATRO',
  'BONTRIL PDM',
  'TRIPROLIDINE HYDROCHLORIDE AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'LONOX',
  'THEO-DUR',
  'H.R.-50',
  'THEOCLEAR',
  'NOVOCAIN',
  'HYDROCHLOROTHIAZIDE W/ HYDRALAZINE',
  'TYLOX',
  'BUTICAPS',
  'HYDRALAZINE HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'A-POXIDE',
  'DELAXIN',
  'DEPO-ESTRADIOL',
  'METHYCLOTHIAZIDE',
  'LIBRITABS',
  'ACETIC ACID W/ HYDROCORTISONE',
  'TRUPHYLLINE',
  'ELIXICON',
  'STATOBEX',
  'PHENAZINE-35',
  'FLUTEX',
  'RESERPINE, HYDRALAZINE HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE',
  'BUTABARBITAL',
  'HYDROXYZINE',
  'PROBEN-C',
  'AK-PENTOLATE',
  'TESTOSTERONE CYPIONATE-ESTRADIOL CYPIONATE',
  'ESTRADIOL CYPIONATE',
  'DEPO-TESTOSTERONE',
  'CODEINE, ASPIRIN, APAP FORMULA ',
  'SUSTAIRE',
  'VICODIN',
  'PROVAL #3',
  'CURRETAB',
  'DI-METREX',
  'PROPOXYPHENE HYDROCHLORIDE W/ ASPIRIN AND CAFFEINE',
  'AMMONIUM CHLORIDE 2.14%',
  'COLONAID',
  'BETAMETHASONE SODIUM PHOSPHATE',
  'LOMANATE',
  'HYDRALAZINE, HYDROCHLOROTHIAZIDE W/ RESERPINE',
  'AEROSEB-HC',
  'HYDRALAZINE AND HYDROCHLOROTHIAZIDE',
  'PHENAPHEN-650 W/ CODEINE',
  'TESTOSTERONE ENANTHATE AND ESTRADIOL VALERATE',
  'BUTABARB',
  'BUTALAN',
  'DUVOID',
  'ISOETHARINE HYDROCHLORIDE',
  'UNIPRES',
  'SPRX-3',
  'OXYCODONE 2.5/APAP 500',
  'OXYCODONE 5/APAP 500',
  'PROMETH PLAIN',
  'SPIRONOLACTONE W/ HYDROCHLOROTHIAZIDE',
  'QUINACT',
  'HYDRO-RX',
  'THEOBID',
  'PEDIATRIC LTA KIT',
  'ALPHADERM',
  'MICROSUL',
  'ANISOTROPINE METHYLBROMIDE',
  'PROCAN SR',
  'THEOLAIR',
  'GUANETHIDINE MONOSULFATE',
  'GERIMAL',
  'PROCTOFOAM HC',
  'DRICORT',
  'KENALOG-H',
  'TRICHLORMAS',
  'ONA-MAST',
  'HOMAPIN',
  'EQUIPIN',
  'VICOPRIN',
  'MEXATE',
  'THEOLAIR-SR',
  'LARYNG-O-JET KIT',
  'NANDROLONE DECANOATE',
  'NANDROLONE PHENPROPIONATE',
  'DITATE-DS',
  'DIPHENOXYLATE HYDROCHLORIDE W/ ATROPINE SULFATE',
  'ANDROID 10',
  'AMITID',
  'EPIFOAM',
  'THEOPHYL-SR',
  'HYPAQUE-76',
  'THEOPHYL',
  'SPIRONOLACTONE AND HYDROCHLOROTHIAZIDE',
  'PHENAZINE',
  'ACTICORT',
  'X-TROZINE',
  'MIGERGOT',
  'WIGRAINE',
  'THEOCLEAR L.A.',
  'TYZINE',
  'BELIX',
  'M-PREDROL',
  'BETA-2',
  'CHOLEDYL SA',
  'WIGRETTES',
  'PHYLLOCONTIN',
  'SCABENE',
  'H-CORT',
  'ELIXOPHYLLIN SR',
  'CHLORTHALIDONE',
  'POLARAMINE',
  'DAPSONE',
  'ORLEX HC',
  'ORLEX',
  'DISULFIRAM',
  'LANORINAL',
  'ROXICET',
  'THEOVENT',
  'ORGATRAX',
  'SOMOPHYLLIN-DF',
  'HYDRALAZINE HYDROCHLORIDE-HYDROCHLOROTHIAZIDE-RESERPINE',
  'AZOLID',
  'TRIACORT',
  'METHDILAZINE HYDROCHLORIDE',
  'FLEXICORT',
  'ACETASOL HC',
  'OBESTIN',
  'ACETASOL',
  'ANDROID ',
  'SOMOPHYLLIN-T',
  'FLUONID',
  'PROMETHEGAN',
  'HYDROGENATED ERGOT ALKALOIDS',
  'SOMOPHYLLIN-CRT',
  'ANDROID-F',
  'FLURANDRENOLIDE',
  'CROTAN',
  'SER-A-GEN',
  'ANDROID 5',
  'LABID',
  'LINDANE',
  'UROBAK',
  'LORCET-HD',
  'PRONESTYL-SR',
  'X-TROZINE L.A.',
  'MD-GASTROVIEW',
  'THEOCHRON',
  'NOGENIC HC',
  'TRIATEX',
  'FOLICET',
  'QUINATIME',
  'SYNACORT',
  'OXYCET',
  'CODOXY',
  'CORTAN',
  'GLYCORT',
  'OPCON',
  'ISOPTO CETAPRED',
  'BUTALBITAL AND ACETAMINOPHEN',
  'QUIBRON-T/SR',
  'LIQUID PRED',
  'ANOQUAN',
  'SULFINPYRAZONE',
  'HY-PHEN',
  'PARACAINE',
  'ERGOMAR',
  'FOLEX',
  'RESERPINE, HYDROCHLOROTHIAZIDE, AND HYDRALAZINE HYDROCHLORIDE',
  'ANGIOVIST ',
  'UROVIST SODIUM 300',
  'ANGIOVIST 282',
  'AMINOPHYLLINE DYE FREE',
  'GASTROVIST',
  'UROVIST CYSTO',
  'UROVIST CYSTO PEDIATRIC',
  'UROVIST MEGLUMINE DIU/CT',
  'ROXIPRIN',
  'CHLOROTHIAZIDE-RESERPINE',
  'BLEPHAMIDE S.O.P.',
  'VIRILON',
  'CO-GESIC',
  'OBY-TRIM',
  'BILIVIST',
  'CETAPRED',
  'METHOXSALEN',
  'DURADYNE DHC',
  'PHRENILIN',
  'SULTEN',
  'OXTRIPHYLLINE',
  'ATROPINE AND DEMEROL',
  'THEOBID JR.',
  'ISOETHARINE MESYLATE',
  'SPS',
  'TRYSUL',
  'SLO-BID',
  'EPINEPHRINE',
  'AQUAPHYLLIN',
  'LARYNGOTRACHEAL ANESTHESIA KIT',
  'SULFAIR ',
  'PROMETHAZINE PLAIN',
  'VITAMIN K1',
  'BANCAP HC',
  'MYIDYL',
  'MYMETHAZINE FORTIS',
  'PREDSULFAIR',
  'LO-TROL',
  'BONTRIL',
  'SPRX',
  'HYDROFLUMETHIAZIDE',
  'PAPA-DEINE #3',
  'BALNEOL-HC',
  'TRIDERM',
  'PREDAMIDE',
  'HYDROPANE',
  'DEXAIR',
  'QUINALAN',
  'KAINAIR',
  'SULPHRIN',
  'HYDROFLUMETHIAZIDE AND RESERPINE',
  'MYFED',
  'TRIPROLIDINE AND PSEUDOEPHEDRINE',
  'HYDROCORTISONE IN ABSORBASE',
  'AMINOPHYLLINE IN SODIUM CHLORIDE 0.45%',
  'CHLORPROMAZINE HYDROCHLORIDE INTENSOL',
  'PREDAIR FORTE',
  'DIATRIZOATE',
  'FLUOTREX',
  'PSEUDOEPHEDRINE HYDROCHLORIDE AND TRIPROLIDINE HYDROCHLORIDE',
  'TRYMEX',
  'PENECORT',
  'FLUOXYMESTERONE',
  'MYDRIAFAIR',
  'OXTRIPHYLLINE PEDIATRIC',
  'TYLOX-325',
  'ANUSOL HC',
  'DEXCHLORPHENIRAMINE MALEATE',
  'DEXAMETHASONE INTENSOL',
  'T-PHYL',
  'THEOPHYLLINE-SR',
  'HISTAFED',
  'LIDOCAINE HYDROCHLORIDE IN PLASTIC CONTAINER',
  'ELIXOMIN',
  'DIBENIL',
  'AXOTAL',
  'ERGOSTAT',
  'BROMANYL',
  'ACTAHIST',
  'HYDRALAZINE HYDROCHLORIDE W/ HYDROCHLOROTHIAZIDE ',
  'FLUOCET',
  'CHLOROTHIAZIDE AND RESERPINE',
  'AMMONIUM CHLORIDE IN PLASTIC CONTAINER',
  'SULFAIR FORTE',
  'SCANDONEST PLAIN',
  'SCANDONEST L',
  'LIGNOSPAN FORTE',
  'LIGNOSPAN STANDARD',
  'OXYPHENBUTAZONE',
  'PREDAIR',
  'METHYCLOTHIAZIDE AND DESERPIDINE',
  'TRILITRON',
  'DURAPHYL',
  'TUSSIGON',
  'DHC PLUS',
  'HYDROCHLOROTHIAZIDE INTENSOL',
  'CORPHED',
  'BOROFAIR',
  'GYNE-SULF',
  'BROMODIPHENHYDRAMINE HYDROCHLORIDE AND CODEINE PHOSPHATE',
  'TRIPHED',
  'GLUCAMIDE',
  'POLOCAINE',
  'QUIBRON-T',
  'TRIACIN-C',
  'PHENYTEX',
  'HY-PAM ',
  'PAPA-DEINE #4',
  'SULFANILAMIDE',
  'BROMANATE DM',
  'PHERAZINE W/ CODEINE',
  'Q-GESIC',
  'ACCURBRON',
  'MEXATE-AQ',
  'PROMETH VC PLAIN',
  'PROMETH W/ DEXTROMETHORPHAN',
  'PROMETH VC W/ CODEINE',
  'LIDOCAINE VISCOUS',
  'CARISOPRODOL COMPOUND',
  'PREDNISONE INTENSOL',
  'BROMFED-DM',
  'PROMETHAZINE W/ CODEINE',
  'PROMETHAZINE VC PLAIN',
  'PROMETHAZINE VC W/ CODEINE',
  'VAGILIA',
  'PHRENILIN FORTE',
  'TRIPROLIDINE HYDROCHLORIDE, PSEUDOEPHEDRINE HYDROCHLORIDE AND CODEINE PHOSPHATE',
  'PREDSULFAIR II',
  'ALLERFED',
  'PROMETHAZINE W/ DEXTROMETHORPHAN',
  'PHERAZINE VC',
  'PHERAZINE VC W/ CODEINE',
  'NORCET',
  'ENLON',
  'BANCAP',
  'ACETAMINOPHEN AND HYDROCODONE BITARTRATE',
  'POTASSIUM CHLORIDE IN PLASTIC CONTAINER',
  'RESERPINE AND HYDROFLUMETHIAZIDE',
  'PHERAZINE DM',
  'THIORIDAZINE HYDROCHLORIDE INTENSOL',
  'SEDAPAP',
  'HYDRA-ZIDE',
  'LOGEN',
  'BUCET',
  'VINBLASTINE SULFATE',
  'PROMETHAZINE',
  'TRIPROLIDINE AND PSEUDOEPHEDRINE HYDROCHLORIDES W/ CODEINE',
  'TRIAD',
  'STIE-CORT',
  'PRELONE',
  'FEMCET',
  'MEDIGESIC PLUS',
  'BROMPHERIL',
  'PHENTERMINE RESIN ',
  'MEPROBAMATE AND ASPIRIN',
  'MEPRO-ASPIRIN',
  'RESPORAL',
  'AEROLATE',
  'ABITREXATE',
  'SYNALGOS-DC-A',
  'BELDIN',
  'ISOETHARINE HYDROCHLORIDE S/F',
  'PROCAINAMIDE HCL',
  'TRIAPRIN',
  'RENOCAL',
  'TYCOLET',
  'TENCON',
  'POLOCAINE-MPF',
  'AZDONE',
  'HYDROCORTISONE ACETATE 1% AND PRAMOXINE HYDROCHLORIDE 1%',
  'METOCURINE IODIDE',
  'ALUMINUM HYDROXIDE AND MAGNESIUM TRISILICATE',
  'U-CORT',
  'BETA-HC',
  'POLOCAINE W/ LEVONORDEFRIN',
  'ORTHO-EST',
  'HYDROCORTISONE SODIUM PHOSPHATE',
  'TRIANEX',
  'REVERSOL',
  'ESGIC',
  'ANEXSIA ',
  'VICODIN ES',
  'MINITRAN',
  'ROXICET ',
  'FLUPHENAZINE HCL',
  'UNI-DUR',
  'MEXATE-AQ PRESERVED',
  'TRIFLUOPERAZINE HCL',
  'METHADONE HYDROCHLORIDE INTENSOL',
  'ALLAY',
  'BUTAPAP',
  'AMLODIPINE BESYLATE AND VALSARTAN',
  'PEG-3350, POTASSIUM CHLORIDE, SODIUM BICARBONATE, SODIUM CHLORIDE',
  'RIFABUTIN',
  'MILRINONE LACTATE IN PLASTIC CONTAINER',
  'DOXERCALCIFEROL',
  'EMTRICITABINE; TENOFOVIR DISOPROXIL FUMARATE',
  'DUTASTERIDE',
  'SYEDA',
  'CARVEDILOL PHOSPHATE',
  'TADALAFIL',
  'CHILDREN\'S CETIRIZINE HYDROCHLORIDE HIVES RELIEF',
  'PEG-3350, SODIUM SULFATE, SODIUM CHLORIDE, POTASSIUM CHLORIDE, SODIUM ASCORBATE AND ASCORBIC ACID',
  'PROMETHAZINE HYDROCHLORIDE; CODEINE PHOSPHATE',
  'PEG 3350 AND ELECTROLYTES',
  'BIVALIRUDIN',
  'POLYETHYLENE GLYCOL 3350; SODIUM CHLORIDE; SODIUM BICARBONATE; POTASSIUM CHLORIDE; BISACODYL',
  'LEVOCETIRIZINE DIHYDROCHLORIDE',
  'GLATOPA',
  'IBUTILIDE FUMARATE',
  'MELPHALAN HYDROCHLORIDE',
  'GILDESS 24 FE',
  'DACTINOMYCIN',
  'LEVOFLOXACIN; DEXTROSE',
  'ABACAVIR SULFATE, LAMIVUDINE AND ZIDOVUDINE',
  'MYCOPHENOLATE MOFETIL HYDROCHLORIDE',
  'MIDOZALAM HYDROCHLORIDE',
  'PEMETREXED',
  'MEFENAMIC ACID',
  'ZAFIRLUKAST',
  'SCANLUX',
  'IBUPROFEN AND DIPHENHYDRAMINE HYDROCHLORIDE',
  'AMLODIPINE AND OLMESARTAN MEDOXOMIL',
  'PIOGLITAZONE HYDROCHLORIDE AND METFORMIN HYDROCHLORIDE',
  'EFAVIRENZ; EMTRICITABINE; TENOFOVIR DISOPROXIL FUMARATE',
  'IMIPRAMINE PAMOATE',
  'BIMATOPROST',
  'MOLINDONE HYDROCHLORIDE',
  'HEATHER',
  'GEMIFLOXACIN MESYLATE',
  'ZYFREL',
  'CINACALCET HYDROCHLORIDE',
  'NORGESTIMATE; ETHINYL ESTRADIOL',
  'EMTRICITABINE AND TENOFOVIR DISOPROXIL FUMARATE',
  'MONO-LINYAH',
  'TRI-LINYAH',
  'OXYBUTYNIN',
  'BRIELLYN',
  'TRI-LO-LINYAH',
  'VORICONAZOLE',
  'IMIPENEM AND CILASTATIN',
  'EPINEPHRINE (AUTOINJECTOR)',
  'NEDOCROMIL SODIUM',
  'DULOXETINE HYDROCHLORIDE',
  'CEFTRIAXONE SODIUM',
  'CANDESARTAN CILEXETIL AND HYDROCHLOROTHIAZIDE',
  'POLYETHYLENE GLYCOL 3350 AND ELECTROLYTES',
  'PALONOSETRON HYDROCHLORIDE',
  'SETLAKIN',
  'LEVONEST',
  'FALMINA',
  'BETAMETHASONE ACETATE AND BETAMETHASONE SODIUM PHOSPHATE',
  'SUMATRIPTAN; NAPROXEN SODIUM',
  'CLENZ-LYTE',
  'TRI-ESTARYLLA',
  'ESTARYLLA',
  'IRBESARTAN; HYDROCHLOROTHIAZIDE',
  'ADAPALENE',
  'PARICALCITOL',
  'EPTIFIBATIDE',
  'ZOLMITRIPTAN',
  'EPINASTINE HYDROCHLORIDE',
  'SUMATRIPTAN AND NAPROXEN SODIUM',
  'ISOSULFAN BLUE',
  'CHLOROTHIAZIDE SODIUM',
  'CLINDAMYCIN PALMITATE HYDROCHLORIDE',
  'SODIUM PHENYLBUTYRATE',
  'FERROUS FUMARATE',
  'MEROPENEM',
  'CAPECITABINE',
  'DASETTA ',
  'PHILITH',
  'OMEGA-3-ACID ETHYL ESTERS',
  'SEVELAMER CARBONATE',
  'LANTHANUM CARBONATE',
  'OCTREOTIDE ACETATE PRESERVATIVE FREE',
  'APREPITANT',
  'ERLOTINIB HYDROCHLORIDE',
  'CHENODIOL',
  'ESZOPICLONE',
  'PREGABALIN',
  'DAPTOMYCIN',
  'GUAIFENESIN AND DEXTROMETHORPHAN HYDROBROMIDE',
  'GUAIFENESIN AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'TELMISARTAN; HYDROCHLOROTHIAZIDE',
  'BRIMONIDINE TARTRATE; TIMOLOL MALEATE',
  'BRIMONIDINE TARTRATE AND TIMOLOL MALEATE',
  'ELINEST',
  'AMINO ACIDS',
  'DEXTROMETHORPHAN POLISTIREX',
  'FORMOTEROL FUMARATE',
  'BUPRENORPHINE HYDROCHLORIDE AND NALOXONE HYDROCHLORIDE',
  'MIFEPRISTONE',
  'DARIFENACIN HYDROBROMIDE',
  'WERA',
  'NORETHINDRONE',
  'ATOVAQUONE AND PROGUANIL HYDROCHLORIDE',
  'EFAVIRENZ, EMTRICITABINE, AND TENOFOVIR DISOPROXIL FUMARATE',
  'TRI-LO-ESTARYLLA',
  'PIMTREA',
  'MYCOPHENOLIC SODIUM',
  'CEVIMELINE HYDROCHLORIDE',
  'TROSPIUM CHLORIDE',
  'FONDAPARINUX SODIUM',
  'JENCYCLA',
  'TRIMETHOBENZAMIDE HYDROCHLORIDE PRESERVATIVE FREE',
  'TRAVOPROST',
  'VIORELE',
  'VARDENAFIL HYDROCHLORIDE',
  'TELMISARTAN AND HYDROCHLOROTHIAZIDE',
  'BLISOVI 24 FE',
  'KURVELO',
  'MARLISSA',
  'LARIN FE ',
  'ACITRETIN',
  'SOLIFENACIN SUCCINATE',
  'DEXMEDETOMIDINE HYDROCHLORIDE',
  'DAYSEE',
  'VANCOMYCIN HYDROCHLORIDE IN PLASTIC CONTAINER',
  'TRANEXAMIC ACID',
  'COLESEVELAM HYDROCHLORIDE',
  'RAMELTEON',
  'TIGECYCLINE',
  'HYDROCODONE POLISTIREX AND CHLORPHENIRAMINE POLISTIREX',
  'ALYACEN ',
  'ATAZANAVIR',
  'GLATIRAMER ACETATE',
  'VALSARTAN; HYDROCHLOROTHIAZIDE',
  'QUININE SULFATE',
  'HYDROCODONE POLISTIREX AND CHLORPHENIRAMNE POLISTIREX',
  'LOPINAVIR AND RITONAVIR',
  'DISULFIRAM ',
  'PROMETHAZINE HYDROCHLORIDE; DEXTROMETHORPHAN HYDROBROMIDE',
  'ETHINYL ESTRADIOL AND LEVONORGESTREL',
  'DOLISHALE',
  'ELSPAR',
  'SANTYL',
  'BOTOX',
  'BOTOX COSMETIC',
  'INTRON A',
  'ALFERON N INJECTION',
  'ACTIVASE',
  'CATHFLO ACTIVASE',
  'EPOGEN/PROCRIT',
  'PROLEUKIN',
  'NEUPOGEN',
  'LEUKINE',
  'ONCASPAR',
  'BETASERON',
  'PULMOZYME',
  'REOPRO',
  'PROSTASCINT',
  'AVONEX',
  'MYOSCINT',
  'REGRANEX',
  'RITUXAN',
  'SIMULECT',
  'ONTAK',
  'SYNAGIS',
  'REMICADE',
  'REBIF',
  'RETAVASE',
  'HERCEPTIN',
  'ENBREL',
  'ACTIMMUNE',
  'MYOBLOC',
  'TNKASE',
  'ELITEK',
  'CAMPATH',
  'LEMTRADA',
  'PEGINTRON',
  'SYLATRON',
  'KINERET',
  'ARANESP',
  'PEGASYS',
  'XOLAIR',
  'FABRAZYME',
  'ZEVALIN',
  'NEULASTA',
  'HUMIRA',
  'ALDURAZYME',
  'ERBITUX',
  'AVASTIN',
  'KEPIVANCE',
  'TYSABRI',
  'NAGLAZYME',
  'ORENCIA',
  'MYOZYME',
  'VECTIBIX',
  'ELAPRASE',
  'LUCENTIS',
  'CIMZIA',
  'MIRCERA',
  'SOLIRIS',
  'ARCALYST',
  'STELARA',
  'NPLATE',
  'DYSPORT',
  'ACTEMRA',
  'KALBITOR',
  'NULOJIX',
  'SIMPONI',
  'EXTAVIA',
  'LUMIZYME',
  'KRYSTEXXA',
  'GRANIX',
  'ILARIS',
  'PROLIA',
  'XGEVA',
  'ARZERRA',
  'KESIMPTA',
  'VORAXAZE',
  'XIAFLEX',
  'RAXIBACUMAB',
  'Xeomin',
  'BENLYSTA',
  'YERVOY',
  'EYLEA',
  'ADCETRIS',
  'MYALEPT',
  'PERJETA',
  'ZALTRAP',
  'JETREA',
  'KADCYLA',
  'TANZEUM',
  'SIMPONI ARIA',
  'VIMIZIM',
  'TRULICITY',
  'ENTYVIO',
  'CYRAMZA',
  'GAZYVA',
  'SYLVANT',
  'PLEGRIDY',
  'COSENTYX',
  'ANTHIM',
  'NATPARA',
  'STRENSIQ',
  'KEYTRUDA',
  'UNITUXIN',
  'TALTZ',
  'REPATHA',
  'NUCALA',
  'OPDIVO',
  'INFLECTRA',
  'RETACRIT',
  'PORTRAZZA',
  'ZARXIO',
  'BLINCYTO',
  'PRALUENT',
  'KANUMA',
  'ARMODAFINIL',
  'AMTURNIDE',
  'CONTRAVE',
  'EZETIMIBE AND SIMVASTATIN',
  'ACAMPROSATE CALCIUM',
  'SELFEMRA',
  'LIPTRUZET',
  'DICLOFENAC SODIUM AND MISOPROSTOL',
  'CISATRACURIUM BESYLATE',
  'EVEKEO',
  'CALCIPOTRIENE AND BETAMETHASONE DIPROPIONATE',
  'TRIBENZOR',
  'STAXYN',
  'PHENELZINE SULFATE',
  'TOPOTECAN',
  'FENOFIBRIC ACID',
  'ARFORMOTEROL TARTRATE',
  'TEFLARO',
  'AMLODIPINE BESYLATE, VALSARTAN AND HYDROCHLOROTHIAZIDE',
  'AMLODIPINE BESYLATE AND ATORVASTATIN CALCIUM',
  'DYCLOPRO',
  'NUCYNTA ER',
  'LATUDA',
  'LAMIVUDINE; TENOFOVIR DISOPROXIL FUMARATE',
  'REPAGLINIDE AND METFORMIN HYDROCHLORIDE',
  'ALOSETRON HYDROCHLORIDE',
  'FLUORODOPA F18',
  'PERIKABIVEN IN PLASTIC CONTAINER',
  'KABIVEN IN PLASTIC CONTAINER',
  'DURLAZA',
  'SIGNIFOR',
  'KOMBIGLYZE XR',
  'CYSTARAN',
  'VALGANCICLOVIR HYDROCHLORIDE',
  'LAMIVUDINE/NEVIRAPINE/ZIDOVUDINE TABLETS',
  'EDARBI',
  'CASPOFUNGIN ACETATE',
  'BUPROPION HYDROBROMIDE',
  'ESOMEPRAZOLE SODIUM',
  'CANDESARTAN CILEXETIL; HYDROCHLOROTHIAZIDE',
  'ISOPTO CARPINE',
  'XULANE',
  'GYNAZOLE',
  'DICLOFENAC SODIUM ',
  'CEFOXITIN IN PLASTIC CONTAINER',
  'PROMETHAZINE HYDROCHLORIDE,PHENYLEPHRINE HYDROCHLORIDE W/CODEINE PHOSPHATE',
  'JEVTANA KIT',
  'RANOLAZINE',
  'PIOGLITAZONE HYDROCHLORIDE AND GLIMEPIRIDE',
  'PHENOXYBENZAMINE HYDROCHLORIDE',
  'SEVELAMER HYDROCHLORIDE',
  'VIENVA',
  'GLYDO',
  'MILPROSA',
  'VIRAMUNE XR',
  'TINIDAZOLE',
  'BROMFENAC SODIUM',
  'SUNITINIB MALATE',
  'CIDOFOVIR',
  'GADAVIST',
  'TRADJENTA',
  'JENTADUETO',
  'GILOTRIF',
  'NITHIODOTE',
  'FALLBACK SOLO',
  'LENALIDOMIDE',
  'IRBESARTAN HYDROCHLOROTHIAZIDE',
  'PIRMELLA ',
  'MAFENIDE ACETATE',
  'PIRMELLA 1/35',
  'HALAVEN',
  'ELETRIPTAN HYDROBROMIDE',
  'AZACITIDINE',
  'FLUCYTOSINE',
  'SIROLIMUS',
  'BLISOVI FE ',
  'TELMISARTAN AND AMLODIPINE',
  'HYDROCHLOROTHIAZIDE; IRBESARTAN',
  'BUPRENORPHINE HYDROCHLORIDE AND NALOXONE HYDROCHLORIDE ',
  'TROKENDI XR',
  'NOCTIVA',
  'NIKKI',
  'FELBAMATE',
  'TOBI PODHALER',
  'CLINDAMYCIN PHOSPHATE IN 5% DEXTROSE IN PLASTIC CONTAINER',
  'DIFICID',
  'AUVI-Q',
  'ARGATROBAN IN DEXTROSE',
  'LINCOMYCIN',
  'ULTACAN FORTE',
  'ULTACAN',
  'VARENICLINE TARTRATE',
  'EMTRICITABINE AND  TENOFOVIR DISOPROXIL FUMARATE',
  'BETHKIS',
  'RASAGILINE MESYLATE',
  'NORGESTREL AND ETHINYL ESTRADIOL',
  'VYFEMLA',
  'ENSKYCE',
  'INCIVEK',
  'VARENICLINE',
  'XIMINO',
  'ILUVIEN',
  'AMYVID',
  'EPROSARTAN MESYLATE',
  'YAELA',
  'MELAMISA',
  'RAYOS',
  'EDURANT',
  'BRONCHITOL',
  'ADEFOVIR DIPIVOXIL',
  'VASCEPA',
  'ONFI',
  'DARUNAVIR',
  'OXAYDO',
  'SUPRENZA',
  'SODIUM OXYBATE',
  'ZENATANE',
  'QUILLIVANT XR',
  'DASATINIB',
  'MEROPENEM AND SODIUM CHLORIDE IN DUPLEX CONTAINER',
  'KORLYM',
  'PEMETREXED DISODIUM',
  'ENTECAVIR',
  'COMPLERA',
  'ZETONNA',
  'KEMEYA',
  'RUBY-FILL',
  'ELIQUIS',
  'RADIOGENIX SYSTEM',
  'LAMIVUDINE; TENOFOVIR DISOPROXIL FUMARATE; NEVIRAPINE',
  'ROSUVASTATIN ZINC',
  'JAKAFI',
  'LYMPHOSEEK KIT',
  'OXYTROL FOR WOMEN',
  'PEG-3350, SODIUM CHLORIDE, SODIUM BICARBONATE, POTASSIUM CHLORIDE AND BISACODYL',
  'BEKYREE',
  'DYMISTA',
  'VICTRELIS',
  'BUSULFAN',
  'JANUMET XR',
  'STENDRA',
  'ZECUITY',
  'MYTESI',
  'FARXIGA',
  'DEXLANSOPRAZOLE',
  'VALCHLOR',
  'KYRA',
  'INLYTA',
  'SITAGLIPTIN PHOSPHATE',
  'EDARBYCLOR',
  'ESOMEPRAZOLE STRONTIUM',
  'JUVISYNC',
  'BINOSTO',
  'ESTRADIOL VALERATE AND DIENOGEST',
  'SITAGLIPTIN PHOSPHATE; METFORMIN HYDROCHLORIDE',
  'ZYTIGA',
  'SITAGLIPTIN; METFORMIN HYDROCHLORIDE',
  'IBUPROFEN LYSINE',
  'AVACLYR',
  'FABIOR',
  'ZELBORAF',
  'TUDORZA PRESSAIR',
  'NAPROXEN AND ESOMEPRAZOLE MAGNESIUM',
  'OLMESARTAN MEDOXOMIL, AMLODIPINE AND HYDROCHLOROTHIAZIDE',
  'MARQIBO KIT',
  'ELIFEMME',
  'DUTASTERIDE AND TAMSULOSIN HYDROCHLORIDE',
  'SODIUM SULFATE, POTASSIUM SULFATE AND MAGNESIUM SULFATE ',
  'GELNIQUE 3%',
  'ZIOPTAN',
  'POLMON',
  'PREPOPIK',
  'LEVETIRACETAM IN SODIUM CHLORIDE',
  'GRISEOFULVIN,ULTRAMICROSIZE',
  'CLINDAMYCIN PHOSPHATE AND TRETINOIN',
  'XALKORI',
  'RITONAVIR',
  'BISMUTH SUBSALICYLATE, METRONIDAZOLE AND TETRACYCLINE HYDROCHLORIDE',
  'OSELTAMIVIR PHOSPHATE',
  'MYRBETRIQ',
  'CAPREOMYCIN SULFATE',
  'OPCICON ONE-STEP',
  'PALIPERIDONE',
  'COSOPT PF',
  'VOLNEA',
  'KYPROLIS',
  'SKLICE',
  'SUBSYS',
  'ISIBLOOM',
  'OMONTYS PRESERVATIVE FREE',
  'OMONTYS',
  'LISDEXAMFETAMINE DIMESYLATE',
  'GRISEOFULVIN, ULTRAMICROSIZE',
  'TAFINLAR',
  'OXTELLAR XR',
  'LINZESS',
  'QNASL',
  'CERINTA',
  'PICATO',
  'FYCOMPA',
  'GENOSYL',
  'ZOHYDRO ER',
  'FROVATRIPTAN SUCCINATE',
  'DEXTROMETHORPHAN HYDROBROMIDE AND QUINIDINE SULFATE',
  'BROMPHENIRAMINE MALEATE, PSEUDOEPHEDRINE HYDROCHLORIDE AND DEXTROMETHORPHAN HYDROBROMIDE',
  'ATOVAQUONE',
  'CHABELINA FE',
  'DECITABINE',
  'ABILIFY MAINTENA KIT',
  'CLONIDINE HYDROCHLORIDE ',
  'SYLEVIA',
  'AUBAGIO',
  'LARIN ',
  'ESTRADIOL VALERATE; ESTRADIOL VALERATE; DIENOGEST',
  'LAPATINIB DITOSYLATE',
  'STIVARGA',
  'VITEKTA',
  'TYBOST',
  'STRIBILD',
  'MAGNESIUM SULFATE ANHYDROUS; POTASSIUM SULFATE; SODIUM SULFATE',
  'STRIVERDI RESPIMAT',
  'LO-BLISOVI FE',
  'VIZAMYL',
  'TEMSIROLIMUS',
  'CHOLINE C-11',
  'SKYLA',
  'ASHLYNA',
  'PROLENSA',
  'BEXAROTENE',
  'KALYDECO',
  'IBUPROFEN AND PHENYLEPHRINE HYDROCHLORIDE',
  'NORTHERA',
  'XELJANZ',
  'SODIUM FLUORIDE F-18',
  'SIGNIFOR LAR KIT',
  'CARBIDOPA',
  'DOXORUBICIN HYDROCHLORIDE (LIPOSOMAL)',
  'RAVICTI',
  'RYTARY',
  'RYZODEG 70/30',
  'TRESIBA',
  'PHOTREXA',
  'PHOTREXA VISCOUS IN DEXTRAN 20%',
  'AMABELZ',
  'NYMALIZE',
  'BOSULIF',
  'MARAVIROC',
  'ERIVEDGE',
  'PROCYSBI',
  'KAZANO',
  'XTANDI',
  'FLUTICASONE PROPIONATE AND SALMETEROL XINAFOATE',
  'LERIBANE',
  'GATTEX KIT',
  'KAITLIB FE',
  'ICLUSIG',
  'CALCIUM CHLORIDE 10%',
  'VERSACLOZ',
  'ALLZITAL',
  'ILEVRO',
  'LOMAIRA',
  'ORENITRAM',
  'OSPHENA',
  'PHENYLEPHRINE HYDROCHLORIDE',
  'RALTEGRAVIR',
  'DEFERASIROX',
  'LEVOLEUCOVORIN CALCIUM',
  'INJECTAFER',
  'JUBLIA',
  'KYNAMRO',
  'OXYCODONE HYDROCHLORIDE AND ACETAMINOPHEN',
  'SYNRIBO',
  'DROSPIRENONE, ETHINYL ESTRADIOL AND LEVOMEFOLATE CALCIUM',
  'SUCLEAR',
  'NEOSTIGMINE METHYLSULFATE',
  'UCERIS',
  'TREPROSTINIL',
  'BORTEZOMIB',
  'NEBIVOLOL HYDROCHLORIDE',
  'MINASTRIN 24 FE',
  'LUMASON',
  'LUPANETA PACK',
  'VAZALORE',
  'MINIVELLE',
  'COMETRIQ',
  'JAIMIESS',
  'ADAPALENE AND BENZOYL PEROXIDE',
  'SITAVIG',
  'HYDROCODONE BITARTRATE, CHLORPHENIRAMINE MALEATE AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'HYDROCODONE BITARTRATE AND PSEUDOEPHEDRINE HYDROCHLORIDE',
  'PAROXETINE',
  'JUXTAPID',
  'SODIUM NITRITE',
  'PRIMAQUINE PHOSPHATE',
  'FOSAPREPITANT DIMEGLUMINE',
  'DUOPA',
  'MIGLITOL',
  'XOFIGO',
  'ANORO ELLIPTA',
  'COLCHICINE',
  'AFINITOR DISPERZ',
  'DESVENLAFAXINE SUCCINATE',
  'DESVENLAFAXINE',
  'TWIRLA',
  'FOSAMPRENAVIR CALCIUM',
  'POMALYST',
  'CLOFARABINE',
  'XARTEMIS XR',
  'INVOKANA',
  'MECAMYLAMINE HYDROCHLORIDE',
  'QUARTETTE',
  'TECFIDERA',
  'BLOXIVERZ',
  'BENDAMUSTINE HYDROCHLORIDE',
  'ASTAGRAF XL',
  'MEKINIST',
  'EFAVIRENZ, LAMIVUDINE, AND TENOFOVIR DISOPROXIL FUMARATE',
  'SODIUM SULFATE; POTASSIUM SULFATE; MAGNESIUM SULFATE',
  'METFORMIN HYDROCHLORIDE AND  SITAGLIPTIN PHOSPHATE',
  'LUZU',
  'IVERMECTIN',
  'FETZIMA',
  'ADRENALIN',
  'ACETAMINOPHEN, CAFFEINE AND DIHYDROCODEINE BITARTRATE',
  'FYAVOLV',
  'ZUBSOLV',
  'SIMBRINZA',
  'BREO ELLIPTA',
  'VAZCULEP',
  'ETHINYL ESTRADIOL; ETONOGESTREL',
  'VITUZ',
  'EPANED KIT',
  'ABACAVIR AND LAMIVUDINE',
  'ADZENYS ER',
  'ADZENYS XR-ODT',
  'INVOKAMET',
  'VRAYLAR',
  'BUPRENORPHINE HYDROCHLORIDE; NALOXONE HYDROCHLORIDE',
  'SIRTURO',
  'MITIGO',
  'VOGELXO',
  'OPSUMIT',
  'DELZICOL',
  'ELEPSIA XR',
  'TAYTULLA',
  'KERYDIN',
  'JYNARQUE',
  'PROBUPHINE',
  'TRINTELLIX',
  'VASOSTRICT',
  'FESOTERODINE FUMARATE',
  'CLINOLIPID 20%',
  'BRISDELLE',
  'PIMOZIDE',
  'COLPREP KIT',
  'BELSOMRA',
  'TETRABENAZINE',
  'TOLCAPONE',
  'ZORVOLEX',
  'JARDIANCE',
  'PROVAYBLUE',
  'ETHACRYNATE SODIUM',
  'AZELAIC ACID',
  'LO MINASTRIN FE',
  'NEXIUM 24HR',
  'SOVALDI',
  'NEURACEQ',
  'KHEDEZLA',
  'IMPAVIDO',
  'ETHYNODIOL DIACETATE AND ETHINYL ESTRADIOL',
  'MIRVASO',
  'SILODOSIN',
  'ACIPHEX SPRINKLE',
  'METOCLOPRAMIDE',
  'MOVANTIK',
  'TIVORBEX',
  'DOTAREM',
  'TREZIX',
  'LACOSAMIDE',
  'TIVICAY',
  'POSIMIR',
  'ADEMPAS',
  'MITIGARE',
  'IZBA',
  'OTREXUP',
  'OTREXUP PFS',
  'CEFIXIME',
  'SUMATRIPTAN',
  'BRINZOLAMIDE',
  'ZONTIVITY',
  'ZILEUTON',
  'BUPRENORPHINE',
  'KENGREAL',
  'TAPENTADOL',
  'OMTRYG',
  'RUFINAMIDE',
  'PRESTALIA',
  'NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL AND ETHINYL ESTRADIOL AND FERROUS FUMARATE',
  'LUTRATE DEPOT KIT',
  'EPANOVA',
  'MILNACIPRAN HYDROCHLORIDE',
  'ISOTRETINOIN',
  'OXICONAZOLE NITRATE',
  'VARITHENA',
  'YOSPRALA',
  'SOTYLIZE',
  'VELPHORO',
  'QUDEXY XR',
  'OLYSIO',
  'BOSENTAN',
  'ECOZA',
  'BUTENAFINE HYDROCHLORIDE',
  'PLERIXAFOR',
  'NUVESSA',
  'ODOMZO',
  'RILPIVIRINE',
  'TIMOLOL',
  'ALEVE PM',
  'FARYDAK',
  'FEBUXOSTAT',
  'INCRUSE ELLIPTA',
  'ORALTAG',
  'OMIDRIA',
  'PREZCOBIX',
  'HEMANGEOL',
  'REXULTI',
  'EVEROLIMUS',
  'KITABIS PAK',
  'FLONASE ALLERGY RELIEF',
  'SIVEXTRO',
  'OTEZLA',
  'HYDROCHLOROTHIAZIDE; VALSARTAN',
  'TRI-MILI',
  'MILI',
  'OBREDON',
  'HYDROCODONE BITARTRATE AND ASPIRIN',
  'NATESTO',
  'COTEMPLA XR-ODT',
  'CERDELGA',
  'ATAZANAVIR SULFATE; RITONAVIR',
  'SYNDROS',
  'CIPROFLOXACIN AND DEXAMETHASONE',
  'TRIUMEQ',
  'IMBRUVICA',
  'STERITALC',
  'RYANODEX',
  'BELRAPZO',
  'MACRILEN',
  'ETHACRYNIC ACID',
  'ARNUITY ELLIPTA',
  'LAMIVUDINE, NEVIRAPINE AND ZIDOVUDINE',
  'PROAIR RESPICLICK',
  'PROAIR DIGIHALER',
  'BUNAVAIL',
  'ASMANEX HFA',
  'TOLVAPTAN',
  'XIGDUO XR',
  'HETLIOZ',
  'DRONEDARONE HYDROCHLORIDE',
  'BASAGLAR',
  'ESMOLOL HYDROCHLORIDE IN PLASTIC CONTAINER',
  'ESMOLOL HYDROCHLORIDE DOUBLE STRENGTH IN PLASTIC CONTAINER',
  'NITROUS OXIDE, USP',
  'MEDICAL AIR, USP',
  'OXYGEN, USP',
  'AKYNZEO',
  'NITROGEN, NF',
  'VELTASSA',
  'GLYCEROL PHENYLBUTYRATE',
  'CITRIC ACID; MAGNESIUM OXIDE; SODIUM PICOSULFATE',
  'ZOLEDRONIC',
  'CHOLBAM',
  'ZYKADIA',
  'TRI-LO-MILI',
  'CARBON DIOXIDE, USP',
  'RASUVO',
  'TARGINIQ',
  'EVZIO',
  'PRASUGREL',
  'DOXYLAMINE SUCCINATE AND PYRIDOXINE HYDROCHLORIDE',
  'HELIUM, USP',
  'APTENSIO XR',
  'OFEV',
  'MOXIFLOXACIN HYDROCHLORIDE IN SODIUM CHLORIDE 0.8% IN PLASTIC CONTAINER',
  'HARVONI',
  'BRIVIACT',
  'ZYDELIG',
  'EFAVIRENZ; LAMIVUDINE; TENOFOVIR DISOPROXIL FUMARATE',
  'FULVESTRANT',
  'AURYXIA',
  'INVOKAMET XR',
  'SODIUM PHENYLACETATE AND SODIUM BENZOATE',
  'PURIXAN',
  'ACTICLATE',
  'PITAVASTATIN CALCIUM',
  'SAXAGLIPTIN',
  'SAXAGLIPTIN;METFORMIN HYDROCHLORIDE',
  'FAYOSIM',
  'DROSPIRENONE, ETHINYL ESTRADIOL AND LEVOMEFOLATE CALCIUM ',
  'TYDEMY',
  'ASENAPINE MALEATE',
  'NAFTIFINE HYDROCHLORIDE',
  'CARNEXIV',
  'ORKAMBI',
  'ATAZANAVIR SULFATE;RITONAVIR;LAMIVUDINE;ZIDOVUDINE',
  'EMTRICITABINE;TENOFOVIR DISOPROXIL FUMARATE;NEVIRAPINE',
  'BEPOTASTINE BESILATE',
  'GLYXAMBI',
  'EZETIMIBE AND ATORVASTATIN CALCIUM',
  'JATENZO',
  'ONZETRA XSAIL',
  'SYNJARDY',
  'CORLANOR',
  'LYNPARZA',
  'PRALATREXATE',
  'XELPROS',
  'COTELLIC',
  'ETHINYL ESTRADIOL; LEVONORGESTREL',
  'REMIFENTANIL HYDROCHLORIDE',
  'LILETTA',
  'ROMIDEPSIN',
  'SOOLANTRA',
  'BELEODAQ',
  'MIBELAS 24 FE',
  'BYVALSON',
  'DEXTROAMPHETAMINE SACCHARATE; AMPHETAMINE ASPARTATE MONOHYDRATE; DEXTROAMPHETAMINE SULFATE; AMPHETAMINE SULFATE',
  'XTORO',
  'SAVAYSA',
  'TRIFERIC',
  'SAXENDA',
  'TUXARIN ER',
  'KYBELLA',
  'ORBACTIV',
  'EVOTAZ',
  'CLOCORTOLONE PIVALATE',
  'ENVARSUS XR',
  'RAPIVAB',
  'VALRUBICIN',
  'HYDROCODONE BITARTRATE AND CHLORPHENIRAMINE MALEATE',
  'NAMZARIC',
  'LINEZOLID IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER',
  'EXONDYS 51',
  'TECHNETIUM TC99M MERTIATIDE KIT',
  'AVYCAZ',
  'METHYLPHENIDATE',
  'VARUBI',
  'DUTREBIS',
  'TOUJEO SOLOSTAR',
  'TOUJEO MAX SOLOSTAR',
  'MORPHABOND ER',
  'IBUPROFEN SODIUM',
  'HAILEY FE ',
  'FERUMOXYTOL',
  'VIEKIRA PAK (COPACKAGED)',
  'HYSINGLA ER',
  'DALFAMPRIDINE',
  'DRONEDARONE',
  'TAPENTADOL HYDROCHLORIDE',
  'ALISKIREN HEMIFUMARATE',
  'FLOLIPID',
  'DIACOMIT',
  'STIOLTO RESPIMAT',
  'ARGATROBAN IN 0.9% SODIUM CHLORIDE',
  'ELTROMBOPAG',
  'ZERBAXA',
  'DAKLINZA',
  'ICLEVIA',
  'SIMPESSE',
  'LO SIMPESSE',
  'SIMLIYA',
  'AYUNA',
  'ATHENTIA NEXT',
  'AFIRMELLE',
  'TIROFIBAN HYDROCHLORIDE',
  'JADENU',
  'BROMSITE',
  'VIBERZI',
  'LENVIMA',
  'HYDROCODONE BITARTRATE',
  'XEGLYZE',
  'LICART',
  'TIROSINT-SOL',
  'LUBIPROSTONE',
  'SORAFENIB TOSYLATE',
  'PROMACTA KIT',
  'NYLIA 7/7/7',
  'CYONANZ',
  'NYLIA 1/35',
  'DOFETILIDE',
  'LOKELMA',
  'KALLIGA',
  'ILOPERIDONE',
  'IBRANCE',
  'PYRIMETHAMINE',
  'CEFAZOLIN IN PLASTIC CONTAINER',
  'PAROXETINE MESYLATE',
  'NOXIVENT',
  'XADAGO',
  'EVOMELA',
  'SAPROPTERIN DIHYDROCHLORIDE',
  'ABILIFY MYCITE KIT',
  'LEUCOVORIN CALCIUM ',
  'VIVLODEX',
  'DARIFENACIN',
  'INCASSIA',
  'NUPLAZID',
  'PACITAXEL',
  'MICAFUNGIN SODIUM',
  'ARIKAYCE KIT',
  'ABACAVIR; LAMIVUDINE',
  'DICLOFENAC',
  'TAMSULOSIN HYDROCHLORIDE ',
  'CRESEMBA',
  'AUROVELA ',
  'ARISTADA',
  'SYMJEPI',
  'NEXESTA FE',
  'GENVOYA',
  'TRIENTINE HYDROCHLORIDE',
  'VERARING',
  'AUROVELA FE ',
  'TESTOSTERONE UNDECANOATE',
  'ENSTILAR',
  'NAPROXEN SODIUM AND DIPHENHYDRAMINE HYDROCHLORIDE',
  'REGADENOSON',
  'LOTEPREDNOL ETABONATE',
  'ENTRESTO',
  'TROXYCA ER',
  'NILUTAMIDE',
  'ERTAPENEM SODIUM',
  'SMOFLIPID 20%',
  'CABAZITAXEL',
  'EUCRISA',
  'AZELASTINE HYDROCHLORIDE AND FLUTICASONE PROPIONATE',
  'TUZISTRA XR',
  'ONIVYDE',
  'VYZULTA',
  'MELPHALAN',
  'CETYLEV',
  'EPIDUO FORTE',
  'QVAR REDIHALER',
  'SEEBRI',
  'OLUMIANT',
  'UTIBRON',
  'TECHNIVIE',
  'BELBUCA',
  'FINGOLIMOD HYDROCHLORIDE',
  'FINGOLIMOD',
  'INVEGA TRINZA',
  'INVEGA HAFYERA',
  'UPTRAVI',
  'LURASIDONE HYDROCHLORIDE',
  'LOGILIA',
  'YONDELIS',
  'SPRITAM',
  'QUILLICHEW ER',
  'DABIGATRAN ETEXILATE MESYLATE',
  'ZTLIDO',
  'READYPREP CHG',
  'JADENU SPRINKLE',
  'CABAZITAXEL INJECTION',
  'VANTRELA ER',
  'HER STYLE',
  'LONSURF',
  'OTIPRIO',
  'ABLYSINOL',
  'ZURAMPIC',
  'RYDAPT',
  'OCALIVA',
  'RAYALDEE',
  'JENTADUETO XR',
  'KOVANAZE',
  'E-Z-HD',
  'E-Z-PAQUE',
  'E-Z-CAT DRY',
  'VARIBAR THIN LIQUID',
  'METHYLNALTREXONE BROMIDE',
  'CASSIPA',
  'NERLYNX',
  'AXUMIN',
  'DEXILANT SOLUTAB',
  'TAGRISSO',
  'XIIDRA',
  'CEFTAROLINE FOSAMIL',
  'FIRDAPSE',
  'SERNIVO',
  'AMELUZ',
  'AUSTEDO',
  'CLINDAMYCIN PHOSPHATE IN 0.9% SODIUM CHLORIDE',
  'XTRELUS',
  'TLANDO',
  'XTAMPZA ER',
  'ALBENDAZOLE',
  'NORETHINDRONE ACETATE; ETHINYL ESTRADIOL; ETHINYL ESTRADIOL; FERROUS FUMARATE',
  'DEFITELIO',
  'TETRACAINE HYDROCHLORIDE',
  'READI-CAT 2',
  'READI-CAT 2 SMOOTHIE',
  'LIQUID E-Z-PAQUE',
  'VARIBAR NECTAR',
  'TAGITOL V',
  'VARIBAR THIN HONEY',
  'VARIBAR HONEY',
  'ENTERO VU 24%',
  'LUMIFY',
  'DYANAVEL XR',
  'ISOPTO ATROPINE',
  'MYXREDLIN',
  'VISTOGARD',
  'XURIDEN',
  'MONOFERRIC',
  'ROFLUMILAST',
  'OZOBAX',
  'BENDEKA',
  'VILAZODONE HYDROCHLORIDE',
  'DESCOVY',
  'VIGABATRIN',
  'LOTEMAX SM',
  'ZEMBRACE SYMTOUCH',
  'KYLEENA',
  'MYCAPSSA',
  'DESFLURANE',
  'XELJANZ XR',
  'OTOVEL',
  'AMBRISENTAN',
  'ACTICLATE CAP',
  'RHOPRESSA',
  'SYMFI LO',
  'TAZAROTENE',
  'ROCKLATAN',
  'ZEPATIER',
  'TEPADINA',
  'ZENAVOD',
  'SOLUPREP',
  'AKOVAZ',
  'BEVESPI AEROSPHERE',
  'ALVIMOPAN',
  'PEMETREXED FOR INJECTION',
  'INFUGEM',
  'ICATIBANT ACETATE',
  'PARSABIV',
  'ABIRATERONE ACETATE',
  'MEMANTINE HYDROCHLORIDE AND DONEPEZIL HYDROCHLORIDE',
  'LINAGLIPTIN',
  'LINAGLIPTIN AND METFORMIN HYDROCHLORIDE',
  'EPCLUSA',
  'MIGLUSTAT',
  'ODEFSEY',
  'PHEXXI',
  'DOLUTEGRAVIR SODIUM',
  'BIVALIRUDIN IN 0.9% SODIUM CHLORIDE',
  'ZYPITAMAG',
  'BEVYXXA',
  'TICAGRELOR',
  'XATMEP',
  'QBRELIS',
  'CALCIUM GLUCONATE',
  'CALCIUM GLUCONATE IN SODIUM CHLORIDE',
  'GONITRO',
  'LINAGLIPTIN; METFORMIN HYDROCHLORIDE',
  'ALECENSA',
  'LONHALA MAGNAIR KIT',
  'ZEJULA',
  'EMTRICITABINE;RILPIVIRINE;TENOFOVIR DISOPROXIL FUMARATE',
  'NINLARO',
  'VEMLIDY',
  'INTRAROSA',
  'ADLYXIN',
  'METFORMIN HYDROCHLORIDE AND SITAGLIPTIN PHOSPHATE',
  'NETSPOT',
  'RIVAROXABAN',
  'RHOFADE',
  'IMVEXXY',
  'VENCLEXTA',
  'ALTAFLUOR BENOX',
  'XULTOPHY ',
  'ENDARI',
  'ARYMO ER',
  'EPHEDRINE SULFATE',
  'BAXDELA',
  'BALCOLTRA',
  'GALAFOLD',
  'VIEKIRA XR',
  'TPOXX',
  'GLEOLAN',
  'EZALLOR SPRINKLE',
  'APADAZ',
  'SYNJARDY XR',
  'LO LARIN FE',
  'DONEPEZIL HYDROCHLORIDE AND MEMANTINE HYDROCHLORIDE',
  'SOLIQUA 100/33',
  'EMFLAZA',
  'EPANED',
  'CABOMETYX',
  'ZERVIATE',
  'LUTATHERA',
  'EGATEN',
  'VONJO',
  'VERZENIO',
  'DEXTENZA',
  'TYMLOS',
  'TRULANCE',
  'FIASP',
  'FIASP FLEXTOUCH',
  'FIASP PENFILL',
  'DOCOSANOL',
  'BENZOYL PEROXIDE AND CLINDAMYCIN PHOSPHATE',
  'ALUNBRIG',
  'POSACONAZOLE',
  'CLOBAZAM',
  'CLOROTEKAL',
  'XERMELO',
  'ARMONAIR RESPICLICK',
  'ARMONAIR DIGIHALER',
  'AIRDUO RESPICLICK',
  'AIRDUO DIGIHALER',
  'DEFERIPRONE',
  'TOREMIFENE CITRATE',
  'PRAZIQUANTEL',
  'SIKLOS',
  'VARIBAR PUDDING',
  'ZILRETTA',
  'SYMPROIC',
  'DRAX EXAMETAZIME',
  'WIXELA INHUB',
  'TOLSURA',
  'FIRVANQ KIT',
  'DEXYCU KIT',
  'CORPHEDRA',
  'GOCOVRI',
  'XEPI',
  'TRIPTODUR KIT',
  'INGENOL MEBUTATE',
  'XHANCE',
  'TAFLUPROST',
  'TIGLUTIK KIT',
  'XYZAL ALLERGY 24HR',
  'QTERN',
  'KISQALI',
  'ASCOR',
  'RUBRACA',
  'DSUVIA',
  'PREXXARTAN',
  'RADICAVA',
  'INBRIJA',
  'VOSEVI',
  'ADMELOG',
  'ADMELOG SOLOSTAR',
  'DUZALLO',
  'BYDUREON BCISE',
  'LUCEMYRA',
  'INGREZZA',
  'XYROSA',
  'MINOLIRA',
  'CARMUSTINE',
  'CINVANTI',
  'HAILEY 1.5/30',
  'TAVALISSE',
  'ESKATA',
  'SINUVA',
  'JORNAY PM',
  'RUZURGI',
  'CARFILZOMIB',
  'PIMECROLIMUS',
  'GANZYK-RTU',
  'ALTRENO',
  'DUOBRII',
  'BRYHALI',
  'GIAPREZA',
  'SOLOSEC',
  'TRALEMENT',
  'MULTRYS',
  'SELENIOUS ACID',
  'PLENVU',
  'GIMOTI',
  'MAVYRET',
  'VYXEOS',
  'TYBLUME',
  'ZUMANDIMINE',
  'CEFEPIME HYDROCHLORIDE IN PLASTIC CONTAINER',
  'OSMOLEX ER',
  'MIRABEGRON',
  'ICOSAPENT ETHYL',
  'FETROJA',
  'NITYR',
  'LORCASERIN HYDROCHLORIDE',
  'PEMFEXY',
  'CAROSPIR',
  'TRELEGY ELLIPTA',
  'IMPOYZ',
  'CAPLYTA',
  'LYRICA CR',
  'BARHEMSYS',
  'XARACOLL',
  'SEYSARA',
  'VESICARE LS',
  'SPINRAZA',
  'PERAMPANEL',
  'MALMOREDE',
  'LO-MALMOREDE',
  'TERIFLUNOMIDE',
  'LINACLOTIDE',
  'SEIZALAM',
  'TISSUEBLUE',
  'BENZNIDAZOLE',
  'NUMBRINO',
  'CLENPIQ',
  'IDHIFA',
  'AZEDRA',
  'ENZALUTAMIDE',
  'ANNOVERA',
  'LO-ZUMANDIMINE',
  'TOFACITINIB',
  'OZEMPIC',
  'BONJESTA',
  'DOLUTEGRAVIR, LAMIVUDINE, AND TENOFOVIR DISOPROXIL FUMARATE',
  'CLOVIQUE',
  'VABOMERE',
  'ROXYBOND',
  'STEGLATRO',
  'STEGLUJAN',
  'SEGLUROMET',
  'APIXABAN',
  'NUZYRA',
  'SUBLOCADE',
  'ARISTADA INITIO KIT',
  'LYMEPAK',
  'EVZIO (AUTOINJECTOR)',
  'XYOSTED (AUTOINJECTOR)',
  'NIKITA',
  'MAYZENT',
  'ZEPOSIA',
  'EVEKEO ODT',
  'PENICILLAMINE',
  'KISQALI FEMARA CO-PACK (COPACKAGED)',
  'ALIQOPA',
  'SODIUM TETRADECYL SULFATE',
  'PREVYMIS',
  'ALYQ',
  'POMALIDOMIDE',
  'GOPRELTO',
  'SODIUM PHOSPHATES',
  'ALOGLIPTIN',
  'CLARISCAN',
  'CONSENSI',
  'FINZALA',
  'PULMOTECH MAA',
  'BIJUVA',
  'BAQSIMI',
  'BRIXADI',
  'ALOGLIPTIN; METFORMIN HYDROCHLORIDE',
  'MOTEGRITY',
  'KETOROLAC TROMETHAMINE AND PHENYLEPHRINE HYDROCHLORIDE',
  'JULUCA',
  'VIGADRONE',
  'ALOGLIPTIN AND METFORMIN HYDROCHLORIDE',
  'DIMETHYL FUMARATE',
  'AXITINIB',
  'DOPTELET',
  'BIKTARVY',
  'CALQUENCE',
  'BAFIERTAM',
  'CANAGLIFLOZIN',
  'ZEMDRI',
  'YONSA',
  'ISOPROTERENOL HYDROCHLORIDE ',
  'YUTIQ',
  'QBREXZA',
  'EPIDIOLEX',
  'KAPSPARGO SPRINKLE',
  'ORILISSA',
  'SYMTUZA',
  'SYMDEKO (COPACKAGED)',
  'BRAFTOVI',
  'MEKTOVI',
  'BETAINE',
  'DYANAVEL XR ',
  'FLUOCINONIDE ACETONIDE',
  'FLUCINOLONE ACETONIDE',
  'VYLEESI (AUTOINJECTOR)',
  'INVELTYS',
  'LEXETTE',
  'CHLORDIAZEPOXIDE HYDROCHLORIDE AND CLIDINIUM BROMIDE',
  'ANJESO',
  'OMEGAVEN',
  'DUAKLIR PRESSAIR',
  'YUPELRI',
  'SEMGLEE',
  'ARAKODA',
  'PERSERIS KIT',
  'DAURISMO',
  'ELCYS',
  'OLINVYK',
  'LEVOMILNACIPRAN HYDROCHLORIDE',
  'FLAC',
  'REDITREX',
  'DOFETILDE',
  'DOLUTEGRAVIR; LAMIVUDINE; TENOFOVIR DISOPROXIL FUMARATE',
  'KRINTAFEL',
  'SCENESSE',
  'LEVOMILNACIPRAN',
  'PIFELTRO',
  'DELSTRIGO',
  'GALLIUM DOTATOC GA 68',
  'ELURYNG',
  'SYMPAZAN',
  'XOFLUZA',
  'VITRAKVI',
  'SESQUIENT',
  'MOXIDECTIN',
  'LORBRENA',
  'ZURAGARD',
  'QTERNMET XR',
  'KYNMOBI',
  'TOSYMRA',
  'AEMCOLO',
  'CEQUA',
  'LEVOCETIRIZINE HYDROCHLORIDE',
  'ONPATTRO',
  'MULPLETA',
  'EYSUVIS',
  'GLOPERBA',
  'ERLEADA',
  'GLYRX-PF',
  'HALCINONIDE',
  'FLUORESCEIN SODIUM AND BENOXINATE HYDROCHLORIDE',
  'NITISINONE',
  'VORTIOXETINE',
  'XERAVA',
  'MACITENTAN',
  'VORTIOXETINE HYDROBROMIDE',
  'PALIPERIDONE PALMITATE',
  'WAKIX',
  'COPIKTRA',
  'TEGSEDI',
  'TIBSOVO',
  'ESLICARBAZEPINE ACETATE',
  'QMIIZ ODT',
  'ANGIOMAX RTU',
  'KHAPZORY',
  'SUNOSI',
  'SPRAVATO',
  'IBRUTINIB',
  'IBUPROFEN AND FAMOTIDINE',
  'REYVOW',
  'PIZENSY',
  'TEMIXYS',
  'VIZIMPRO',
  'TAVABOROLE',
  'CYSTADROPS',
  'NAYZILAM',
  'KATERZIA',
  'XOSPATA',
  'SOFOSBUVIR',
  'SLYND',
  'ZULRESSO',
  'HEMADY',
  'QUZYTTIR',
  'DAPAGLIFLOZIN',
  'CAMCEVI KIT',
  'DIFLUPREDNATE',
  'AKLIEF',
  'BENDAMUSTINE',
  'DAPAGLIFLOZIN AND SAXAGLIPTIN',
  'VASOPRESSIN',
  'SITAGLIPTIN',
  'SPY AGENT GREEN KIT',
  'TRIAMTERENE',
  'TASIMELTEON',
  'NEXLETOL',
  'NEXLIZET',
  'VALTOCO',
  'TALZENNA',
  'DROXIDOPA',
  'XENLETA',
  'RINVOQ',
  'LEVOCARNITINE SF',
  'BUDESONIDE AND FORMOTEROL FUMARATE DIHYDRATE',
  'TAZVERIK',
  'JELMYTO',
  'ADVIL DUAL ACTION WITH ACETAMINOPHEN',
  'APREMILAST',
  'RYALTRIS',
  'UBRELVY',
  'IBSRELA',
  'TURALIO',
  'EFINACONAZOLE',
  'THIOLA EC',
  'MIDAZOLAM IN 0.9% SODIUM CHLORIDE',
  'VUMERITY',
  'FOSFOMYCIN TROMETHAMINE',
  'ARAZLO',
  'ZIPRASIDONE MESYLATE',
  'DURYSTA',
  'ABSORICA LD',
  'ORTIKOS',
  'BONSITY',
  'CARBOPROST TROMETHAMINE',
  'XIPERE',
  'QELBREE',
  'VYONDYS 53',
  'ZYNRELEF KIT',
  'DOVATO',
  'VYNDAQEL',
  'ZINC CHLORIDE',
  'BALVERSA',
  'APOMORPHINE HYDROCHLORIDE',
  'DAYVIGO',
  'ADHANSIA XR',
  'KLOXXADO',
  'GVOKE PFS',
  'GVOKE HYPOPEN',
  'NUBEQA',
  'FINTEPLA',
  'POTASSIUM PHOSPHATES',
  'BREZTRI AEROSPHERE',
  'TAUVID',
  'MICAFUNGIN',
  'VILTEPSO',
  'CERIANNA',
  'ELYXYB',
  'VYNDAMAX',
  'GIVLAARI',
  'EMPAGLIFLOZIN; METFORMIN HYDROCHLORIDE',
  'VIVIMUSTA',
  'EMPAGLIFLOZIN AND LINAGLIPTIN',
  'SECUADO',
  'TRIKAFTA (COPACKAGED)',
  'EXEM FOAM KIT',
  'BYFAVO',
  'DEOXYCHOLIC ACID',
  'DOLUTEGRAVIR, LAMIVUDINE AND TENOFOVIR DISOPROXIL FUMARATE',
  'ADLARITY',
  'XPOVIO',
  'ATROPINE (AUTOINJECTOR)',
  'ACCRUFER',
  'EMPAGLIFLOZIN',
  'INREBIC',
  'DEXTROSE 5% AND POTASSIUM CHLORIDE 0.075%',
  'DEXTROSE 5% AND POTASSIUM CHLORIDE 0.15%',
  'EMPAGLIFLOZIN; LINAGLIPTIN',
  'CALCIPOTRIENE AND BETHAMETHASONE DIPROPIONATE',
  'AMZEEQ',
  'ELIGLUSTAT TARTRATE',
  'ONGENTYS',
  'DRIZALMA SPRINKLE',
  'UPNEEQ',
  'PIQRAY',
  'DOLUTEGRAVIR LAMIVUDINE TENOFOVIR ALAFENAMIDE',
  'NOURESS',
  'NINTEDANIB',
  'PIRFENIDONE',
  'INQOVI',
  'RIOMET ER',
  'AYVAKIT',
  'TRIJARDY XR',
  'NALOXEGOL',
  'EXSERVAN',
  'XYWAV',
  'MERZEE',
  'ROZLYTREK',
  'NURTEC ODT',
  'ISTURISA',
  'RECARBRIO',
  'XCOPRI',
  'ZIMHI',
  'TRIFERIC AVNU',
  'PRETOMANID',
  'VOCABRIA',
  'CABENUVA KIT',
  'CONJUPRI',
  'FOTIVDA',
  'BIORPHEN',
  'TOFACITINIB CITRATE',
  'RUKOBIA',
  'NALMEFENE HYDROCHLORIDE',
  'AZSTARYS',
  'TALICIA',
  'GEMTESA',
  'AMONDYS 45',
  'ARTESUNATE',
  'RYBELSUS',
  'ROSZET',
  'PALBOCICLIB',
  'SUTAB',
  'OXBRYTA',
  'FENSOLVI KIT',
  'UKONIQ',
  'KLISYRI',
  'BRUKINSA',
  'SOAANZ',
  'BYNFEZIA PEN',
  'DETECTNET',
  'RETEVMO',
  'FYARRO',
  'GEMMILY',
  'LYBALVI',
  'ORIAHNN (COPACKAGED)',
  'EMERPHED',
  'TUKYSA',
  'WYNZORA',
  'SEGLENTIS',
  'WINLEVI',
  'TRUDHESA',
  'LAMPIT',
  'HYFTOR',
  'PONVORY',
  'GABAPENTIN ENCARBIL',
  'EVRYSDI',
  'REZIPRES',
  'ELUXADOLINE',
  'OXYMETAZOLINE HYDROCHLORIDE',
  'TABRECTA',
  'DAPZURA RT',
  'EMPAGLIFLOZIN;METFORMIN HYDROCHLORIDE',
  'BREXPIPRAZOLE',
  'DOJOLVI',
  'ZILXI',
  'IMPEKLO',
  'ZEPZELCA',
  'LUPKYNIS',
  'GAVRETO',
  'CARGLUMIC ACID',
  'METYROSINE',
  'PEMAZYRE',
  'KOSELUGO',
  'IOFLUPANE I-123',
  'IMCIVREE',
  'MYRBETRIQ GRANULES',
  'NITAZOXANIDE',
  'TENOFOVIR ALAFENAMIDE',
  'CIBINQO',
  'ASTEPRO ALLERGY',
  'CHILDREN\'S ASTEPRO ALLERGY',
  'ALKINDI SPRINKLE',
  'VANCOMYCIN',
  'ETHINYL ESTRADIOL AND NORELGESTROMIN',
  'ZOKINVY',
  'QINLOCK',
  'ONSURA',
  'TYRVAYA',
  'TIVICAY PD',
  'LEQVIO',
  'NULIBRY',
  'VUITY',
  'ILLUCCIX',
  'QDOLO',
  'THYQUIDITY',
  'IVABRADINE HYDROCHLORIDE',
  'ORLADEYO',
  'TEPMETKO',
  'OXLUMO',
  'ONUREG',
  'METHOTREXATE',
  'RECORLEV',
  'NEXTSTELLIS',
  'KIMYRSA',
  'ETRAVIRINE',
  'COSELA',
  'ZEGALOGUE',
  'ZEGALOGUE (AUTOINJECTOR)',
  'OSIMERTINIB MESYLATE',
  'NOREPINEPHRINE BITARTRATE IN 5% DEXTROSE',
  'TIOPRONIN',
  'SELEXIPAG',
  'VERQUVO',
  'PEPAXTO',
  'FEXINIDAZOLE',
  'NORLIQVA',
  'TEMBEXA',
  'TAVNEOS',
  'AZILSARTAN MEDOXOMIL',
  'BRIVARACETAM',
  'PIMAVANSERIN',
  'EPSOLAY',
  'HETLIOZ LQ',
  'ORGOVYX',
  'TRUSELTIQ',
  'MYCOPHENOLIC ACID',
  'LIVMARLI',
  'LUMAKRAS',
  'EPRONTIA',
  'NOXAFIL POWDERMIX KIT',
  'REZUROCK',
  'VEKLURY',
  'PYLARIFY',
  'LOREEV XR',
  'MYFEMBREE',
  'DHIVY',
  'BREXAFEMME',
  'TWYNEO',
  'CYTALUX',
  'KORSUVA',
  'VOXZOGO',
  'TASCENSO ODT',
  'VERKAZIA',
  'QUVIVIQ',
  'NEPHROSCAN',
  'CAMZYOS',
  'EMPAVELI',
  'OBETICHOLIC ACID',
  'DARTISLA ODT',
  'NELARABINE',
  'VIJOICE',
  'LIFITEGRAST',
  'QULIPTA',
  'DILTIAZEM HYDROCHLORIDE IN DEXTROSE 5%',
  'WEGOVY',
  'GADOTERATE MEGLUMINE',
  'OPZELURA',
  'EXKIVITY',
  'KERENDIA',
  'SCEMBLIX',
  'WELIREG',
  'IGALMI',
  'LANREOTIDE ACETATE',
  'XELSTRYM',
  'TRIUMEQ PD',
  'LYVISPAH',
  'ENTADFI',
  'NALOXONE HYDROCHLORIDE (AUTOINJECTOR)',
  'BYLVAY',
  'APRETUDE',
  'ISOSORBIDE DINITRATE AND HYDRALAZINE HYDROCHLORIDE',
  'LIVTENCITY',
  'FLEQSUVY',
  'XACIATO',
  'NASONEX 24HR ALLERGY',
  'CUVRIOR',
  'ERMEZA',
  'PLUVICTO',
  'LOCAMETZ',
  'VIVJOA',
  'ZTALMY',
  'TARPEYO',
  'ASPRUZYO SPRINKLE',
  'PYRUKYND',
  'AMJEVITA',
  'PRAXBIND',
  'MVASI',
  'ZINBRYTA',
  'SILIQ',
  'CINQAIR',
  'TECENTRIQ',
  'EMPLICITI',
  'DARZALEX',
  'KEVZARA',
  'LARTRUVO',
  'UDENYCA',
  'BESPONSA',
  'ERELZI',
  'ZIEXTENZO',
  'ZINPLAVA',
  'MEPSEVII',
  'BAVENCIO',
  'POTELIGEO',
  'BRINEURA',
  'OCREVUS',
  'RENFLEXIS',
  'DUPIXENT',
  'CYLTEZO',
  'HADLIMA',
  'MYLOTARG',
  'TREMFYA',
  'EVENITY',
  'EMGALITY',
  'RITUXAN HYCELA',
  'TROGARZO',
  'ETICOVO',
  'ILUMYA',
  'CRYSVITA',
  'IMFINZI',
  'FASENRA',
  'HYRIMOZ',
  'IXIFI',
  'KANJINTI',
  'OGIVRI',
  'FULPHILA',
  'AIMOVIG',
  'PALYNZIQ',
  'NIVESTYM',
  'TRAZIMERA',
  'RELEUKO',
  'HEMLIBRA',
  'JEUVEAU',
  'AVSOLA',
  'TRUXIMA',
  'AJOVY',
  'TAKHZYRO',
  'HERZUMA',
  'REVCOVI',
  'OXERVATE',
  'LIBTAYO',
  'ZIRABEV',
  'ONTRUZANT',
  'ASPARLAS',
  'RUXIENCE',
  'LUMOXITI',
  'SKYRIZI',
  'HERCEPTIN HYLECTA',
  'GAMIFANT',
  'ULTOMIRIS',
  'LYUMJEV',
  'NYVEPRIA',
  'CABLIVI',
  'SARCLISA',
  'TRODELVY',
  'ELZONRIS',
  'ABRILADA',
  'VYEPTI',
  'POLIVY',
  'SAPHNELO',
  'BEOVU',
  'ADAKVEO',
  'REBLOZYL',
  'PADCEV',
  'ENHERTU',
  'RIABNI',
  'UPLIZNA',
  'TEPEZZA',
  'DARZALEX FASPRO',
  'QWO',
  'ENSPRYNG',
  'MARGENZA',
  'HULIO',
  'SOGROYA',
  'BLENREP',
  'MONJUVI',
  'ENJAYMO',
  'BESREMI',
  'INMAZEB',
  'PHESGO',
  'DANYELZA',
  'EBANGA',
  'JEMPERLI',
  'SKYTROFA',
  'ADUHELM',
  'RYLAZE',
  'ADBRY',
  'EVKEEZA',
  'NEXVIAZYME',
  'VYVGART',
  'ZYNLONTA',
  'SUSVIMO',
  'BYOOVIZ',
  'TIVDAK',
  'RYBREVANT',
  'REZVOGLAR',
  'YUSIMRY',
  'TEZSPIRE',
  'KIMMTRAK',
  'ALYMSYS',
  'OPDUALAG',
  'VABYSMO',
]