import { Auth } from 'aws-amplify';
import axios from 'axios';
import i18n from "../i18n";

export const changePassword: (oldPassword: string, newPassword: string, userId: string) => Promise<void> = async (oldPassword, newPassword, userId) => {
  try {
    await axios.patch(`/users/${userId}`, { newPassword: newPassword, oldPassword: oldPassword });
  } catch (error: any) {
    handlePasswordChangeError(error);
  }
};

export const changeEmail: (newEmail: string, userId: string) => Promise<void> = async (newEmail, userId) => {
  await axios.patch(`/users/${userId}`, { email: newEmail });
};

export const isValidEmail = (newEmail: string) => {
  const re = /^([\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return newEmail.match(re);
};

export const verifyEmail: (code: string) => Promise<void> = async (code) => {
  await Auth.verifyCurrentUserAttributeSubmit("email", code);
};

export const changePhoneNumber: (newPhoneNumber: string, userId: string) => Promise<void> = async (newPhoneNumber, userId) => {
  try {
    await axios.patch(`/users/${userId}`, {
      phoneNumber: newPhoneNumber,
      phoneNumberVerified: false,
    });
    await axios.post("/users/verify-phone-number", newPhoneNumber);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message === "SMS is not supported by landline phone number") {
      throw new Error(i18n.t("onboarding.verifyPhone.landlineError2"));
    } else {
      throw error;
    }
  }
};

export const sendVerifyCode: () => Promise<void> = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      phone_number_verified: false,
    });
    await axios.post("/users/verify-phone-number", user.phone_number);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message === "SMS is not supported by landline phone number") {
      throw new Error(i18n.t("onboarding.verifyPhone.landlineError"));
    } else {
      throw error;
    }
  }
};

export const verifyPhoneNumber: (code: string) => Promise<void> = async (code) => {
  const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  const phoneNumber = user.attributes.phone_number;
  await axios.patch("/users/verify-phone-number", { phoneNumber, code });
  await Auth.currentAuthenticatedUser({ bypassCache: true });
};

export const isUserPhoneNumberVerified: () => Promise<string> = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const isPhoneNumberVerified = user.attributes.phone_number_verified;
  return isPhoneNumberVerified === true ? "true" : "false";
};

export const handlePasswordChangeError = (error: any) => {
  if (error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;

    switch (message) {
      case "Password does not conform to policy: Password must have uppercase characters":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.needsUppercase"));
      case "Password does not conform to policy: Password not long enough":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.notLongEnough"));
      case "Password does not conform to policy: Password must have symbol characters":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.needsSymbol"));
      case "Password does not conform to policy: Password must have lowercase characters":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.needsLowercase"));
      case "Password does not conform to policy: Password must have digit characters":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.needsDigit"));
      case "Password does not conform to policy: Password must have numeric characters":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.needsDigit"));
      case "Password does not conform to policy: Password too long":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.tooLong"));
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.notLongEnough"));
      case "Incorrect username or password.":
        throw new Error(i18n.t("dashboard.profile.changePassword.passwordErrors.wrongCurrentPassword"));
      default:
        throw new Error(message);
    }
  } else {
    throw new Error(i18n.t("profile.changePassword.passwordErrors.generalError"));
  }
};