import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import FaceDetailDisplay from './FaceDetailDisplay';

const ClearMindWebStream: React.FC = () => {
  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = React.useRef<any>(null);
  const [capturing, setCapturing] = React.useState(false);

  const getContentType = () => {
    return mediaRecorderRef.current.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4'
  }

  const handleDataAvailable = React.useCallback(
    async ({ data }) => {

      if (data.size > 0) {
        // const recordedChunks: BlobPart[] | undefined = []
        // const videoBlob = new Blob(recordedChunks.concat(data), {
        //   type: getContentType(),
        // });
        // await testingS3Service.addVideoToBucket(videoBlob, getContentType())
        ;
      }
    },
    []
  );
  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: getContentType()
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing,]);

  return (
    <>
      <Webcam
        audio={false} ref={webcamRef} mirrored />
      {capturing ? (
        <button onClick={handleStopCaptureClick}>Stop Capture</button>
      ) : (
        <button onClick={handleStartCaptureClick}>Start Capture</button>
      )}
      <FaceDetailDisplay />
    </>
  );
};

export default ClearMindWebStream;
