import React, { useState } from 'react';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import { Card, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import UserCard from '../Components/UserCardComponent/UserCard';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import ClearMindButton from '../Components/Controls/ClearMindButton/ClearMindButton';
import UpdateSupporterList from './UpdateSupporterList';
import { trackPromise } from 'react-promise-tracker';
import { observer } from 'mobx-react-lite';
import { IUser } from '../Models/User';
import ErrorDisplay from '../Components/Messaging/ErrorDisplay';
import FooterBackButton from '../Components/BackButton/FooterBackButton';

const Supporters: React.FC = () => {
  const { behaviorContract } = useClearMindContext();
  const [showUserCards, setShowUserCards] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const showErrorMessage = (message: string) => {
    setErrorMsg(message);
    setTimeout(() => {
      setErrorMsg("");
    }, 3500);
  };

  const handleDelete = async (index: number) => {
    if (behaviorContract.supporters.length === 1) {
      showErrorMessage("One supporter is required, please add another supporter before deleting the current supporter");
    } else {
      behaviorContract.removeSupporter(index);
      await trackPromise(behaviorContract.save());
    }
  };

  const onSupporterUpdate = () => {
    setShowUserCards(!showUserCards);
  };

  return (
    <>
      <Grid centered>
        <Grid.Row>
          <Segment textAlign={"center"} basic>
            <Header style={{ color: "var(--secondary-color)" }} as={"h2"} textAlign="center">
              Supporters
            </Header>
          </Segment>
        </Grid.Row>

        <Grid.Row
          style={{
            maxWidth: "800px",
            paddingTop: "10px",
          }}
        >
          <FrostedFrame>
            <Segment basic style={{ width: "320px" }}>
              <Header style={{ color: "var(--secondary-color)" }} icon textAlign="center">
                <Icon name="user" circular />
                Monitored Member
              </Header>
              <UserCard user={behaviorContract.member} />
            </Segment>
          </FrostedFrame>
          <FrostedFrame>
            <Segment basic style={{ width: "320px" }}>
              <Header style={{ color: "var(--secondary-color)" }} icon textAlign="center">
                <Icon name="users" circular />
                <Header.Content>Supporters</Header.Content>
              </Header>
              {!showUserCards && (
                <Card.Group centered>
                  {behaviorContract.supporters.map((user: IUser, index: number) => (
                    <UserCard user={user} key={`supporter-${index}`} index={index} handleDelete={handleDelete} />
                  ))}
                </Card.Group>
              )}
              <Segment textAlign={"center"} basic>
                <ErrorDisplay message={errorMsg} />
                <ClearMindButton
                  opaque
                  onClick={() => {
                    setShowUserCards(!showUserCards);
                  }}
                >
                  <span>{showUserCards ? <Icon name={"redo"} link corner="top right" size="large" /> : <Icon name="plus" link corner="top right" size="large" />}</span>
                  {showUserCards ? "Back" : "New Supporter"}
                </ClearMindButton>
              </Segment>

              {showUserCards && <UpdateSupporterList onSupporterUpdate={onSupporterUpdate} behaviorContract={behaviorContract} />}
            </Segment>
          </FrostedFrame>
        </Grid.Row>
        <FooterBackButton />
      </Grid>
    </>
  );
};

export default observer(Supporters);
