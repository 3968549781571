import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Switch from "react-switch";
import { Icon } from "semantic-ui-react";
import styles from "./EventSchedulerStyles.module.css";
import EventScheduleComponent from "./EventScheduleComponent";
import { useTranslation } from "react-i18next";

interface ToggleProps {
  setList: React.Dispatch<React.SetStateAction<boolean>>;
  list: boolean;
}

const ResultsTrackerHeader: React.FC<ToggleProps> = (props) => {
  const { setList, list } = props;
  const [eventScheduleVisible, setEventScheduleVisible] = useState(false);
  const [checked, setChecked] = useState(true);
  const { t } = useTranslation();

  const handleListAndCalendarChange = () => {
    setList(!list);
    setChecked(!checked);
  };

  return (
    <>
      <div className={styles.container}>
        <Switch
          height={36}
          width={64}
          uncheckedIcon={<Icon className={styles.listIcon} name={"list"} />}
          checkedIcon={<Icon className={styles.calendarIcon} name={"calendar alternate"} />}
          onColor="#10B0AD"
          offColor="#10B0AD"
          onChange={handleListAndCalendarChange}
          checked={checked}
        />
        <div onClick={() => setEventScheduleVisible(!eventScheduleVisible)}>
          <Icon size="big" color="teal" name={"plus"} /> {t("results.header.addEvent")}
        </div>
        <EventScheduleComponent setVisible={setEventScheduleVisible} visible={eventScheduleVisible} />
      </div>
    </>
  );
};

export default observer(ResultsTrackerHeader);
