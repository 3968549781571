import i18n from "../i18n";

interface TimedStateTransitions {
  currentState: string;
  nextState: string;
  interval: number;
  cancelOn?: (state: string) => boolean;
  timeoutRef?: NodeJS.Timeout
}

const timedStateTransitionsEn: TimedStateTransitions[] = [
  {
    currentState: "phone-orientation-check",
    nextState: "test-checklist",
    interval: 12000,
  },
  {
    currentState: "test-checklist-success",
    nextState: "follow-instructions-prompt",
    interval: 9000,
  },
  {
    currentState: "follow-instructions-prompt",
    nextState: "mouth-is-open",
    interval: 7000,
  },
  {
    currentState: "mouth-is-open",
    nextState: "tongue-check-complete",
    interval: 10000,
  },
  {
    currentState: "tongue-check-complete",
    nextState: "validate-unopened-test-kit",
    interval: 5000,
  },
  {
    currentState: "validate-test-cube",
    nextState: "test-cube-stay-in-frame",
    interval: 7000,
  },
  {
    currentState: "test-cube-stay-in-frame",
    nextState: "cube-wrapper-removal",
    interval: 9000,
  },
  {
    currentState: "cube-wrapper-removal",
    nextState: "cube-and-stick-in-view",
    interval: 10000,
  },
  {
    currentState: "cube-and-stick-in-view",
    nextState: "insert-test-stick-in-mouth-1",
    interval: 10000,
  },
  {
    currentState: "insert-test-stick-in-mouth-1",
    nextState: "insert-test-stick-in-mouth-2",
    interval: 5000,
  },
  {
    currentState: "insert-test-stick-in-mouth-2",
    nextState: "insert-test-stick-in-mouth-3",
    interval: 8000,
  },
  {
    currentState: "insert-test-stick-in-mouth-3",
    nextState: "insert-test-stick-in-mouth-4",
    interval: 10000,
  },
  {
    currentState: "insert-test-stick-in-mouth-4",
    nextState: "show-green-label",
    interval: 7000,
  },
  {
    currentState: "show-green-label",
    nextState: "remove-green-label",
    interval: 7000,
  },
  {
    currentState: "remove-green-label",
    nextState: "cube-and-face-in-frame",
    interval: 10000,
  },
  {
    currentState: "cube-and-face-in-frame",
    nextState: "test-stick-process-message-1",
    interval: 21000,
  },
  {
    currentState: "test-stick-process-message-1",
    nextState: "test-stick-process-message-2",
    interval: 15000,
  },
  {
    currentState: "test-stick-process-message-2",
    nextState: "test-stick-process-message-3",
    interval: 15000,
  },
  {
    currentState: "test-stick-process-message-3",
    nextState: "test-stick-process-message-4",
    interval: 45000,
  },
  {
    currentState: "test-stick-process-message-4",
    nextState: "test-stick-process-message-5",
    interval: 105000,
  },
  {
    currentState: "place-stick-in-cube-1",
    nextState: "place-stick-in-cube-2",
    interval: 10000,
  },
  {
    currentState: "place-stick-in-cube-2",
    nextState: "cube-on-mat-prompt",
    interval: 10000,
  },
  {
    currentState: "cube-on-mat-prompt",
    nextState: "2-min-reminder-alcohol",
    interval: 40000,
  },
  {
    currentState: "2-min-reminder-alcohol",
    nextState: "last-reminder-alcohol",
    interval: 110000,
  },
  {
    currentState: "last-reminder-alcohol",
    nextState: "confirm-test-side-a-alcohol",
    interval: 15000,
  },
  {
    currentState: "test-side-a-alcohol-confirmed",
    nextState: "confirm-test-side-b-alcohol",
    interval: 3500,
  },
  {
    currentState: "test-side-b-alcohol-confirmed",
    nextState: "cube-on-mat-reminder",
    interval: 3500,
  },
  {
    currentState: "cube-on-mat-reminder",
    nextState: "remain-in-view",
    interval: 120000,
    cancelOn: (currentState) => currentState === "confirm-test-side-a",
  },
  {
    currentState: "remain-in-view",
    nextState: "remain-in-view-reminder",
    interval: 120000,
    cancelOn: (currentState) => currentState === "confirm-test-side-a",
  },
  {
    currentState: "test-side-a-confirmed",
    nextState: "confirm-test-side-b",
    interval: 3000,
  },
  {
    currentState: "test-side-b-confirmed",
    nextState: "test-confirmed",
    interval: 3000,
  },
];
const timedStateTransitionsEs: TimedStateTransitions[] = [
  {
    currentState: "phone-orientation-check",
    nextState: "test-checklist",
    interval: 12000,
  },
  {
    currentState: "test-checklist-success",
    nextState: "follow-instructions-prompt",
    interval: 9000,
  },
  {
    currentState: "follow-instructions-prompt",
    nextState: "mouth-is-open",
    interval: 9000,
  },
  {
    currentState: "mouth-is-open",
    nextState: "tongue-check-complete",
    interval: 12000,
  },
  {
    currentState: "tongue-check-complete",
    nextState: "validate-unopened-test-kit",
    interval: 5000,
  },
  {
    currentState: "validate-test-cube",
    nextState: "test-cube-stay-in-frame",
    interval: 9000,
  },
  {
    currentState: "test-cube-stay-in-frame",
    nextState: "cube-wrapper-removal",
    interval: 11000,
  },
  {
    currentState: "cube-wrapper-removal",
    nextState: "cube-and-stick-in-view",
    interval: 12000,
  },
  {
    currentState: "cube-and-stick-in-view",
    nextState: "insert-test-stick-in-mouth-1",
    interval: 10000,
  },
  {
    currentState: "insert-test-stick-in-mouth-1",
    nextState: "insert-test-stick-in-mouth-2",
    interval: 5000,
  },
  {
    currentState: "insert-test-stick-in-mouth-2",
    nextState: "insert-test-stick-in-mouth-3",
    interval: 8000,
  },
  {
    currentState: "insert-test-stick-in-mouth-3",
    nextState: "insert-test-stick-in-mouth-4",
    interval: 10000,
  },
  {
    currentState: "insert-test-stick-in-mouth-4",
    nextState: "show-green-label",
    interval: 7000,
  },
  {
    currentState: "show-green-label",
    nextState: "remove-green-label",
    interval: 7000,
  },
  {
    currentState: "remove-green-label",
    nextState: "cube-and-face-in-frame",
    interval: 10000,
  },
  {
    currentState: "cube-and-face-in-frame",
    nextState: "test-stick-process-message-1",
    interval: 21000,
  },
  {
    currentState: "test-stick-process-message-1",
    nextState: "test-stick-process-message-2",
    interval: 15000,
  },
  {
    currentState: "test-stick-process-message-2",
    nextState: "test-stick-process-message-3",
    interval: 15000,
  },
  {
    currentState: "test-stick-process-message-3",
    nextState: "test-stick-process-message-4",
    interval: 45000,
  },
  {
    currentState: "test-stick-process-message-4",
    nextState: "test-stick-process-message-5",
    interval: 105000,
  },
  {
    currentState: "place-stick-in-cube-1",
    nextState: "place-stick-in-cube-2",
    interval: 10000,
  },
  {
    currentState: "place-stick-in-cube-2",
    nextState: "cube-on-mat-prompt",
    interval: 10000,
  },
  {
    currentState: "cube-on-mat-prompt",
    nextState: "2-min-reminder-alcohol",
    interval: 40000,
  },
  {
    currentState: "2-min-reminder-alcohol",
    nextState: "last-reminder-alcohol",
    interval: 110000,
  },
  {
    currentState: "last-reminder-alcohol",
    nextState: "confirm-test-side-a-alcohol",
    interval: 15000,
  },
  {
    currentState: "test-side-a-alcohol-confirmed",
    nextState: "confirm-test-side-b-alcohol",
    interval: 3500,
  },
  {
    currentState: "test-side-b-alcohol-confirmed",
    nextState: "cube-on-mat-reminder",
    interval: 3500,
  },
  {
    currentState: "cube-on-mat-reminder",
    nextState: "remain-in-view",
    interval: 120000,
    cancelOn: (currentState) => currentState === "confirm-test-side-a",
  },
  {
    currentState: "remain-in-view",
    nextState: "remain-in-view-reminder",
    interval: 120000,
    cancelOn: (currentState) => currentState === "confirm-test-side-a",
  },
  {
    currentState: "test-side-a-confirmed",
    nextState: "confirm-test-side-b",
    interval: 3000,
  },
  {
    currentState: "test-side-b-confirmed",
    nextState: "test-confirmed",
    interval: 3000,
  },
];

const timedStateTransitionsDictionaryEn = timedStateTransitionsEn.reduce(
  (prev: { [key: string]: TimedStateTransitions }, current) => {
    prev[current.currentState] = current;
    return prev;
  },
  {}
);

const timedStateTransitionsDictionaryEs = timedStateTransitionsEs.reduce(
  (prev: { [key: string]: TimedStateTransitions }, current) => {
    prev[current.currentState] = current;
    return prev;
  },
  {}
);


export const timeStateTransitioner = (
  previousState: string,
  currentState: string,
  testingService: any
) => {

  const englishTransition = () => {
    timedStateTransitionsEn.forEach(transition => {
      if (transition.timeoutRef && transition.cancelOn && transition.cancelOn(currentState)) {
        clearTimeout(transition.timeoutRef)
      }
    });
  
    const stateTransition = timedStateTransitionsDictionaryEn[currentState];
  
    if (stateTransition) {
      stateTransition.timeoutRef = setTimeout(
        async () => await testingService.onStateTransition(stateTransition.nextState),
        stateTransition.interval + 1000
      );
    }
  }

  const spanishTransition = () => {
    timedStateTransitionsEs.forEach(transition => {
      if (transition.timeoutRef && transition.cancelOn && transition.cancelOn(currentState)) {
        clearTimeout(transition.timeoutRef)
      }
    });
  
    const stateTransition = timedStateTransitionsDictionaryEs[currentState];
  
    if (stateTransition) {
      stateTransition.timeoutRef = setTimeout(
        async () => await testingService.onStateTransition(stateTransition.nextState),
        stateTransition.interval + 1000
      );
    }
  }

  switch (i18n.language) {
    case "en":
      englishTransition();
      break;
    case "es":
      spanishTransition();
      break;
    default:
      englishTransition()
      break;
  }
};