import React from "react";
import { Header, Icon } from "semantic-ui-react";
import Switch from "react-switch";
import styles from "./EventSchedulerStyles.module.css";
import { observer } from "mobx-react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";

const EventPrivacy: React.FC = (props) => {
  const { behaviorContract, user } = useClearMindContext();
  const { t } = useTranslation();

  const onSupporterVisibilityClick = async () => {
    behaviorContract.setEventsVisibleToSupporter(!behaviorContract.eventsVisibleToSupporter);
    await behaviorContract.save();
  };

  const onMonitoringPartnerVisibilityClick = async () => {
    behaviorContract.setEventsVisibleToMonitoringPartner(!behaviorContract.eventsVisibleToMonitoringPartner);
    await behaviorContract.save();
  };

  return (
    <div className={styles.shareEventsContainer}>
      <Header textAlign="right">
        {t("results.events.share")} <br /> {t("results.events.supportNetwork")}
      </Header>
      <Header as={"h4"}>{t("results.events.supporters")}</Header>
      <Switch
        height={36}
        width={64}
        uncheckedIcon={<Icon className={styles.listIcon} name={"eye slash"} />}
        checkedIcon={<Icon className={styles.calendarIcon} name={"eye"} />}
        onColor="#10B0AD"
        offColor="#10B0AD"
        onChange={onSupporterVisibilityClick}
        checked={behaviorContract.eventsVisibleToSupporter}
      />
      {user.hasFacilityPartner && (
        <>
          <Header as={"h4"}>{t("results.events.monitoringPartner")}</Header>
          <Switch
            height={36}
            width={64}
            uncheckedIcon={<Icon className={styles.listIcon} name={"eye slash"} />}
            checkedIcon={<Icon className={styles.calendarIcon} name={"eye"} />}
            onColor="#10B0AD"
            offColor="#10B0AD"
            onChange={onMonitoringPartnerVisibilityClick}
            checked={behaviorContract.eventsVisibleToMonitoringPartner}
          />
        </>
      )}
    </div>
  );
};

export default observer(EventPrivacy);
