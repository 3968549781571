import axios from 'axios';
import { IUser } from '../Models/User';

export const SaveUserRole = async (id: string, role: string) => {
  await axios.patch(`/users/${id}`, { role });
};
export const ConfirmUser = async (id: string, email: string, firstName: string) => {
  await axios.post(`/user-confirm/${id}`, {email, firstName});
};
export const SaveUserSupporterRole = async (id: string, supporterRole: string) => {
  await axios.patch(`/users/${id}`, { supporterRole });
};
export const SaveUserTimeZone = async (id: string, timeZone: string) => {
  await axios.patch(`/users/${id}`, { timeZone });
}
export const SaveBirthDate = async (id: string, birthDate: Date) => {
  await axios.patch(`/users/${id}`, { birthDate });
}
export const SaveUserLanguage = async (id: string, language: string) => {
  await axios.patch(`/users/${id}`, { language });
}
export const SaveUserAddress = async (id: string, streetAddress: string, city: string, state: string, zipCode: string) => {
  await axios.patch(`/users/${id}`, { streetAddress, city, state, zipCode });
}

export const inviteNewUser = async (user: IUser) => {
  const response = await axios.post<IUser>('/invites', user);

  return response.data;
};

export const onSelfReport = async (user: IUser) => {
  await axios.post(`/user/${user.userId}/self-report`, user);
};
export const testsPerDuration = async () => {
  const response = await axios.get('/testing-frequency');

  return JSON.parse(response.data);
};

export const deleteProfile = async () => {
  await axios.delete('/user-data');
};

export const testResults = async (memberId: string | undefined) => {
  const response = await axios.get(`/members/${memberId}/test-results`);

  return response.data;
};

export const getSelfReportedSessions = async (memberId: string | undefined) => {
  const encodedTestResult = encodeURIComponent("Self Reported");
  const response = await axios.get(`/test-sessions?memberId=${memberId}&testResult=${encodedTestResult}`);

  return response.data;
};