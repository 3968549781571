import React, { useState } from "react";
import { Form, Grid, Header, Icon, Input, Message } from "semantic-ui-react";
import { changePassword } from "../../Services/UserProfileService";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import ErrorDisplay from "../Messaging/ErrorDisplay";
import SuccessDisplay from "../Messaging/SuccessDisplay";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import * as Sentry from "@sentry/react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const ChangePassword: React.FunctionComponent = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useClearMindContext();
  const { t, i18n } = useTranslation();
  const { promiseInProgress } = usePromiseTracker({ area: "changePassword-btn" });

  const onSaveClick = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (newPassword && currentPassword) {
      try {
        await trackPromise(changePassword(currentPassword, newPassword, user.userId!), "changePassword-btn");
        setSuccessMessage(i18n.t("dashboard.profile.changePassword.successMessage"));
        setNewPassword("");
        setCurrentPassword("");
      } catch (error: any) {
        Sentry.captureException(error);
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage(i18n.t("dashboard.profile.changePassword.errorMessage"));
    }
  };

  const iconClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Header>{t("dashboard.profile.changePassword.changePassword")}</Header>
      <Message content={t("dashboard.profile.changePassword.restrictions")} icon="bell outline" style={{ background: "var(--secondary-color)", boxShadow: "none", color: "#333" }} />
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>{t("dashboard.profile.changePassword.currentPassword")}</label>
                <Input value={currentPassword} placeholder={t("dashboard.profile.changePassword.currentPassword")} onChange={(e) => setCurrentPassword(e.target.value)} type="password" fluid />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>{t("dashboard.profile.changePassword.newPassword")}</label>
                <Input
                  value={newPassword}
                  placeholder={t("dashboard.profile.changePassword.newPassword")}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fluid
                  type={showPassword ? "input" : "password"}
                  icon={() => <Icon name={showPassword ? "eye slash" : "eye"} onClick={iconClick} link />}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ClearMindButton opaque style={{ width: 153 }} loading={promiseInProgress} onClick={onSaveClick}>
                {t("dashboard.profile.changePassword.changePassword")}
              </ClearMindButton>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={successMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default observer(ChangePassword);
