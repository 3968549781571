import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import RadioSelector from '../../Controls/Selector/RadioSelector';
import StepButtons from '../StepButtons';
import { observer } from 'mobx-react-lite';
import { IsSelectedChangeEvent } from '../../Controls/Selector/Selector';
import { trackPromise } from 'react-promise-tracker';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import i18n from "../../../i18n";

interface TimeZoneProps {
  nextStep?: () => void;
  isEdit?: boolean;
  beforeStepChange: () => Promise<void>;
  isActive?: boolean;
}

const TimeZoneStep: React.FC<TimeZoneProps> = (props) => {
  const { nextStep, beforeStepChange, isEdit } = props;
  const [radioValue, setRadioValue] = useState("");
  const [timeZoneName, setTimeZoneName] = useState<string | undefined>("");
  const [hasTimeZone, setHasTimeZone] = useState<boolean | undefined>(undefined);
  const { user } = useClearMindContext();
  
  const options = [
    { name: "Eastern Standard Time", label: i18n.t("onboarding.timeZoneStep.options.est") },
    { name: "Central Standard Time", label: i18n.t("onboarding.timeZoneStep.options.cst") },
    { name: "Mountain Standard Time", label: i18n.t("onboarding.timeZoneStep.options.mst") },
    { name: "Pacific Standard Time", label: i18n.t("onboarding.timeZoneStep.options.pst") },
  ];

  const getIndex = options.findIndex((option) => {
    return option.name === radioValue;
  });

  const onChange = (e: IsSelectedChangeEvent) => {
    setRadioValue(e.name);
    setHasTimeZone(e.name !== "");
    const option = options.find((option) => option.name === e.name);
    setTimeZoneName(option?.name);
  };

  const nextEnabled = () => {
    return hasTimeZone !== undefined;
  };

  const onNextStep = async () => {
    if (timeZoneName) {
      nextStep!();
    }
  };

  const onBeforeStepChange = async () => {
    if (timeZoneName) {
      await trackPromise(user.setUserTimeZone(timeZoneName));
    }
    await beforeStepChange!();
  };

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h2"}>
        {i18n.t("onboarding.timeZoneStep.header")}
      </Header>
      <Header textAlign="center" as={"h4"}>
        {i18n.t("onboarding.timeZoneStep.required")}
      </Header>
      <RadioSelector options={options} onChange={onChange} selectedIndex={getIndex} />
      <br />

      <StepButtons {...props} nextStep={onNextStep} isFinalStep={false} nextEnabled={nextEnabled()} beforeStepChange={onBeforeStepChange} isEdit={isEdit} />
    </Segment>
  );
};

export default observer(TimeZoneStep);
