import React, { useEffect, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import DayComponent from './DayComponent';
import { ITestResult } from '../../Models/TestResult';
import styles from './CalendarStyles.module.css';
import { observer } from 'mobx-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useTranslation } from "react-i18next";

interface TestResultsCalendarProps {
  testResults: ITestResult[];
}

interface TestResultMap {
  [date: string]: ITestResult[]; 
}

const CalendarComponent: React.FC<TestResultsCalendarProps> = (props) => {
  const [testResultsMap, setTestResultsMap] = useState<TestResultMap>({});
  const { testResults } = props;
  const { scheduledEventsStore } = useClearMindContext();
  const { t } = useTranslation();

  useEffect(() => {
    const mapTestResults: TestResultMap = testResults.reduce((prev: any, current) => {
      if (prev.hasOwnProperty(current.dateKey)) {
        prev[current.dateKey].push(current);
      } else {
        prev[current.dateKey] = [current];
      }
      return prev;
    }, {});
    setTestResultsMap(mapTestResults);
  }, [testResults]);

  const getOrderedDayList = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0);
    const startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    
    const differenceInTime = Math.abs(+lastDayOfMonthDate - +startDate);
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    const days = [];

    for (let i = 0; i <= differenceInDays; i++) {
      const workingDate = new Date(startDate);
      workingDate.setDate(workingDate.getDate() + i);
      days.push(workingDate);
    }

    const listOfMonths = days.reduce((prev: any, curr) => {
      const yearMonth = `${curr.getMonth()}-${curr.getFullYear()}`;

      if (!prev[yearMonth]) {
        prev[yearMonth] = [];
      }

      prev[yearMonth].push(curr);

      return prev;
    }, {});

    const reverseMonthList: Date[][] = [];

    for (let key in listOfMonths) {
      reverseMonthList.unshift(listOfMonths[key]);
    }

    return reverseMonthList.flat();
  };

  const iterator = (count: number) => {
    return new Array(count).fill({});
  }

  const getDayName = (dayNumber: number) => {
    var days = t("results.calendar.days").split(",");
    return days[dayNumber];
  }

  const monthNames = t("results.calendar.months").split(",");

  return (
    <>
      <Grid id={styles.calendarComponentContainer}>
        {getOrderedDayList().map((day: Date, index: any) => (
          <React.Fragment key={`$calendar-row-${day?.getMonth()}-${day?.getDate()}`}>
            {day?.getDate() === 1 ? (
              <>
                <Grid.Row>
                  <Header key={`$calendar-header-${index}`} style={{ margin: "1.5rem 0 0rem .5rem", fontSize: "2rem", padding: 0, textAlign: "center" }}>
                    {monthNames[day.getMonth()]}
                  </Header>
                </Grid.Row>
                <Grid.Row>
                  {iterator(7).map((_, i) => <div id={styles.dayRoundedBox} style={{ color: "black", height: "unset", fontSize: 12, padding: 0, textAlign: "center", border: "none", background: "transparent" }}>{getDayName(i)}</div>)}
                </Grid.Row>
                {iterator(day.getDay()).map(() => <div style={{ border: "none", background: "transparent" }} id={styles.dayRoundedBox}><div className={styles.dayRoundContent}></div></div>)}
              </>
            ) : null}
            <DayComponent events={scheduledEventsStore.mappedEvents[day.toDateString().replace("/", "")]} testResultsForDate={Object.values(testResultsMap[day.toDateString()?.replace("/", "") ?? ""] ?? [])} date={day} />
          </React.Fragment>
        ))}
      </Grid >
    </>
  );
};

export default observer(CalendarComponent);
