import React, { useEffect, useState } from 'react';
import { Header, Transition } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import styles from './TestMessageStyle.module.css';
import { textToSpeech } from '../../../Services/TextToSpeech';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import i18n from '../../../i18n';

interface TestMessagingProps {
  message?: string;
  showBackground?: boolean | undefined;
}
const TestMessaging: React.FC<TestMessagingProps> = (props) => {
  const { message, showBackground } = props;
  const [visible, setVisible] = useState(false);
  const [newMessage, setNewMessage] = useState<string | undefined>(undefined);
  const { testingService } = useClearMindContext();
  const [messagePosition, setMessagePosition] = useState(styles.messageFooter);
  const isChecklistStep = testingService.currentState === "test-checklist" || testingService.currentState === "test-checklist-success" || testingService.currentState === "phone-orientation-check";

  useEffect(() => {
    const getMessageToPrompt = (message: string) => {
      if (message === i18n.t("testing.checkList.centerYourself") || message?.toLowerCase().includes(i18n.t("testing.identityConfirm").toLowerCase()) || message === i18n.t("testing.checkList.removeSunglasses")) {
        return message;
      }

      return testingService.currentState;
    };

    if (!newMessage?.includes(i18n.t("testing.finalize"))) {
      setVisible(false);
    }
    const getMessagePosition = () => {
      if (isChecklistStep) {
        setMessagePosition(styles.messageFooter);
      } else {
        setMessagePosition(styles.messageHeader);
      }
    };

    if (message) {
      const newMessageRef = setTimeout(() => {
        getMessagePosition();
        setNewMessage(message);
      }, 400);
      const visibleREf = setTimeout(() => {
        setVisible(true);
        textToSpeech(getMessageToPrompt(message));
      }, 600);

      return () => {
        clearInterval(newMessageRef);
        clearInterval(visibleREf);
      };
    }
  }, [message, testingService.currentState, newMessage, isChecklistStep]);

  const containerStyle = {
    padding: 10,
    backgroundColor: showBackground ? "rgba(0, 0, 0, 0.6)" : "",
    borderRadius: showBackground ? "0px 15px 15px 15px" : "",
    maxWidth: 900,
    margin: "auto",
  };

  return (
    <div className={messagePosition}>
      <Transition visible={visible} animation="scale" duration={200}>
        <div style={containerStyle}>
          <Header as={"h3"} textAlign={"center"} className={styles.header}>
            {newMessage}
          </Header>
        </div>
      </Transition>
    </div>
  );
};

export default observer(TestMessaging);
