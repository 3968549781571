import React from "react";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import BehaviorContractForm from "../Components/BehaviorContract/BehaviorContractForm";
import FrostedFrame from "../Components/Controls/FrostedFrame/FrostedFrame";
import { Redirect } from "react-router-dom";

const MemberOnboardingPage: React.FC = () => {
  const { behaviorContract, user } = useClearMindContext();

 
  if (!behaviorContract.isOnboardingLanguageSelected) {
    return <Redirect to="language-select" />;
  }
  if (behaviorContract.governmentIdRequired && !behaviorContract.idVerificationSessionComplete) {
    return <Redirect to="id-verification" />;
  }
  if (user.needsIndexedFace) {
    return <Redirect to="user-photo" />;
  }

  if (user.facilityCode.includes("MIAMIDV") && !behaviorContract.isDemographicsSet) {
    return <Redirect to="demographics" />;
  }

  if (behaviorContract.onboardingComplete) {
    return <Redirect to="member-dashboard" />;
  }

  behaviorContract.setMember(user);

  return (
    <>
      <FrostedFrame>
        <BehaviorContractForm behaviorContract={behaviorContract} />
      </FrostedFrame>
    </>
  );
};

export default MemberOnboardingPage;
