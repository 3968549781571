import axios from 'axios';
import { getSnapshot } from 'mobx-state-tree';
import { IUser } from '../Models/User';
import * as Sentry from "@sentry/react";

export interface BehaviorContractResponse {
  onboardingComplete: boolean;
  onboardingCompleteTimestamp: number;
  supporterOnboardingComplete: boolean;
  questionsAndAnswers: [{ id: string; question: string; answer: string }];
  member: IUser;
  supporters: IUser[];
  onboardingStepComplete: number;
  memberSignature: string;
  supporterSignature: string;
  facilityCode: string[];
  eventsVisibleToSupporter: boolean;
  eventsVisibleToMonitoringPartner: boolean;
  numberOfTests: number;
  isNewMember: boolean;
  idVerified: boolean;
  idVerificationSessionComplete: boolean;
}

export const SaveBehaviorContract = async (behaviorContract: any) => {
  let body = getSnapshot(behaviorContract) as any;

  if (!behaviorContract.onboardingCompleteTimestamp && behaviorContract.onboardingComplete) {
    behaviorContract.onboardingCompleteTimestamp = Math.round(new Date().getTime() / 1000);

    if (behaviorContract.numberOfTests === 2 || behaviorContract.numberOfTests === 3) {
      try {
        await axios.post(`/semi-monthly-signup/${behaviorContract.memberId}`);
      } catch (error: any) {
        Sentry.captureException(error);
      }
    }
  }

  body = {
    memberId: body.member?.userId,
    questionsAndAnswers: [].concat(...body.questions),
    supporterIds: behaviorContract.supporters.map((supporter: IUser) => supporter.userId),
    onboardingStepComplete: behaviorContract.onboardingStepComplete,
    onboardingComplete: behaviorContract.onboardingComplete,
    onboardingCompleteTimestamp: behaviorContract.onboardingCompleteTimestamp,
    supporterOnboardingComplete: behaviorContract.supporterOnboardingComplete,
    memberSignature: behaviorContract.memberSignature,
    supporterSignature: behaviorContract.supporterSignature,
    facilityCode: behaviorContract.facilityCode,
    eventsVisibleToSupporter: behaviorContract.eventsVisibleToSupporter,
    eventsVisibleToMonitoringPartner: behaviorContract.eventsVisibleToMonitoringPartner,
    numberOfTests: behaviorContract.numberOfTests,
    race: behaviorContract.race,
    sex: behaviorContract.sex,
    ethnicity: behaviorContract.ethnicity,
    isNewMember: behaviorContract.isNewMember,
    idVerified: behaviorContract.idVerified,
    idVerificationSessionComplete: behaviorContract.idVerificationSessionComplete
  };

  delete body.questions;

  const response = await axios.put("/behavior-contract", body);

  return response.data;
};

export const GetBehaviorContract = async () => {
  try {
    const response = await axios.get<BehaviorContractResponse>("/behavior-contract");
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return undefined;
    } else {
      Sentry.captureException(error);
    }
    throw error;
  }
};
