import React from "react";
import styles from "./DashboardTileComponent.module.css";
import { useHistory } from "react-router";
import resultsTrackerIcon from "../../Images/DashboardPhotos/MyResultsIcon.png";
import allowableSubsIcon from "../../Images/DashboardPhotos/AllowableSubstancesIcon.png";
import understandingResultsIcon from "../../Images/DashboardPhotos/UnderstandingResultsIcon.png";
import FaqIcon from "../../Images/DashboardPhotos/TestingInfoImg1.png";
import TestingTipsIcon from "../../Images/DashboardPhotos/HelpfulTipsIcon.png";
import howToIcon from "../../Images/DashboardPhotos/HowToVideoIcon.png";
import DashboardTile from "./DashboardTile";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import i18n from "../../i18n";

const DashboardTileComponent: React.FC = () => {
  const history = useHistory();
  const { user } = useClearMindContext();

  const handleTestPageClick = () => {
    history.push("/test-results");
  };
  const UnderstandingResultsPageClick = () => {
    history.push("/understanding-results");
  };
  const handleAllowableSubstancesPageClick = () => {
    history.push("/allowable-substances");
  };
  const testingFAQClick = () => {
    if (user.isMember) {
      history.push("/faqs-about-testing");
    } else {
      history.push("/faqs-about-testing-supporter");
    }
  };
  const testingTipsPageClick = () => {
    history.push("/testing-tips");
  };
  const howToVideoClick = () => {
    history.push("/how-to-video");
  };

  return (
    <>
      <div className={styles.gridContainer}>
        <div className={styles.gridItem}>
          <DashboardTile text={i18n.t("dashboard.tiles.resultsTracker")} image={resultsTrackerIcon} onClick={handleTestPageClick} />
        </div>
        {user.isMember && (
          <div className={styles.gridItem}>
            <DashboardTile text={i18n.t("dashboard.tiles.allowableSubstances")} image={allowableSubsIcon} onClick={handleAllowableSubstancesPageClick} />
          </div>
        )}
        <div className={styles.gridItem}>
          <DashboardTile text={i18n.t("dashboard.tiles.understandingResults")} image={understandingResultsIcon} onClick={UnderstandingResultsPageClick} />
        </div>
        <div className={styles.gridItem}>
          <DashboardTile text={i18n.t("dashboard.tiles.faqs")} image={FaqIcon} onClick={testingFAQClick} />
        </div>
        {user.isMember && (
          <div className={styles.gridItem}>
            <DashboardTile text={i18n.t("dashboard.tiles.testingTips")} image={TestingTipsIcon} onClick={testingTipsPageClick} />
          </div>
        )}
        <div className={styles.gridItem}>
          <DashboardTile text={user.isMember ? i18n.t("dashboard.tiles.howToVideoMember") : i18n.t("dashboard.tiles.howToVideoSupporter")} image={howToIcon} onClick={howToVideoClick} />
        </div>
      </div>
    </>
  );
};

export default DashboardTileComponent;
