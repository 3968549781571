import React, { useEffect, useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import ClearMindButton from './Controls/ClearMindButton/ClearMindButton';

interface InputListProps {
    buttonText: string,
    inputPlaceholder: string,
    answer: string,
    onAnswerChange: (newAnswer: string) => void
}
const InputList: React.FC<InputListProps> = (props) => {
    const { buttonText, onAnswerChange, answer, inputPlaceholder } = props;
    const [inputs, setInputs] = useState<string[]>(answer.split(', '));
    const [inputValues, setInputValues] = useState<string[]>(answer.split(', '));

    useEffect(() => {
        onAnswerChange(inputValues.join(', '))
    }, [inputValues, onAnswerChange])

    const handleInputChange = (index: number, value: string) => {
        const updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
        const updatedInputValues = [...inputValues];
        updatedInputValues[index] = value;
        setInputValues(updatedInputValues);
    };

    const addInput = () => {
        const updatedInputs = [...inputs, ''];
        if (inputs[inputs.length - 1].trim().length !== 0) {
            setInputs(updatedInputs);
            setInputValues([...inputValues, '']);
        }

    };

    const removeInput = (index: number) => {
        if (inputs.length === 1) {
            // Clear the input value if it's the last remaining input
            setInputs(['']);
        } else {
            const updatedInputs = [...inputs];
            updatedInputs.splice(index, 1);
            setInputs(updatedInputs);
        }
        const updatedInputValues = [...inputValues];
        updatedInputValues.splice(index, 1);
        setInputValues(updatedInputValues);
    };
    return (
        <div style={{ textAlign: "center" }}>
            {inputs.map((input, index) => (
                <div key={index} style={{ marginBottom: '1rem' }}>
                    <Input
                        style={{ width: "100%" }}
                        value={input}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        placeholder={inputPlaceholder}
                        icon={
                            <Icon
                                link
                                name="trash alternate outline"
                                color="red"
                                onClick={() => {
                                    removeInput(index)
                                }}
                            />
                        }
                    />
                </div>
            ))}
            <ClearMindButton opaque onClick={addInput} >
                {buttonText}
            </ClearMindButton>
        </div>
    );
};

export default InputList;