import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";
import { Header, Checkbox, Segment, List, Grid } from "semantic-ui-react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { textToSpeech } from "../../Services/TextToSpeech";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import FrostedFrame from "../Controls/FrostedFrame/FrostedFrame";
import { useTranslation } from "react-i18next";

const RequiredMaterialsStep: React.FC = () => {
  const { testingService } = useClearMindContext();
  const { testSession } = testingService;
  const history = useHistory();
  const { t } = useTranslation();

  const handleNext = () => {
    testingService.onRequiredMaterialsComplete();
    textToSpeech(t("testing.getStarted"));
    testSession?.setPhoneStandAgreement(false);
    history.push("/testing");
  };


  return (
    <FrostedFrame>
      <Grid textAlign="center" stackable>
        <Grid.Row>
          <Grid.Column>
            <Header style={{ color: "var(--secondary-color)" }} as="h3">
              {t("testing.requiredMaterials.header")}
            </Header>
            <Header.Subheader>{t("testing.requiredMaterials.confirm")}</Header.Subheader>

            <Segment
              basic
              style={{
                color: "#333",
                fontSize: "16px",
              }}
              textAlign="left"
            >
              <List bulleted style={{ margin: "0 auto", width: "fit-content", color: "#333" }}>
                <List.Item>{t("testing.requiredMaterials.stand")}</List.Item>
                <List.Item>{t("testing.requiredMaterials.clearSpace")}</List.Item>
                <List.Item>{t("testing.requiredMaterials.unOpened")}</List.Item>
              </List>
              <br />
              {t("testing.requiredMaterials.avoidFail")} <br />
              <br />
              <Checkbox
                label={
                  <label style={{ color: "#333" }}>
                    {t("testing.requiredMaterials.iConfirm")}
                    <List bulleted style={{ marginTop: "12px", width: "fit-content", color: "#333" }}>
                      <List.Item>{t("testing.requiredMaterials.inView")}</List.Item>
                      <List.Item>{t("testing.requiredMaterials.interruption")}</List.Item>
                    </List>
                  </label>
                }
                onChange={(_, d) => {
                  testSession?.setRequiredMaterialsAgreement(d.checked!);
                }}
              />
            </Segment>
            <ClearMindButton opaque disabled={!testSession?.requiredMaterialsAgreement} onClick={handleNext}>
              {t("testing.requiredMaterials.begin")}
            </ClearMindButton>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FrostedFrame>
  );
};

export default observer(RequiredMaterialsStep);
