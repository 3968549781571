import React, { useEffect, useState } from 'react';
import { Header, Image, Accordion, Icon, Segment } from 'semantic-ui-react';
import FooterBackButton from '../Components/BackButton/FooterBackButton';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import faqHeaderImage from '../Images/DashboardPhotos/TestingInfoImg1.png';
import { scrollToTop } from '../Services/PageService';

const TestingFAQsSupporterPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const pageStyles = {
    link: { color: '#3EBABF', fontWeight: 'bold' },
  };

  const handleClick = (e: any, data: any) => {
    const index = data.index;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <FrostedFrame>
        <Segment basic>
          <Header as={'h3'} textAlign='center'>
            Frequently Asked Questions About Testing
          </Header>
        </Segment>
        <br />
        <Image centered src={faqHeaderImage} />
        <br />
        <Segment basic>
          <Accordion styled fluid>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              When does Member testing start?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              Testing begins the day they receive their Enrollment Kit. The
              Enrollment Kit ships once your account set up is complete and
              arrives quickly to the address on listed on file for the Member.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How do they know when to test?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              A Member will receive a text and email notifying them when to
              test.  Our tests are randomized by day and time and possibly test
              type, depending on the selected program.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How long does it take a Member to complete a test in the app?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              The first test will take the longest, about 20 mins. As they
              become familiar with the process, it is closer to 17 minutes each
              time.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How do they take their test?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              Once they receive a test request, they open the app when they have
              the required materials and a clear work surface. Once the test
              starts, they are guided through each step, given direction each
              step of the way. It is a very straightforward process. We are very
              clear on what can cause a Fail of any type.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 4}
              index={4}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How long do they have to take the test once requested?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              Their testing window is determined by the selected program,
              typically 4 hours unless otherwise determined by a Monitoring
              Partner, and always listed in the test request text. We send text
              reminders throughout the testing window. A missed test is a Fail
              by Decline.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 5}
              index={5}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What if they don’t have everything they need when sent a test
              request? Like a TestPACKET?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
              It is their responsibility to have a TestPACKET accessible. The
              inability to take a test within the window, for any reason, is a
              Decline. Ample time is given within the test window to
              prepare to test, charge a phone battery, or relocate to an area
              with sufficient cellular or WiFi service. We make it very easy to
              take a break at work, have time between classes or find a rest
              stop in order to test.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 6}
              index={6}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What if something interrupts a test, like a phone call?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              Members are advised to put their phone on Do Not Disturb, which
              minimizes this issue. We suggest it is best to take test time
              seriously and eliminate distractions. Misstep occurs when
              a testing experience is interrupted, or there is evidence of
              tampering with testing process or product. Once you are familiar
              with our testing, tests are quick and easy!
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 7}
              index={7}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How does the app know my identity?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>
              We use Facial Recognition and Human Review to verify your
              identity. The test will not progress if your identity is not
              confirmed or switched during a test. This is a Misstep and
              the misstep listed will be Identity Validation.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 8}
              index={8}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What if I can’t take my test?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              Missing a test results in a Decline. However, if you are
              working with a Monitoring Partner, notify them immediately so they
              know you are safe. Refer to their protocols for missing a test. If
              you are going to be unable to test for any extended amount of
              time, (ex. you are on “pass”) your Monitoring Partner should be
              notified in advance.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 9}
              index={9}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              Can they stop the test and start over?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 9}>
              Nope. This results in a Misstep as it disrupts Identity
              Validation or “chain of custody”. Once a test starts it cannot be
              interrupted.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 10}
              index={10}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What if they want to contest their test result?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 10}>
              Presently, contesting a result requires retesting with your
              Monitoring Partner onsite at their facility. Coming soon, we will
              have multiple confirmation options.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 11}
              index={11}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What if there is a tech issue or something goes wrong during
              testing?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 11}>
              If there is a tech issue or something happens beyond the Member
              control, they are guided to always communicate this to their
              Monitoring Partner immediately and to you, their Supporter(s) and
              contact use immediately. With our internal tracking capabilities,
              we are typically alerted at the time of a true issue.
              Communication is key! For suspected tech issues, please email{' '}
              <a style={pageStyles.link} href='mailto:support@clearlee.com'>
                support@clearlee.com
              </a>
              .
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 12}
              index={12}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              What do they do with their TestCUBE once the test is complete?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 12}>
              They can discard it. There is no way it could be used again
              without being detected. This would be an example of Fail by
              Misstep, Tamper.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 13}
              index={13}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              How soon are results available?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 13}>
              Results are processed quickly and human reviewed. We also review
              the testing process for Misstep and to re-confirm results. You
              will receive a text and email that the results are ready, and they
              are viewed once you log into the app. Rapid test results are
              typically shared within an hour.
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 14}
              index={14}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              Who gets the results?
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 14}>
              Results are only viewable by Member and their Support Network,
              which is any Supporter or Monitoring Partner listed in their
              account. They can change these parties at any time; however, all
              parties are notified of changes.
            </Accordion.Content>
          </Accordion>
        </Segment>
        <Segment textAlign='center' basic>
          <Header as={'h3'}>
            For additional questions and concerns, email{' '}
            <a style={pageStyles.link} href='mailto:support@clearlee.com'>
              support@clearlee.com{' '}
            </a>
            so we can ensure you have a great experience.{' '}
          </Header>
          <FooterBackButton />
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default TestingFAQsSupporterPage;
