import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import { withResponsiveContainer } from '../Components/Layout/ResponsiveContainer';
import ClearMindTestingPage from '../Pages/TestingPage';
import MemberOnboardingPage from '../Pages/MemberOnboardingPage';
import SupporterOnboardingPage from '../Pages/SupporterOnboardingPage';
import UserProfileDashboard from '../Pages/UserDashbordPage';
import UserPhotoPage from '../Pages/UserPhotoPage';
import { observer } from 'mobx-react-lite';
import MemberDashboard from '../Pages/MemberDashboard';
import MemberAllowableSubstancesPage from '../Pages/MemberAllowableSubstancesPage';
import SupporterDashboardPage from '../Pages/SupporterDashboardPage';
import SupporterResourcesPage from '../Pages/SupporterResourcesPage';
import MemberTestResultsPage from '../Pages/MemberTestResultsPage';
import HelpPage from '../Pages/HelpPage';
import Supporters from '../Pages/Supporters';
import LibraryPage from '../Pages/LibraryPage';
import TestSuccessPage from '../Pages/TestSuccessPage';
import WellnessAgreementPage from '../Pages/WellnessAgreementPage';
import PretestingPage from '../Pages/PretestingPage';
import ClearMindWebStream from '../Components/Testing/ClearMindWebcam';
import UnderstandingResultsPage from '../Pages/UnderstandingResultsPage';
import TestingFAQsMemberPage from '../Pages/TestingFAQsMemberPage';
import TestingTipsPage from '../Pages/TestingTipsPage';
import HowToVideoPage from '../Pages/HowToVideoPage';
import TestingFAQsSupporterPage from '../Pages/TestingFAQsSupporterPage';
 import OnSiteTestingPage from '../Pages/OnSiteTesting/OnSiteTestingPage';
import WelcomeStep from '../Components/BehaviorContract/WelcomeStep/WelcomeStep';
import LanguageSelectStep from "../Components/BehaviorContract/LanguageSelectStep";
import DemographicsFormStep from "../Pages/DemographicsFormStep";
import IdVerificationStep from '../Components/BehaviorContract/IdVerificationStep';

const Routes = () => {
  const { user, behaviorContract } = useClearMindContext();

  const getRedirectPath = () => {
    
    if (behaviorContract.onboardingComplete && user.needsIndexedFace) {
      return "user-photo";
    }
    if (behaviorContract.governmentIdRequired && !behaviorContract.idVerificationSessionComplete) {
      return "id-verification";
    }
    
    if (user.isMember) {
      return behaviorContract.onboardingComplete ? "member-dashboard" : "member-onboarding";
    }

    return behaviorContract.supporterOnboardingComplete ? "supporter-dashboard" : "supporter-onboarding";
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Redirect from="/" exact to={getRedirectPath()} />
          <Route path="/demographics" component={withResponsiveContainer(DemographicsFormStep, "")} />
          <Route path="/welcome" component={withResponsiveContainer(WelcomeStep, "")} />
          <Route path="/member-onboarding" component={withResponsiveContainer(MemberOnboardingPage, "")} />
          <Route path="/on-site-testing" component={withResponsiveContainer(OnSiteTestingPage, "")} />
          <Route path="/supporter-onboarding" component={withResponsiveContainer(SupporterOnboardingPage, "")} />
          <Route path="/user-photo" component={withResponsiveContainer(UserPhotoPage, "")} />
          <Route path="/language-select" component={withResponsiveContainer(LanguageSelectStep, "")} />
          <Route path="/id-verification" component={withResponsiveContainer(IdVerificationStep, "")} />
          <Route path="/pre-testing" component={PretestingPage} />
          <Route path="/testing" component={ClearMindTestingPage} />
          <Route path="/user-profile" component={withResponsiveContainer(UserProfileDashboard, "")} />
          <Route path="/member-dashboard" component={withResponsiveContainer(MemberDashboard, "")} />
          <Route path="/test-results" component={withResponsiveContainer(MemberTestResultsPage, "")} />
          <Route path="/understanding-results" component={withResponsiveContainer(UnderstandingResultsPage, "")} />
          <Route path="/faqs-about-testing" component={withResponsiveContainer(TestingFAQsMemberPage, "")} />
          <Route path="/faqs-about-testing-supporter" component={withResponsiveContainer(TestingFAQsSupporterPage, "")} />
          <Route path="/testing-tips" component={withResponsiveContainer(TestingTipsPage, "")} />
          <Route path="/how-to-video" component={withResponsiveContainer(HowToVideoPage, "")} />
          <Route path="/allowable-substances" component={withResponsiveContainer(MemberAllowableSubstancesPage, "")} />
          <Route path="/supporter-dashboard" component={withResponsiveContainer(SupporterDashboardPage, "")} />
          <Route path="/supporter-resources" component={withResponsiveContainer(SupporterResourcesPage, "")} />
          <Route path="/supporter-results" component={withResponsiveContainer(SupporterResourcesPage, "")} />
          <Route path="/help" component={withResponsiveContainer(HelpPage, "")} />
          <Route path="/wellness-agreement" component={withResponsiveContainer(WellnessAgreementPage, "")} />
          <Route path="/supporters" component={withResponsiveContainer(Supporters, "")} />
          <Route path="/library" component={withResponsiveContainer(LibraryPage, "")} />
          <Route path="/test-success" component={withResponsiveContainer(TestSuccessPage, "")} />
          <Route path="/web-cam" component={withResponsiveContainer(ClearMindWebStream, "")} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default observer(Routes);
