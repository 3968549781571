import { getSnapshot } from "mobx-state-tree";
import axios from "axios";

export const DeleteScheduledEvent = async (scheduledEventId: string) => {
  const response = await axios.delete(`/scheduled-events/${scheduledEventId}`);

  return response;
}

export const SaveScheduledEvent = async (scheduledEvent: any) => {
  let body = getSnapshot(scheduledEvent);

  body = {
    memberId: scheduledEvent.memberId,
    eventType: scheduledEvent.eventType,
    eventDate: scheduledEvent.eventDate,
    eventDetails: scheduledEvent.eventDetails,
    privateEvent: scheduledEvent.privateEvent,
  };

  const response = await axios.post("/scheduled-events", body);
  return response.data;
};

export const UpdateScheduledEvent = async (scheduledEvent: any) => {
  let body = getSnapshot(scheduledEvent);

  body = {
    id: scheduledEvent.id,
    memberId: scheduledEvent.memberId,
    eventType: scheduledEvent.eventType,
    eventDate: scheduledEvent.eventDate,
    eventDetails: scheduledEvent.eventDetails,
    privateEvent: scheduledEvent.privateEvent,
  };

  const response = await axios.patch("/scheduled-events", body);
  return response.data;
};

export const GetScheduledEventsByMemberId = async (memberId: string | undefined) => {
  const response = await axios.get(`/scheduled-events?memberId=${memberId}`);
  return response.data;
};