import React from 'react';
import { Header } from 'semantic-ui-react';

interface SubProps {
  medication?: string | null | undefined;
}

const SubstanceCard: React.FC<SubProps> = (props) => {
  const { medication } = props;

  if (!medication) {
    return null;
  }

  return (
    <Header textAlign='center' as={'h3'}>
      {medication}
    </Header>
  );
};

export default SubstanceCard;
