import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import { Progress } from 'semantic-ui-react';
import GenericStep from './GenericStep';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import BehaviorContract from '../../Models/BehaviorContract';
import TermsAndConditionsStep from './TermsAndConditionsStep';
import AddSupportersStep from './AddSupportersStep';
import ReviewStep from './ReviewStep';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useHistory } from 'react-router';
import { trackPromise } from 'react-promise-tracker';
import VerifyPhoneNumber from './VerifyPhoneNumber/VerifyPhoneNumberStep';
import FinalStep from "./FinalStep/FinalStep";
import FacilityPartnerStep from './FacilityCodeStep/FacilityPartnerStep';
import TimeZoneStep from './TimeZoneStep/TimeZoneStep';
import ShippingAddressStep from './ShippingAddressStep/ShippingAddressStep';
import BirthdateStep from '../BirthdateStep/BirthdateStep';
import i18n from '../../i18n';
import { GetFacilityPartner } from '../../Services/FacilityPartnerService';

interface BehaviorContractFormProps {
  behaviorContract: Instance<typeof BehaviorContract>;
}

const BehaviorContractForm: React.FC<BehaviorContractFormProps> = (props) => {
  const { questions } = props.behaviorContract;
  const { behaviorContract } = props;
  const { user } = useClearMindContext();
  const history = useHistory();
  const [currentStep, setCurrenStep] = useState(
    behaviorContract.onboardingStepComplete + 1
  );
  const [isEdit, setIsEdit] = useState(false);
  const termsLink = 'https://clearlee.com/user-agreement/';

  if (user.hasFacilityPartner) {
    behaviorContract.setFacilityCode(user.facilityCode!);

    user.facilityCode.forEach(async (code) => {
      await GetFacilityPartner(code).then((partner: any) => {
        if (partner.some((partner: any) => partner.governmentIdRequired === true)) {
          behaviorContract.setGovernmentIdRequired(true)
        }
      });
    });
  }

  const beforeStepChange = async () => {
    behaviorContract.setStepCompleted(currentStep);
    await trackPromise(behaviorContract.save());
  };

  const onStepChange = async (stepChange: any) => {
    setCurrenStep(stepChange.activeStep);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const submit = async () => {
    if (user.isMember) {
      behaviorContract.setOnboardingComplete(true);
    } else {
      behaviorContract.setSupporterOnboardingComplete(true);
    }
    await trackPromise(behaviorContract.saveSupporters());
    await trackPromise(behaviorContract.save());
    history.push('/');
  };

  const mapQuestions: Function = (): JSX.Element[] => {
    return questions.map((step: any, index: number) => (
      <GenericStep
        key={`step-${index}`}
        questions={step}
        isFinalStep={false}
        isEdit={isEdit}
        beforeStepChange={beforeStepChange}
      />
    ));
  };

  const stepLengthByRole = user.isSupporter ? 3 : 8;
  const calculateProgressPercent = user.isSupporter ? Math.ceil(((currentStep) / (stepLengthByRole)) * 100) : Math.ceil(((currentStep - 1) / (questions.length + stepLengthByRole)) * 100);

  return (
    <>
      <div style={{ textAlign: "center", padding: 10 }}>
        <Progress style={{ marginBottom: 0, width: "100%" }} percent={calculateProgressPercent} size="small" color="teal"></Progress>
      </div>

      {user.isSupporter ? (
        <StepWizard initialStep={currentStep} onStepChange={onStepChange} className={"no-border"} isHashEnabled={true}>
          <VerifyPhoneNumber beforeStepChange={beforeStepChange} />
          <TermsAndConditionsStep termsLink={termsLink} beforeStepChange={beforeStepChange} />
          <FinalStep message={i18n.t("onboarding.finalStep.supporterMsg")} route={"/supporter-dashboard"} submit={submit} {...props} />
        </StepWizard>
      ) : (
        <StepWizard initialStep={currentStep} onStepChange={onStepChange} className={"no-border"} isHashEnabled={true}>
          <FacilityPartnerStep behaviorContract={behaviorContract} beforeStepChange={beforeStepChange} />
          {mapQuestions()}
          {!user.hasBusinessPartner && <AddSupportersStep behaviorContract={behaviorContract} beforeStepChange={beforeStepChange} isEdit={isEdit} />}
          <VerifyPhoneNumber beforeStepChange={beforeStepChange} />
          <ReviewStep {...props} inviteStep={questions.length + 3 - 2} beforeStepChange={beforeStepChange} setIsEdit={setIsEdit} />
          <TimeZoneStep beforeStepChange={beforeStepChange} />
          <BirthdateStep beforeStepChange={beforeStepChange} />
          <ShippingAddressStep beforeStepChange={beforeStepChange} />
          <TermsAndConditionsStep termsLink={termsLink} beforeStepChange={beforeStepChange} />
          <FinalStep message={i18n.t("onboarding.finalStep.memberMsg")} route={"/member-dashboard"} submit={submit} {...props} />
        </StepWizard>
      )}
    </>
  );
};

export default observer(BehaviorContractForm);
