import { observer } from 'mobx-react-lite';
import React from "react";
import { Checkbox, Header } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import styles from './Testing.module.css';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from "react-i18next";

const TestingIntroduction: React.FC = () => {
  const { testingService } = useClearMindContext();

  const { testSession } = testingService;
  const { promiseInProgress } = usePromiseTracker();

  const onContinueClick = () => {
    testingService.onIntroductionAcknowledgement();
  };

  const { t } = useTranslation();

  return (
    <>
      <FrostedFrame>
        <div className={styles.requirementsContainer}>
          <Header style={{ color: "var(--secondary-color)" }} as={"h2"} textAlign="center">
            {t("testing.testingRequirements.header")}
          </Header>
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.consent")}</label>}
            onChange={(_, d) => {
              testSession?.setNotificationConsentAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.iAgree")}</label>}
            onChange={(_, d) => {
              testSession?.setConsumptionAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.avoidSubstances")}</label>}
            onChange={(_, d) => {
              testSession?.setAvoidSubstancesAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.batteryCharged")}</label>}
            onChange={(_, d) => {
              testSession?.setBatteryChargedAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.dontDisturb")}</label>}
            onChange={(_, d) => {
              testSession?.setDoNotDisturbAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.wifi")}</label>}
            onChange={(_, d) => {
              testSession?.setWifiConfirmationAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
          <Checkbox
            label={<label className={styles.label}>{t("testing.testingRequirements.acknowledge")}</label>}
            onChange={(_, d) => {
              testSession?.setMisstepWarningAgreement(!!d.checked);
            }}
          />
          <br />
          <br />
        </div>
        <div style={{ textAlign: "center" }}>
          <ClearMindButton opaque disabled={!testSession?.testingRequirementsChecked} onClick={onContinueClick} loading={promiseInProgress}>
            {t("testing.testingRequirements.next")}
          </ClearMindButton>
        </div>
      </FrostedFrame>
    </>
  );
};

export default observer(TestingIntroduction);
