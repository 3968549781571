import React from "react";
import { Card, Container, Icon } from "semantic-ui-react";
import { IUser } from "../../Models/User";

interface UserCardProps {
  user?: IUser;
  index?: number;
  handleDelete?: (index: number) => Promise<void>;
}

const UserCard: React.FC<UserCardProps> = (props) => {
  const { user, handleDelete, index } = props;

  if (!user) {
    return null;
  }
  const styles = {
    card: { backgroundColor: "var(--secondary-color)", borderRadius: "15px" },
    text: { color: "white" },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      color: "white",
    },
  };
  const icon = handleDelete !== undefined && index !== undefined;

  return (
    <>
      <Card style={styles.card} key={`supporter-${index}`}>
        <Card.Content>
          {icon && (
            <Container style={styles.iconContainer}>
              <Icon onClick={() => handleDelete(index)} name="delete" link corner="top right" size="large" />
            </Container>
          )}
          <Card.Header style={styles.text}>{user.fullName}</Card.Header>
          <Card.Meta style={styles.text}>{user.phoneNumber}</Card.Meta>
          <Card.Description style={styles.text}>{user.email}</Card.Description>
        </Card.Content>
      </Card>
    </>
  );
};

export default UserCard;
