import styles from './ErrorStyle.module.css';
import { Header, Segment } from 'semantic-ui-react';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';

const ErrorPage: React.FC = () => {
  return (
    <FrostedFrame>
      <Segment textAlign={'center'} basic placeholder>
        <Header as='p'>
          <div>
            <h1 className={styles.oops}>Oops!</h1>
          </div>
          <br />
          Something went wrong. Please restart <br /> your app.
        </Header>
        <div>
          <p style={{ color: '#fff' }}>
            If you keep experiencing problems please email:{' '}
            <a
              href='mailto:support@clearlee.com'
              style={{ color: 'white' }}
            >
              support@clearlee.com
            </a>
          </p>
        </div>
      </Segment>
    </FrostedFrame>
  );
};

export default ErrorPage;
