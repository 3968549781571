import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import {
  changeEmail,
  isValidEmail,
  verifyEmail,
} from '../../Services/UserProfileService';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import SuccessDisplay from '../Messaging/SuccessDisplay';
import { trackPromise } from 'react-promise-tracker';
import * as Sentry from "@sentry/react";
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import { useTranslation } from "react-i18next";

const ChangeEmail: React.FunctionComponent = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [changeEmailSuccessMessage, setchangeEmailSuccessMessage] = useState("");
  const [verificationSuccessMessage, setVerificationSuccessMessage] = useState("");

  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState("0");
  const { user } = useClearMindContext();
  const { t, i18n } = useTranslation();

  const onChangeEmailClick = async () => {
    try {
      if (!isValidEmail(email)) {
        setErrorMessage(i18n.t("dashboard.profile.changeEmail.invalidEmailMessage"));

        return;
      }

      setErrorMessage("");
      setchangeEmailSuccessMessage("");
      setVerificationSuccessMessage("");
      await trackPromise(changeEmail(email, user.userId!));
      setShowVerify(true);
      setchangeEmailSuccessMessage(i18n.t("dashboard.profile.changeEmail.verificationCodeMessage"));
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  const onVerifyClick = async () => {
    try {
      setErrorMessage("");
      setVerificationSuccessMessage("");
      await trackPromise(verifyEmail(code));
      setVerificationSuccessMessage(i18n.t("dashboard.profile.changeEmail.thankYouMessage"));
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Header>{t("dashboard.profile.changeEmail.changeEmail")}</Header>
      <Form>
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <label>{t("dashboard.profile.changeEmail.newEmail")}</label>
                <Input placeholder={t("dashboard.profile.changeEmail.newEmail")} onChange={(e) => setEmail(e.target.value)} fluid />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ClearMindButton opaque style={{ width: 153 }} onClick={onChangeEmailClick}>
                <Button.Content visible>{t("dashboard.profile.changeEmail.changeEmail")}</Button.Content>
              </ClearMindButton>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          {showVerify && (
            <>
              <Grid.Column>
                <SuccessDisplay message={changeEmailSuccessMessage} />
              </Grid.Column>
              <Grid.Row>
                <Grid.Column>
                  <Input placeholder="Code" onChange={(e) => setCode(e.target.value)} fluid />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button style={{ width: 153 }} onClick={onVerifyClick}>
                    {t("dashboard.profile.changeEmail.verify")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Column>
              <ErrorDisplay message={errorMessage} />
              <SuccessDisplay message={verificationSuccessMessage} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default ChangeEmail;
