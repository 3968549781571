import React from 'react';
import CreateControlForQuestion from './StepControlFactory';
import { IBehaviorContractQuestion } from '../../Models/BehaviorContractQuestion';
import { observer } from 'mobx-react';
import StepButtons from './StepButtons';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import TreatmentHistoryStep from './TreatmentHistoryStep';

interface GenericStepProps {
  beforeStepChange: () => Promise<void>;
  previousStep?: () => void;
  questions: IBehaviorContractQuestion[];
  isFinalStep: boolean;
  isEdit: boolean;
}

function GenericStep(props: GenericStepProps) {
  const { questions, beforeStepChange, isEdit } = props;
  const { user } = useClearMindContext();
  const isTreatmentStep = questions.some(
    (question) => question.id === 'treatments'
  );
  return (
    <>
      {isTreatmentStep ? (
        <TreatmentHistoryStep treatmentStep={questions} />
      ) : (
        questions.map(CreateControlForQuestion)
      )}

      <StepButtons
        {...props}
        isEdit={isEdit}
        isMember={user.isMember}
        beforeStepChange={beforeStepChange}
        nextEnabled={questions.every((question) => question.requirementMet)}
      />
    </>
  );
}

export default observer(GenericStep);
