import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import FooterBackButton from '../BackButton/FooterBackButton';
import CreateControlForQuestion from '../BehaviorContract/StepControlFactory';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import SubstanceCard from './SubstanceCard';
import i18n from '../../i18n';

const AllowableSubstancesComponent: React.FC = () => {
  const { behaviorContract } = useClearMindContext();

  const [updateMedicationClick, setUpdateMedicationClick] = useState(false);
  const [updateSupplementClick, setUpdateSupplementClick] = useState(false);
  
  const onClick = (conditionalBool: boolean, setConditionalBool: React.Dispatch<React.SetStateAction<boolean>>, returnBool: boolean, setReturnBool: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (conditionalBool) {
      setConditionalBool(!conditionalBool);
    }
    return setReturnBool(!returnBool);
  };

  const addMedication = async () => {
    await behaviorContract.save()
    onClick(updateSupplementClick, setUpdateSupplementClick, updateMedicationClick, setUpdateMedicationClick)
  };

  const addSupplement = async () => {
    await behaviorContract.save()
    onClick(updateMedicationClick, setUpdateMedicationClick, updateSupplementClick, setUpdateSupplementClick)
  };

  return (
    <Grid centered>
      <Grid.Row
        style={{
          maxWidth: "800px",
          paddingTop: "50px",
        }}
      >
        <FrostedFrame>
          <Segment basic style={{ width: "320px" }}>
            <Header style={{ color: "var(--secondary-color)" }} as={"h2"} icon textAlign="center">
              <Icon name="pills" circular />
              {i18n.t("dashboard.allowableSubstances.medicationHeader")}
            </Header>

            <SubstanceCard medication={behaviorContract.medication?.answer} />

            <Segment textAlign={"center"} basic>
              <ClearMindButton
                opaque
                disabled={updateMedicationClick}
                onClick={() => {
                  onClick(updateSupplementClick, setUpdateSupplementClick, updateMedicationClick, setUpdateMedicationClick);
                }}
              >
                {i18n.t("dashboard.allowableSubstances.addMedication")}
              </ClearMindButton>
              {updateMedicationClick && (
                <>
                  {CreateControlForQuestion(behaviorContract.medication, 1)}
                  <Segment textAlign={"center"} basic>
                    <ClearMindButton opaque onClick={addMedication}>
                      {i18n.t("dashboard.allowableSubstances.update")}
                    </ClearMindButton>
                  </Segment>
                </>
              )}
            </Segment>
          </Segment>
        </FrostedFrame>
        <FrostedFrame>
          <Segment basic style={{ width: "320px" }}>
            <Header style={{ color: "var(--secondary-color)" }} as={"h2"} icon textAlign="center">
              <Icon name="tree" circular />
              <Header.Content>{i18n.t("dashboard.allowableSubstances.supplementHeader")}</Header.Content>
              <SubstanceCard medication={behaviorContract.supplements?.answer} />
            </Header>
            <Segment textAlign={"center"} basic>
              <ClearMindButton
                opaque
                disabled={updateSupplementClick}
                onClick={() => {
                  onClick(updateMedicationClick, setUpdateMedicationClick, updateSupplementClick, setUpdateSupplementClick);
                }}
              >
                {i18n.t("dashboard.allowableSubstances.addSupplement")}
              </ClearMindButton>
              {updateSupplementClick && (
                <>
                  {CreateControlForQuestion(behaviorContract.supplements, 1)}
                  <Segment textAlign={"center"} basic>
                    <ClearMindButton opaque onClick={addSupplement}>
                      {i18n.t("dashboard.allowableSubstances.update")}
                    </ClearMindButton>
                  </Segment>
                </>
              )}
            </Segment>
          </Segment>
        </FrostedFrame>
        <FooterBackButton />
      </Grid.Row>
    </Grid>
  );
};

export default observer(AllowableSubstancesComponent);
