import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Header,
  HeaderContent,
  Input,
  Segment,
} from 'semantic-ui-react';
import ClearMindButton from '../../Controls/ClearMindButton/ClearMindButton';
import SuccessDisplay from '../../Messaging/SuccessDisplay';
import StepButtons from '../StepButtons';
import { trackPromise } from 'react-promise-tracker';
import {
  sendVerifyCode,
  verifyPhoneNumber,
  isUserPhoneNumberVerified,
} from '../../../Services/UserProfileService';
import ErrorDisplay from '../../Messaging/ErrorDisplay';
import ChangePhoneNumber from '../../UserDashboard/ChangePhoneNumber';
import { Link } from 'react-router-dom';
import styles from './VerifyStyles.module.css';
import * as Sentry from "@sentry/react";
import i18n from "../../../i18n";

interface VerifyPhoneNumberProps {
  nextStep?: () => void;
  beforeStepChange: () => Promise<void>;
}
const VerifyPhoneNumber: React.FC<VerifyPhoneNumberProps> = (props) => {
  const { nextStep, beforeStepChange } = props;
  const [verificationSuccessMessage, setVerificationSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [enableNextStep, setEnableNextStep] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showChangeNumber, setShowChangeNumber] = useState(false);
  const [code, setCode] = useState("");
  const [reSendVerifyCodeConfirmationMessage, setReSendVerifyCodeConfermationMessage] = useState("");
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);

  useEffect(() => {
    if (phoneNumberChanged) {
      setEnableNextStep(true);
    }
  }, [phoneNumberChanged]);

  const onSendVerifyCodeClick = async () => {
    try {
      await trackPromise(sendVerifyCode());
      setErrorMessage("");
      setVerificationSuccessMessage("");
      setShowVerify(true);
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  const reSendMessageHandler = async () => {
    try {
      setReSendVerifyCodeConfermationMessage(i18n.t("onboarding.verifyPhone.sentCodeMsg"));
      setTimeout(() => {
        setReSendVerifyCodeConfermationMessage("");
      }, 3000);
      await trackPromise(sendVerifyCode());
      setErrorMessage("");
      setVerificationSuccessMessage("");
      setShowVerify(true);
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  const onVerifyClick = async () => {
    try {
      setErrorMessage("");
      setVerificationSuccessMessage("");
      await trackPromise(verifyPhoneNumber(code));

      if ((await trackPromise(isUserPhoneNumberVerified())) === "true") {
        setShowVerify(false);
        setEnableNextStep(true);

        setVerificationSuccessMessage(i18n.t("onboarding.verifyPhone.thankYou"));
      } else {
        setErrorMessage(i18n.t("onboarding.verifyPhone.unsuccessful"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  const nextStepHandler = async () => {
    if (nextStep) {
      nextStep();
    }
  };

  const showVerifyNumberIfRequired = () => {
    if (showChangeNumber) {
      return null;
    }

    return (
      <Grid.Row>
        <Grid.Column>
          <ClearMindButton opaque style={{ width: 211 }} onClick={onSendVerifyCodeClick}>
            <Button.Content visible>{i18n.t("onboarding.verifyPhone.verifyNumber")}</Button.Content>
          </ClearMindButton>
        </Grid.Column>
      </Grid.Row>
    );
  };
  const showVerifyNumber = () => {
    if (!showVerify) {
      return null;
    }
    return (
      <>
        <Segment basic>
          <Segment basic>
            <Input placeholder={i18n.t("onboarding.verifyPhone.code")} onChange={(e) => setCode(e.target.value)} />
          </Segment>

          <Button id={code.length === 6 ? styles.clearMindButtonVerify : ""} style={{ width: 153, margin: "1rem" }} onClick={onVerifyClick}>
            <Button.Content visible>{i18n.t("onboarding.verifyPhone.verify")}</Button.Content>
          </Button>

          <HeaderContent style={{ fontSize: "9pt" }}>
            {i18n.t("onboarding.verifyPhone.hyperlink.noCode")}
            <Link style={{ color: "#00CCCB" }} onClick={reSendMessageHandler} to={"#"}>
              {" "}
              {i18n.t("onboarding.verifyPhone.hyperlink.here")}
            </Link>{" "}
            {i18n.t("onboarding.verifyPhone.hyperlink.resend")}
          </HeaderContent>
        </Segment>
        <Segment basic style={{ width: "400px", margin: "auto" }}>
          <SuccessDisplay message={reSendVerifyCodeConfirmationMessage} />
        </Segment>
      </>
    );
  };
  const showChangeNummberIfRequired = () => {
    if (!showChangeNumber) {
      return null;
    }
    return (
      <>
        <Segment basic>
          <Segment textAlign="center" basic>
            <ChangePhoneNumber setPhoneNumberChanged={setPhoneNumberChanged} />
          </Segment>
        </Segment>
      </>
    );
  };

  const verifyOrChangeNumberTextToggle = () => {
    if (!showChangeNumber) {
      return (
        <HeaderContent>
          ( {i18n.t("onboarding.verifyPhone.changeNumberHyperLink.incorrectPhone")}{" "}
          <Link
            style={{ color: "#00CCCB" }}
            onClick={() => {
              setErrorMessage("");
              setShowChangeNumber(!showChangeNumber);
              setShowVerify(false);
            }}
            to={"#"}
          >
            {i18n.t("onboarding.verifyPhone.changeNumberHyperLink.here")}
          </Link>
          . )
        </HeaderContent>
      );
    }
    return (
      <HeaderContent>
        ( {i18n.t("onboarding.verifyPhone.returnHyperLink.returnTo")}{" "}
        <Link
          style={{ color: "#00CCCB" }}
          onClick={() => {
            setShowChangeNumber(!showChangeNumber);
          }}
          to={"#"}
        >
          {i18n.t("onboarding.verifyPhone.returnHyperLink.verify")}
        </Link>{" "}
        )
      </HeaderContent>
    );
  };

  return (
    <>
      <Segment padded style={{ margin: "auto" }} basic textAlign="center">
        <Header textAlign="center" as={"h3"} icon>
          {i18n.t("onboarding.verifyPhone.header")}
        </Header>
        {!enableNextStep ? (
          <>
            {showVerifyNumberIfRequired()}
            {showVerifyNumber()}
            {showChangeNummberIfRequired()}
            <Grid.Row>
              <Grid.Column>
                <ErrorDisplay message={errorMessage} />
                <SuccessDisplay message={verificationSuccessMessage} />
              </Grid.Column>
            </Grid.Row>
            <Header textAlign="center" as={"h5"} icon>
              {verifyOrChangeNumberTextToggle()}
            </Header>
          </>
        ) : (
          <>
            <Header as="h3">
              <HeaderContent>
                {" "}
                <div>
                  <Header style={{ fontSize: "12vw" }} className={styles.verified}>
                    {i18n.t("onboarding.verifyPhone.verified")}
                  </Header>
                  <Header textAlign="center" as={"h3"}>
                    {i18n.t("onboarding.verifyPhone.allSet")}
                  </Header>
                </div>
              </HeaderContent>
            </Header>
          </>
        )}

        <StepButtons {...props} nextStep={nextStepHandler} isFinalStep={false} nextEnabled={enableNextStep} beforeStepChange={beforeStepChange} />
      </Segment>
    </>
  );
};

export default VerifyPhoneNumber;
