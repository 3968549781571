import { observer } from 'mobx-react-lite';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import styles from './Testing.module.css';
import { useTranslation } from "react-i18next";

const TestCompleteStep: React.FC = () => {
  const history = useHistory();
  const { testingService } = useClearMindContext();
  const { promiseInProgress } = usePromiseTracker();
  const { t } = useTranslation();

  const handleReturnHomeButton = () => {
    testingService.reset();
    history.push("/");
  };
  const handleOnSiteTestingButton = () => {
    testingService.reset();
    history.push("/on-site-testing");
  };
  const headerText = () => {
    if (JSON.parse(showOnsiteTestingButton) === true) {
      return (
        <Segment style={{ color: "#fff", maxWidth: "650px", margin: "auto" }} basic textAlign="left">
          <Header style={{ paddingBottom: "1em" }} textAlign="center">
            {t("testing.testComplete.thanks")}
            <br /> <br />
            {t("testing.testComplete.contest")}
          </Header>
        </Segment>
      );
    }
    return (
      <Segment style={{ color: "#fff", maxWidth: "650px", margin: "auto" }} basic textAlign="left">
        <Header style={{ paddingBottom: "1em" }} textAlign="center">
          {t("testing.testComplete.thanks")}
        </Header>
      </Segment>
    );
  };
  const showOnsiteTestingButton = process.env.REACT_APP_SHOW_FORMFOX_ONSITE_TESTING_FLOW!;

  return (
    <>
      <FrostedFrame>
        <Grid textAlign="center" stackable>
          <Grid.Row>
            <Grid.Column>
              <h1 className={styles.header}>{t("testing.testComplete.header")}</h1>
              <Divider />
              {headerText()}
              {JSON.parse(showOnsiteTestingButton) === true && (
                <ClearMindButton opaque onClick={handleOnSiteTestingButton} loading={promiseInProgress}>
                  {t("testing.testComplete.location")}
                </ClearMindButton>
              )}
              <ClearMindButton opaque onClick={handleReturnHomeButton} loading={promiseInProgress}>
                {t("testing.testComplete.backHome")}
              </ClearMindButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FrostedFrame>
    </>
  );
};

export default observer(TestCompleteStep);
