import { useState } from 'react';
import { ITestResult } from '../../Models/TestResult';
import { GetResultsReportPdf } from '../../Services/GetResultsReportPdf';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import * as Sentry from '@sentry/react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { useTranslation } from "react-i18next";

interface IShareProps {
  testResult: ITestResult;
  opaque?: boolean;
}

const SharePdfButton: React.FC<IShareProps> = (props) => {
  const { testResult, opaque } = props;
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const { promiseInProgress } = usePromiseTracker({ area: `pdf-share-${testResult.testSessionId}` });

  const handleSharing = async (e: any) => {
    e.stopPropagation();
    if (!testResult) {
      return;
    }

    const getPdf = await trackPromise(GetResultsReportPdf(testResult.testSessionId), `pdf-share-${testResult.testSessionId}`);

    const pdf = await trackPromise(fetch(`data:application/pdf;base64,${getPdf}`), `pdf-share-${testResult.testSessionId}`);

    const blob = await trackPromise(pdf.blob(), `pdf-share-${testResult.testSessionId}`);

    const file = new File([blob], `TestResult${testResult.localDateString}.pdf`, { type: blob.type });

    const shareDetails = {
      title: 'Result',
      text: `Test Result from ${testResult.localDateString}`,
      files: [file],
    };

    if (navigator.share) {
      try {
        await navigator.share(shareDetails);
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      setError('Unsupported browser. Try opening the app using a different browser.');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };
  return (
    <>
      {opaque ? (
        <ClearMindButton loading={promiseInProgress} opaque size={'small'} onClick={handleSharing}>
          {t("results.cards.share")}
        </ClearMindButton>
      ) : (
        <ClearMindButton loading={promiseInProgress} size={'small'} onClick={handleSharing}>
          {t("results.cards.share")}
        </ClearMindButton>
      )}

      {error && <ErrorDisplay message={error} />}
    </>
  );
};
export default SharePdfButton;
