import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import throttle from 'lodash/throttle';

interface PdfViewerProps {
  pdfBase64: string
}

const PdfViewer: React.FC<PdfViewerProps> = (props) => {
  const { pdfBase64 } = props;
  const [numPages, setNumPages] = useState(null);
  const pdfWrapper = useRef<any>(null);
  const [initialWidth, setInitialWidth] = useState(undefined);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth((pdfWrapper.current.getBoundingClientRect().width));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);

  if (!pdfBase64) {
    return <p>Wellness Agreement PDF is not found</p>;
  }


  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return <div style={{ height: '100%', overflowY: 'scroll', overflowX: 'clip', textAlign: 'center' }} ref={pdfWrapper}>
    <Document
      file={`data:application/pdf;base64,${pdfBase64}`}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={initialWidth}
          />
        ),
      )}
    </Document>
  </div>
}
export default PdfViewer;
