import React from 'react';
import { Redirect } from 'react-router-dom';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import BehaviorContractForm from '../Components/BehaviorContract/BehaviorContractForm';

const SupporterOnboardingPage: React.FC = () => {
  const { behaviorContract, user } = useClearMindContext();

  if (user.isMember) {
    return <Redirect to="/" />
  }

  return <>
    <FrostedFrame>
      <BehaviorContractForm behaviorContract={behaviorContract} />
    </FrostedFrame>
  </>
};

export default SupporterOnboardingPage;
