import React from "react";
import FrostedFrame from "../Components/Controls/FrostedFrame/FrostedFrame";
import { Header, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import ClearMindButton from "../Components/Controls/ClearMindButton/ClearMindButton";
import RadioSelector from "../Components/Controls/Selector/RadioSelector";
import { observer } from "mobx-react-lite";

const DemographicsFormStep: React.FC = () => {
  const { behaviorContract } = useClearMindContext();
  const { promiseInProgress } = usePromiseTracker({ area: "saving-demographics" });

  const history = useHistory();
  const { t } = useTranslation();

  const setDemographics = async () => {
    await trackPromise(behaviorContract.save(), "saving-demographics");
    history.push("/");
  };

  const sexOptions = [
    {
      name: "Male",
      label: t("onboarding.demographics.sex.male"),
    },
    {
      name: "Female",
      label: t("onboarding.demographics.sex.female"),
    },
  ];

  const getSexIndex = sexOptions.findIndex((option) => {
    return option.name === behaviorContract.sex;
  });

  const ethnicityOptions = [
    {
      name: "Hispanic or Latino",
      label: t("onboarding.demographics.ethnicity.hispanic"),
    },
    {
      name: "Not Hispanic or Latino",
      label: t("onboarding.demographics.ethnicity.notHispanic"),
    },
  ];

  const getEthnicityIndex = ethnicityOptions.findIndex((option) => {
    return option.name === behaviorContract.ethnicity;
  });

  const raceOptions = [
    {
      name: "White",
      label: t("onboarding.demographics.race.white"),
    },
    {
      name: "Black or African American",
      label: t("onboarding.demographics.race.blackOrAfricanAmerican"),
    },
    {
      name: "Asian",
      label: t("onboarding.demographics.race.asian"),
    },
    {
      name: "American Indian or Alaska Native",
      label: t("onboarding.demographics.race.americanIndianOrAlaskaNative"),
    },
    {
      name: "Pacific Islander or Native Hawaiian",
      label: t("onboarding.demographics.race.pacificIslanderOrNativeHawaiian"),
    },
    {
      name: "Two or more of the 5 races listed above",
      label: t("onboarding.demographics.race.twoOrMore"),
    },
  ];

  const getRaceIndex = raceOptions.findIndex((option) => {
    return option.name === behaviorContract.race;
  });

  return (
    <FrostedFrame>
      <Segment
        basic
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header textAlign="center" as={"h2"} icon>
          {t("onboarding.demographics.header")}
        </Header>
        <br />
        <Header textAlign="center" as={"h2"} icon>
          {t("onboarding.demographics.sex.header")}
        </Header>
        <RadioSelector options={sexOptions} selectedIndex={getSexIndex} onChange={(e) => behaviorContract.setSelectedSex(e.name)} />
        <Header textAlign="center" as={"h2"} icon>
          {t("onboarding.demographics.ethnicity.header")}
        </Header>
        <RadioSelector options={ethnicityOptions} selectedIndex={getEthnicityIndex} onChange={(e) => behaviorContract.setSelectedEthnicity(e.name)} />
        <Header textAlign="center" as={"h2"} icon>
          {t("onboarding.demographics.race.header")}
        </Header>
        <RadioSelector options={raceOptions} selectedIndex={getRaceIndex} onChange={(e) => behaviorContract.setSelectedRace(e.name)} />
      </Segment>
      <Segment
        basic
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: " 50px",
        }}
      >
        <ClearMindButton opaque onClick={setDemographics} disabled={!behaviorContract.isDemographicsSet} loading={promiseInProgress} style={{ width: 200, margin: "1rem auto" }}>
          Next
        </ClearMindButton>
      </Segment>
    </FrostedFrame>
  );
};

export default observer(DemographicsFormStep);
