import React from 'react';
import { useHistory } from 'react-router';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';

const LibraryPage: React.FC = () => {
    const history = useHistory();
    const goBack = () => {
        history.push('/');
    };
    return (<Segment basic placeholder>
        <Header icon>
            <Icon name='bullhorn' />
            Page coming soon.
        </Header>
        <Button onClick={goBack} primary>
            Back
        </Button>
    </Segment>)
}

export default LibraryPage;