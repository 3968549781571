import React, { useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import { scrollToTop } from '../Services/PageService';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import UnderstandingResultsMemberContent from './UnderstandingResultsMemberContent';
import UnderstandingResultsSupporterContent from './UnderstandingResultsSupporterContent';
import FooterBackButton from '../Components/BackButton/FooterBackButton';

const UnderstandingResultsPage: React.FC = () => {
  const { user } = useClearMindContext();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <FrostedFrame>
      <Segment
        basic
        content
        textAlign="left"
        style={{
          color: "black",
        }}
      >
        {user.isSupporter ? <UnderstandingResultsSupporterContent /> : <UnderstandingResultsMemberContent />}
      </Segment>
      <FooterBackButton />
    </FrostedFrame>
  );
};

export default UnderstandingResultsPage;
