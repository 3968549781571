import React from 'react';
import { Image } from 'semantic-ui-react';
import styles from './SquareWidget.module.css';

interface SquareWidgetProps {
  img?: string;
}

const SquareWidget: React.FC<SquareWidgetProps> = (props) => {
  const { children, img } = props;
  return (
    <div className={styles.squareFrame}>
      {img && (
        <Image
          fluid
          style={{
            position: 'absolute',
            objectFit: 'cover',
            borderRadius: '15px',
            height: '100%',
          }}
          src={img}
        />
      )}
      <div className={styles.squareFrameContents}>{children}</div>
    </div>
  );
};

export default SquareWidget;
