import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { BrowserRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Routes from "./Routes/Routes";
import { UserInitializer } from "./UserInitializer/UserInitializer";
import { useClearMindContext } from "./ClearMindContext/ClearMindContext";
import { useState } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const App = () => {
  const { user } = useClearMindContext();
  const [isInitialized, setIsInitialized] = useState(false);


  if (isInitialized || user.userInitialized) {
    return (
      <>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <Routes />
          </Elements>
        </BrowserRouter>
      </>
    );
  }

  return <UserInitializer setIsInitialized={setIsInitialized} />;
};

export default observer(App);
