import axios from 'axios';
import { applySnapshot, flow, types, Instance, clone } from 'mobx-state-tree';
import { SaveUserRole, SaveUserAddress, SaveUserSupporterRole, SaveUserTimeZone, SaveBirthDate, onSelfReport, SaveUserLanguage } from '../Services/UserService';
import { userSubscriptionService } from '../Services/UserSubscriptionService';
import { IBehaviorContract } from './BehaviorContract';
import { Address } from './Address';
import * as Sentry from '@sentry/react';
import i18n from "../i18n";

const DateIso = types.snapshotProcessor(types.Date, {
  // Convert the date object to an ISO string before storing the snapshot
  preProcessor(isoDateString) {
    if (!isoDateString) {
      return isoDateString;
    }
    return new Date(`${isoDateString.toString()}Z`);
  },
  // Convert the ISO string back to a date object when applying a snapshot
  postProcessor(dateNumber: number) {
    return new Date(dateNumber);
  },
});

const User = types
  .model("UserModel", {
    userId: types.maybe(types.string),
    faceId: types.maybeNull(types.string),
    isLoading: types.optional(types.boolean, false),
    firstName: types.string,
    lastName: types.string,
    birthdate: types.maybe(types.string),
    role: types.enumeration(["Member", "Supporter", ""]),
    facilityCode: types.array(types.string),
    supporterRole: types.maybe(types.string),
    email: types.string,
    phoneNumber: types.string,
    fullAddress: types.maybe(types.string),
    streetAddress: types.maybe(types.string),
    city: types.maybe(types.string),
    zipCode: types.maybe(types.string),
    state: types.maybe(types.string),
    customerId: types.maybeNull(types.string),
    paymentMethodId: types.maybeNull(types.string),
    subscriptionId: types.maybeNull(types.string),
    subscriptionItemId: types.maybeNull(types.string),
    paymentMethodLastFour: types.maybeNull(types.string),
    testPeriodStartDateTimeUTC: types.maybeNull(DateIso),
    testPeriodEndDateTimeUTC: types.maybeNull(DateIso),
    dateLastTestTaken: types.maybe(types.string),
    shippingAddress: types.optional(Address, {}),
    billingAddress: types.optional(Address, {}),
    promoCode: types.maybe(types.string),
    invitedBy: types.maybe(types.string),
    timeZone: types.maybe(types.string),
    partnerType: types.maybeNull(types.string),
    disableFacialRecognition: types.optional(types.boolean, false),
    language: types.maybeNull(types.enumeration(["en", "es"])),
    userInitialized: types.optional(types.boolean, false),
    mfaEnabled: types.optional(types.boolean, false)
  })
  .views((self) => ({
    get testingPeriodActive() {
      const testingStartDateTime = self.testPeriodStartDateTimeUTC;
      const testingEndDateTime = self.testPeriodEndDateTimeUTC;
      const currentTime = new Date();

      if (!testingStartDateTime || !testingEndDateTime) {
        return false;
      }

      console.log("testing start time", testingStartDateTime.toLocaleString());
      console.log("testing end time", testingEndDateTime.toLocaleString());
      console.log("currentTime", currentTime.toLocaleString());

      console.log("testing start time", testingStartDateTime);
      console.log("testing end time", testingEndDateTime);
      console.log("currentTime", currentTime);

      return currentTime >= testingStartDateTime && currentTime <= testingEndDateTime;
    },
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
    get needsIndexedFace() {
      return self.role === "Member" && !self.faceId;
    },
    get hasRole() {
      return !!self.role;
    },
    get hasFacilityPartner() {
      return (self.facilityCode?.length || 0) > 0;
    },
    get hasBusinessPartner() {
      return self.partnerType === "business";
    },
    get hasTimeZone() {
      return !!self.timeZone;
    },
    get hasAddress() {
      return !!self.streetAddress && !!self.city && !!self.state && !!self.zipCode;
    },
    get hasBirthdate() {
      const noBirthDateString = "0001-01-01";
      return !!self.birthdate && self.birthdate.substring(0, 10) !== noBirthDateString;
    },
    get shortBirthdateString() {
      return self.birthdate?.substring(0, 10);
    },
    get partnerFacilityCode() {
      return self.facilityCode;
    },
    get isMember() {
      return self.role === "Member";
    },
    get isSupporter() {
      return self.role === "Supporter";
    },
    get isValidEmail() {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return self.email?.match(re);
    },
    get name() {
      return `${self.firstName} ${self.lastName}`;
    },
    get isInvitedUser() {
      return !!self.invitedBy;
    },
    get isInitialUser() {
      return !self.invitedBy;
    },
    get hasRequiredFields() {
      return !!self.firstName && !!self.lastName && !!self.phoneNumber && !!self.email && !!self.role && (self.role === "Member" || !!self.supporterRole);
    },
    defaultRouteForUser(memberBehaviorContract: IBehaviorContract, supporterBehaviorContract: IBehaviorContract): string {
      if (self.role === "Member" && !self.faceId) {
        return "user-photo";
      }

      if (self.role === "Member") {
        return memberBehaviorContract.onboardingComplete ? "member-dashboard" : "member-onboarding";
      }

      return supporterBehaviorContract.onboardingComplete ? "supporter-dashboard" : "supporter-onboarding";
    },
  }))
  .actions((self: any) => {
    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        const result = yield serviceAction();
        self.isLoading = false;
        return result;
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        self.isLoading = false;
        throw err;
      }
    });
    return {
      createStripeSubscription: flow(function* (priceId: string) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            if (!self.paymentMethodId || !priceId) {
              return;
            }

            if (!self.customerId) {
              const customerId = yield userSubscriptionService.createCustomer(self);
              self.customerId = customerId;
            }

            if (!self.subscriptionId) {
              const subscription = yield userSubscriptionService.createSubscription(self.paymentMethodId, self.customerId, self.promoCode, priceId);
              self.subscriptionId = subscription.subscriptionId;
              self.subscriptionItemId = subscription.subscriptionItemId;
              self.paymentMethodLastFour = subscription.paymentMethodLastFour;
            }
          })
        );
      }),
      checkSubscriptionStatus: flow(function* () {
        return yield wrapLoadingDuringAction(
          flow(function* () {
            return yield userSubscriptionService.checkSubscription();
          })
        );
      }),
      indexUserFace: flow(function* (image: string) {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result = yield axios.put("/faces", { image });
            self.faceId = result.data.faceId;
          })
        );
      }),
      setUserRole: flow(function* (value: "Member" | "Supporter") {
        yield wrapLoadingDuringAction(
          flow(function* () {
            if (!self.userId) {
              throw new Error("User is not set");
            }
            yield SaveUserRole(self.userId, value);
            self.role = value;
          })
        );
      }),
      setUserTimeZone: flow(function* (value: string) {
        if (!self.userId) {
          throw new Error("User is not set");
        }
        yield SaveUserTimeZone(self.userId, value);
        self.timeZone = value;
      }),
      setUserAddress: flow(function* () {
        if (!self.userId) {
          throw new Error("User is not set");
        }
        yield SaveUserAddress(self.userId, self.streetAddress, self.city, self.state, self.zipCode);
      }),
      saveBirthdate: flow(function* (value: Date) {
        if (!self.userId) {
          throw new Error("User is not set");
        }
        if (value) {
          yield SaveBirthDate(self.userId, value);
          self.birthdate = value.toDateString().substring(0, 10);
        }
      }),
      saveUserSupporterRole: flow(function* (value: "Significant Other" | "Co-parent (ex-spouse)" | "Parent" | "Sibling" | "Friend" | "Other") {
        yield wrapLoadingDuringAction(
          flow(function* () {
            yield SaveUserSupporterRole(self.userId, value);
            self.supporterRole = value;
          })
        );
      }),
      load: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result = yield axios.get("/current-user");
            applySnapshot(self, result.data ?? {});
          })
        );
      }),
      onSelfReport: flow(function* () {
        if (!self.userId) {
          throw new Error("User is not set");
        }
        yield onSelfReport(self);
      }),
      onInputChangeEvent: (e: any) => {
        self[e.target.name] = e.target.value;
      },
      onSupporterRoleChangeEvent: (data: any) => {
        self.supporterRole = data.name;
      },
      setPhoneNumber: (phoneNumber: string | undefined) => {
        self.phoneNumber = phoneNumber || "";
      },
      setShippingAddress: (shippingAddress: string | undefined) => {
        self.shippingAddress = shippingAddress || "";
      },
      setCity: (city: string) => {
        self.city = city;
      },
      setState: (state: string) => {
        self.state = state;
      },
      setZipCode: (zipCode: string) => {
        self.zipCode = zipCode;
      },
      setStreetAddress: (streetAddress: string) => {
        self.streetAddress = streetAddress;
      },
      setPaymentMethodId: (paymentMethodId: string) => {
        self.paymentMethodId = paymentMethodId;
      },
      setPromoCode: (promoCode: string) => {
        self.promoCode = promoCode;
      },
      setUserInitialized: (value: boolean) => {
        self.userInitialized = value;
      },
      setUserLanguage: flow(function* (value: "en" | "es") {
        let originalLanguage = self.language;

        try {
          if (!self.userId) {
            throw new Error("User is not set");
          }
          self.language = value;
          i18n.changeLanguage(value);
          yield SaveUserLanguage(self.userId, value);
        } catch (error) {
          console.error("Failed to save user language:", error);
          console.log(`Current${self.language} and og ${originalLanguage}`);
          self.language = originalLanguage;
          i18n.changeLanguage(originalLanguage);
          throw error;
        }
      }),
      setShippingAddressSameAsBilling: () => {
        self.shippingAddress = clone(self.billingAddress);
      },
      clearTestPeriodStartHourAfterTestComplete: () => {
        self.testPeriodStartDateTimeUTC = undefined;
        self.testPeriodEndDateTimeUTC = undefined;
      },
    };
  });

export default User;
export interface IUser extends Instance<typeof User> { }
