import React from 'react';
import styles from './Styles.module.css';
interface IFrostedFrame {
  darkMode?: boolean;
}
const FrostedFrame: React.FC<IFrostedFrame> = (props) => {
  const { children, darkMode } = props;

  const frameClassName = darkMode ? styles.frameDarkMode : styles.frame;

  return (
    <div className={frameClassName}>
      <div className={styles.frameContents}>{children}</div>
    </div>
  );
};

export default FrostedFrame;
