import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import ClearMindButton from '../../Controls/ClearMindButton/ClearMindButton';
import FrostedFrame from '../../Controls/FrostedFrame/FrostedFrame';
import styles from './WelcomeStepStyle.module.css';

const WelcomeStep: React.FC = (props) => {
  const { user } = useClearMindContext();

  const welcomeMessage = () => {
    if (user.isMember) {
      return (
        <>
          <p className={styles.text}>Your results are only shared with your Supporter(s) and Monitoring Partner (if applicable). Only you can add additional Supporters.</p>
          <p className={styles.text}>
            The questions you are about to answer will take less than 5 minutes and allow us to tailor your experience. Questions with a “*” are populated to your Wellness Agreement and shared with your Supporters and Monitoring Partner. Your
            Wellness Agreement is accessed within the app and works to help you achieve your goals.
          </p>
          <p className={styles.text}>You can invite your Supporter(s) to join and choose your testing program shortly.</p>
        </>
      );
    } else {
      return <p className={styles.text}>'We are excited to serve you and the needs of your family.'</p>;
    }
  };

  return (
    <FrostedFrame>
      <Segment padded style={{ margin: "auto", maxWidth: "500px", textAlign: "justify" }} basic>
        <h1 className={styles.welcome}>
          Hello, and welcome <br /> to Clearlee.
        </h1>
        <p style={{ color: "#333" }}>
          Our platform is designed for a Member (Monitored Individual) who is randomly drug tested and a Supporter (Loved One) who receives results. These parties meet in our Wellness Agreement to define their goals. Our purpose is to help families
          obtain the things they want and need with balanced accountability and education.
        </p>
        <p style={{ color: "#333" }}>
          Thank you for letting us join your journey to wellness, <br />
          Your Clearlee Team.
          <br />
        </p>
        <p>{welcomeMessage()}</p>

        <Container textAlign={"center"}>
          <Link to={"/"}>
            <ClearMindButton opaque> Get Started</ClearMindButton>
          </Link>
        </Container>
      </Segment>
    </FrostedFrame>
  );
};

export default WelcomeStep;
