import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import RectangleWidget from "../Components/MemberDashboard/RectangleWidgetContainer/RectangleWidget";
import TakeTestButton from "../Components/TakeTestButtonComponent/TakeTestButton";
import { useClearMindContext } from "../ClearMindContext/ClearMindContext";
import "react-multi-carousel/lib/styles.css";
import DashboardTileComponent from "../Components/CarouselComponent/DashboardTileComponent";
import TimeZoneModal from "../Components/BehaviorContract/TimeZoneStep/TimeZoneModal";
import TakeTestNowButton from '../Components/TakeTestButtonComponent/TakeTestNowButton';

const timeZoneAddedSuccessMsg = 'Success! Your timezone has been added. If you ever need to update your timezone, you can do so in the "Profile" tab';
const MemberDashboard: React.FC = () => {
  const history = useHistory();
  const { user, behaviorContract } = useClearMindContext();
  const [timeZoneModalOpen, setTimeZoneModalOpen] = useState(false);

  useEffect(() => {
    const userTimeZoneFirstWord = user.timeZone?.split(" ").shift();
    const localTimeZone = new Intl.DateTimeFormat("en-US", {
      timeZoneName: "long",
    })
      .format()
      .split(",")
      .pop();

    const timeZoneHasChanged = !localTimeZone?.includes(userTimeZoneFirstWord!);

    if (timeZoneHasChanged) {
      setTimeZoneModalOpen(true);
    }

    if (user.isMember && !user.hasTimeZone && behaviorContract.onboardingComplete) {
      setTimeZoneModalOpen(true);
    }
  }, [behaviorContract.onboardingComplete, user.hasTimeZone, user.isMember, user.timeZone]);

  if (!behaviorContract.onboardingComplete) {
    history.push(user.isMember ? "member-onboarding" : "supporter-onboarding");
  }
  
  if (user.isSupporter) {
    behaviorContract.supporterOnboardingComplete ? history.push("supporter-dashboard") : history.push("supporter-onboarding");
  }

  return (
    <Grid textAlign="center">
      {user.testingPeriodActive && (
        <RectangleWidget>
          <TakeTestButton />
        </RectangleWidget>
      )}
      <TimeZoneModal successMessage={timeZoneAddedSuccessMsg} successMessageTimeout={10000} open={timeZoneModalOpen} setOpen={setTimeZoneModalOpen} />
      <Grid.Row>
        <DashboardTileComponent />
      </Grid.Row>
      <TakeTestNowButton />
    </Grid>
  );
};

export default MemberDashboard;
