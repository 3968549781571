import { flow, Instance, types } from 'mobx-state-tree';
import { FacilityPartnerResponse, GetFacilityPartner } from '../Services/FacilityPartnerService';


export const FacilityPartner = types.model("FacilityPartner", {
    facilityPartnerId: types.maybe(types.string),
    facilityName: types.maybe(types.string),
    facilityCode: types.optional(types.string, ""),
    facilityAddress: types.maybe(types.string),
    facilityPhoneNumber: types.maybe(types.string),
    contactName: types.maybe(types.string),
    contactEmail: types.maybe(types.string),
})
    .actions((self) => ({
        loadFacilityPartner: flow(function* (facilityCode: string) {
            const results: [FacilityPartnerResponse] =
                yield GetFacilityPartner(facilityCode);

            if (results.length < 1) {
                self.facilityPartnerId = ''
                self.facilityName = ''
                self.facilityAddress = ''
                self.facilityPhoneNumber = ''
                self.contactName = ''
                self.contactEmail = ''
                self.facilityCode = ''
                return self
            }
            let result = results[0]
            self.facilityPartnerId = result.monitoringPartnerId
            self.facilityName = result.facilityName;
            self.facilityAddress = result.facilityAddress;
            self.facilityPhoneNumber = result.facilityPhoneNumber;
            self.contactName = result.contactName;
            self.contactEmail = result.contactEmail;
            self.facilityCode = result.facilityCode;
            return self
        })
    })
    )

export default FacilityPartner;
export interface IFacilityPartner extends Instance<typeof FacilityPartner> { }
