import React, { useEffect, useState } from 'react';
import styles from './TakeTestNowButtonStyle.module.css';
import { Link } from 'react-router-dom';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import RectangleWidget from '../MemberDashboard/RectangleWidgetContainer/RectangleWidget';
import { useTranslation } from "react-i18next";

const TakeTestNowButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);
  const { user } = useClearMindContext();
  const testingWindowStartTime = user.testPeriodStartDateTimeUTC?.getTime();
  const testingWindowEndTime = user.testPeriodEndDateTimeUTC?.getTime() || 0;
  const currentTime = new Date().getTime();
  const isTestingWindowClosed = currentTime > testingWindowEndTime;
  const { t } = useTranslation();
  
  useEffect(() => {
    if (!testingWindowStartTime) {
      setShowButton(true);
    }
    if (isTestingWindowClosed) {
      setShowButton(true);
    }
    if (user.dateLastTestTaken !== undefined && testingWindowStartTime) {
      const isTestCompleted = Date.parse(user.dateLastTestTaken) > testingWindowStartTime;
      const isBeforeTestWindow = currentTime < testingWindowStartTime;

      if (isTestCompleted) {
        setShowButton(true);
      }
      if (isBeforeTestWindow) {
        setShowButton(true);
      }
    }
  }, [testingWindowEndTime, user.dateLastTestTaken, testingWindowStartTime, currentTime, isTestingWindowClosed]);

  if (!showButton) {
    return null;
  }

  return (
    <RectangleWidget>
      <div className={styles.container}>
        <Link to="/pre-testing">
          <div className={styles.stageContainer}>
            <div className={styles.stage}>
              <div className={`${styles.pulseIcon} ${styles.pulsing}`}>
                <h1 className={styles.header}>
                  {t("dashboard.takeTestNowButton.takeTest")} <br /> {t("dashboard.takeTestNowButton.now")}
                </h1>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ width: 350 }}></div>
    </RectangleWidget>
  );
};

export default TakeTestNowButton;
