import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import ResultsImg from '../Images/DashboardPhotos/MamberSliderPhotos/MyResultsV2.png';
import Image2 from '../Images/DashboardPhotos/UnderstandingResultsImg1.png';
import Image3 from '../Images/DashboardPhotos/UnderstandigResultsImg2.png';
import i18n from '../i18n';

const UnderstandingResultsSupporterContent: React.FC = () => {
  const pageStyles = {
    redSpan: { color: '#ff4e4e', fontWeight: 'bold' },
    tealSpan: { color: '#47d4d9', fontWeight: 'bold' },
    yellowSpan: { color: '#F1C232', fontWeight: 'bold' },
    link: { color: '#3EBABF', fontWeight: 'bold' },
  };
  return (
    <>
      <Header style={{ color: "var(--secondary-color)" }} as={'h1'} textAlign='center'>
        {i18n.t("dashboard.tiles.understandingResults")}
      </Header>
      <Image centered src={ResultsImg} />
      <Header>Understanding Test Results</Header>
      Our result types are similar to the drug test result structure used in
      Drug Courts across the country.  Here are a few things to consider
      regarding results with Clearlee testing.
      <br />
      <br />
      Our saliva tests are 99% accurate and are similar to those used by law
      enforcement. There is less than a 1% chance of a false positive or a false
      negative, but it can happen. Each substance has its own detection window
      and saliva is very good at determining recent use.
      <br />
      <br />
      Our technology is designed for a highly secure testing process, using a
      combination of artificial intelligence and human review. We use the same
      collection procedures as employers and laboratories with a few additional
      steps for an enhanced experience.
      <br />
      <br />
      We work hard to bring you the most reliable results. We believe there is
      no such thing as a “bad” test result. A Pass result is an achievement.
      Each time! A Fail result is an indication there is more work to do and can
      assist someone in discovering relapse early. The most important thing is
      getting someone help when they need it and keeping people safe. While drug
      testing can be extremely helpful, human behavior is the best indicator of
      how someone is doing.
      <Header>
        There are two ways clear tests results occur within our platform.
      </Header>
      <span style={pageStyles.tealSpan}>Pass </span>
      This means none of the substances listed on the panels were detected at
      the time of the test. It also verifies the Member's identity and that all
      testing guidelines were followed correctly. Basically, it is an A+.
      <br />
      <br />
      <span style={pageStyles.tealSpan}>Pass - Allowable Substance </span>
      This Program is not available now but coming soon. It verifies the
      Member's identity, and all testing guidelines were followed and verifies
      only Allowable Substances were detected. Also, an A+ for following
      guidelines and testing correctly. It is designed for someone on a
      prescribed medication by their physician as part of their treatment or
      care where the Allowable Substance may render a positive on one of our
      tests. This program is recommended for patients on medically assisted
      treatment (MAT), prescription amphetamine (Adderall or Vyvanse) or a
      benzodiazepine. This program utilizes a Medical Review Officer. There is a
      chance of cross-reactivity, which means another substance causes the
      positive, but it is uncommon. Therefore, we ask for prescription
      medications at onboarding and before each test. Keeping this information
      up to date is the Member’s responsibility.
      <Header>
        There are three types of Fail results that can occur.
      </Header>A{' '}
      <span style={pageStyles.redSpan}> Detection </span>
      means a metabolite of the tested substance(s) has been detected.
      <br />
      <br />A <span style={pageStyles.redSpan}> Misstep </span> indicates
      the Member did not follow guidelines, attempted a false identity, or
      allowed their test to be interrupted. Examples include but are not limited
      to opening or tampering with the TestPACKET prior to testing, having
      someone else take their test, or allowing their test components out of the
      camera (face, TestCUBE, TestSTICK). Interruptions (anytime the camera is
      stopped) cannot be allowed in order to verify the collection and results
      have not been tampered with or taken by anyone else. Missteps are double
      verified both by our artificial intelligence and human review. The entire
      process is videoed for human review. We will always list the Misstep.
      Missteps are easily avoidable if the provided Testing Tips are followed.
      Our job is to make sure no one else except the Member takes their test and
      the “chain of custody” of the collection is accurate and secure. We take
      this very seriously.
      <br />
      <br />
      Lastly, a<span style={pageStyles.redSpan}> Decline </span>
      means a test request was sent and the test was not taken within the
      designated time window. Essentially, it is a missed test. Test requests
      are sent from 6:00am-6:00pm with a 4-hour window to complete the test (the
      testing window may be determined by a Monitoring Partner, if it is part of
      your Support Network). Not having a TestPACKET is not an acceptable reason
      to decline a test. Not making time to test is not an acceptable reason to
      decline a test. Our tests require a fraction of the time onsite lab
      testing requires. Our TestPACKETS are small and travel easily. If the
      Member knows in advance they are unable to test if requested, it is their
      responsibility to notify their Support Network and discuss the situation
      so undo consequences or concerns do not occur. Clear communication is
      essential! If they are testing with a Monitoring Partner, they may have
      their own procedures and guidelines. While no one person is perfect,
      missing tests is a signal a change in behavior. It is very important to
      encourage your Member to get back and stay on track!
      <br />
      <br />
      <Image size='small' floated='left' src={Image2} />
      <Header verticalAlign='top' as={'h3'}>
        Be prepared. Testing on the go is easy.
      </Header>
      <br />
      <br />
      <br />
      <Header>In the event of a Fail Result of any type</Header>
      the Monitoring Partner may require additional testing. As the Supporter,
      always refer to your Wellness Agreement, which is agreed to prior to the
      start of testing. All expectations are set on the front end for balanced
      accountability. You know your Member best. Our mantra is to trust, but
      always verify if you are concerned.
      <br />
      <br />
      <Header>
        What does an <span style={pageStyles.yellowSpan}>Invalid Result</span>{' '}
        mean?
      </Header>
      An Invalid Result means a test panel did not process correctly during
      collection and there is no result: positive or negative. There is no way a
      Member can intentionally cause this. It is uncommon if the test collection
      guidelines are followed correctly.
      <Header>In Summary...</Header>
      We advocate for our Members and want to help them do their very best. If
      you have an issue or question that needs further explanation, email us at{' '}
      <a style={pageStyles.link} href='mailto:support@clearlee.com'>
        support@clearlee.com{' '}
      </a>
      and let us help.
      <br />
      <br />
      At the end of the day, behavioral patterns are the best indicator of how
      someone is doing. If you suspect your Member is struggling, don’t wait for
      a test result. Check on them. While an active addiction will always tell
      on itself, there is no reason to wait. Follow your gut. We are fighting
      with you and for you, for a clear mind, peace of mind, together.
      <br />
      <br />
      <Image centered src={Image3} />
    </>
  );
};
export default UnderstandingResultsSupporterContent;
