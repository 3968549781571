import React, { useRef, useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import FrostedFrame from "../Controls/FrostedFrame/FrostedFrame";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import { useTranslation } from "react-i18next";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

const PreTestingHowToVideoPage: React.FC = () => {
  const { testingService, behaviorContract, user } = useClearMindContext();
  const videoUrlEn = process.env.REACT_APP_HOW_TO_VIDEO_LINK_EN;
  const videoUrlEs = process.env.REACT_APP_HOW_TO_VIDEO_LINK_ES;
  const [videoComplete, setVideoComplete] = useState(false);
  const { promiseInProgress } = usePromiseTracker();
  const videoRef = useRef<HTMLVideoElement>(null);

  const getVideoUrlByLanguage = () => {
    switch (user.language) {
      case "en":
        return videoUrlEn;
      case "es":
        return videoUrlEs;
      default:
        return videoUrlEn;
    }
  };
  const getVideoLengthByLanguage = () => {
    switch (user.language) {
      case "en":
        return 115000;
      case "es":
        return 133000;
      default:
        return 115000;
    }
  };
  const { t } = useTranslation();

  const handleNext = async () => {

    behaviorContract.setIsFirstTest(false);
    await trackPromise(behaviorContract.save());
    if (videoRef.current) {
      videoRef.current.src = ''; // Clear the video source
      videoRef.current.load(); // Unload the video
    }
    testingService.onHowToComplete();
  };
  setTimeout(() => {
    setVideoComplete(true);
  }, getVideoLengthByLanguage());
  return (
    <>
      <FrostedFrame>
        <Segment basic textAlign="center">
          <Header style={{ color: "var(--secondary-color)" }} as={"h4"} textAlign="center">
            {t("testing.howToVideo.howToHeader")}
          </Header>
          <video
            controls
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
            src={getVideoUrlByLanguage()}
            autoPlay
            ref={videoRef} />
          <ClearMindButton loading={promiseInProgress} opaque disabled={!videoComplete} onClick={handleNext}>
            {t("testing.howToVideo.continue")}
          </ClearMindButton>
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default PreTestingHowToVideoPage;
