/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import React, { useState } from 'react';
import FooterNavMobile from './FooterNavMobile';

import {
  Visibility,
  Image,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Container,
} from 'semantic-ui-react';
import logo from '../../oneLineLogo.png';
import ClearMindHeader from './ClearMindHeader';
import MobileSliderMenu from './MobileSliderMenu';
import HelloWelcome from '../HelloWelcomeComponent/HelloWelcome';
import { useLocation } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});
interface IDesktopConntainerProps {
  title?: string;
  children: any;
}
const DesktopContainer: React.FC<IDesktopConntainerProps> = (props) => {
  const { children } = props;
  const location = useLocation();
  const isOnboardingState =
    location.pathname.indexOf("onboarding") >= 0 ||
    location.pathname.indexOf("user-photo") >= 0 ||
    location.pathname.indexOf("language-select") >= 0 ||
    location.pathname.indexOf("welcome") >= 0 ||
    location.pathname.indexOf("demographics") >= 0;
  
  return (
    <>
      <Media greaterThan="mobile">
        <Visibility once={false}>
          <ClearMindHeader />
          {!isOnboardingState && (
            <Segment basic style={{ margin: 0, padding: "0 0 0 4em" }}>
              <HelloWelcome />
            </Segment>
          )}
          {children}
        </Visibility>
      </Media>
    </>
  );
};

const MobileContainer: React.FC = (props) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const handleSidebarHide = () => setSidebarOpened(false);

  const handleToggle = () => setSidebarOpened(true);

  const { children } = props;
  const location = useLocation();

  const isOnboardingState =
    location.pathname.indexOf("onboarding") >= 0 ||
    location.pathname.indexOf("user-photo") >= 0 ||
    location.pathname.indexOf("welcome") >= 0 ||
    location.pathname.indexOf("language-select") >= 0 ||
    location.pathname.indexOf("demographics") >= 0;

  return (
    <Media at="mobile">
      <Sidebar.Pushable style={{ minHeight: "100vh", height: "100%" }}>
        <Sidebar style={{ background: "#0000007a" }} direction={"right"} width={"thin"} as={Menu} inverted animation="push" onHide={handleSidebarHide} vertical visible={sidebarOpened}>
          <MobileSliderMenu />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{
              backgroundColor: "rgba(13, 40, 71,0)",
              top: "5px",
            }}
            vertical
          >
            <Menu inverted secondary size="large" style={{ height: "70px", paddingTop: "1.5rem" }}>
              <BackButton />
              <Menu.Item
                position="left"
                style={{
                  paddingRight: 0,
                  paddingLeft: 10,
                }}
              >
                <Image style={{ width: "150px", maxHeight: "60px" }} src={logo} />
              </Menu.Item>

              {!isOnboardingState && (
                <Menu.Item style={{ paddingLeft: 0 }} onClick={handleToggle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                      width: "40px",
                      height: "40px",
                      borderRadius: 5,
                      color: "rgb(255, 255, 255)",
                      backgroundImage: "linear-gradient(to right, #3bcbbdb4, #3b91cbb6)",
                    }}
                  >
                    <Icon
                      size={"big"}
                      style={{
                        margin: 0,
                        color: "rgb(255, 255, 255)",
                      }}
                      name="sidebar"
                    />
                  </div>
                </Menu.Item>
              )}
            </Menu>
          </Segment>
          {!isOnboardingState && (
            <Container>
              <HelloWelcome />
            </Container>
          )}

          <div
            style={{
              height: "100%",
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 80,
              overflow: "clip",
            }}
          >
            {children}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      <FooterNavMobile />
    </Media>
  );
};

const ResponsiveContainer: React.FC<{ children: any; title: string }> = ({
  children,
  title,
}) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer title={title}>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

export default ResponsiveContainer;

export const withResponsiveContainer = (Component: any, title: string) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <ResponsiveContainer title={title}>
      <Component {...props} />
    </ResponsiveContainer>
  );
};
