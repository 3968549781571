import React from 'react';
import { Card } from 'semantic-ui-react';
import { IUser } from '../../Models/User';

interface UserCardProps {
  user?: IUser;
}

const UserCardMinimal: React.FC<UserCardProps> = (props) => {
  const { user } = props;

  if (!user) {
    return null;
  }

  return (
    <Card>
      <Card.Content>
        <Card.Header>{user.fullName}</Card.Header>
        <Card.Description>
          {user.phoneNumber} {user.email}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default UserCardMinimal;
