import React, { useEffect, useState } from 'react';
import styles from './TakeTestButtonStyle.module.css';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment } from "semantic-ui-react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";

const TakeTestButton: React.FC = () => {
  const [hideButton, setHideButton] = useState(false);
  const { user } = useClearMindContext();
  const { t } = useTranslation();
  console.log(user.testPeriodEndDateTimeUTC);
  const testingTime = user.testPeriodEndDateTimeUTC?.getTime() || 0;
  console.log("testing time", testingTime);
  console.log("d", new Date().getTime());

  useEffect(() => {
    if (testingTime < new Date().getTime()) {
      setHideButton(true);
    }
    if (user.dateLastTestTaken !== undefined && user.testPeriodStartDateTimeUTC) {
      if (Date.parse(user.dateLastTestTaken) > user.testPeriodStartDateTimeUTC.getTime()) {
        setHideButton(true);
      }
    }
  }, [testingTime, user.dateLastTestTaken, user.testPeriodStartDateTimeUTC]);

  const renderer = ({ hours, minutes, seconds, completed }: { hours: number; minutes: number; seconds: number; completed: boolean }) => {
    if (completed) {
      return (
        <Segment basic textAlign="center">
          <Header color={"grey"} textAlign="center" as={"h5"}>
            {t("dashboard.takeTestButton.windowClosed")}
          </Header>
        </Segment>
      );
    } else if (hours === 0 && minutes === 0 && seconds !== 0) {
      return (
        <Segment basic textAlign="center">
          {minutes + 1} {t("dashboard.takeTestButton.minuteRemaining")}
        </Segment>
      );
    } else if (minutes === 59) {
      return (
        <Segment basic textAlign="center">
          {hours === 0 ? `${hours + 1} ${t("dashboard.takeTestButton.hourRemaining")}` : `${hours + 1} ${t("dashboard.takeTestButton.hoursRemaining")}`}
        </Segment>
      );
    } else if (hours === 0 && minutes < 59) {
      return (
        <Segment basic textAlign="center">
          {minutes + 1} {t("dashboard.takeTestButton.minutesRemaining")}
        </Segment>
      );
    } else {
      return (
        <Segment basic textAlign="center">
          {hours} {hours === 1 ? t("dashboard.takeTestButton.countdownMessage.hour") : t("dashboard.takeTestButton.countdownMessage.hours")} {t("dashboard.takeTestButton.countdownMessage.and")} {minutes + 1}{" "}
          {minutes === 0 ? t("dashboard.takeTestButton.countdownMessage.minute") : t("dashboard.takeTestButton.countdownMessage.minutes")} {t("dashboard.takeTestButton.countdownMessage.remaining")}
        </Segment>
      );
    }
  };

  if (hideButton) {
    return <Countdown date={user.dateLastTestTaken ? Date.parse(user.dateLastTestTaken) : new Date(testingTime)} renderer={renderer} />;
  }

  return (
    <Grid>
      <Grid.Column>
        <Grid.Row>
          {" "}
          <div className={styles.container}>
            <Link to="/pre-testing">
              <div className={styles.stageContainer}>
                <div className={styles.stage}>
                  <div className={`${styles.pulseIcon} ${styles.pulsing}`}>
                    <h1 className={styles.header}>
                      {t("dashboard.takeTestButton.take")}
                      <br />
                      {t("dashboard.takeTestButton.test")}
                    </h1>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Grid.Row>
        <Grid.Row>
          <Countdown date={new Date(testingTime)} renderer={renderer} onComplete={(r) => setHideButton(true)} />
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default TakeTestButton;
