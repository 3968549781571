import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import FrostedFrame from '../Controls/FrostedFrame/FrostedFrame';
import styles from './Testing.module.css';

const MobileDeviceRequired: React.FC = () => {
    return (
        <>
            <FrostedFrame>
                <Grid textAlign='center' stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <h1 className={styles.header}>
                                Welcome to the Clearlee Testing Portal
                            </h1>
                            <Divider />
                            <Segment
                                style={{ color: '#333', maxWidth: '650px', margin: 'auto' }}
                                basic
                                textAlign='left'
                            >
                                <Header style={{ paddingBottom: '1em'}} textAlign='center'>Mobile Device Required </Header>
                                A mobile device is required for testing. Please retry testing from a mobile device.
                            </Segment>
                            <Link
                                className={styles.clearMindLink}
                                to="/member-dashboard"
                            >
                                Back to Homepage
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </FrostedFrame>
        </>
    );
};

export default observer(MobileDeviceRequired);
