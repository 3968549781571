import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Transition, Modal, Segment, Header, Icon, Form, Input, Dropdown, Button, TextArea, InputOnChangeData, DropdownProps } from "semantic-ui-react";
import ScheduledEvent, { IScheduledEvent } from "../../Models/ScheduledEvent";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import styles from "./EventSchedulerStyles.module.css";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface IEventScheduleComponentProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  updateEvent?: IScheduledEvent;
}
const EventScheduleComponent: React.FC<IEventScheduleComponentProps> = (props) => {
  const { visible, setVisible, updateEvent } = props;
  const { user, scheduledEventsStore } = useClearMindContext();
  const [otherOption, setOtherOption] = useState(false);
  const { promiseInProgress } = usePromiseTracker({ area: "save-event" });
  const { t } = useTranslation();

  const utcToLocalISO = (dateString: string | undefined) => {
    if (!dateString) {
      return undefined;
    }

    const date = new Date(dateString);
    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    const local = date.getTime() - timezoneOffset;
    const localDate = new Date(local);
    return localDate.toISOString().split(".")[0];
  };

  const [localDate, setLocalDate] = useState("");
  const [localTime, setLocalTime] = useState("");

  const [eventSchedule, setEventSchedule] = useState<IScheduledEvent>(
    updateEvent ??
    ScheduledEvent.create({
      id: "",
      memberId: user.userId,
      eventDate: new Date().toISOString(),
      eventType: "",
      eventDetails: "",
    })
  );

  const initializeBlankEvent = useCallback(() => {
    const date = new Date().toISOString();
    const iso = utcToLocalISO(date)?.split("T");

    if (iso?.length) {
      setLocalDate(iso[0]);
      setLocalTime(iso[1]);
    }

    setEventSchedule(
      ScheduledEvent.create({
        id: "",
        memberId: user.userId,
        eventDate: date,
        eventType: "",
        eventDetails: "",
      })
    );
  }, [user.userId]);

  useEffect(() => {
    if (updateEvent) {
      setEventSchedule(updateEvent);
      const iso = utcToLocalISO(updateEvent.eventDate)?.split("T");

      if (!iso?.length) {
        return;
      }

      setLocalDate(iso[0]);
      setLocalTime(iso[1]);
    } else {
      initializeBlankEvent();
    }
  }, [updateEvent, user.userId, initializeBlankEvent]);

  const handleDatePickerChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setLocalDate(data.value);
  };

  const handleTimeChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setLocalTime(data.value);
  };

  const onSubmit = async () => {
    const local = new Date(localDate + "T" + localTime);
    const newDateString = new Date(local.getTime() + local.getTimezoneOffset()).toISOString();

    eventSchedule.setDate(newDateString);
    if (eventSchedule.id) {
      await updateScheduleEvent();
    } else {
      await addScheduleEvent();
    }
  };

  const addScheduleEvent = async () => {
    await trackPromise(eventSchedule.saveEvent(), "save-event");
    scheduledEventsStore.add(eventSchedule);
    onExitScheduling();
    setVisible(!visible);
  };

  const updateScheduleEvent = async () => {
    await trackPromise(eventSchedule.updateEvent(), "save-event");
    onExitScheduling();
    setVisible(!visible);
  };

  const onTestTypeChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value === "Other") {
      eventSchedule.setEventType("");
      setOtherOption(true);
    } else {
      setOtherOption(false);
      const value: any = data.value || [];
      eventSchedule.setEventType(value);
    }
  };

  const onExitScheduling = () => {
    setVisible(false);
    initializeBlankEvent();
  };

  const handleTestTypeChange = (_: ChangeEvent, data: { value: any }) => {
    eventSchedule.setEventType(data.value);
  };

  const eventTypeCharacterLimit = eventSchedule.eventType.length === 50;
  const eventDetailsCharacterLimit = eventSchedule?.eventDetails?.length === 200;

  const options = [
    { key: 1, text: t("results.events.recoveryMeeting"), value: "Recovery Meeting" },
    { key: 2, text: t("results.events.therapy"), value: "Therapy" },
    { key: 3, text: t("results.events.other"), value: "Other" },
  ];

  return (
    <Transition.Group animation={"fade up"} duration={500}>
      <Modal dimmer="inverted" open={visible} onClose={onExitScheduling}>
        <Segment>
          <Header className={styles.eventHeaderStyle}>
            {updateEvent ? t("results.events.updateEvent") : t("results.events.newEvent")}
            <Icon onClick={onExitScheduling} fitted size="large" color="teal" name={"x"} />
          </Header>
          <Form>
            <Form.Field>
              <label>{t("results.events.date")}</label>
              <Input value={localDate} onChange={handleDatePickerChange} type="date" />
            </Form.Field>
            <Form.Field>
              <label>{t("results.events.time")}</label>
              <Input value={localTime} onChange={handleTimeChange} type="time" />
            </Form.Field>
            <Form.Field id="form-input-control-event-type" search onChange={onTestTypeChange} selection options={options} control={Dropdown} label={t("results.events.eventType")} placeholder={t("results.events.eventType")} required value={updateEvent?.eventType} />
            {otherOption && <Form.Field error={eventTypeCharacterLimit} id="form-input-control-event-type-custom" maxLength={50} control={Input} label="" placeholder={t("results.events.eventTypePlaceholder")} onChange={handleTestTypeChange} />}
            <Form.Field error={eventDetailsCharacterLimit} onChange={(e: any) => eventSchedule.setDetails(e.target.value)} maxLength={200} id="form-textarea-control-opinion" control={TextArea} label={t("results.events.details")} placeholder={t("results.events.details")} />
            <Form.Field inline>
              <Button color="teal" disabled={!eventSchedule.eventType} onClick={onSubmit} content={eventSchedule.id ? t("results.events.update") : t("results.events.add")} loading={promiseInProgress} />
            </Form.Field>
          </Form>
        </Segment>
      </Modal>
    </Transition.Group>
  );
};

export default observer(EventScheduleComponent);
