import React from 'react';
import { Message } from 'semantic-ui-react';

const SuccessDisplay: React.FunctionComponent<{ message: string }> = (props) => {
  const { message } = props;

  if (!message) {
    return null;
  }

  return <Message positive>{message}</Message>;
};

export default SuccessDisplay;
