import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import ClearMindPhoto from '../Components/Testing/ClearMindPhoto';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { trackPromise } from 'react-promise-tracker';
import ErrorDisplay from '../Components/Messaging/ErrorDisplay';
import * as Sentry from '@sentry/react';
import styles from './UserPhotoPage.module.css';
import i18n from "../i18n";

const UserPhotoPage: React.FC = () => {
  const { user } = useClearMindContext();
  const [errorMessage, setErrorMessage] = useState('');
  const onPhoto = async (photo: string) => {
    try {
      setErrorMessage('');
      await trackPromise(user.indexUserFace(photo));
    } catch (error: any) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  if (!user.needsIndexedFace) {
    return <Redirect to='/' />;
  }

  return (
    <FrostedFrame>
      <Segment
        style={{
          margin: "auto",
          maxWidth: "500px",
        }}
        basic
      >
        <Header textAlign="center">
          <Header.Content as={"h2"}>
            {i18n.t("onboarding.photoCapture.capturePhoto")}
            <Header.Subheader>
              {i18n.t("onboarding.photoCapture.pictureUse")}
            </Header.Subheader>
            <Header as={"h3"} textAlign="center" className={styles.remove}>
              {i18n.t("onboarding.photoCapture.itemRemoval")}
            </Header>
          </Header.Content>
        </Header>
      </Segment>

      <div style={{ textAlign: "center" }}>
        <ClearMindPhoto onPhoto={onPhoto} />
      </div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
        <ErrorDisplay message={errorMessage} />
      </div>
    </FrostedFrame>
  );
};

export default observer(UserPhotoPage);
