import React, { useEffect, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { Card, Message } from 'semantic-ui-react'
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext'
import ClearMindButton from '../../Components/Controls/ClearMindButton/ClearMindButton'
import Spinner from '../../Components/Spinner'
import { IOnSiteTestingModel } from '../../Models/OnSiteTestingModel'
import { createOnSiteTestingOrder } from '../../Services/FormFoxService'


interface OnSiteTestingCardGroupProps {
    locations: IOnSiteTestingModel[]
    setLocationSelected: React.Dispatch<React.SetStateAction<boolean>>
    locationSelected: Boolean
}
const OnSiteTestingCardGroup: React.FC<OnSiteTestingCardGroupProps> = (props) => {
    const { locations, setLocationSelected, locationSelected } = props
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const { user } = useClearMindContext();
    const selectLocationClick = async (locationId: string) => {
        setShowLoader(true)
        const response = await trackPromise(createOnSiteTestingOrder(user.userId!, locationId))
        setShowLoader(false)
        if (response === 200) {
            setLocationSelected(true)
            
        } else {
            setShowErrorMessage(true)
            setTimeout(() => setShowErrorMessage(false), 5000)
        }
    }
    useEffect(() => {
        locations.forEach(element => {
            console.log(element)
        });
    }, [locations])
    
    return (
      <Card.Group>
        {!locationSelected &&
          locations.map((location) => (
            <Card style={{ background: "none" }}>
              <Card.Content>
                <Card.Meta style={{ fontSize: "18px", marginBottom: "8px" }}>{location.name}</Card.Meta>
                <Card.Meta>{location.fullAddress}</Card.Meta>
                <Card.Meta>
                  <a href={`tel:+${location.phone}`}>{location.phone}</a>
                </Card.Meta>
                <Card.Meta>Distance: {location.distance < 0.01 ? "less than .01 miles" : `${location.distance.toFixed(2)} mile(s)`}</Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <ClearMindButton opaque onClick={() => selectLocationClick(location.code!)}>{showLoader ? <Spinner spinnerText="Selecting Location..." /> : "Select Location"}</ClearMindButton>
              </Card.Content>
              {showErrorMessage && (
                <Card.Content extra>
                  <Message icon="warning" negative style={{ textAlign: "left" }}>
                    Location could not be selected, please try another location
                  </Message>
                </Card.Content>
              )}
            </Card>
          ))}
      </Card.Group>
    );
}

export default OnSiteTestingCardGroup
