import React, { useEffect, useState } from 'react';
import { Header, Image, Accordion, Icon, Segment } from 'semantic-ui-react';
import FooterBackButton from '../Components/BackButton/FooterBackButton';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import faqHeaderImage from '../Images/DashboardPhotos/TestingInfoImg1.png';
import { scrollToTop } from '../Services/PageService';
import { useTranslation } from "react-i18next";

const TestingFAQsMemberPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const pageStyles = {
    link: { color: "#3EBABF", fontWeight: "bold" },
  };
  const { t } = useTranslation();

  const handleClick = (e: any, data: any) => {
    const index = data.index;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <FrostedFrame>
        <Segment basic>
          <Header style={{ color: "var(--secondary-color)" }} as={"h1"} textAlign="center">
            {t("dashboard.faq.frequentlyAsked")}
          </Header>
          <Image centered src={faqHeaderImage} />
          <br />
          <br />
          <Accordion styled fluid>
            <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.when")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}> {t("dashboard.faq.begins")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.how")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>{t("dashboard.faq.youReceive")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 2} index={2} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.howLong")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}> {t("dashboard.faq.firstTest")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 3} index={3} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.howToTest")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>{t("dashboard.faq.howToTestAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 4} index={4} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.howLongDoIHave")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>{t("dashboard.faq.howLongDoIHaveAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 5} index={5} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.whatIf")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>{t("dashboard.faq.whatIfAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 6} index={6} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.interrupts")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>{t("dashboard.faq.interruptsAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 7} index={7} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.identity")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>{t("dashboard.faq.identityAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 8} index={8} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.cantTakeTest")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>{t("dashboard.faq.cantTakeTestAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 9} index={9} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.cantStop")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 9}> {t("dashboard.faq.cantStopAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 10} index={10} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.contest")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 10}>{t("dashboard.faq.contestAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 11} index={11} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.techIssue")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 11}>
              {t("dashboard.faq.techIssueAnswer")}
              <a style={pageStyles.link} href="mailto:support@clearlee.com">
                {t("dashboard.faq.techIssueContact")}
              </a>
              .
            </Accordion.Content>
            <Accordion.Title active={activeIndex === 12} index={12} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.whatDoIDo")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 12}> {t("dashboard.faq.whatDoIDoAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 13} index={13} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.howSoon")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 13}>{t("dashboard.faq.howSoonAnswer")}</Accordion.Content>
            <Accordion.Title active={activeIndex === 14} index={14} onClick={handleClick}>
              <Icon name="dropdown" />
              {t("dashboard.faq.whoGets")}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 14}>{t("dashboard.faq.whoGetsAnswer")}</Accordion.Content>
          </Accordion>
        </Segment>
        <Segment textAlign="center" basic>
          <Header as={"h3"}>
            {t("dashboard.faq.additionalQuestions")}
            <br />
            <a style={pageStyles.link} href="mailto:support@clearlee.com">
              {t("dashboard.faq.contact")}
            </a>
            <br />
            {t("dashboard.faq.soWeCanHave")}
            <br /> {t("dashboard.faq.greatExperience")}
          </Header>
          <FooterBackButton />
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default TestingFAQsMemberPage;
