export class BlobBuilder {
    parts: Blob[] = [];

    append = (part: Blob) => {
        this.parts.push(part);
    };

    size = () => {
        return this.parts.reduce((prev, current) => {
            const val = current.size + prev;

            return val;
        }, 0);
    };

    get = () => {
        const blob = new Blob(this.parts);

        this.parts = [];
        return blob;
    };
}
