import { types, Instance } from 'mobx-state-tree';
import { IsSelectedChangeEvent } from '../Components/Controls/Selector/Selector';

const BehaviorContractQuestion = types
  .model('BehaviorContractQuestionModel', {
    id: types.string,
    question: types.maybe(types.string),
    answer: types.maybeNull(types.string),
    placeholder: types.maybe(types.string),
    label: types.maybe(types.string),
    lowerLabel: types.maybe(types.string),
    inputType: types.maybe(
      types.enumeration([
        'input',
        'label',
        'lowerLabel',
        'radioButton',
        'checkboxGroup',
        'prescriptionList',
        'healthInsuranceSelector',
        'supplementList',
        'textArea',
        'DaysSoberInput',
        'ticker',
        'subHeader',
      ])
    ),
    options: types.maybe(types.array(types.string)),
    required: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isAnswered() {
      return self.inputType === 'label' || self.answer?.trim() !== '';
    },
    get selectedOptionIndex() {
      if (!self.options) {
        return -1;
      }

      return self.options.indexOf(self.answer || '');
    },
    get requirementMet() {
      return (
        !self.required ||
        self.inputType === 'label' ||
        self.answer?.trim() !== ''
      );
    },
  }))
  .actions((self) => ({
    onInputChangeEvent: (e: any) => {
      self.answer = e.target.value;
    },
    containsAnswer: (value: string) => {
      const answers = self.answer?.split(',');
      return answers?.some((answer) => answer === value);
    },
    onSelectorChangeEvent: (e: IsSelectedChangeEvent) => {
      let isSelected = e.isSelected;
      let answer = e.name;
        if (isSelected) {
          const answers = self.answer?.split(',').filter((s) => s !== '') || [];
          answers.push(answer);
          self.answer = answers.join(',');
        } else {
          const answers = self.answer?.split(',') || [];
          var filtered = answers.filter((value) => {
            return value !== answer;
          });
          self.answer = filtered.join(',');
        }
    },
    onRadioChangeEvent: (data: any) => {
      self.answer = data.name;
    },
    setAnswer: (answer: string | null) => {
      self.answer = answer;
    },
  }));

export default BehaviorContractQuestion;
export interface IBehaviorContractQuestion
  extends Instance<typeof BehaviorContractQuestion> {}
