import React from 'react';
import { Icon, Reveal } from 'semantic-ui-react';

interface CheckListCheckIconProps {
  active: boolean;
}

const CheckListCheckIcon: React.FC<CheckListCheckIconProps> = (props) => {
  const { active } = props;

  return (
    <Reveal
      animated='fade'
      disabled
      active={active}
      style={{ display: 'inline-block' }}
    >
      <Reveal.Content style={{ visibility: !active && 'hidden' }} hidden>
        <Icon
          name='check circle'
          color='teal'
          size='big'
        />
      </Reveal.Content>

      <Reveal.Content visible>
      <Icon style={{ color: "#6c6c6c" }} name="minus circle" size="big" />
      </Reveal.Content>
    </Reveal>
  );
};

export default CheckListCheckIcon;
