import React, { useEffect, useState } from 'react';
import { Button, Header, Input, Loader, Message, Segment } from 'semantic-ui-react';
import FrostedFrame from '../../Components/Controls/FrostedFrame/FrostedFrame';
import FooterBackButton from '../../Components/BackButton/FooterBackButton';
import ClearMindButton from '../../Components/Controls/ClearMindButton/ClearMindButton';
import { trackPromise } from 'react-promise-tracker';
import { getTestingSites, } from '../../Services/FormFoxService';
import OnSiteTestingCardGroup from './OnSiteTestingCardGroup';
import { IOnSiteTestingModel } from '../../Models/OnSiteTestingModel';

const OnSiteTestingPage: React.FC = () => {
    const [zipCode, setZipCode] = useState('');
    const [testingSites, setTestingSites] = useState<IOnSiteTestingModel[]>()
    const [showLoader, setShowLoader] = useState(false)
    const [locationSelected, setLocationSelected] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [locationsFound, setLocationsFound] = useState(false)
    useEffect(() => {
        if (testingSites !== undefined && testingSites.length > 0) {
            setLocationsFound(true)
            console.log(locationsFound)
        }
    }, [locationsFound, testingSites])


    const onSetZipCodeClick = async () => {
        if (zipCode !== '') {
            setShowLoader(true)
            setTestingSites(await trackPromise(
                getTestingSites(zipCode)
            ));
            setShowLoader(false)
        } else {
            setShowErrorMessage(true)
            setTimeout(() => setShowErrorMessage(false), 5000)
        }

    }
    return (
      <>
        <FrostedFrame>
          <Segment basic textAlign="center">
            <Header as={"h1"} textAlign="center" style={{ color: "var(--secondary-color)" }}>
              On-Site Testing
            </Header>
            <Message content="Enter in your zip code below to retrieve your closest testing sites" style={{ background: "var(--secondary-color)", boxShadow: "none" }} />
          </Segment>
          <Input placeholder="Zip Code" style={{ paddingLeft: "16px", paddingRight: "16px" }} onChange={(e) => setZipCode(e.target.value)} fluid />
          <div className="ui center aligned" style={{ textAlign: "center", paddingTop: "8px", paddingBottom: "12px" }}>
            <ClearMindButton opaque style={{ width: 180 }} onClick={onSetZipCodeClick}>
              <Button.Content visible>{showLoader ? <Loader size="small" active inline /> : "Get Testing Sites"}</Button.Content>
            </ClearMindButton>
          </div>

          {showErrorMessage && (
            <Message icon="warning" negative style={{ textAlign: "left" }}>
              A zip code is required to find testing sites
            </Message>
          )}
          {locationsFound && <OnSiteTestingCardGroup locations={testingSites!} setLocationSelected={setLocationSelected} locationSelected={locationSelected} />}
          {locationSelected && (
            <Message icon="warning" success style={{ textAlign: "left", marginTop: "24px" }}>
              Success! Your location has been selected.
            </Message>
          )}
          <FooterBackButton />
        </FrostedFrame>
      </>
    );
};
export default OnSiteTestingPage;


