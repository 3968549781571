import React, { createContext, useContext } from 'react';
import User from '../Models/User';
import FacilityPartner from '../Models/FacilityPartner';
import { NullBehaviorContract } from '../Models/BehaviorContract';
import TestingService from '../Services/TestingService';
import TestingS3Service from "../Models/TestingS3Service";
import { IClearMindContext } from './IClearMindContext';
import { ScheduledEventsStore } from '../Models/ScheduledEventsStore';

const user = User.create({ isLoading: false, role: "Member", email: "", firstName: "", lastName: "", phoneNumber: "", birthdate: "0001-01-01" });

const testingService = TestingService.create({
  currentState: "legal-disclaimer",
});
const facilityPartner = FacilityPartner.create();
const scheduledEventsStore = ScheduledEventsStore.create();
const testingS3Service = TestingS3Service.create({ presignedUrl: "", fileName: "", chunks: [] });
const createContextDependencies = (): IClearMindContext => {
  const behaviorContract = NullBehaviorContract;

  return {
    user,
    testingS3Service,
    testingService,
    behaviorContract,
    facilityPartner,
    scheduledEventsStore,
  };
};

export const contextDependencies = createContextDependencies();

export const ClearMindContextProvider = createContext(contextDependencies);

export const useClearMindContext = (): IClearMindContext => {
  return useContext(ClearMindContextProvider);
};

export const ClearMindContext: React.FC = (props) => {

  return (
    <ClearMindContextProvider.Provider value={contextDependencies}>
      {props.children}
    </ClearMindContextProvider.Provider>
  );
};
