export const hideBackArrowArray = [
  '/demographics',
  '/user-photo',
  '/language-select',
  '/member-onboarding',
  '/member-dashboard',
  '/supporter-onboarding',
  '/supporter-dashboard',
  '/wellness-agreement',
  '/welcome',
  '/supporters',
  '/help',
  '/pre-testing',
];