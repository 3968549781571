import { ICognitoStorage } from 'amazon-cognito-identity-js'
import { clearAllTokens, putAuthTokensInStorage } from './Services/NativeBridgeEventsService';
export class AuthenticationStorage implements ICognitoStorage {
    _sessionStorage: Storage;
    _localStorage: Storage;
    _storage: Storage;
    _userLoggedIn = false;
    constructor(sessionStorage: Storage, localStorage: Storage) {
        this._sessionStorage = sessionStorage;
        this._localStorage = localStorage;
        this._storage = this._localStorage.getItem('remember-me') ? this._localStorage : this._sessionStorage;
    }

    public get length() {
        return this._storage.length;
    }

    public key(index: number) {
        return this._storage.key(index);
    }

    public setUserLoggedIn(isLoggedIn: boolean) {
        this._userLoggedIn = true;
    }

    setRememberMe(rememberMe: boolean) {
        if (rememberMe) {
            this._storage = this._localStorage;
        } else {
            this._storage = this._sessionStorage;
        }
    }

    setItem(key: string, value: string) {
        const val = this._storage.setItem(key, value);
        if (this._userLoggedIn) {
            putAuthTokensInStorage(this.getAllStorageDataAsJson());
        }

        return val
    }

    getItem(key: string) {
        return this._storage.getItem(key);
    }

    removeItem(key: string) {
        this._storage.removeItem(key);
        if (this._userLoggedIn) {
            putAuthTokensInStorage(this.getAllStorageDataAsJson());
        }
    }

    clear() {
        this._storage.clear();
        if (this._userLoggedIn) {
            clearAllTokens();
        }
    }

    getAllStorageDataAsJson() {
        let allData: { [key: string]: any } = {};

        for (let i = 0; i < this._storage.length; i++) {
            const key = this._storage.key(i);
            if (key) {
                try {
                    // Parse each item in case they are stored as JSON strings
                    allData[key] = JSON.parse(this._storage.getItem(key) as string);
                } catch (e) {
                    // If not JSON, store as a raw string
                    allData[key] = this._storage.getItem(key);
                }
            }
        }

        return JSON.stringify(allData);
    }
}

const _authenticationStorage = new AuthenticationStorage(window.sessionStorage, window.localStorage);

export default _authenticationStorage;
