import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import PretestCheckList from '../Components/Testing/PretestStep/PretestCheckList';
import TestCamera from '../Components/Testing/TestCamera';
import TestMessaging from '../Components/Testing/TestMessaging/TestMessaging';
import TestTimer from '../Components/Testing/TestTimer';
import TestConfirmationSquare from '../Components/Testing/TestConfirmationSquare/TestConfirmationSquare';
import TestCompleteStep from '../Components/Testing/TestCompleteStep';
import ResponsiveContainer from '../Components/Layout/ResponsiveContainer';
import UserInFrameOverlay from '../Components/Testing/UserInFrameOverlay/UserInFrameOverlay';
import UploadFailure from '../Components/Testing/UploadFailure';
import { getPersonalizedPrompt } from '../Services/TextToSpeech';
import { testMessageFactory } from "../Services/TestMessageFactory";

const TestingPage: React.FC = () => {
  const { testingService, user } = useClearMindContext();
  const { currentImageMessage } = testingService;

  const noGlasses = currentImageMessage.isWearingSunglasses;
  const isUser = currentImageMessage.found;
  const pretestComplete = isUser && !noGlasses;
  const [finalMessageComplete, setFinalMessageComplete] = useState(false);

  const delayTransition = (ms: number): void => {
    setTimeout(() => {
      setFinalMessageComplete(true);
    }, ms);
  }

  useEffect(() => {
    // Prefetch personalized prompt to avoid delay
    if (testingService.currentState === "phone-orientation-check" && !isUser) {
      getPersonalizedPrompt(testMessageFactory("test-checklist-success", user));
    }
    if (pretestComplete && testingService.currentState === 'test-checklist') {
      testingService.onPretestComplete();
    }
  }, [pretestComplete, testingService, testingService.currentState, user, isUser]);

  useEffect(() => {
    if (testingService.currentState === "test-complete") {
      delayTransition(5000);
    }
  }, [testingService.currentState])

  if (testingService.currentState === 'upload-failure') {
    return <ResponsiveContainer children={<UploadFailure />} title={''} />;
  }

  if ((testingService.currentState === 'test-complete' && finalMessageComplete) || testingService.currentState === "retry-upload-success") {
    user.clearTestPeriodStartHourAfterTestComplete();
    return <ResponsiveContainer children={<TestCompleteStep />} title={''} />;
  }

  const tenMinuteTimerHandler = () => testingService.onStateTransition('confirm-test-side-a');
  const fiveMinuteTimerHandler = () => testingService.onStateTransition('place-stick-in-cube-1');

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      overflow: "clip"
    }}>
      <TestCamera>
        <PretestCheckList />
        <UserInFrameOverlay />
        <TestMessaging message={testingService.currentTestMessage(user)} showBackground />
        <TestConfirmationSquare />
        <TestTimer date={testingService.testSwabTimerEndTime} onChange={fiveMinuteTimerHandler} />
        <TestTimer date={testingService.testTimerEndTime} onChange={tenMinuteTimerHandler} />
      </TestCamera>
    </div>
  );
};

export default observer(TestingPage);
