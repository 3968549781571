import React, { useState } from 'react';
import { IBehaviorContract } from '../../../Models/BehaviorContract';
import { Header, Input, Message, Segment } from 'semantic-ui-react';
import RadioSelector from '../../Controls/Selector/RadioSelector';
import StepButtons from '../StepButtons';
import { observer } from 'mobx-react-lite';
import { IsSelectedChangeEvent } from '../../Controls/Selector/Selector';
import { trackPromise } from 'react-promise-tracker';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import i18n from "../../../i18n";

interface FacilityPartnerProps {
  behaviorContract: IBehaviorContract;
  nextStep?: () => void;
  isEdit?: boolean;
  beforeStepChange: () => Promise<void>;
  isActive?: boolean;
}

const FacilityPartnerStep: React.FC<FacilityPartnerProps> = (props) => {
  const { behaviorContract, nextStep, beforeStepChange, isEdit, isActive } = props;
  const [radioValue, setRadioValue] = useState("");
  const [facilityCode, setFacilityCode] = useState("");
  const [facilityCodeNotFound, setFacilityCodeNotFound] = useState<boolean>(false);
  const [hasFacilityParnter, setHasFacilityPartner] = useState<boolean | undefined>(undefined);
  const { facilityPartner } = useClearMindContext();
  const { user } = useClearMindContext();

  const handleInputChange = (event: any) => {
    const formattedValue = event.target.value.replace(/ /g, "");
    setFacilityCode(formattedValue);
  };

  if (user.hasFacilityPartner && isActive) {
    nextStep!();
    return null;
  }

  const options = [{ name: "Yes", label: i18n.t("onboarding.facilityCode.yes") }, { name: "No", label: i18n.t("onboarding.facilityCode.no") }];

  const getIndex = options.findIndex((option) => {
    return option.name === radioValue;
  });

  const onChange = (e: IsSelectedChangeEvent) => {
    setRadioValue(e.name);
    setHasFacilityPartner(e.name === "Yes");
  };

  const nextEnabled = () => {
    return hasFacilityParnter !== undefined || (hasFacilityParnter && behaviorContract.facilityCode) || !hasFacilityParnter;
  };

  const onNextStep = () => {
    if (facilityPartner.facilityCode || !hasFacilityParnter) {
      nextStep!();
    }
  };

  const onBeforeStepChange = async () => {
    await trackPromise(facilityPartner.loadFacilityPartner(facilityCode) || Promise.resolve());
    if (!!facilityPartner.facilityCode) {
      behaviorContract.setFacilityCode([facilityCode]);
    } else {
      setFacilityCodeNotFound(true);
    }
    await beforeStepChange();
  };

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h2"}>
        {i18n.t("onboarding.facilityCode.header")}
      </Header>
      <Header textAlign="center" as={"h4"}>
        {i18n.t("onboarding.facilityCode.confirm")}
      </Header>
      <RadioSelector options={options} onChange={onChange} selectedIndex={getIndex} />
      <br />
      {hasFacilityParnter && (
        <>
          <Header textAlign="center" as={"h4"}>
            {i18n.t("onboarding.facilityCode.subHeader")}
          </Header>
          <Input style={{ maxWidth: 400, width: "100%" }} name="facilityCode" onChange={handleInputChange} placeholder={i18n.t("onboarding.facilityCode.placeHolder")} />
          {facilityCodeNotFound && (
            <Message icon="warning" negative style={{ textAlign: "left" }}>
              {i18n.t("onboarding.facilityCode.notFound")}
            </Message>
          )}
        </>
      )}

      <StepButtons {...props} nextStep={onNextStep} isFinalStep={false} nextEnabled={nextEnabled()} beforeStepChange={onBeforeStepChange} isEdit={isEdit} />
    </Segment>
  );
};

export default observer(FacilityPartnerStep);
