import React, { useEffect, useState } from 'react';
import { IBehaviorContract } from '../../Models/BehaviorContract';
import PhoneInput from 'react-phone-number-input/input';
import { Icon, Input, Header, Segment } from 'semantic-ui-react';
import User, { IUser } from '../../Models/User';
import RadioSelector from '../Controls/Selector/RadioSelector';
import ErrorDisplay from '../Messaging/ErrorDisplay';
import StepButtons from './StepButtons';
import { observer } from 'mobx-react-lite';
import { useClearMindContext } from '../../ClearMindContext/ClearMindContext';
import RectangleWidgetGradient from '../MemberDashboard/RectangleWidgetGradient/RectangleWidgetGradient';
import { trackPromise } from 'react-promise-tracker';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import i18n from "../../i18n";

interface AddSupportersProps {
  behaviorContract: IBehaviorContract;
  nextStep?: () => void;
  isEdit?: boolean;
  beforeStepChange: () => Promise<void>;
}

const AddSupportersStep: React.FC<AddSupportersProps> = (props) => {
  const { behaviorContract, nextStep, beforeStepChange, isEdit } = props;

  const [errorMsg, setErrorMsg] = useState('');
  const { user } = useClearMindContext();
  const [newUser, setNewUser] = useState<IUser>(
    User.create({
      email: '',
      firstName: '',
      lastName: '',
      role: 'Supporter',
      supporterRole: '',
      phoneNumber: '',
      invitedBy: user.userId,
      birthdate: new Date().toDateString(),
    })
  );

  useEffect(() => {
    if (user.isSupporter && !behaviorContract.supporters.includes(user)) {
      behaviorContract.addSupporter(user);
    }
  });

  const createUser = () => {
    behaviorContract.addSupporter(newUser);
    setNewUser(
      User.create({
        email: '',
        firstName: '',
        lastName: '',
        role: 'Supporter',
        supporterRole: '',
        phoneNumber: '',
        invitedBy: user.userId,

      })
    );
  };

  const showErrorMessage = (message: string) => {
    setErrorMsg(message);
    setTimeout(() => {
      setErrorMsg('');
    }, 3000);
  };

  const addUser = () => {
    if (!newUser.isValidEmail) {
      showErrorMessage(i18n.t("onboarding.verifyPhone.reTypePhone"));
      return;
    }

    if (behaviorContract.alreadyAdded(newUser)) {
      showErrorMessage(i18n.t("onboarding.verifyPhone.supporterExists"));
      return;
    }
    if (user.email.toLowerCase() === newUser.email.toLowerCase()) {
      showErrorMessage(i18n.t("onboarding.verifyPhone.mustNotMatchMemberEmail"));
      return;
    }
    if (newUser.hasRequiredFields) {
      createUser();
    }
  };

  const options = [
    { name: "Significant Other", label: i18n.t("onboarding.supporterInfo.options.significantOther") },
    { name: "Co-parent (ex-spouse)", label: i18n.t("onboarding.supporterInfo.options.coParent") },
    { name: "Parent", label: i18n.t("onboarding.supporterInfo.options.parent") },
    { name: "Sibling", label: i18n.t("onboarding.supporterInfo.options.sibling") },
    { name: "Friend", label: i18n.t("onboarding.supporterInfo.options.friend") },
    { name: "Other", label: i18n.t("onboarding.supporterInfo.options.other") },
  ];

  const getIndex = options.findIndex((option) => {
    return option.name === newUser.supporterRole;
  });

  const nextStepHandler = () => {
    if (nextStep) {
      nextStep();
    }
  };

  const inputStyle = { maxWidth: 400, width: "100%" };

  const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <Segment basic textAlign="center">
      <Header textAlign="center" as={"h2"} icon>
        {i18n.t("onboarding.supporterInfo.header")}
      </Header>
      <p style={{ color: "#333", fontSize: "9pt", letterSpacing: ".5px" }}>
        {i18n.t("onboarding.supporterInfo.explainer")} <span style={{ fontWeight: "bold" }}> {behaviorContract.hasFacilityPartner ? i18n.t("onboarding.supporterInfo.notRequired") : i18n.t("onboarding.supporterInfo.required")}</span>
      </p>

      <Header textAlign="center" as={"h4"} icon>
        {i18n.t("onboarding.supporterInfo.firstName")}
      </Header>
      <Input style={inputStyle} value={capitalize(newUser.firstName)} name="firstName" onChange={newUser.onInputChangeEvent} placeholder={i18n.t("onboarding.supporterInfo.firstName")}></Input>
      <Header textAlign="center" as={"h4"} icon>
        {i18n.t("onboarding.supporterInfo.lastName")}
      </Header>
      <Input style={inputStyle} value={capitalize(newUser.lastName)} name="lastName" onChange={newUser.onInputChangeEvent} placeholder={i18n.t("onboarding.supporterInfo.lastName")}></Input>
      <Header textAlign="center" as={"h4"} icon>
        {i18n.t("onboarding.supporterInfo.email")}
      </Header>
      <Input style={inputStyle} name={"email"} value={newUser.email} onChange={newUser.onInputChangeEvent} placeholder={i18n.t("onboarding.supporterInfo.email")} />
      <Header textAlign="center" as={"h4"} icon>
        {i18n.t("onboarding.supporterInfo.phone")}
      </Header>
      <div className="ui input" style={inputStyle}>
        <PhoneInput country="US" placeholder={i18n.t("onboarding.supporterInfo.phone")} value={newUser.phoneNumber} onChange={newUser.setPhoneNumber} />
      </div>
      <Header textAlign="center" as={"h4"} icon>
        {i18n.t("onboarding.supporterInfo.type")}
      </Header>
      <RadioSelector options={options} onChange={newUser.onSupporterRoleChangeEvent} selectedIndex={getIndex} />
      <br />

      <ClearMindButton opaque onClick={addUser} disabled={!newUser.hasRequiredFields} style={{ marginTop: 10 }}>
        {i18n.t("onboarding.supporterInfo.add")}
      </ClearMindButton>
      <br />
      <ErrorDisplay message={errorMsg} />
      <div style={{ margin: "auto", display: "inline-block", paddingTop: 15 }}>
        {behaviorContract.supporters.length !== 0 && <Header as={"h3"}> {i18n.t("onboarding.supporterInfo.currentSupporter")}</Header>}
        {behaviorContract.supporters.map((user: IUser, index: number) => (
          <>
            <RectangleWidgetGradient>
              <Segment basic>
                <Header key={index} textAlign="left" color="grey">
                  <Icon onClick={() => behaviorContract.removeSupporter(index)} name="delete" link corner="top right" size="tiny" />
                  <Header.Content>
                    {user.name}
                    <Header.Subheader style={{ color: "#ffff" }}>{user.email}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </RectangleWidgetGradient>
          </>
        ))}
      </div>
      <StepButtons
        {...props}
        nextStep={nextStepHandler}
        isFinalStep={false}
        nextEnabled={behaviorContract.supporters.length > 0 || behaviorContract.hasFacilityPartner}
        beforeStepChange={async () => {
          await trackPromise(behaviorContract.saveSupporters());
          await beforeStepChange!();
        }}
        isEdit={isEdit}
      />
    </Segment>
  );
};

export default observer(AddSupportersStep);
