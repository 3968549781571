import { useEffect } from "react";
import { putAuthTokensInStorage } from "../../Services/NativeBridgeEventsService";
import authStorage from '../../AuthenticationStorage';

export const TokenStorage: React.FC = () => {
    useEffect(() => {
        const value = authStorage.getAllStorageDataAsJson();
        putAuthTokensInStorage(value);
    }, []);
    return null;
}

export default TokenStorage;




