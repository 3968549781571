import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react';
import { Medications } from '../../MedicationList';
import i18n from '../../i18n';

interface PrescriptionListProps {
  answer: string,
  onAnswerChange: (newAnswer: string) => void
}

const PrescriptionList: React.FC<PrescriptionListProps> = (props) => {
  const [firstLetter, setFirstLetter] = useState<string>('A');
  const [optionBuckets, setOptionBuckets] = useState<any>({});
  const { answer, onAnswerChange } = props;

  useEffect(() => {
    MedicationOptions();
  }, []);

  const onChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value: any = data.value || [];
    onAnswerChange(value.join(", "));
  }

  const onSearchChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
    setFirstLetter(data.searchQuery?.charAt(0).toLocaleUpperCase() || 'A');
  }

  const MedicationOptions = () => {

    const optionBuckets = Medications.reduce((previous: any, currentValue, currentIndex) => {
      const firstLetter = currentValue.charAt(0);

      if (!previous[firstLetter]) {
        previous[firstLetter] = []
      }

      const upperCaseFirst = currentValue
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

      previous[firstLetter].push({
        key: currentIndex,
        text: upperCaseFirst,
        value: upperCaseFirst,
      });

      return previous;
    }, {});

    setOptionBuckets(optionBuckets);
  };

  return (
    <Dropdown
      fluid
      multiple
      search
      selection
      options={optionBuckets[firstLetter]}
      placeholder={i18n.t("onboarding.prescriptions.subHeader")}
      defaultValue={answer.split(", ")}
      onChange={onChange}
      onSearchChange={onSearchChange}
    />
  );
};

export default PrescriptionList;
