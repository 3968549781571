import { observer } from 'mobx-react-lite';
import React from 'react';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import styles from './TestConfirmationSquare.module.css';

const TestConfirmationSquare: React.FC = () => {
  const { testingService } = useClearMindContext();
  const { testReadyForCapture } = testingService;

  const testingStateRequiresConfirmationSquare = () => {
    return (
      testingService.currentState === 'confirm-test-side-a-alcohol' ||
      testingService.currentState === 'confirm-test-side-b-alcohol' ||
      testingService.currentState === 'confirm-test-side-a' ||
      testingService.currentState === 'confirm-test-side-b'
    );
  };

  const setClassName = () => {
    if (testReadyForCapture) {
      return styles.confirmSquareGreen;
    }

    return styles.confirmSquare;
  };

  if (!testingStateRequiresConfirmationSquare()) {
    return null;
  }

  return (
    <div className={setClassName()}></div>
  );
};

export default observer(TestConfirmationSquare);
