import axios from 'axios';
import {
  flow,
  getSnapshot,
  Instance,
  types,
  SnapshotIn,
} from 'mobx-state-tree';
import { useClearMindContext } from '../ClearMindContext/ClearMindContext';
import TestResult from './TestResult';
import Step from './Step';

const defaultSteps = [
  { index: 1, timestamp: 0, text: '' },
  { index: 2, timestamp: 0, text: '' },
  { index: 3, timestamp: 0, text: '' },
  { index: 4, timestamp: 0, text: '' },
  { index: 5, timestamp: 0, text: '' },
  { index: 6, timestamp: 0, text: '' },
  { index: 7, timestamp: 0, text: '' },
  { index: 8, timestamp: 0, text: '' },
];

const _testSession = types
  .model("TestSession", {
    notificationConsentAgreement: types.maybe(types.boolean),
    consumptionAgreement: types.maybe(types.boolean),
    governmentIdRequired: types.optional(types.boolean, false),
    avoidSubstancesAgreement: types.maybe(types.boolean),
    batteryChargedAgreement: types.maybe(types.boolean),
    doNotDisturbAgreement: types.maybe(types.boolean),
    wifiConfirmationAgreement: types.maybe(types.boolean),
    misstepWarningAgreement: types.maybe(types.boolean),
    testCubeExpirationDate: types.maybe(types.string),
    requiredMaterialsAgreement: types.maybe(types.boolean),
    tamperWarningAgreement: types.maybe(types.boolean),
    phoneStandAgreement: types.maybe(types.boolean),
    acceptedSubstances: types.maybe(types.string),
    legalDisclaimerAgreement: types.maybe(types.boolean),
    legalDisclaimerAgreementTimestamp: types.maybe(types.number),
    sessionId: types.maybe(types.string),
    videoName: types.maybe(types.string),
    memberId: types.string,
    facilityCode: types.string,
    reviewed: types.optional(types.boolean, false),
    testCompleted: types.optional(types.boolean, false),
    testPacketShown: types.maybe(types.boolean),
    testPacketOpened: types.maybe(types.boolean),
    dateTestTaken: types.string,
    testCompleteTimestamp: types.maybe(types.number),
    alcoholSideAPhoto: types.maybe(types.string),
    alcoholSideBPhoto: types.maybe(types.string),
    testResultSideAPhoto: types.maybe(types.string),
    testResultSideBPhoto: types.maybe(types.string),
    testResultSideACaptured: types.optional(types.boolean, false),
    testResultSideBCaptured: types.optional(types.boolean, false),
    uploadId: types.maybe(types.string),
    steps: types.array(Step),
    testResults: types.maybe(types.array(TestResult)),
    testCubeType: types.maybe(types.string),
    memberSignature: types.maybe(types.string),
  })
  .views((self) => ({
    get testVerificationComplete() {
      return self.memberSignature && self.tamperWarningAgreement;
    },
    get isGovernmentIdRequired() {
      return self.governmentIdRequired
    },
    get testingRequirementsChecked() {
      return self.notificationConsentAgreement && self.batteryChargedAgreement && self.doNotDisturbAgreement && self.consumptionAgreement && self.wifiConfirmationAgreement && self.misstepWarningAgreement && self.avoidSubstancesAgreement;
    },
  }))
  .actions((self) => {
    const { testingService } = useClearMindContext();

    return {
      setMemberSignature: (memberSignature: string) => {
        self.memberSignature = memberSignature;
      },
      setGovernmentIdRequired: (required: boolean)=>{
        self.governmentIdRequired = required;
      },
      setBatteryChargedAgreement: (value: boolean) => {
        self.batteryChargedAgreement = value;
      },
      setNotificationConsentAgreement: (value: boolean) => {
        self.notificationConsentAgreement = value;
      },
      setDoNotDisturbAgreement: (value: boolean) => {
        self.doNotDisturbAgreement = value;
      },
      setWifiConfirmationAgreement: (value: boolean) => {
        self.wifiConfirmationAgreement = value;
      },
      setMisstepWarningAgreement: (value: boolean) => {
        self.misstepWarningAgreement = value;
      },
      setConsumptionAgreement: (value: boolean) => {
        self.consumptionAgreement = value;
      },
      setAvoidSubstancesAgreement: (value: boolean) => {
        self.avoidSubstancesAgreement = value;
      },
      setTestCubeType: (testCubeType: string) => {
        self.testCubeType = testCubeType;
      },
      setUploadId: (value: string) => {
        self.uploadId = value;
      },
      setTestCompleteTimestamp: (timestamp: number) => {
        self.testCompleteTimestamp = timestamp;
      },
      setTestCubeExpirationDate: (date: string) => {
        self.testCubeExpirationDate = date;
      },
      setTamperWarningAgreement: (value: boolean) => {
        self.tamperWarningAgreement = value;
      },
      setRequiredMaterialsAgreement: (value: boolean) => {
        self.requiredMaterialsAgreement = value;
      },
      setPhoneStandAgreement: (value: boolean) => {
        self.phoneStandAgreement = value;
      },
      setLegalDisclaimerAgreement: (value: boolean) => {
        self.legalDisclaimerAgreement = value;
      },
      setAcceptedSubstances: (acceptedSubstances: string) => {
        self.acceptedSubstances = acceptedSubstances;
      },
      setVideoName: (videoName: string) => {
        self.videoName = videoName;
      },
      setTestPacketShown: (value: boolean) => {
        self.testPacketShown = value;
      },
      setTestPacketOpened: (value: boolean) => {
        self.testPacketOpened = value;
      },
      setTestCompleted: (testCompleted: boolean) => {
        self.testCompleted = testCompleted;
      },
      update: flow(function* () {
        yield axios.patch(`test-sessions/${self.sessionId}`, getSnapshot(self));
      }),
      post: flow(function* () {
        yield axios.post("test-sessions", getSnapshot(self));
      }),
      setLegalDisclaimerTimestamp: () => {
        self.legalDisclaimerAgreementTimestamp = Date.now();
      },
      setTestImage: (image: string) => {
        if (testingService.currentState === "confirm-test-side-a-alcohol") {
          self.alcoholSideAPhoto = image;
          testingService.onStateTransition("test-side-a-alcohol-confirmed");
        } else if (testingService.currentState === "confirm-test-side-b-alcohol") {
          self.alcoholSideBPhoto = image;
          testingService.onStateTransition("test-side-b-alcohol-confirmed");
        } else if (testingService.currentState === "confirm-test-side-a") {
          self.testResultSideAPhoto = image;
          testingService.onStateTransition("test-side-a-confirmed");
        } else if (testingService.currentState === "confirm-test-side-b") {
          self.testResultSideBPhoto = image;
          testingService.onStateTransition("test-side-b-confirmed");
        }
      },
      setTimestampForState: (newState: string) => {
        if (newState === "mouth-is-open") {
          self.steps[0].timestamp = Date.now();
        } else if (newState === "validate-test-cube") {
          self.steps[1].timestamp = Date.now();
        } else if (newState === "cube-wrapper-removal") {
          self.steps[2].timestamp = Date.now();
        } else if (newState === "insert-test-stick-in-mouth-1") {
          self.steps[3].timestamp = Date.now();
        } else if (newState === "insert-test-stick-in-mouth-3") {
          self.steps[4].timestamp = Date.now();
        } else if (newState === "place-stick-in-cube-1") {
          self.steps[5].timestamp = Date.now();
        } else if (newState === "place-stick-in-cube-2") {
          self.steps[6].timestamp = Date.now();
        } else if (newState === "confirm-test-side-a") {
          self.steps[7].timestamp = Date.now();
        }
      },
    };
  });

export const TestSession = types.snapshotProcessor(_testSession, {
  preProcessor(snapshot: ITestSessionSnapshotIn) {
    const defaults = {
      steps: defaultSteps,
    };

    if (!snapshot) {
      throw new Error('Cannot have empty snapshot for test session model');
    }

    return { ...snapshot, ...defaults };
  },
});

export interface ITestSession extends Instance<typeof TestSession> { }
export interface ITestSessionSnapshotIn
  extends SnapshotIn<typeof _testSession> { }