import React, { useEffect } from 'react';
import { Header, Segment, Image, List, Icon } from 'semantic-ui-react';
import FooterBackButton from '../Components/BackButton/FooterBackButton';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import HelpfulTipsIcon from '../Images/DashboardPhotos/HelpfulTipsIcon.png';
import { scrollToTop } from '../Services/PageService';
import i18n from "../i18n";

const TestingTipsPage: React.FC = () => {
  const pageStyles = {
    boldSpan: { fontWeight: 'bold' },
    italic: {
      fontStyle: 'italic',
    },
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <FrostedFrame>
        <Segment
          basic
          content
          textAlign="left"
          style={{
            color: "black",
          }}
        >
          <Header style={{ color: "var(--secondary-color)" }} as={"h1"} textAlign="center">
            {i18n.t("dashboard.testingTips.header")}
          </Header>
          <Image centered src={HelpfulTipsIcon} />
          <br />
          <Header.Subheader>{i18n.t("dashboard.testingTips.subHeader")}</Header.Subheader>
          <List>
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.deviceRestrictions")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.clearWorkArea")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.watchHowTo")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.useTestMat")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.requiredMaterials")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.wifiSignal")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.preTestAvoidance")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.doNotDisturb")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.phonePosition.position")}
              <span style={pageStyles.boldSpan}>{i18n.t("dashboard.testingTips.phonePosition.span")}</span>
              {i18n.t("dashboard.testingTips.phonePosition.clearView")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.dontTouchPhone")}
            </List.Item>
            <br />
            <List.Item as="li">
              <Icon name="circle" />
              {i18n.t("dashboard.testingTips.faceInCamera")}
            </List.Item>
          </List>
          <br />
          {i18n.t("dashboard.testingTips.followSteps")}
          <br />
          <br />
          <span style={pageStyles.italic}>{i18n.t("dashboard.testingTips.closingTag")}</span>
          <FooterBackButton />
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default TestingTipsPage;
