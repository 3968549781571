import React, { useState, useEffect } from 'react';
import './Curtain.css';
import { Icon } from 'semantic-ui-react';

const Curtain = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`fade-div ${!isVisible && 'fade-out'}`}>
            <div className="centered-text">
                <Icon name='video camera' size="huge" className='blink' />
                <br />
                <br />
                Initializing Camera
            </div>

        </div>
    );
};

export default Curtain;
