import React, { useEffect, useState } from "react";
import { Card, Header } from "semantic-ui-react";
import { ITestResult } from "../../Models/TestResult";
import TestResultsCard from "./TestResultsCard";
import { observer } from "mobx-react-lite";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { useTranslation } from "react-i18next";

interface TestResultsCardListProps {
  testResults: ITestResult[];
}

const TestResultsCardList: React.FC<TestResultsCardListProps> = (props) => {
  const { testResults } = props;
  const { scheduledEventsStore } = useClearMindContext();
  const [testResultsMap, setTestResultsMap] = useState<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    const mapTestResults = testResults.reduce((prev: any, current) => {
      prev[current.dateKey] = current;
      return prev;
    }, {});
    setTestResultsMap(mapTestResults);
  }, [testResults]);

  const getOrderedDayList = (): Date[] => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0);

    const startDate = new Date(lastDayOfMonthDate);
    startDate.setMonth(startDate.getMonth() - 6);

    const days: Date[] = [];

    while (startDate <= lastDayOfMonthDate) {
      days.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    const listOfMonths: { [key: string]: Date[] } = {};

    days.forEach((day) => {
      const yearMonth = `${day.getMonth() + 1}-${day.getFullYear()}`;

      if (!listOfMonths[yearMonth]) {
        listOfMonths[yearMonth] = [];
      }

      listOfMonths[yearMonth].push(day);
    });

    const reverseMonthList: Date[][] = [];

    Object.values(listOfMonths).forEach((daysInMonth) => {
      reverseMonthList.unshift(daysInMonth.reverse());
    });

    return reverseMonthList.flat();
  };
  return (
    <>
      <Header style={{ color: "var(--primary-color)" }} textAlign={"center"} size="large">
        {t("results.cards.testResults")}
      </Header>
      <Card.Group
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          justifyItems: "center",
        }}
        centered
      >
        {getOrderedDayList().map((day: Date, index: any) => {
          const isNullData = testResultsMap[day.toDateString().replace("/", "")] === undefined && scheduledEventsStore.mappedEvents[day.toDateString().replace("/", "")] === undefined;
          if (isNullData) {
            return null;
          }

          return (
            <React.Fragment key={`$calendar-row-${day.getMonth()}-${day.getDate()}`}>
              <TestResultsCard events={scheduledEventsStore.mappedEvents[day.toDateString().replace("/", "")]} testResult={testResultsMap[day.toDateString().replace("/", "")]} date={day} key={index} />
            </React.Fragment>
          );
        })}
      </Card.Group>
    </>
  );
};

export default observer(TestResultsCardList);
