import { Segment } from 'semantic-ui-react';
import React from 'react';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import { usePromiseTracker } from 'react-promise-tracker';
import i18n from '../../i18n';
import { scrollToTop } from '../../Services/PageService';

interface StepButtonsProps {
  nextStep?: () => void;
  previousStep?: () => void;
  nextEnabled: boolean;
  submit?: () => void;
  isFinalStep?: boolean;
  currentStep?: number;
  isEdit?: boolean;
  goToStep?: (step: number) => void;
  beforeStepChange?: () => Promise<void>;
  isMember?: boolean;
}
const StepButtons: React.FC<StepButtonsProps> = (props) => {
  const { previousStep, nextStep, nextEnabled, submit, isFinalStep, currentStep, beforeStepChange, isEdit, goToStep, isMember } = props;
  const { promiseInProgress } = usePromiseTracker();
  const memberReviewStepIndex = 14;
  const supportReviewStepIndex = 6;
  const nextClick = async () => {
    if (isFinalStep) {
      await submit!();
    } else {
      await beforeStepChange!();
      nextStep!();
      scrollToTop();
    }
  };
  const reviewClick = async () => {
    if (isMember) {
      await beforeStepChange!();
      goToStep!(memberReviewStepIndex);
    } else {
      await beforeStepChange!();
      goToStep!(supportReviewStepIndex);
    }
  };
  const previousClick = () => {
    if (previousStep) {
      previousStep();
    }
  };
  const getButtons = () => {
    if (isEdit) {
      return (
        <ClearMindButton opaque onClick={reviewClick} disabled={!nextEnabled} loading={promiseInProgress}>
          {i18n.t("onboarding.stepButtons.returnToReview")}
        </ClearMindButton>
      );
    }
    if (currentStep && currentStep > 1 && !isEdit) {
      return (
        <>
          <ClearMindButton opaque onClick={previousClick}>
            {i18n.t("onboarding.stepButtons.back")}
          </ClearMindButton>
          <ClearMindButton opaque onClick={nextClick} disabled={!nextEnabled} loading={promiseInProgress}>
            {!isFinalStep ? i18n.t("onboarding.stepButtons.next") : i18n.t("onboarding.stepButtons.submit")}
          </ClearMindButton>
        </>
      );
    }
    return (
      <ClearMindButton opaque onClick={nextClick} disabled={!nextEnabled} loading={promiseInProgress}>
        {!isFinalStep ? i18n.t("onboarding.stepButtons.next") : i18n.t("onboarding.stepButtons.submit")}
      </ClearMindButton>
    );
  };
  return (
    <Segment
      basic
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: " 50px",
      }}
    >
      {getButtons()}
    </Segment>
  );
};

export default StepButtons;
