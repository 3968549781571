import { observer } from 'mobx-react-lite';
import { IStateTreeNode, IMaybe, IType } from 'mobx-state-tree';
import React from 'react';
import Countdown from 'react-countdown';
import { CountdownTimeDeltaFn } from 'react-countdown/dist/Countdown';
import styles from '../../Pages/TestingPage.module.css';

interface ITestTimerProps {
  date: (Date & IStateTreeNode<IMaybe<IType<number | Date, number, Date>>>) | undefined;
  onChange: CountdownTimeDeltaFn | (() => void) | undefined;
}

const TestTimer: React.FC<ITestTimerProps> = (props: any) => {
  const { date, onChange } = props;

  const format = (number: number) => {
    return number < 10 ? `0${number.toString()}` : number.toString();
  };

  const renderer = ({ minutes, seconds }: { minutes: number; seconds: number; completed: boolean }) => {
    if (!minutes && !seconds) {
      return null;
    }

    return (
      <div className={styles.timer}>
        {minutes}:{format(seconds)}
      </div>
    );
  };

  if (!date) {
    return null;
  }
  return (
    <>
      <Countdown onComplete={onChange} date={date} renderer={renderer} />
    </>
  );
};

export default observer(TestTimer);
