import React from "react";
import { Header, List, Segment } from "semantic-ui-react";
import FrostedFrame from "../Components/Controls/FrostedFrame/FrostedFrame";
import { useTranslation } from "react-i18next";

const HelpPage: React.FC = () => {
  const styles = {
    phoneNumber: { color: "white", fontWeight: "bold" },
    header: { color: "#fff", paddingBottom: "5px" },
    emergencyNumber: { color: "#f0474a", fontWeight: "bold" },
    selfReportLowerText: { color: "#fff", fontSize: "16px" },
  };

  const { t } = useTranslation();

  return (
    <>
      <FrostedFrame darkMode>
        <Segment basic>
          <List divided relaxed="very" size="big">
            <Header color="grey">{t("dashboard.help.emergencyHotlines")}</Header>
            <List.Item>
              <List.Icon name="phone" size="large" verticalAlign="middle" inverted />
              <List.Content>
                <List.Header style={styles.header} as="p">
                  {t("dashboard.help.medicalEmergency")}
                </List.Header>
                <List.Description style={styles.emergencyNumber} as="a" href="tel:911">
                  {t("dashboard.help.call")}911
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" size="large" verticalAlign="middle" inverted />
              <List.Content>
                <List.Header style={styles.header} as="p">
                  {t("dashboard.help.suicidePreventionLifeline")}
                </List.Header>
                <List.Description style={styles.phoneNumber} as="a" href="tel:8002738255">
                  {t("dashboard.help.call")}800-273-8255
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" size="large" verticalAlign="middle" inverted />
              <List.Content>
                <List.Header style={styles.header} as="h3">
                  {t("dashboard.help.substanceAbuse")}
                </List.Header>
                <List.Description style={styles.phoneNumber} as="a" href="tel:18006624357">
                  {t("dashboard.help.call")} 800-662-HELP (4357)
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Segment>
      </FrostedFrame>
      <FrostedFrame darkMode>
        <Segment basic>
          <List divided relaxed="very" size="big">
            <Header color="grey">{t("dashboard.help.applicationHelp")}</Header>
            <List.Item>
              <List.Icon name="phone" size="large" verticalAlign="middle" inverted />
              <List.Content>
                <List.Header style={styles.header} as="h3">
                  {t("dashboard.help.technicalSupport")}
                </List.Header>
                <List.Description style={styles.phoneNumber} as="a" href="tel:18882424120p2">
                  {t("dashboard.help.call")} 888-242-4120 - ext 2
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" size="large" verticalAlign="middle" inverted />
              <List.Content>
                <List.Header style={styles.header} as="p">
                  {t("dashboard.help.emailSupport")}
                </List.Header>
                <List.Description style={styles.phoneNumber} as="a" href="mailto:support@clearlee.com">
                  support@clearlee.com
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Segment>
      </FrostedFrame>
    </>
  );
};

export default HelpPage;
