import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import FooterBackButton from '../Components/BackButton/FooterBackButton';

const TestSuccessPage: React.FC = () => {
  return (
    <Segment basic placeholder>
      <Header icon>
        <Icon name='bullhorn' />
        Page coming soon.
      </Header>
      <FooterBackButton />
    </Segment>
  );
};

export default TestSuccessPage;