import React from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import StepButtons from '../StepButtons';
import { observer } from 'mobx-react-lite';
import { trackPromise } from 'react-promise-tracker';
import { useClearMindContext } from '../../../ClearMindContext/ClearMindContext';
import stateOptions from '../../CreditCardDetails/StateList';
import i18n from "../../../i18n";

interface ShippingAddressProps {
    nextStep?: () => void;
    isEdit?: boolean;
    beforeStepChange: () => Promise<void>;
    isActive?: boolean;
}

const ShippingAddressStep: React.FC<ShippingAddressProps> = (props) => {
    const { nextStep, beforeStepChange, isEdit, } = props;
    const { user } = useClearMindContext();

    const nextEnabled = () => {
        return user.hasAddress
    };

    const onNextStep = () => {
        if (user.hasAddress) {
            nextStep!();
        };
    };

    const onBeforeStepChange = async () => {
        if (user.hasAddress) {
            await trackPromise(
                user.setUserAddress()
            );
        };
        await beforeStepChange!();
    };

    return (
      <Segment basic textAlign="center">
        <Header textAlign="center" as={"h2"}>
          {i18n.t("onboarding.shippingAddressStep.header")}
        </Header>
        <Header textAlign="center" as={"h4"}>
          {user.hasAddress ? i18n.t("onboarding.shippingAddressStep.nextStep") : i18n.t("onboarding.shippingAddressStep.enterAddress")}
        </Header>
        <Form>
          <Form.Input
            style={{ color: "#333", maxWidth: 400, marginBottom: "8px", width: "100%" }}
            name="streetAddress"
            label={i18n.t("onboarding.shippingAddressStep.streetLabel")}
            onChange={(e) => user.setStreetAddress(e.target.value)}
            defaultValue={user.streetAddress || ""}
            placeholder={i18n.t("onboarding.shippingAddressStep.address")}
          />
          <Form.Input
            style={{ maxWidth: 400, marginBottom: "8px", width: "100%" }}
            name="city"
            label={i18n.t("onboarding.shippingAddressStep.cityLabel")}
            onChange={(e) => user.setCity(e.target.value)}
            defaultValue={user.city || ""}
            placeholder={i18n.t("onboarding.shippingAddressStep.cityLabel")}
          />
          <Form.Dropdown
            style={{ maxWidth: 400, marginBottom: "8px", width: "100%" }}
            name="state"
            placeholder={i18n.t("onboarding.shippingAddressStep.stateLabel")}
            label={i18n.t("onboarding.shippingAddressStep.stateLabel")}
            selection
            options={stateOptions}
            defaultValue={user.state}
            onChange={(e, data) => user.setState(data.value?.toString() || "")}
          />
          <Form.Input
            style={{ minWidth: "196px", maxWidth: 400, marginBottom: "8px", width: "100%" }}
            name="zipCode"
            label={i18n.t("onboarding.shippingAddressStep.zipLabel")}
            onChange={(e) => user.setZipCode(e.target.value)}
            defaultValue={user.zipCode || ""}
            placeholder={i18n.t("onboarding.shippingAddressStep.zipLabel")}
          />
        </Form>
        <StepButtons {...props} nextStep={onNextStep} isFinalStep={false} nextEnabled={nextEnabled()} beforeStepChange={onBeforeStepChange} isEdit={isEdit} />
      </Segment>
    );
};

export default observer(ShippingAddressStep);
