import { Instance, types } from 'mobx-state-tree';

export const Address = types.model("Address", {
  streetAddress: types.maybe(types.string),
  streetAddress2: types.maybe(types.string),
  city: types.maybe(types.string),
  zipCode: types.maybe(types.string),
  state: types.maybe(types.string),
}).views(self => ({
  get isValidZipCode() {
    const re = /(^\d{5}$)/;
    return self.zipCode?.match(re);
  },
  get isAddressValid() {
    return self.streetAddress && self.state && self.zipCode && self.city;
  },
}))
  .actions(self => ({
    setState: (state: string) => {
      self.state = state;
    },
    setZipCode: (zipCode: string) => {
      self.zipCode = zipCode;
    },
    setCity: (city: string) => {
      self.city = city;
    },
    setStreetAddress: (streetAddress: string) => {
      self.streetAddress = streetAddress;
    },
    setStreetAddress2: (streetAddress2: string) => {
      self.streetAddress2 = streetAddress2;
    },
  }));

export default Address;
export interface IAddress extends Instance<typeof Address> { }
