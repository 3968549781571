import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { deleteProfile } from '../../Services/UserService';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { useSignOutContext } from '../Authenticator/ClearMindAuthenticator';
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

export interface ConfirmDisplayProps {
  header: string;
  content: string;
}

const DeleteUserProfile: React.FC<ConfirmDisplayProps> = (props) => {
  const { header, content } = props;
  const { promiseInProgress } = usePromiseTracker({ area: "delete-btn" });

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { signOut } = useSignOutContext();
  const { t } = useTranslation();

  const show = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const handleConfirm = async () => {
    try {
      setOpen(false);
      await trackPromise(deleteProfile(),"delete-btn");
      await signOut();
      history.push("/");
    } catch (ex) {
      setOpen(false);
      Sentry.captureException(ex);
      throw ex;
    }
  };

  return (
    <>
      <Button style={{ borderRadius: "15px", width: 153 }} color="red" onClick={show} loading={promiseInProgress}>
        {t("dashboard.profile.deleteAccount.deleteAccount")}
      </Button>
      <Confirm cancelButton={t("dashboard.profile.deleteAccount.cancel")} confirmButton={t("dashboard.profile.deleteAccount.confirm")} open={open} header={header} content={content} onCancel={handleCancel} onConfirm={handleConfirm} />
    </>
  );
};

export default DeleteUserProfile;
