import { Header } from "semantic-ui-react";
import { IScheduledEvent } from "../../Models/ScheduledEvent";
import { useState } from "react";

interface EventDisplayProps {
  event: IScheduledEvent;
  darkMode?: true;
}
const EventDisplay: React.FC<EventDisplayProps> = (props) => {
  const { event, darkMode } = props;
  const [scheduledEvent] = useState<IScheduledEvent>(event);
  const getHeaderTextColor = darkMode ? "grey" : "blue";
  const getSubHeaderTextColor = darkMode ? "#fff" : "";
  
  return (
    <>
      <Header color={getHeaderTextColor}>
        Time
        <Header.Subheader style={{ color: getSubHeaderTextColor }} color={getHeaderTextColor}>
          {scheduledEvent.localeTimeString}
        </Header.Subheader>
      </Header>
      <Header color={getHeaderTextColor}>
        Type
        <Header.Subheader style={{ color: getSubHeaderTextColor }}>{scheduledEvent.eventType}</Header.Subheader>
      </Header>
      {scheduledEvent.eventDetails && (
        <>
          <Header color={getHeaderTextColor}>
            Details:
            <Header.Subheader style={{ color: getSubHeaderTextColor }}>{scheduledEvent.eventDetails}</Header.Subheader>
          </Header>
        </>
      )}
    </>
  );
};

export default EventDisplay;
