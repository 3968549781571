import { playAudioPrompt, getIsShell } from "../NativeBridgeEventsService";
import { IPromptAudioProvider } from "./IPromptAudioProvider";

export class PromptAudioProviderShellDecorator implements IPromptAudioProvider {

    constructor(private _languageProvider: IPromptAudioProvider) {

    }
    public get shellLanguageFolder() {
        return this._languageProvider.shellLanguageFolder;
    }
    public get testCheckListSuccess() {
        return this._languageProvider.testCheckListSuccess.then(result => this.handleShell(result));
    }
    public get letsGetStarted() {
        return this._languageProvider.letsGetStarted.then(result => this.handleShell(result));
    }
    public get phoneOrientationCheck() {
        return this._languageProvider.phoneOrientationCheck.then(result => this.handleShell(result));
    }
    public get centerYourself() {
        return this._languageProvider.centerYourself.then(result => this.handleShell(result));
    }
    public get mouthIsOpen() {
        return this._languageProvider.mouthIsOpen.then(result => this.handleShell(result));
    }
    public get tongueCheckComplete() {
        return this._languageProvider.tongueCheckComplete.then(result => this.handleShell(result));
    }
    public get validateTestCube() {
        return this._languageProvider.validateTestCube.then(result => this.handleShell(result));
    }
    public get testCubeInFrame() {
        return this._languageProvider.testCubeInFrame.then(result => this.handleShell(result));
    }
    public get wrapperRemoval() {
        return this._languageProvider.wrapperRemoval.then(result => this.handleShell(result));
    }
    public get cubeAndStickInView() {
        return this._languageProvider.cubeAndStickInView.then(result => this.handleShell(result));
    }
    public get validateUnopenedTestKit() {
        return this._languageProvider.validateUnopenedTestKit.then(result => this.handleShell(result));
    }
    public get insertTestStickInMouth1() {
        return this._languageProvider.insertTestStickInMouth1.then(result => this.handleShell(result));
    }
    public get followInstructions() {
        return this._languageProvider.followInstructions.then(result => this.handleShell(result));
    }
    public get insertTestStickInMouth2() {
        return this._languageProvider.insertTestStickInMouth2.then(result => this.handleShell(result));
    }
    public get insertTestStickInMouth3() {
        return this._languageProvider.insertTestStickInMouth3.then(result => this.handleShell(result));
    }
    public get insertTestStickInMouth4() {
        return this._languageProvider.insertTestStickInMouth4.then(result => this.handleShell(result));
    }
    public get showGreenLabel() {
        return this._languageProvider.showGreenLabel.then(result => this.handleShell(result));
    }
    public get removeGreenLabel() {
        return this._languageProvider.removeGreenLabel.then(result => this.handleShell(result));
    }
    public get cubeAndFaceInFrame() {
        return this._languageProvider.cubeAndFaceInFrame.then(result => this.handleShell(result));
    }
    public get testStickProcessMessage1() {
        return this._languageProvider.testStickProcessMessage1.then(result => this.handleShell(result));
    }
    public get testStickProcessMessage2() {
        return this._languageProvider.testStickProcessMessage2.then(result => this.handleShell(result));
    }
    public get testStickProcessMessage3() {
        return this._languageProvider.testStickProcessMessage3.then(result => this.handleShell(result));
    }
    public get testStickProcessMessage4() {
        return this._languageProvider.testStickProcessMessage4.then(result => this.handleShell(result));
    }
    public get testStickProcessMessage5() {
        return this._languageProvider.testStickProcessMessage5.then(result => this.handleShell(result));
    }
    public get placeStickInCube1() {
        return this._languageProvider.placeStickInCube1.then(result => this.handleShell(result));
    }
    public get placeStickInCube2() {
        return this._languageProvider.placeStickInCube2.then(result => this.handleShell(result));
    }
    public get cubeOnMatPrompt() {
        return this._languageProvider.cubeOnMatPrompt.then(result => this.handleShell(result));
    }
    public get cubeOnMatReminder() {
        return this._languageProvider.cubeOnMatReminder.then(result => this.handleShell(result));
    }
    public get remainInView() {
        return this._languageProvider.remainInView.then(result => this.handleShell(result));
    }
    public get remainInViewReminder() {
        return this._languageProvider.remainInViewReminder.then(result => this.handleShell(result));
    }
    public get confirmTestSideA() {
        return this._languageProvider.confirmTestSideA.then(result => this.handleShell(result));
    }
    public get testSideAConfirmed() {
        return this._languageProvider.testSideAConfirmed.then(result => this.handleShell(result));
    }
    public get confirmTestSideB() {
        return this._languageProvider.confirmTestSideB.then(result => this.handleShell(result));
    }
    public get testSideBAlcoholConfirmed() {
        return this._languageProvider.testSideBAlcoholConfirmed.then(result => this.handleShell(result));
    }
    public get testSideBConfirmed() {
        return this._languageProvider.testSideBConfirmed.then(result => this.handleShell(result));
    }
    public get testConfirmed() {
        return this._languageProvider.testConfirmed.then(result => this.handleShell(result));
    }
    public get pleaseRemoveSunglasses() {
        return this._languageProvider.pleaseRemoveSunglasses.then(result => this.handleShell(result));
    }
    public get twoMinReminderAlcohol() {
        return this._languageProvider.twoMinReminderAlcohol.then(result => this.handleShell(result));
    }
    public get lastReminderAlcohol() {
        return this._languageProvider.lastReminderAlcohol.then(result => this.handleShell(result));
    }
    public get bell() {
        return this._languageProvider.bell.then(result => this.handleShell(result));
    }

    private async handleShell(source: string): Promise<string> {
        const shell = await getIsShell();

        if (!shell) {
            return Promise.resolve(source);
        }

        const regex = /\/static\/media\/(.+?)\.[a-f0-9]+\.(mp3)/;
        const match = source.match(regex);

        if (match && match.length > 1) {
            return playAudioPrompt(`prompts/${this._languageProvider.shellLanguageFolder}/${match[1]}.${match[2]}`).then(() => "");
        } else {
            return Promise.resolve(source);
        }
    }
}


