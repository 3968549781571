import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import ClearMindButton from '../Controls/ClearMindButton/ClearMindButton';
import { usePromiseTracker } from 'react-promise-tracker';
import i18n from '../../i18n';

const ClearMindPhoto: React.FC<{ onPhoto: (photo: string) => void }> = ({
  onPhoto,
}) => {
  const webcamRef = useRef<any>(null);
  const canvasRef = useRef(null);

  const { promiseInProgress } = usePromiseTracker();

  const videoConstraints = {
    height: 416,
    width: 416,
    facingMode: 'user',
    frameRate: { max: 30 },
  };

  const takePhoto = () => {
    let photo = webcamRef.current.getScreenshot() || "" as string;

    photo = photo.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    onPhoto(photo);
  };

  const drawOval = () => {
    const video = webcamRef.current as any;
    const canvas = canvasRef.current as any;

    if (video && canvas) {
      var ctx = canvas.getContext('2d');

      canvas.width = video.video.videoWidth;
      canvas.height = video.video.videoHeight;

      ctx.filter = 'blur(10px)';
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height);
      ctx.scale(-1, 1);
      ctx.translate(-canvas.width, 0);
      ctx.filter = 'none';
      ctx.save();

      var pX = canvas.width / 2;
      var pY = canvas.height / 2;

      ctx.beginPath();
      ctx.ellipse(pX, pY, 120, 155, 0, 0, 2 * Math.PI);
      ctx.clip();

      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height);
      ctx.scale(-1, 1);
      ctx.translate(-canvas.width, 0);

      ctx.restore();
      setTimeout(drawOval, 33);
    }
  };

  setTimeout(drawOval, 1000);

  return (
    <>
      <Webcam
        screenshotFormat='image/jpeg'
        style={{ visibility: "hidden", position: 'absolute' }}
        screenshotQuality={0.7}
        mirrored
        videoConstraints={videoConstraints}
        ref={webcamRef}
        audio={false}
      />
      <canvas
        ref={canvasRef}
        style={{ width: '100%', borderRadius: 5, maxWidth: 400 }}
      />
      <div style={{ textAlign: 'center', width: '100%' }}>
        <ClearMindButton opaque
          style={{ maxWidth: 500, marginTop: 20, marginBottom: 20 }}
          onClick={takePhoto}
          loading={promiseInProgress}
        >
          {i18n.t("onboarding.photoCapture.takePhoto")}
        </ClearMindButton>
      </div>
    </>
  );
};

export default ClearMindPhoto;
