import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import FrostedFrame from '../Components/Controls/FrostedFrame/FrostedFrame';
import ResultsTrackerComponent from '../Components/TestResultsDisplayComponent/ResultsTrackerComponent';
import i18n from "../i18n";

const MemberTestResultsPage: React.FC = () => {
  return (
    <>
      <Grid.Row>
        <Segment padded textAlign={"center"} basic>
          <Header style={{ color: "var(--secondary-color" }} as={"h2"} textAlign="center">
            {i18n.t("dashboard.tiles.resultsTracker")}
          </Header>
        </Segment>
      </Grid.Row>
      <FrostedFrame>
        <ResultsTrackerComponent />
      </FrostedFrame>
    </>
  );
};

export default MemberTestResultsPage;
