import { types, Instance } from 'mobx-state-tree';
const DrugPanelResults = types
    .model('DrugPanelResults model', {
        id: types.maybe(types.string),
        result: types.string,
        controlLine: types.maybe(types.boolean),
        testLine: types.maybe(types.boolean),
    })

export default DrugPanelResults;
export interface IDrugPanelResults extends Instance<typeof DrugPanelResults> { }
