import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./public/locales/en.json";
import spTranslations from "./public/locales/es.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    es: { translation: spTranslations },
  },
  lng: "en", // default language
  fallbackLng: "en", // fallback language in case translation for the user's preferred language is not available
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
  },
});

export default i18n;
