import { useEffect, useState } from "react";
import { useTokenLogin } from "./useTokenLogin";
import { biometricAuthenticateUser } from "../../Services/NativeBridgeEventsService";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import { AxiosInitializer } from "../../AxiosInitializer";
import i18n from "../../i18n";
import * as Sentry from "@sentry/browser";
import FadeInLoader from "../FadeInLoader";

interface BiometricAuthenticatorProps {
  onComplete: () => void;
  onError: () => void;
  tokens: string;
}

const BiometricAuthenticator: React.FC<BiometricAuthenticatorProps> = ({ onComplete, onError, tokens }) => {
  const { user, behaviorContract, scheduledEventsStore } = useClearMindContext();
  const [loginTokens, setLoginTokens] = useState<any>(undefined);
  const { error, data } = useTokenLogin(loginTokens);
  
  useEffect(() => {
    biometricAuthenticateUser().then((authenticated: any) => {
      if (authenticated) {
        setLoginTokens(JSON.parse(tokens));
      } else {
        onError();
      }
    });
  }, [onError, tokens]);

  if (data) {
    AxiosInitializer()
      .then(() => {
        return user.load();
      })
      .then(() => {
        Sentry.setUser({ id: user.userId, email: user.email });
        i18n.changeLanguage(user.language || "en");
        if (user.isMember) {
          return Promise.all([behaviorContract.load(), behaviorContract.loadDefaultQuestions(user), scheduledEventsStore.load(user.userId || "")]).then(() => console.log("done"));
        } else {
          return Promise.all([behaviorContract.load(), behaviorContract.loadDefaultQuestions(user)]).then(() => {
            scheduledEventsStore.load(behaviorContract.member?.userId || "");
          });
        }
      })
      .then(() => {
        user.setUserInitialized(true);
        onComplete();
      });
  }

  if (error) {
    onError();
    onComplete();
  }

  return <FadeInLoader />;
};

export default BiometricAuthenticator;
