import { observer } from 'mobx-react-lite';
import React from "react";
import { Header, Segment, Checkbox } from "semantic-ui-react";
import { useClearMindContext } from "../../ClearMindContext/ClearMindContext";
import ClearMindButton from "../Controls/ClearMindButton/ClearMindButton";
import FrostedFrame from "../Controls/FrostedFrame/FrostedFrame";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { sendCameraPermissionCheckEvent } from "../../Services/NativeBridgeEventsService";
import { useTranslation } from "react-i18next";
import { GetFacilityPartner } from '../../Services/FacilityPartnerService';

const LegalDisclaimer: React.FC = () => {
  const { testingService, user, behaviorContract } = useClearMindContext();
  const { promiseInProgress } = usePromiseTracker();
  const { t } = useTranslation();

  if (!testingService.testSession) {
    testingService.initializeNewTest(user.userId || "", user.facilityCode || []);
  }

  const handleNext = async () => {
    sendCameraPermissionCheckEvent();
    if(user.facilityCode.length > 0){
      user.facilityCode.forEach(async (code)=>{
        await GetFacilityPartner(code).then((partner: any) => {
          if(partner.some((partner: any) => partner.governmentIdRequired === true)){
            testingService.testSession?.setGovernmentIdRequired(true)
          }
        });
      });
    }
    await trackPromise(testingService.testSession?.post() || Promise.resolve());
    testingService.onLegalDisclaimerComplete(behaviorContract.isNewMember);
  };
  
  const styles = {
    bold: {
      fontWeight: "bold",
    },
    textBody: {
      textAlign: "justify",
      textJustify: "inter-word",
    },
    text: {
      color: "#333",
    },
    header: {
      color: "var(--secondary-color)",
    },
  };

  return (
    <FrostedFrame>
      <Segment basic style={styles.textBody}>
        <Header style={styles.header} textAlign="center" as="h3">
          {t("testing.legalDisclaimer.header")}
        </Header>
        <p style={styles.text}>
          <span style={styles.bold}>{t("testing.legalDisclaimer.servicesUse")}</span>
          <br /> <br />
          {t("testing.legalDisclaimer.notResponsible")}
        </p>
        <br />
        <Segment basic textAlign="center">
          <Checkbox
            label={<label style={styles.text}>{t("testing.legalDisclaimer.accept")}</label>}
            onChange={(_, d) => {
              testingService.testSession?.setLegalDisclaimerAgreement(d.checked!);
            }}
          />
          <br /> <br /> <br />
          <ClearMindButton opaque loading={promiseInProgress} disabled={!testingService.testSession?.legalDisclaimerAgreement} onClick={handleNext}>
          {t("testing.legalDisclaimer.continue")}
          </ClearMindButton>
        </Segment>
      </Segment>
    </FrostedFrame>
  );
};

export default observer(LegalDisclaimer);

